import React, { Component } from "react";
import { Button, Card, CardContent, CardHeader, Box, TextField, withStyles, Typography, Grid } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { withSnackbar } from "notistack";
import { Link } from "react-router-dom";

import UserService from "../services/UserService";
import CompanyService from "../services/CompanyService";
import ValidatorService from "../services/ValidatorService";
import NotFoundPage from "./NotFoundPage";
import { privateRoutes } from "../routes";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  wrapper: {
    marginTop: theme.spacing(3)
  },
  cardheader: {
    paddingBottom: 0
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    marginTop: theme.spacing()
  },
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    border: "1px solid #e0e0e0",
    borderRadius: "4px"
  }
});

@inject("sessionStore")
@observer
class PaymentPage extends Component {
  constructor(props) {
    super(props);
    const { company } = props.sessionStore.user;
    company.iban = company.iban || "";
    company.bic = company.bic || "";
    this.state = { company };
  }

  componentDidMount() {
    const { sessionStore } = this.props;
    if (!sessionStore.user.company) {
      UserService.me().then(response => {
        const { company } = response;
        company.iban = company.iban || "";
        company.bic = company.bic || "";
        this.setState({ company });
      });
    }
  }

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    const { company } = this.state;
    this.setState({ company: { ...company, [name]: value } });
  };

  handleSubmit = () => {
    const { company } = this.state;
    const { sessionStore } = this.props;
    CompanyService.patchCompany(company)
      .then(() => {
        sessionStore.user.company = company;
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Les paramètres ont été mis à jour", {
          variant: "success"
        });
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue pendant la mise à jour des paramètres", {
          variant: "error"
        });
      });
  };

  render() {
    const { classes, sessionStore } = this.props;
    const { company } = this.state;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["SETTING_WRITE", "SETTING_READ"],
        aclType: "oneof",
        requiredFeatures: ["SETTING"]
      })
    ) {
      return <NotFoundPage />;
    }

    const isSubmissible =
      ValidatorService.validateIBAN(company.iban, { allowEmpty: true }) &&
      ValidatorService.validateBic(company.bic, { allowEmpty: true });

    const canEdit = sessionStore.userHasAccess({
      requiredAcls: ["SETTING_WRITE"],
      requiredFeatures: ["SETTING"]
    });

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Paiement</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Card className={classes.wrapper}>
              <CardHeader
                title="Code BIC et IBAN"
                className={classes.cardheader}
                subheader="Ils seront affichés en pied de page avec les informations de l'entreprise"
              />
              <CardContent>
                <TextField
                  className={classes.input}
                  name="iban"
                  label="IBAN"
                  placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={company.iban.length > 0 && !ValidatorService.validateIBAN(company.iban, { allowEmpty: true })}
                  value={company.iban}
                  onChange={this.onChange}
                  disabled={!canEdit}
                />
                <TextField
                  className={classes.input}
                  name="bic"
                  label="Code BIC"
                  placeholder="XXXXXXXXXXX"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={company.bic.length > 0 && !ValidatorService.validateBic(company.bic, { allowEmpty: true })}
                  value={company.bic}
                  onChange={this.onChange}
                  disabled={!canEdit}
                />
                {canEdit && (
                  <div className="react-draft-button">
                    <div>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                        disabled={!isSubmissible}
                      >
                        Enregistrer les paramètres
                      </Button>
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
            <Card className={classes.wrapper}>
              <CardHeader
                title="Modalités de paiement"
                className={classes.cardheader}
                subheader="Les modalités par défaut seront utilisées si vous ne les précisez pas pour un type de document"
              />
              <CardContent>
                <Box className={classes.cardContainer}>
                  <Box className={classes.card}>
                    <Box style={{ flex: 1 }}>
                      <Typography variant="h6">Par défaut</Typography>
                      <Typography variant="body2">Vous pouvez définir ici les textes et valeurs par défaut</Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={{ pathname: privateRoutes.admin.settings.paymentSettings.path }}
                    >
                      Modifier
                    </Button>
                  </Box>
                  <Box className={classes.card}>
                    <Box style={{ flex: 1 }}>
                      <Typography variant="h6">Bons de commande</Typography>
                      <Typography variant="body2">Spécifiez les textes et valeurs pour les bons de commande</Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={{
                        pathname: privateRoutes.admin.settings.paymentVariant.path.replace(":variantType", "ORDER")
                      }}
                    >
                      Modifier
                    </Button>
                  </Box>
                  <Box className={classes.card}>
                    <Box style={{ flex: 1 }}>
                      <Typography variant="h6">Devis</Typography>
                      <Typography variant="body2">Spécifiez les textes et valeurs pour les devis</Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={{
                        pathname: privateRoutes.admin.settings.paymentVariant.path.replace(":variantType", "ESTIMATE")
                      }}
                    >
                      Modifier
                    </Button>
                  </Box>
                  <Box className={classes.card}>
                    <Box style={{ flex: 1 }}>
                      <Typography variant="h6">Factures</Typography>
                      <Typography variant="body2">Spécifiez les textes et valeurs pour les factures</Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={{
                        pathname: privateRoutes.admin.settings.paymentVariant.path.replace(":variantType", "INVOICE")
                      }}
                    >
                      Modifier
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(PaymentPage));
