const validateFrenchBankAccount = bankAccount => {
  const normalized = Array.from(bankAccount);
  for (let index = 0; index < bankAccount.length; index += 1) {
    const c = normalized[index].charCodeAt(0);
    if (c >= 65) {
      switch (c) {
        case 65:
        case 74:
          normalized[index] = "1";
          break;
        case 66:
        case 75:
        case 83:
          normalized[index] = "2";
          break;
        case 67:
        case 76:
        case 84:
          normalized[index] = "3";
          break;
        case 68:
        case 77:
        case 85:
          normalized[index] = "4";
          break;
        case 69:
        case 78:
        case 86:
          normalized[index] = "5";
          break;
        case 70:
        case 79:
        case 87:
          normalized[index] = "6";
          break;
        case 71:
        case 80:
        case 88:
          normalized[index] = "7";
          break;
        case 72:
        case 81:
        case 89:
          normalized[index] = "8";
          break;
        case 73:
        case 82:
        case 90:
          normalized[index] = "9";
          break;
        default:
          break;
      }
    }
  }
  let joinedNormalized = normalized.join("");
  while (joinedNormalized.length > 2) {
    const part = joinedNormalized.slice(0, 6);
    const partInt = parseInt(part, 10);
    if (Number.isNaN(partInt)) {
      return false;
    }
    joinedNormalized = (partInt % 97) + joinedNormalized.slice(part.length);
  }
  return parseInt(joinedNormalized, 10) % 97 === 0;
};

const IBAN_VALIDATION = [
  {
    code: "DE",
    length: 22,
    validateBankAccount: bankAccount => {
      const regBankAccount = /^[0-9]{18}$/;
      if (regBankAccount.test(bankAccount)) {
        return true;
      }
      return false;
    }
  },
  {
    code: "FR",
    length: 27,
    validateBankAccount: bankAccount => {
      const regBankAccount = /^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$/;
      if (regBankAccount.test(bankAccount)) {
        return validateFrenchBankAccount(bankAccount);
      }
      return false;
    }
  }
];

const COUNTRIES = IBAN_VALIDATION.map(country => country.code);

class ValidatorService {
  static validateEmail = email => {
    const reg = /\S+@\S+\.\S{2,}/;
    return reg.test(email);
  };

  static validatePhone = phone => {
    const reg = /^(0|\+[0-9]{2}){1}[0-9]{9}$/g;
    return reg.test(phone.replace(/[-\s.]+/g, ""));
  };

  static validateMobilePhone = phone => {
    const reg = /^(0|\+[0-9]{2}){1}(6|7){1}[0-9]{8}$/g;
    return reg.test(phone.replace(/[-\s.]+/g, ""));
  };

  static validateIBAN = (iban, { allowEmpty } = {}) => {
    if (!iban && allowEmpty) {
      return true;
    }
    if (!iban) {
      return false;
    }
    const _iban = iban.replace(/[-\s.]+/g, "").toUpperCase();
    const ibanCountry = _iban.slice(0, 2);
    if (!COUNTRIES.includes(ibanCountry)) {
      return false;
    }
    const ibanSpecifications = IBAN_VALIDATION.find(country => country.code === ibanCountry);
    if (!ibanSpecifications) {
      return false;
    }
    if (ibanSpecifications.length !== _iban.length) {
      return false;
    }
    const ibanIdentifier = _iban.slice(0, 4);
    if (!/^[A-Z]{2}[0-9]{2}$/.test(ibanIdentifier)) {
      return false;
    }
    const bankAccount = _iban.slice(4);
    if (!bankAccount) {
      return false;
    }
    if (!ibanSpecifications.validateBankAccount(bankAccount)) {
      return false;
    }
    return true;
  };

  static validateBic = (bic, { allowEmpty } = {}) => {
    if (!bic && allowEmpty) {
      return true;
    }
    if (!bic) {
      return false;
    }
    const _bic = bic.replace(/[-\s.]+/g, "").toUpperCase();
    const reg = /^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/;
    const bicCountry = _bic.slice(4, 6);
    if (COUNTRIES.includes(bicCountry)) {
      return reg.test(_bic);
    }
    return false;
  };
}

export default ValidatorService;
