import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";

import AddUserForm from "../../components/form/AddUserForm";
import AdminBreadcrumb from "../../components/admin/AdminBreadcrumb";
import { superAdmin } from "../../routes";
import AdminService from "../../services/AdminService";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  }
}));

function SuperAdminUserPage({ match, history }) {
  const [loading, setLoading] = useState(Boolean(match && match.params && match.params.companyId));
  const [company, setCompany] = useState({});
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (loading) {
      AdminService.getCompany(match.params.companyId)
        .then(res => {
          setCompany(res.content);
          setLoading(false);
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la récupération des informations", { variant: "error" });
        });
    }
  }, []);

  const handleRedirection = () => {
    if (company.id) {
      history.push(`/superbg/entreprises/details/${company.id}`);
    } else {
      history.push("/superbg/utilisateurs");
    }
  };

  return (
    <div>
      <AdminBreadcrumb routes={superAdmin} match={match} />
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Informations de l&apos;utilisateur</Typography>
        </Grid>
        <Grid item xs={12}>
          {loading ? <CircularProgress /> : <AddUserForm company={company} onSuccess={handleRedirection} />}
        </Grid>
      </Grid>
    </div>
  );
}

export default SuperAdminUserPage;
