import React, { useCallback, useMemo } from "react";
import {
  Card,
  CardActions,
  Button,
  CardContent,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { isValidPhoneNumber } from "libphonenumber-js";

import ValidatorService from "../../services/ValidatorService";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";

function SignatureSignatory({ contact, onClick, onDelete, index, updateContact, sessionStore }) {
  const providerIsYouSign = sessionStore.user.company.signatureProvider === "YOUSIGN";

  const canOtp = useMemo(() => {
    if (!contact.phone) return false;
    if (providerIsYouSign) {
      return isValidPhoneNumber(contact.phone, "FR");
    }
    return ValidatorService.validateMobilePhone(contact.phone);
  }, [contact]);

  const handleUpdate = useCallback(() => {
    onClick(contact, index);
  }, [onClick, contact, index]);

  const handleDelete = useCallback(() => {
    onDelete(index);
  }, [onDelete, index]);

  const handleChange = e => {
    updateContact({ ...contact, signatureType: e.target.value });
  };

  return (
    <ConfirmationDialog situationText="Êtes-vous sûr de vouloir supprimer ce signataire ?">
      {confirmBeforeAct => (
        <Card>
          <CardContent>
            <Typography variant="h5">
              Signataire #{index + 1} - {contact.firstname} {contact.lastname}
            </Typography>
            {providerIsYouSign ? (
              <FormControl style={{ marginTop: 16 }} component="fieldset">
                <FormLabel component="legend">Niveau de signature</FormLabel>
                <RadioGroup
                  aria-label="clientSignatureType"
                  name={`clientSignatureType-${index}`}
                  value={contact.signatureType}
                  onChange={handleChange}
                >
                  <FormControlLabel value="no_otp" control={<Radio color="primary" />} label="Manuscrite" />
                  <FormControlLabel
                    value="otp_email"
                    control={<Radio color="primary" />}
                    label="Avec validation par code email"
                  />
                  <FormControlLabel
                    value="otp_sms"
                    control={<Radio color="primary" />}
                    label="Avec validation par code SMS"
                  />
                </RadioGroup>
              </FormControl>
            ) : (
              <Typography variant="subtitle1" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                {canOtp ? `Signature SMS possible : ${contact.phone}` : "Non éligible à la signature SMS"}
              </Typography>
            )}
          </CardContent>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Button
              style={{ color: "#F40B27" }}
              onClick={e => stopPropagationForConfirmation(e, confirmBeforeAct(() => handleDelete()))}
            >
              Supprimer
            </Button>
            <Button variant="outlined" color="primary" onClick={handleUpdate}>
              Modifier
            </Button>
          </CardActions>
        </Card>
      )}
    </ConfirmationDialog>
  );
}

export default inject("sessionStore")(observer(props => <SignatureSignatory {...props} />));
