import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withStyles, Fab } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";

import { privateRoutes } from "../routes";
import OrderFormList from "../components/orderForm/OrderFormList";
import NotFoundPage from "./NotFoundPage";

const styles = theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
});

@inject("sessionStore")
@observer
class OrderFormPage extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const filter = {};
    if (location.state && location.state.from) {
      filter.from = moment(location.state.from);
    }
    if (location.state && location.state.to) {
      filter.to = moment(location.state.to);
    }
    if (location.state && location.state.client) {
      filter.client = location.state.client;
    }
    this.state = {
      filter
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.replace({ state: null });
  }

  render() {
    const { classes, sessionStore } = this.props;
    const { filter } = this.state;
    const { CGV, withdrawal } = sessionStore.user.company;
    const isAccountValid =
      sessionStore.siggnedIn && CGV !== null && CGV !== undefined && withdrawal !== null && withdrawal !== undefined;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["ORDER_MY", "ORDER_READ"],
        aclType: "oneof",
        requiredFeatures: ["ORDER"]
      })
    )
      return <NotFoundPage />;

    return (
      <Fragment>
        <OrderFormList title="Bons de commande" filter={filter} />
        {isAccountValid &&
          sessionStore.userHasAccess({
            requiredAcls: ["ORDER_MY"],
            requiredFeatures: ["ORDER"]
          }) && (
            <Fab
              color="primary"
              aria-label="add"
              className={classes.fab}
              component={Link}
              to={privateRoutes.common.orderForms.creationForm.path}
            >
              <AddIcon />
            </Fab>
          )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(OrderFormPage);
