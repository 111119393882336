import { Alert } from "@material-ui/lab";
import Decimal from "decimal.js-light";
import React from "react";

export default function FranfinanceDataDeltaAlert({
  financingForm,
  amount,
  totalDeposit,
  scaleCode,
  equipmentCode,
  dueNumber,
  insuranceType
}) {
  const hasInput =
    financingForm._franfinance &&
    financingForm._franfinance.simulationResult &&
    financingForm._franfinance.simulationResult._input;
  if (!hasInput) return null;

  const dataInput = { ...financingForm._franfinance.simulationResult._input };

  if (
    new Decimal(dataInput.askedAmount)
      .toDecimalPlaces(3, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(3) !==
      new Decimal(amount)
        .toDecimalPlaces(3, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(3) ||
    new Decimal(dataInput.askedDeposit)
      .toDecimalPlaces(3, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(3) !==
      new Decimal(totalDeposit)
        .toDecimalPlaces(3, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(3) ||
    dataInput.scaleCode !== scaleCode ||
    dataInput.equipmentCode !== equipmentCode ||
    dataInput.askedDueNumber !== dueNumber ||
    dataInput.askedInsuranceType !== insuranceType
  ) {
    return (
      <Alert severity="info" style={{ marginTop: 6 }}>
        Les données diffèrent de celles utilisées pour la simulation
      </Alert>
    );
  }

  return null;
}
