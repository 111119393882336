import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import CompanyService from "../../services/CompanyService";
import ManageUserFeaturesForm from "../form/ManageUserFeaturesForm";

export default function ManageUserFeaturesDialog({ user, open, onClose, onUpdateSuccess, onUpdateFail }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [acls, setAcls] = useState(null);
  const [profile, setProfile] = useState("NONE");

  useEffect(() => {
    if (open && user) {
      setAcls(user.acl || []);
      const _profile = user.profil ? user.profil.id : "NONE";
      setProfile(_profile);
    }
  }, [user, open]);

  const updateProfile = async () => {
    const res = await CompanyService.setUserProfile(user.id, profile === "NONE" ? null : profile)
      .then(() => true)
      .catch(() => false);
    return res;
  };

  const updateAcls = async () => {
    const res = await CompanyService.setUserAcls(user.id, acls)
      .then(() => true)
      .catch(() => false);
    return res;
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    let updated;
    updated = await updateProfile();
    if (profile === "NONE" && updated) {
      updated = await updateAcls();
    }
    setIsProcessing(false);
    if (updated) {
      onUpdateSuccess();
    } else {
      onUpdateFail();
    }
  };

  if (user) {
    const disableSubmit = isProcessing || (profile === "NONE" && ((acls && acls.length === 0) || acls === null));
    return (
      <Dialog
        modal="false"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Gestion des droits - {user.firstname} {user.lastname}
        </DialogTitle>
        <DialogContent style={{ marginBottom: 8 }}>
          <ManageUserFeaturesForm acls={acls} setAcls={setAcls} profile={profile} setProfile={setProfile} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Annuler
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            autoFocus
            disabled={disableSubmit}
            style={{ position: "relative" }}
          >
            {isProcessing && (
              <div
                style={{ position: "absolute", top: "50%", marginTop: -10, marginLeft: -10, left: "50%", zIndex: 2 }}
              >
                <CircularProgress size={20} />
              </div>
            )}
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return <div />;
}
