import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import React from "react";

const TYPES = [
  {
    label: "Manuscrite : vous signez en face à face avec le(s) signataire(s)",
    value: "PAD"
  },
  {
    label:
      "SMS : le(s) signataire(s) recevront un mail avec un lien où ils seront invités code à renseigner pour valider leur signature",
    value: "OTP"
  }
];

export default function SignatureTypeSelection({ availablesTypes, selectedType, onChange }) {
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <Box>
      <Typography variant="body1">Quel type de signature souhaitez-vous mettre en place :</Typography>
      <Box style={{ paddingLeft: 8, paddingTop: 8, paddingBottom: 8 }}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="signatureType"
            value={selectedType}
            onChange={handleChange}
            name="radio-buttons-group"
          >
            {TYPES.map(signType => (
              <FormControlLabel
                key={signType.value}
                value={signType.value}
                control={<Radio disabled={!availablesTypes.includes(signType.value)} />}
                label={signType.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
