import React, { useEffect, useState, Fragment } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import DoneIcon from "@material-ui/icons/Done";

import ValidatorService from "../../services/ValidatorService";
import ClientService from "../../services/ClientService";
import EstimateService from "../../services/EstimateService";

const useStyles = makeStyles(theme => ({
  errorTxt: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(2)
  },
  details: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
    opacity: 0.8
  },
  backDrop: {
    backdropFilter: "blur(7px)",
    backgroundColor: "rgba(0,0,30,0.4)"
  },
  successTitle: { margin: theme.spacing(1, 0) },
  successContainer: {
    textAlign: "center",
    padding: theme.spacing(3, 2),
    margin: theme.spacing(2)
  }
}));

function MobileConfirmationDialog({ estimate, open, onConfirm, onClose }) {
  const [showDialog, setShowDialog] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorTxt, setErrorTxt] = useState("");
  const [consentMail, setConsentMail] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  useEffect(() => {
    if (estimate && open) {
      setPhone(estimate.contact.phone || "");
      setShowDialog(true);
      setIsProcessing(false);
      setErrorTxt("");
    }
    if (estimate === null && open === false) {
      setIsProcessing(false);
      setShowDialog(false);
    }
  }, [open, estimate]);

  useEffect(() => {
    setIsPhoneValid(ValidatorService.validateMobilePhone(phone));
  }, [phone]);

  const handleClose = () => {
    setShowDialog(false);
    setConsentMail(false);
    onClose();
    setIsSuccess(false);
  };
  const handleChange = e => {
    setPhone(e.target.value);
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    if (phone !== estimate.contact.phone) {
      const updated = await ClientService.patchClient({ id: estimate.contact.id, phone })
        .then(() => true)
        .catch(() => false);
      if (!updated) {
        setErrorTxt("Une erreur interne est survenue, réessayer ultérieurement");
      }
    }
    await EstimateService.sendForSignature(estimate)
      .then(() => {
        onConfirm();
        setIsSuccess(true);
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de l'envoi pour signature du devis", { variant: "error" });
      });
    setIsProcessing(false);
  };

  const toggleConsent = () => {
    setConsentMail(curr => !curr);
  };

  if (!estimate) return null;

  return (
    <Dialog fullWidth maxWidth="sm" open={showDialog} BackdropProps={{ classes: { root: classes.backDrop } }}>
      <DialogTitle>Confirmation de l&apos;envoi pour signature</DialogTitle>
      {isSuccess ? (
        <Fragment>
          <DialogContent className={classes.successContainer}>
            <DoneIcon fontSize="large" style={{ fontSize: 50 }} />
            <Typography variant="h4" className={classes.successTitle}>
              Félicitations
            </Typography>
            <Typography variant="body1">Vous venez d&apos;envoyer votre devis pour signature !</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Fermer
            </Button>
          </DialogActions>
        </Fragment>
      ) : (
        <Fragment>
          <DialogContent>
            <Typography variant="subtitle1">
              Afin d&apos;envoyer pour signature le devis, veuillez confirmer le numéro de téléphone de votre client.
            </Typography>
            <Typography variant="body2" className={classes.details}>
              Le numéro doit être au format numéro de téléphone mobile pour être valide
            </Typography>
            <TextField
              autoFocus
              fullWidth
              label="Téléphone mobile"
              name="phone"
              onChange={handleChange}
              placeholder="Téléphone mobile"
              value={phone}
              variant="outlined"
              error={!isPhoneValid}
              helperText={!isPhoneValid && "Le numéro renseigné n'est pas valide"}
            />
            {errorTxt && (
              <Typography className={classes.errorTxt} variant="body2">
                {errorTxt}
              </Typography>
            )}
            <FormControlLabel
              style={{ marginTop: 20 }}
              control={<Checkbox checked={consentMail} name="show-deleted" color="primary" onChange={toggleConsent} />}
              label={
                <Typography>
                  Je soussigné(e) {estimate.contact.firstname} {estimate.contact.lastname} reconnais avoir expressément
                  donné mon accord afin de recevoir uniquement par mail mes documents contractuels de vente – devis, et
                  conditions générales de vente -.
                </Typography>
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" disabled={isProcessing}>
              Annuler
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              disabled={!isPhoneValid || isProcessing || !consentMail}
            >
              Envoyer pour signature
            </Button>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
}

export default MobileConfirmationDialog;
