import React, { useEffect, useRef, useState } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";

import CompanyLightRow from "./CompanyLightRow";
import AdminService from "../../../services/AdminService";

export default function CompaniesListLight({ compact, handleSubmit }) {
  const [focusedCompany, setFocusedCompany] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastPageFetched, setLastPageFetched] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [q, setQ] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const listEnd = useRef(null);

  const fetchCompanies = (resetState = false) => {
    if (!isRequesting) {
      setIsRequesting(true);
      AdminService.getAllCompanies({ page: lastPageFetched > 0 ? lastPageFetched + 1 : 0, filter: { q } })
        .then(res => {
          if (resetState || companies === null) {
            setCompanies([...res.content]);
          } else {
            setCompanies([...companies, ...res.content]);
          }
          setIsRequesting(false);
          setIsLoadingMore(false);
          setLastPageFetched(lastPageFetched ? lastPageFetched + 1 : 0);
          setTotalCount(res.totalCount);
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la récupération des entreprises", {
            variant: "error"
          });
          setCompanies([]);
          setIsRequesting(false);
        });
    }
  };

  useEffect(() => {
    fetchCompanies(true);
  }, [q]);

  const isInViewport = (offset = 0) => {
    if (!listEnd) return false;
    const bouncingC = listEnd.current.getBoundingClientRect();
    return bouncingC.top + offset >= 0 && bouncingC.top - offset <= window.innerHeight;
  };

  const handleScroll = () => {
    if (isInViewport() && !isRequesting && totalCount > companies.length) {
      setIsLoadingMore(true);
      fetchCompanies();
    }
  };

  const handleQueryChange = e => {
    const {
      target: { value }
    } = e;
    setLastPageFetched(null);
    setQ(value);
  };

  const handleCompanyFocus = companyId => {
    setFocusedCompany(companyId === focusedCompany ? null : companyId);
  };

  return (
    <div>
      {companies || q ? (
        <div>
          {companies.length >= 0 ? (
            <div>
              <TextField
                style={{ marginTop: compact ? 5 : 30, marginBottom: compact ? 5 : 30 }}
                onClick={e => e.stopPropagation()}
                name="qClients"
                placeholder="Recherche"
                label="Recherche"
                margin="normal"
                fullWidth
                value={q}
                autoComplete="off"
                variant="outlined"
                onChange={handleQueryChange}
              />
              <div
                style={{ height: compact ? "50vh" : "45vh", overflowX: "hidden", overflowY: "auto" }}
                onScroll={handleScroll}
              >
                {companies.length > 0 ? (
                  companies.map(company => (
                    <CompanyLightRow
                      company={company}
                      key={company.id}
                      showDetails={focusedCompany === company.id}
                      handleShowDetails={handleCompanyFocus}
                      handleSelect={arg => {
                        handleSubmit({ ...arg });
                      }}
                    />
                  ))
                ) : (
                  <div>Aucun résultat pour la recherche</div>
                )}
                <div ref={listEnd}>
                  {isLoadingMore && (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                      <CircularProgress size={40} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p>Aucune entreprise</p>
            </div>
          )}
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
          <CircularProgress size={80} />
        </div>
      )}
    </div>
  );
}
