import React from "react";
import DrawMeAUnicorn from "drawmeaunicorn";
import { AppBar, Dialog, IconButton, Toolbar, Typography, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionUp } from "../utils/transitions/TransitionUp";

const styles = () => ({
  appBar: {
    position: "relative"
  },
  drawerContainer: {
    position: "relative",
    flex: 1
  }
});

function DrawingTable({ backgroundImg, classes, handleClose, onSave, open, title }) {
  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={TransitionUp}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit">
            {title || "Schéma"}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.drawerContainer}>
        <DrawMeAUnicorn onSave={onSave} backgroundImg={backgroundImg} />
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(DrawingTable);
