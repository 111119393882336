import React, { useState, useEffect, Fragment } from "react";
import {
  MenuItem,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Grid,
  TablePagination,
  Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";

import AdminService from "../../../services/AdminService";
import TableAction from "../../TableAction";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../../dialog/ConfirmationDialog";
import UsersListFilter, { defaultListFilter } from "./UsersListFilter";
import TableSortableHeader from "../../table/TableSortableHeader";
import CookieService from "../../../services/CookieService";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
}));

function UsersList({ defaultFilter = {}, disabledFilters = false }) {
  const [currentSort, setCurrentSort] = useState();
  const [users, setUsers] = useState(null);
  const [usersCount, setUsersCount] = useState(0);
  const [menuFor, setMenuFor] = useState();
  const [filter, setFilter] = useState({ ...defaultListFilter, ...defaultFilter });
  const [currentPage, setCurrentPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const fetchUsers = () => {
    setUsers(null);
    setUsersCount(0);
    AdminService.getAllUsers({
      page: currentPage,
      filter: { ...filter },
      sort: currentSort ? currentSort.column : undefined,
      order: currentSort ? currentSort.order : undefined
    })
      .then(resp => {
        setUsersCount(resp.totalCount);
        setUsers(resp.content);
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la récupération des utilisateurs", { variant: "error" });
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage, filter, currentSort]);

  const deleteUser = user => {
    AdminService.deleteUser(user.id)
      .then(() => {
        fetchUsers();
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la suppression de l'utilisateur", { variant: "error" });
      });
  };

  const toggleUserAdminStatus = user => {
    const patchedUser = { ...user, isAdminCompany: !user.isAdminCompany };
    AdminService.patchUser(patchedUser)
      .then(() => {
        fetchUsers();
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors du changement de statut de l'utilisateur", { variant: "error" });
      });
  };

  const updateFilter = async _filter => {
    await setFilter({ ..._filter });
  };

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  const handleSort = arg => {
    setCurrentSort(arg);
  };

  const logAs = userId => {
    AdminService.logAs(userId)
      .then(resp => {
        CookieService.setCookie("logAs", resp.token, 1);
        window.location.replace("/");
      })
      .catch(resp => {
        enqueueSnackbar(resp.data.error, { variant: "error" });
      });
  };

  return (
    <Grid container>
      {!disabledFilters && (
        <Grid item xs={12}>
          <UsersListFilter
            filter={filter}
            updateFilter={updateFilter}
            onSubmit={fetchUsers}
            defaultFilter={{ ...defaultListFilter, ...defaultFilter }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  {!defaultFilter.companyId && (
                    <Fragment>
                      <TableSortableHeader
                        onClick={handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="company.name"
                      >
                        Entreprise
                      </TableSortableHeader>{" "}
                      /{" "}
                    </Fragment>
                  )}
                  <TableSortableHeader
                    onClick={handleSort}
                    className={classes.sortableHeader}
                    currentSort={currentSort}
                    attribute="user.firstname"
                  >
                    Prénom
                  </TableSortableHeader>{" "}
                  /{" "}
                  <TableSortableHeader
                    onClick={handleSort}
                    className={classes.sortableHeader}
                    currentSort={currentSort}
                    attribute="user.lastname"
                  >
                    Nom
                  </TableSortableHeader>
                </TableCell>
                <TableCell>
                  <TableSortableHeader
                    onClick={handleSort}
                    className={classes.sortableHeader}
                    currentSort={currentSort}
                    attribute="user.email"
                  >
                    Email
                  </TableSortableHeader>
                </TableCell>
                <TableCell>Job</TableCell>
                {!filter.showDeleted && <TableCell>Admin</TableCell>}
                {!filter.showDeleted && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {users ? (
                users.length > 0 ? (
                  users.map(user => (
                    <TableRow key={user.id} selected={user.deleted} selected={user.id === menuFor}>
                      <TableCell component="th" scope="row">
                        <p>
                          {!defaultFilter.companyId && (
                            <Fragment>
                              <strong>{user.company ? user.company.name : "Pas d'entreprise"}</strong>
                              <br />
                            </Fragment>
                          )}
                          {user.firstname} {user.lastname.toUpperCase()}
                        </p>
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.job}</TableCell>
                      {!filter.showDeleted && (
                        <TableCell>
                          <Checkbox
                            checked={user.isAdminCompany}
                            color="primary"
                            onClick={() => toggleUserAdminStatus(user)}
                          />
                        </TableCell>
                      )}
                      {!filter.showDeleted && (
                        <TableCell>
                          <TableAction onOpen={() => setMenuFor(user.id)} onClose={() => setMenuFor(null)}>
                            <ConfirmationDialog>
                              {confirmBeforeAct => (
                                <MenuItem
                                  onClick={e =>
                                    stopPropagationForConfirmation(e, confirmBeforeAct(() => deleteUser(user)))
                                  }
                                >
                                  Supprimer
                                </MenuItem>
                              )}
                            </ConfirmationDialog>
                            <MenuItem onClick={() => logAs(user.id)}>Se connecter en tant que</MenuItem>
                          </TableAction>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={filter.showDeleted ? 3 : 5} align="center">
                      Aucun résultat pour la recherche
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={filter.showDeleted ? 3 : 5} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {users && usersCount > 0 && (
            <TablePagination
              component="div"
              count={usersCount}
              page={currentPage}
              rowsPerPage={50}
              rowsPerPageOptions={[50]}
              page={currentPage}
              backIconButtonProps={{ "aria-label": "Page précédente" }}
              nextIconButtonProps={{ "aria-label": "Page suivante" }}
              onChangePage={handleChangePage}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withRouter(UsersList);
