import React from "react";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";

export const SOFINCO_EQUIPMENT_CODES = {
  "292": "Chauffage climatisation",
  "291": "Cheminées atres",
  "050": "Cuisine équipée",
  "090": "Divers egm",
  "295": "Fenêtres",
  "29A": "Isolation",
  "29C": "Panneaux solaires",
  "296": "Piscine",
  "29B": "Ravalement",
  "420": "Sécurité, alarmes",
  "294": "Traitement charpente",
  "297": "Traitement de l'air",
  "298": "Traitement de l'eau",
  "299": "Véranda"
};

export default function SofincoEquipmentCodeSelect({
  value,
  onChange,
  selectProps = {},
  formControlProps = {},
  hideLabel = false
}) {
  return (
    <FormControl fullWidth variant="outlined" {...formControlProps}>
      {!hideLabel && <InputLabel>Nature de bien</InputLabel>}
      <Select
        id="equipmentCode"
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={hideLabel ? undefined : "Nature de bien"} />}
        displayEmpty
        {...selectProps}
        // renderValue={selected => (
        //   <div className={classes.chipContainer}>
        //     {selected.map(value => (
        //       <div key={value} className={classes.chip}>
        //         <EstimateStatusLabel estimate={{ status: value }} />
        //       </div>
        //     ))}
        //   </div>
        // )}
        // MenuProps={MenuProps}
      >
        <MenuItem value="" disabled>
          <em> -- Sélectionnez une nature de bien --</em>
        </MenuItem>
        {Object.entries(SOFINCO_EQUIPMENT_CODES).map(([equipmentCode, equipmentCodeLabel]) => (
          <MenuItem key={equipmentCode} value={equipmentCode}>
            {equipmentCodeLabel} ({equipmentCode})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
