export const isTouchScreenDevice = () => {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
};

export const sortInvoiceByAttribute = (invoices, attribute) =>
  invoices.sort((a, b) => {
    if (a[attribute] > b[attribute]) return -1;
    if (a[attribute] < b[attribute]) return 1;
    return 0;
  });
