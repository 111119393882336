import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useSnackbar } from "notistack";

import FranfinanceLogo from "../../estimate/complementaryStepForms/franfinance.png";
import SofincoLogo from "../../estimate/complementaryStepForms/sofinco.svg";
import AdminService from "../../../services/AdminService";
import SofincoEquipmentCodeSelect from "../../financing/SofincoEquipmentCodeSelect";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block"
  },
  card: { height: "100%" },
  row: {
    display: "flex",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  rowPicto: {
    flexBasis: 40,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  companyItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  }
}));

export default function CompanyFinancing({ company, onPatch }) {
  const classes = useStyles();
  const [franfinanceToken, setFranfinanceToken] = React.useState(company.franfinanceToken);
  const [brandStructure, setBrandStructure] = React.useState(company.brandStructure);
  const [equipmentCode, setEquipmentCode] = React.useState(company.equipmentCode);
  const { enqueueSnackbar } = useSnackbar();

  const handleReset = () => {
    setFranfinanceToken(company.franfinanceToken);
    setBrandStructure(company.brandStructure);
    setEquipmentCode(company.equipmentCode);
  };

  const handleSubmit = () => {
    AdminService.patchCompany({
      ...company,
      franfinanceToken,
      brandStructure,
      equipmentCode
    })
      .then(updatedCompany => {
        onPatch(updatedCompany);
        setFranfinanceToken(updatedCompany.franfinanceToken);
        setBrandStructure(updatedCompany.brandStructure);
        setEquipmentCode(updatedCompany.equipmentCode);
        enqueueSnackbar("L'entreprise a été mise à jour", { variant: "success" });
      })
      .catch(err => {
        console.log(" ERROR ", err);
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'entreprise", {
          variant: "error"
        });
      });
  };

  if (company.features.includes("OPTION_SOFINCO") || company.features.includes("OPTION_FRANFINANCE")) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="h3">
                Financement
              </Typography>
              {company.features.includes("OPTION_SOFINCO") && (
                <Box style={{ marginBottom: company.features.includes("OPTION_FRANFINANCE") ? 24 : 0 }}>
                  <img width={100} src={SofincoLogo} alt="Logo SOFINCO" />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Code enseigne
                  </Typography>
                  <TextField
                    size="small"
                    name="brandStructure"
                    placeholder="XX"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={brandStructure}
                    onChange={e => setBrandStructure(e.target.value)}
                  />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Nature du bien
                  </Typography>
                  <SofincoEquipmentCodeSelect
                    hideLabel
                    value={equipmentCode}
                    onChange={e => setEquipmentCode(e.target.value)}
                    selectProps={{ size: "small" }}
                    formControlProps={{ size: "small" }}
                  />
                </Box>
              )}
              {company.features.includes("OPTION_FRANFINANCE") && (
                <Box>
                  <img width={100} src={FranfinanceLogo} alt="Logo FRANFINANCE" />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Chaine d&apos;authentification
                  </Typography>
                  <TextField
                    size="small"
                    name="franfinanceToken"
                    placeholder="xxxxxxxxx"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={franfinanceToken}
                    onChange={e => setFranfinanceToken(e.target.value)}
                  />
                </Box>
              )}
            </CardContent>
            <CardActions style={{ justifyContent: "flex-end" }}>
              <Button variant="text" onClick={handleReset}>
                Annuler
              </Button>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Enregistrer
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  }
  return <div />;
}
