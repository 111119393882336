import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Grid,
  Button,
  TextField,
  MenuItem,
  Badge,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ProductService from "../../services/ProductService";

const MenuProps = { PaperProps: { style: { width: 250 } } };

const useStyles = makeStyles(theme => ({
  woMarginTop: { marginTop: 0 },
  advancedContainer: { marginTop: theme.spacing(3) },
  advancedFieldsContainer: {
    paddingBottom: theme.spacing(),
    backgroundColor: "#FCFCFC"
  },
  advancedActionContainer: { backgroundColor: "#FCFCFC" },
  advancedInput: { marginTop: theme.spacing(2) },
  advancedInputContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  expWrapper: { overflow: "hidden" },
  chip: { margin: 2 },
  chipContainer: { display: "flex", flexWrap: "wrap" }
}));

let timeoutSearch;

export default function ProductsListFilter({ filter, updateFilter, onSubmit, defaultFilter, onReset }) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    let _activeFiltersCount = 0;
    if (filter.category.length > 0) _activeFiltersCount += 1;
    setActiveFiltersCount(_activeFiltersCount);
  }, [filter]);

  const { data: productCategories } = useQuery({
    queryKey: ["company_categories"],
    queryFn: () => ProductService.getProductCategories()
  });

  const handleChange = event => {
    updateFilter({ ...filter, category: event.target.value });
  };

  const handleExpansion = (e, expanded) => {
    setIsExpanded(expanded);
  };

  const handleQueryChange = e => {
    updateFilter({ ...filter, q: e.target.value });
    clearTimeout(timeoutSearch);
    timeoutSearch = setTimeout(() => {
      onSubmit();
    }, 500);
  };

  const handleSearch = () => {
    setIsExpanded(false);
    onSubmit();
  };

  const handleResetAdvanced = async () => {
    updateFilter(defaultFilter);
    onReset();
  };

  return (
    <Grid item xs={12} className={classes.advancedContainer}>
      <ExpansionPanel onChange={handleExpansion} expanded={isExpanded} className={classes.expWrapper}>
        <ExpansionPanelSummary
          expandIcon={
            !isExpanded && activeFiltersCount > 0 ? (
              <Badge badgeContent={activeFiltersCount} color="primary">
                <ExpandMoreIcon />
              </Badge>
            ) : (
              <ExpandMoreIcon />
            )
          }
        >
          <TextField
            className={classes.woMarginTop}
            onClick={e => e.stopPropagation()}
            name="q"
            placeholder={matches ? "Recherche" : "Dénomination, description, référence"}
            label={matches ? "Recherche" : "Dénomination, description, référence"}
            margin="normal"
            fullWidth
            value={filter.q}
            onChange={handleQueryChange}
          />
        </ExpansionPanelSummary>
        {productCategories && (
          <ExpansionPanelDetails className={classes.advancedFieldsContainer}>
            <Grid container>
              <Grid item xs={12} className={classes.advancedInputContainer}>
                <FormControl fullWidth variant="outlined" className={classes.advancedInput}>
                  <InputLabel>Catégorie(s)</InputLabel>
                  <Select
                    id="product-category"
                    multiple
                    value={filter.category}
                    onChange={handleChange}
                    input={<OutlinedInput labelWidth={90} />}
                    renderValue={selected => (
                      <div className={classes.chipContainer}>
                        {selected.map(value => (
                          <div key={value} className={classes.chip}>
                            <Chip label={productCategories.content.find(item => item.id === value).name} />
                          </div>
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {productCategories.content.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={filter.category.includes(item.id)} color="primary" />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        )}
        <ExpansionPanelActions className={classes.advancedActionContainer}>
          <Button size="small" color="primary" onClick={handleResetAdvanced}>
            Réinitialiser
          </Button>
          <Button size="small" variant="contained" color="primary" onClick={handleSearch}>
            Filtrer
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    </Grid>
  );
}
