import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, Tab, Tabs, Button } from "@material-ui/core";
import { Formik } from "formik";

import ClientsListLight from "../client/ClientsListLight";
import ClientForm from "../form/ClientForm";
import { ClientSchema } from "../../services/ClientService";
import Client from "../../classes/Client";

const defaultClient = new Client();

export default function LinkClientDialog({ client, handleClose, disableCreation, clientIdDisabled }) {
  const [selectedTab, setSelectedTab] = useState(disableCreation ? "CONTACTS" : "NEWONE");
  const [initialClient, setInitialClient] = useState(client ? { ...client } : { ...defaultClient });

  const onClose = _client => {
    handleClose(_client);
  };

  const handleTabChange = (e, _selectedTab) => {
    setSelectedTab(_selectedTab);
  };

  return (
    <Dialog
      modal="false"
      open
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
    >
      <DialogTitle id="alert-dialog-title">Choix du client</DialogTitle>

      <DialogContent style={{ width: "70vw", maxWidth: 900, height: "65vh" }}>
        {!disableCreation && (
          <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
            <Tab value="NEWONE" label={initialClient.id || initialClient.firstname ? "Modification" : "Nouveau"} />
            <Tab value="CONTACTS" label="Annuaire" />
          </Tabs>
        )}
        {selectedTab === "CONTACTS" && (
          <div>
            <ClientsListLight
              handleSubmit={onClose}
              compact={disableCreation}
              clientIdDisabled={clientIdDisabled || []}
            />
          </div>
        )}
        {selectedTab === "NEWONE" && (
          <div style={{ paddingTop: 20 }}>
            <Formik
              enableReinitialize
              initialValues={initialClient}
              validationSchema={ClientSchema}
              onSubmit={values => {
                onClose({ ...values });
              }}
            >
              {formikProps => (
                <div>
                  <ClientForm
                    woDOM
                    formik={formikProps}
                    disableFirstnameInput={!!initialClient.id}
                    disableLastnameInput={!!initialClient.id}
                    disableBusinessNameInput={!!initialClient.id}
                  />
                  <div style={{ display: "flex", justifyContent: "center", marginTop: 25 }}>
                    {initialClient && initialClient.id && (
                      <Button
                        onClick={() => {
                          setInitialClient(new Client());
                        }}
                        color="primary"
                        style={{ marginRight: 15 }}
                      >
                        Supprimer
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        formikProps.handleSubmit();
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Enregistrer
                    </Button>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
