import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Select, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import ManageUserFeaturesRow from "../dialog/ManageUserFeaturesRow";
import { FEATURES } from "../../utils/matrix";
import CompanyService from "../../services/CompanyService";

const useStyles = makeStyles(theme => ({
  tab: {
    borderBottom: "1px solid #E6E6E6"
  },
  tabContent: {
    paddingTop: theme.spacing(2)
  },
  selectProfile: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  feats: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export default function ManageUserFeaturesForm({ profile, setProfile, acls, setAcls }) {
  const [value, setValue] = useState(0);
  const [focusedFeature, setFocusedFeature] = useState("");
  const [profiles, setProfiles] = useState(null);

  useEffect(() => {
    CompanyService.getProfiles().then(res => {
      setProfiles(res.content);
    });
    setValue(profile === "NONE" ? 1 : 0);
    setFocusedFeature("");
  }, []);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleProfileChange = event => {
    setProfile(event.target.value);
  };

  const toggleAcl = targettedAcl => {
    const _acls = [...acls];
    const indexOf = _acls.indexOf(targettedAcl);
    if (indexOf > -1) {
      _acls.splice(indexOf, 1);
    } else {
      _acls.push(targettedAcl);
    }
    setAcls(_acls);
  };

  const toggleFocusedFeature = feature => {
    setFocusedFeature(feature === focusedFeature ? "" : feature);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange} aria-label="Tabs sélection droits" variant="fullWidth">
          <Tab label="Profil" className={classes.tab} />
          <Tab label="Personnalisés" className={classes.tab} disabled={profile !== "NONE"} />
        </Tabs>
        <Grid container>
          <Grid item xs={12} className={classes.tabContent}>
            {value === 0 && (
              <Grid container>
                <Grid item xs={false} sm={3} />
                <Grid item xs={12} sm={6}>
                  <Select
                    value={profile}
                    onChange={handleProfileChange}
                    variant="outlined"
                    fullWidth
                    className={classes.selectProfile}
                  >
                    <MenuItem value="NONE">
                      <em>Aucun</em>
                    </MenuItem>
                    {profiles &&
                      profiles.length > 0 &&
                      profiles.map(prof => (
                        <MenuItem key={prof.id} value={prof.id}>
                          {prof.name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item xs={false} sm={3} />
              </Grid>
            )}
            {value === 1 && (
              <Grid container>
                <Grid item xs={12} className={classes.feats}>
                  {FEATURES.filter(feat => feat.hideInAcl !== true).map(feature => (
                    <ManageUserFeaturesRow
                      key={`feat-cat-${feature.key}`}
                      feature={feature}
                      acls={acls}
                      toggleAcl={toggleAcl}
                      focusedFeature={focusedFeature}
                      toggleFocusedFeature={toggleFocusedFeature}
                    />
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
