import React from "react";
import { ListItem, withStyles } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";

const styles = theme => ({
  item: {
    color: "black"
  },
  subitem: {
    paddingLeft: theme.spacing(4)
  },
  menuItemLink: {
    textDecoration: "none",
    width: 250
  },
  currentMenuItemLink: {
    textDecoration: "none",
    width: 250,
    backgroundColor: "rgba(0, 0, 0, 0.05)"
  }
});

const SimpleMenuItem = ({ item, handleToggle, location, classes, isSubItem, notExact = false }) => (
  <Link
    to={item.path}
    className={classNames(classes.menuItemLink, {
      [classes.currentMenuItemLink]: notExact
        ? location.pathname.startsWith(item.path)
        : location.pathname === item.path
    })}
    onClick={handleToggle}
  >
    <ListItem
      className={classNames(
        classes.item,
        {
          [classes.currentMenuItemLink]: notExact
            ? location.pathname.startsWith(item.path)
            : location.pathname === item.path
        },
        { [classes.subitem]: isSubItem === true }
      )}
    >
      {item.icon}
      {item.label}
      {item.suffix}
    </ListItem>
  </Link>
);

export default withRouter(withStyles(styles)(SimpleMenuItem));
