import React, { Component } from "react";
import { withStyles, AppBar, Dialog, IconButton, Toolbar, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar } from "notistack";

import ProductService from "../../services/ProductService";
import { TransitionUp } from "../utils/transitions/TransitionUp";
import AddProductCategoryForm from "../form/AddProductCategoryForm";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const DEFAULT_PRODUCT_CATEGORY = { name: "" };

class AddEditProductCategoryDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      productCategory: { ...DEFAULT_PRODUCT_CATEGORY }
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ productCategory: props.currentProductCategory || DEFAULT_PRODUCT_CATEGORY, open: props.open });
  }

  handleClose = () => {
    const { handleClose } = this.props;
    if (handleClose) {
      handleClose();
      this.setState({ productCategory: DEFAULT_PRODUCT_CATEGORY });
    } else {
      this.setState({ open: false, productCategory: DEFAULT_PRODUCT_CATEGORY });
    }
  };

  handleSubmit = category => {
    const { getAllProductCategories, onSuccess } = this.props;

    this.setState({ loading: true }, () => {
      ProductService.postProductCategory(category)
        .then(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("La catégorie a été créée", { variant: "success" });
          this.setState({ open: false, loading: false, productCategory: { ...DEFAULT_PRODUCT_CATEGORY } });
          onSuccess();
          getAllProductCategories();
        })
        .catch(error => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar(
            error.status === 400
              ? "Les informations de la catégorie sont incorrectes"
              : "Une erreur est survenue lors de la création de la catégorie",
            {
              variant: "error"
            }
          );
          this.setState({ loading: false });
        });
    });
  };

  handleEditSubmit = category => {
    const { getAllProductCategories, onSuccess } = this.props;
    this.setState({ loading: true }, () => {
      ProductService.patchProductCategory(category)
        .then(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("La catégorie a été modifiée", { variant: "success" });
          this.setState({
            open: false,
            loading: false,
            productCategory: { ...DEFAULT_PRODUCT_CATEGORY }
          });
          onSuccess();
          getAllProductCategories();
        })
        .catch(error => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar(
            error.status === 400
              ? "Les informations de la catégorie sont incorrectes"
              : "Une erreur est survenue lors de la mise à jour de la catégorie",
            {
              variant: "error"
            }
          );
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { classes } = this.props;
    const { open, loading, productCategory } = this.state;

    return (
      <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={TransitionUp}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {productCategory.id ? "Modifier la catégorie" : "Ajouter une catégorie"}
            </Typography>
          </Toolbar>
        </AppBar>
        <AddProductCategoryForm
          productCategory={productCategory}
          isSubmitting={loading}
          handleSubmitForm={productCategory.id ? this.handleEditSubmit : this.handleSubmit}
          handleCancel={this.handleClose}
        />
      </Dialog>
    );
  }
}

export default withSnackbar(withStyles(styles)(AddEditProductCategoryDialog));
