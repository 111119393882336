import React, { memo } from "react";
import AdminBreadcrumb from "../../components/admin/AdminBreadcrumb";

import ManageCompany from "../../components/admin/ManageCompany";
import { superAdmin } from "../../routes";

function SuperAdminManageCompany({ match }) {
  return (
    <div>
      <AdminBreadcrumb routes={superAdmin} match={match} />
      <ManageCompany companyId={match.params.companyId} />
    </div>
  );
}

export default memo(SuperAdminManageCompany);
