import React, { Component, Fragment } from "react";
import { Tab, Tabs, Typography, withStyles, InputAdornment, IconButton } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { inject, observer } from "mobx-react";
import moment from "moment";

import DashboardEstimates from "../components/dashboard/estimates";
import DashboardInvoices from "../components/dashboard/invoices";
import DashboardOrderForms from "../components/dashboard/orderForms";
import { MIN_DATE } from "../utils/dashboard";
import NotFoundPage from "./NotFoundPage";

@inject("sessionStore")
@observer
class DashboardPage extends Component {
  constructor(props, context) {
    super(props, context);
    let selectedTab = "";
    if (this.hasInvoice()) {
      selectedTab = "INVOICES";
    } else if (this.hasEstimate()) {
      selectedTab = "ESTIMATES";
    } else if (this.hasOrderForm()) {
      selectedTab = "ORDER_FORMS";
    }

    this.state = { selectedTab, from: moment().startOf("month"), to: moment().endOf("month") };
  }

  handleChangeFrom = newFrom => {
    this.setState({ from: newFrom });
  };

  handleChangeTo = newTo => {
    this.setState({ to: newTo });
  };

  handleChange = (e, selectedTab) => {
    this.setState({ selectedTab });
  };

  hasOrderForm = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["ORDER_READ", "ORDER_MY"],
      aclType: "oneof",
      requiredFeatures: ["ORDER"]
    });
  };

  hasEstimate = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["ESTIMATE_READ", "ESTIMATE_MY"],
      aclType: "oneof",
      requiredFeatures: ["ESTIMATE"]
    });
  };

  hasInvoice = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["INVOICE_READ", "INVOICE_MY"],
      aclType: "oneof",
      requiredFeatures: ["INVOICE"]
    });
  };

  handleDetailsNavigation = path => {
    const { history } = this.props;
    const { from, to } = this.state;
    history.push({ pathname: path, state: { from: from.format("YYYY-MM-DD"), to: to.format("YYYY-MM-DD") } });
  };

  render() {
    const { selectedTab, from, to } = this.state;
    const { classes, sessionStore } = this.props;

    if (!sessionStore.userHasAccess({ requiredAcls: ["DASHBOARD_READ"], requiredFeatures: ["DASHBOARD"] }))
      return <NotFoundPage />;

    return (
      <Fragment>
        <div className={classes.head}>
          <Typography variant="h4" component="h2" className={classes.title}>
            Tableau de bord
          </Typography>
          <div className={classes.pickersContainer}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                format="DD/MM/YYYY"
                className={classes.picker}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <EventIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                label="Du"
                minDate={MIN_DATE}
                maxDate={to}
                onChange={this.handleChangeFrom}
                value={from}
                variant="inline"
                inputVariant="standard"
              />
            </MuiPickersUtilsProvider>
            <span className={classes.separator}>-</span>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                format="DD/MM/YYYY"
                className={classes.picker}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <EventIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                label="au"
                minDate={from}
                maxDate={moment().endOf("month")}
                onChange={this.handleChangeTo}
                value={to}
                variant="inline"
                inputVariant="standard"
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <Tabs value={selectedTab} onChange={this.handleChange} variant="fullWidth" className={classes.tabscontainer}>
          {this.hasEstimate() && <Tab value="ESTIMATES" label="Devis" className={classes.tab} />}
          {this.hasOrderForm() && <Tab value="ORDER_FORMS" label="Bons de commande" wrapped className={classes.tab} />}
          {this.hasInvoice() && <Tab value="INVOICES" label="Factures" className={classes.tab} />}
        </Tabs>
        {this.hasEstimate() && selectedTab === "ESTIMATES" && (
          <DashboardEstimates from={from} to={to} handleDetailsNavigation={this.handleDetailsNavigation} />
        )}
        {this.hasOrderForm() && selectedTab === "ORDER_FORMS" && (
          <DashboardOrderForms from={from} to={to} handleDetailsNavigation={this.handleDetailsNavigation} />
        )}
        {this.hasInvoice() && selectedTab === "INVOICES" && (
          <DashboardInvoices from={from} to={to} handleDetailsNavigation={this.handleDetailsNavigation} />
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  head: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(),
    marginTop: theme.spacing()
  },
  tabscontainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tab: {
    borderBottom: "1px solid #E6E6E6"
  },
  picker: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing()
    },
    width: 160
  },
  pickersContainer: {
    display: "flex",
    alignItems: "center"
  },
  separator: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  }
});

export default withStyles(styles)(DashboardPage);
