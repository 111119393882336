import React, { Component, Fragment } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      callback: null
    };
  }

  handleClose = () => {
    this.setState({ open: false, callback: null });
  };

  handleShow = callback => () => {
    this.setState({
      open: true,
      callback: () => callback()
    });
  };

  confirm = () => {
    const { callback } = this.state;
    callback();
    this.handleClose();
  };

  render() {
    const { open } = this.state;
    const { children, confirmText, cancelText, situationText, title, hideCancel = false } = this.props;

    return (
      <Fragment>
        {children(this.handleShow)}
        <Dialog
          modal="false"
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title || "Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {situationText || "Êtes-vous sûr de vouloir effectuer cette action ?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!hideCancel && (
              <Button onClick={this.handleClose} color="primary">
                {cancelText || "Annuler"}
              </Button>
            )}
            <Button onClick={this.confirm} color="primary" autoFocus>
              {confirmText || "Oui"}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default ConfirmationDialog;

export const stopPropagationForConfirmation = (e, action) => {
  e.stopPropagation();
  action();
};
