import { useFormikContext } from "formik";
import { Dialog, DialogActions, DialogContent, Button, DialogTitle } from "@material-ui/core";
import React, { useEffect } from "react";

let uploadLogo;

export default function PictureManager({ onClose, index }) {
  const { values, setFieldValue } = useFormikContext();
  const [currentPicture, setCurrentPicture] = React.useState(null);

  useEffect(() => {
    setCurrentPicture(index !== undefined ? values.additionalInformation[index].picture || "" : null);
  }, [index]);

  const handleSubmit = () => {
    setFieldValue(`additionalInformation.${index}.picture`, currentPicture);
    onClose();
  };

  const handleChangeLogo = () => {
    const file = uploadLogo.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const baseURL = reader.result;
      setCurrentPicture(baseURL);
    };
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      modal="false"
      open={index !== undefined}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Gestion du visuel</DialogTitle>
      {index !== undefined && (
        <DialogContent>
          {currentPicture !== "" ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 8 }}>
              <img src={currentPicture} alt="currentPicture" style={{ maxWidth: 100, maxHeight: 50 }} />
              <Button color="primary" variant="outlined" onClick={() => setCurrentPicture("")}>
                Supprimer
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 8 }}>
              <input
                accept="image/*"
                id="uploadLogo"
                type="file"
                style={{ display: "none" }}
                onChange={handleChangeLogo}
                ref={ref => {
                  uploadLogo = ref;
                }}
              />
              <label htmlFor="uploadLogo">
                <Button component="span" variant="outlined" color="primary">
                  Ajouter
                </Button>
              </label>
            </div>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary" autoFocus>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}
