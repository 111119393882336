import { green, orange, red } from "@material-ui/core/colors";
import moment from "moment";

export const getColorFromInvoiceDate = nextInvoiceDate => {
  const _now = moment();
  const _next = moment(nextInvoiceDate);
  const _dRange = moment().subtract(2, "days");
  const _uRange = moment().add(2, "days");
  if (_next.isBefore(_dRange)) {
    return "red";
  }
  if (_next.isBefore(_now)) {
    return "orange";
  }
  if (_next.isBefore(_uRange)) {
    return "lightgreen";
  }
  if (nextInvoiceDate) {
    return "#43a047";
  }
  return "rgba(0, 0, 0, 0.14)";
};

export const getColorFromSignatures = (signatures, plan) => {
  const _signatures = parseInt(signatures || 0, 10);
  const _plan = parseInt(plan || 0, 10);
  if (_signatures >= 0) return green[500];
  if (Math.abs(_signatures) > _plan) return red[500];
  return orange[500];
};

export default {
  getColorFromInvoiceDate
};
