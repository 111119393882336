import React, { Fragment } from "react";
import { Button, Modal, Typography } from "@material-ui/core";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    position: "fixed",
    width: "80%",
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    backgroundColor: "#fff",
    boxShadow: "0 5px 15px rgba(0, 0, 0, .5)",
    padding: "4%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  };
}

const FeedbackModal = ({ title, description, handleCloseFeedBack, open }) => (
  <Fragment>
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleCloseFeedBack}
    >
      <div style={getModalStyle()}>
        <Typography variant="h6" id="simple-modal-title">
          {title}
        </Typography>
        <Typography variant="body1" id="simple-modal-description">
          {description}
        </Typography>
        <Button variant="contained" color="primary" style={{ alignSelf: "flex-end" }} onClick={handleCloseFeedBack}>
          OK
        </Button>
      </div>
    </Modal>
  </Fragment>
);

export default FeedbackModal;
