import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { Fragment, useEffect } from "react";
import * as yup from "yup";
import ValidatorService from "../../services/ValidatorService";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  wrapper: {
    margin: theme.spacing(),
    position: "relative"
  },
  rightIcon: {
    marginLeft: theme.spacing()
  },
  mainWrapper: {
    marginTop: theme.spacing(3)
  }
}));

const SPACING = 2;

const CompanySchema = yup.object({
  mainAddressTitle: yup.string().nullable(),
  email: yup
    .string()
    .email("Email invalide")
    .required("Champ requis"),
  phone: yup
    .mixed()
    .test("check-phone", "Format invalide", value => (value ? ValidatorService.validatePhone(value) : true))
    .required("Champ requis"),
  website: yup.string().nullable(),
  useSecondaryAddress: yup.bool(),
  secondaryAddressTitle: yup
    .string()
    .nullable()
    .when("useSecondaryAddress", {
      is: true,
      then: yup.string().required("Champ requis")
    }),
  secondaryAddressName: yup
    .string()
    .nullable()
    .when("useSecondaryAddress", {
      is: true,
      then: yup.string().required("Champ requis")
    }),
  secondaryAddress: yup
    .string()
    .nullable()
    .when("useSecondaryAddress", {
      is: true,
      then: yup.string().required("Champ requis")
    }),
  secondaryAddressZipCode: yup
    .string()
    .nullable()
    .when("useSecondaryAddress", {
      is: true,
      then: yup.string().required("Champ requis")
    }),
  secondaryAddressCity: yup
    .string()
    .nullable()
    .when("useSecondaryAddress", {
      is: true,
      then: yup.string().required("Champ requis")
    }),
  secondaryAddressEmail: yup
    .string()
    .email("Email invalide")
    .nullable()
    .when("useSecondaryAddress", {
      is: true,
      then: yup
        .string()
        .email("Email invalide")
        .required("Champ requis")
    }),
  secondaryAddressPhone: yup
    .mixed()
    .nullable()
    .test("check-phone", "Format invalide", value => (value ? ValidatorService.validatePhone(value) : true))
    .when("useSecondaryAddress", {
      is: true,
      then: yup
        .mixed()
        .test("check-phone", "Format invalide", value => (value ? ValidatorService.validatePhone(value) : true))
        .required("Champ requis")
    }),
  insuranceArtisan: yup.bool(),
  insuranceType: yup
    .string()
    .nullable()
    .when("insuranceArtisan", {
      is: true,
      then: yup.string().required("Champ requis")
    }),
  insuranceName: yup
    .string()
    .nullable()
    .when("insuranceArtisan", {
      is: true,
      then: yup.string().required("Champ requis")
    }),
  insuranceAddress: yup
    .string()
    .nullable()
    .when("insuranceArtisan", {
      is: true,
      then: yup.string().required("Champ requis")
    })
});

export default function InfoForm({ company, hasSecondary, canEdit, onSubmit }) {
  const classes = useStyles();

  const { values, handleChange, handleSubmit, errors, resetForm } = useFormik({
    initialValues: { ...company },
    validationSchema: CompanySchema,
    onSubmit: formData => {
      onSubmit(formData);
    }
  });

  useEffect(() => {
    resetForm({ values: { ...company } });
  }, [company]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h4">Général</Typography>
      </Grid>
      <Grid item xs={12} className={classes.mainWrapper}>
        <Grid container spacing={SPACING}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Informations générales" />
              <CardContent>
                <Grid container spacing={SPACING}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Forme de la société
                    </Typography>
                    <Typography variant="body1" color="textPrimary" component="p">
                      {company.status || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Nom
                    </Typography>
                    <Typography variant="body1" color="textPrimary" component="p">
                      {company.name || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Capital
                    </Typography>
                    <Typography variant="body1" color="textPrimary" component="p">
                      {company.capital || ""} €
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Numéro de TVA Intracommunautaire
                    </Typography>
                    <Typography variant="body1" color="textPrimary" component="p">
                      {company.tvaNumber || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      RCS
                    </Typography>
                    <Typography variant="body1" color="textPrimary" component="p">
                      {company.rcs || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      SIRET
                    </Typography>
                    <Typography variant="body1" color="textPrimary" component="p">
                      {company.siret || ""}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader title="Coordonnées principales de l'entreprise" />
              <CardContent>
                <Grid container spacing={SPACING}>
                  <Grid item xs={12}>
                    <TextField
                      name="mainAddressTitle"
                      placeholder="Entreprise"
                      label="Titre de l'adresse"
                      fullWidth
                      variant="outlined"
                      value={values.mainAddressTitle}
                      onChange={handleChange}
                      disabled={!canEdit}
                      helperText="Par défaut : Entreprise"
                      error={errors.mainAddressTitle && errors.mainAddressTitle.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Nom
                    </Typography>
                    <Typography variant="body1" color="textPrimary" component="p">
                      {company.name || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Adresse de l&apos;entreprise
                    </Typography>
                    <Typography variant="body1" color="textPrimary" component="p">
                      {company.address || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Code postal
                    </Typography>
                    <Typography variant="body1" color="textPrimary" component="p">
                      {company.zipCode || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Commune
                    </Typography>
                    <Typography variant="body1" color="textPrimary" component="p">
                      {company.city || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="email"
                      placeholder="john.doe@example.org"
                      label="Adresse email"
                      required
                      fullWidth
                      variant="outlined"
                      value={values.email}
                      onChange={handleChange}
                      error={!!errors.email}
                      helperText={errors.email}
                      type="email"
                      disabled={!canEdit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="phone"
                      placeholder="06XXXXXXXX"
                      label="Téléphone"
                      required
                      fullWidth
                      variant="outlined"
                      value={values.phone}
                      onChange={handleChange}
                      error={!!errors.phone}
                      helperText={errors.phone}
                      type="tel"
                      disabled={!canEdit}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="website"
                      placeholder="https://exemple.fr"
                      label="Site web de l'entreprise"
                      fullWidth
                      variant="outlined"
                      value={values.website}
                      onChange={handleChange}
                      type="url"
                      disabled={!canEdit}
                      error={!!errors.website}
                      helperText={errors.website}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {hasSecondary && (
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Coordonnées secondaires" />
                <CardContent>
                  <Grid container spacing={SPACING}>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="useSecondaryAddress"
                            checked={values.useSecondaryAddress}
                            onChange={handleChange}
                            color="primary"
                            disabled={!canEdit}
                          />
                        }
                        label="Afficher une adresse secondaire sur les documents"
                      />
                    </Grid>
                    {values.useSecondaryAddress && (
                      <Fragment>
                        <Grid item xs={12}>
                          <TextField
                            name="secondaryAddressTitle"
                            placeholder="Entreprise"
                            label="Titre de l'adresse"
                            fullWidth
                            variant="outlined"
                            value={values.secondaryAddressTitle}
                            onChange={handleChange}
                            disabled={!canEdit}
                            helperText="Par défaut : Entreprise"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="secondaryAddressName"
                            label="Nom"
                            fullWidth
                            variant="outlined"
                            value={values.secondaryAddressName}
                            onChange={handleChange}
                            disabled={!canEdit}
                            required
                            error={!!errors.secondaryAddressName}
                            helperText={errors.secondaryAddressName}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="secondaryAddress"
                            label="Adresse"
                            fullWidth
                            variant="outlined"
                            required
                            error={!!errors.secondaryAddress}
                            helperText={errors.secondaryAddress}
                            value={values.secondaryAddress}
                            onChange={handleChange}
                            disabled={!canEdit}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="secondaryAddressZipCode"
                            label="Code postal"
                            fullWidth
                            variant="outlined"
                            value={values.secondaryAddressZipCode}
                            onChange={handleChange}
                            disabled={!canEdit}
                            required
                            error={!!errors.secondaryAddressZipCode}
                            helperText={errors.secondaryAddressZipCode}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextField
                            name="secondaryAddressCity"
                            label="Commune"
                            fullWidth
                            variant="outlined"
                            value={values.secondaryAddressCity}
                            onChange={handleChange}
                            disabled={!canEdit}
                            required
                            error={!!errors.secondaryAddressCity}
                            helperText={errors.secondaryAddressCity}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="secondaryAddressEmail"
                            label="Adresse email"
                            fullWidth
                            variant="outlined"
                            value={values.secondaryAddressEmail}
                            onChange={handleChange}
                            type="email"
                            disabled={!canEdit}
                            required
                            error={!!errors.secondaryAddressEmail}
                            helperText={errors.secondaryAddressEmail}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="secondaryAddressPhone"
                            label="Téléphone"
                            fullWidth
                            variant="outlined"
                            value={values.secondaryAddressPhone}
                            onChange={handleChange}
                            type="tel"
                            disabled={!canEdit}
                            required
                            error={!!errors.secondaryAddressPhone}
                            helperText={errors.secondaryAddressPhone}
                          />
                        </Grid>
                      </Fragment>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={12}>
            <Card>
              <CardHeader title="Assurance obligatoire" />
              <CardContent>
                <Grid container spacing={SPACING}>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="insuranceArtisan"
                          checked={values.insuranceArtisan}
                          onChange={handleChange}
                          color="primary"
                          disabled={!canEdit}
                        />
                      }
                      label="Je suis soumis à l'assurance obligatoire, au sens de la réglementation"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="insuranceType"
                      placeholder="Type d'assurance"
                      label="Type d'assurance"
                      fullWidth
                      variant="outlined"
                      value={values.insuranceType}
                      onChange={handleChange}
                      required={values.insuranceArtisan}
                      disabled={!values.insuranceArtisan || !canEdit}
                      error={!!errors.insuranceType}
                      helperText={errors.insuranceType}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="insuranceName"
                      placeholder="Assureur"
                      label="Nom de l'assureur"
                      fullWidth
                      variant="outlined"
                      value={values.insuranceName}
                      onChange={handleChange}
                      required={values.insuranceArtisan}
                      disabled={!values.insuranceArtisan || !canEdit}
                      error={!!errors.insuranceName}
                      helperText={errors.insuranceName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="insuranceAddress"
                      placeholder="Rue de Rouen, 76000 ROUEN"
                      label="Adresse de l'assurance"
                      fullWidth
                      variant="outlined"
                      value={values.insuranceAddress}
                      onChange={handleChange}
                      required={values.insuranceArtisan}
                      disabled={!values.insuranceArtisan || !canEdit}
                      error={!!errors.insuranceAddress}
                      helperText={errors.insuranceAddress}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {canEdit && (
          <Grid container className={classes.container} justify="center">
            <Grid item xs={12} sm={2}>
              <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>
                Enregistrer
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
