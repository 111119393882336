import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import AdminService from "../../../services/AdminService";
import CompanyService from "../../../services/CompanyService";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block"
  },
  card: { height: "100%" },
  row: {
    display: "flex",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  rowPicto: {
    flexBasis: 40,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  companyItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  }
}));

export default function CompanyDocument({ company, onPatch }) {
  const classes = useStyles();
  const [documentTemplateId, setDocumentTemplateId] = React.useState(
    company.documentTemplate && company.documentTemplate.id ? company.documentTemplate.id : ""
  );
  const [orderFormLimitDate, setOrderFormLimitDate] = React.useState(company.orderFormLimitDate);
  const [estimateLimitDate, setEstimateLimitDate] = React.useState(company.estimateLimitDate);
  const [documentNumberSuffix, setDocumentNumberSuffix] = React.useState(company.documentNumberSuffix);
  const [documentContactEmailDisplay, setDocumentContactEmailDisplay] = React.useState(
    company.documentContactEmailDisplay || false
  );
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, data } = useQuery({
    queryKey: ["document_templates"],
    queryFn: () => CompanyService.getDocumentTemplates()
  });

  const handleChange = event => {
    setDocumentTemplateId(event.target.value);
  };

  const handleReset = () => {
    setDocumentTemplateId(company.documentTemplate || "");
  };

  const handleSubmit = () => {
    if (orderFormLimitDate >= 0 && estimateLimitDate >= 0) {
      AdminService.patchCompany({
        ...company,
        documentTemplateId: documentTemplateId || null,
        orderFormLimitDate,
        estimateLimitDate,
        documentNumberSuffix,
        documentContactEmailDisplay
      })
        .then(updatedCompany => {
          onPatch(updatedCompany);
          enqueueSnackbar("L'entreprise a été mise à jour", { variant: "success" });
        })
        .catch(err => {
          console.log(" ERROR ", err);
          enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'entreprise", {
            variant: "error"
          });
        });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Documents
            </Typography>
            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Typography variant="body2" gutterBottom>
                  Template de document
                </Typography>
                {isLoading || !data.content ? (
                  <CircularProgress />
                ) : (
                  <Select
                    fullWidth
                    value={documentTemplateId}
                    onChange={handleChange}
                    displayEmpty
                    variant="outlined"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em> -- Aucun template --</em>
                    </MenuItem>
                    {data.content.map(template => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
              <Box>
                <Typography variant="body2" gutterBottom>
                  Date limite d&apos;exécution de la prestation (bon de commande)
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  name="orderFormLimitDate"
                  value={orderFormLimitDate}
                  onChange={e => setOrderFormLimitDate(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">jours</InputAdornment>
                  }}
                  type="number"
                  inputProps={{ min: "0", step: "1" }}
                  error={orderFormLimitDate < 0}
                />
              </Box>
              <Box>
                <Typography variant="body2" gutterBottom>
                  Date d&apos;expiration de l&apos;offre (devis)
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  name="estimateLimitDate"
                  value={estimateLimitDate}
                  onChange={e => setEstimateLimitDate(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">jours</InputAdornment>
                  }}
                  type="number"
                  inputProps={{ min: "0", step: "1" }}
                  error={estimateLimitDate < 0}
                />
              </Box>
              <Box>
                <Typography variant="body2" gutterBottom>
                  Suffixe du numéro de document
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  name="documentNumberSuffix"
                  value={documentNumberSuffix}
                  onChange={e => setDocumentNumberSuffix(e.target.value)}
                  fullWidth
                />
              </Box>
              <Box>
                <Typography variant="body2">Informations du client</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={documentContactEmailDisplay}
                      name="documentContactEmailDisplay"
                      color="primary"
                      onChange={() => setDocumentContactEmailDisplay(!documentContactEmailDisplay)}
                    />
                  }
                  label="Afficher l'email du client"
                />
              </Box>
            </Box>
          </CardContent>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Button variant="text" onClick={handleReset}>
              Annuler
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Enregistrer
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
