import React from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
  stepper: {
    borderRadius: `${theme.shape.borderRadius}px`,
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(2)
    }
  },
  step: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing()
    }
  }
}));

const SignatureStepper = ({ activeStep, steps }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Stepper activeStep={activeStep} orientation={matchXs ? "vertical" : "horizontal"} className={classes.stepper}>
      {steps.map((step, index) => (
        <Step key={`step-${index + 1}`} className={classes.step}>
          <StepLabel>{step.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default SignatureStepper;
