import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import MailIcon from "@material-ui/icons/Mail";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import RoomIcon from "@material-ui/icons/Room";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventIcon from "@material-ui/icons/Event";

import ClientDateChip from "./ClientDateChip";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block",
    textDecoration: "none"
  },
  card: { height: "100%" },
  row: {
    display: "flex",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  rowPicto: {
    flexBasis: 40,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  companyItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  chip: {
    minWidth: 120
  },
  chipLabel: {
    flex: 2,
    textAlign: "center"
  },
  chipWrapper: {
    paddingTop: theme.spacing(),
    display: "flex",
    alignItems: "center"
  }
}));

function ClientInformations({ client }) {
  const classes = useStyles();

  const {
    phone = "",
    phone2 = "",
    address = "",
    city = "",
    zipCode = "",
    email = "",
    firstname = "",
    lastname = "",
    businessName = "",
    installationDate = null,
    reminderDate = null,
    comments = ""
  } = client;

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Identité
            </Typography>
            <Grid container>
              <Grid item xs={12} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Prénom/Nom
                </Typography>
                <Typography variant="body1" color="textPrimary" component="p">
                  {firstname} {lastname.toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Entreprise
                </Typography>
                <Typography variant="body1" color="textPrimary" component="p">
                  {businessName || "-"}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Contact
            </Typography>
            <div>
              <div className={classes.row}>
                <div className={classes.rowPicto}>
                  <MailIcon fontSize="small" />
                </div>
                <div>
                  {email ? (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="a"
                      href={`mailto:${email}`}
                      className={classes.link}
                    >
                      {email}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary" className={classes.link}>
                      -
                    </Typography>
                  )}
                </div>
              </div>
              <div className={classes.rowWithPicto}>
                <div className={classes.row}>
                  <div className={classes.rowPicto}>
                    <PermPhoneMsgIcon fontSize="small" />
                  </div>
                  <div>
                    {phone ? (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="a"
                        href={`tel:${phone}`}
                        className={classes.link}
                      >
                        {phone}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="textSecondary" className={classes.link}>
                        -
                      </Typography>
                    )}
                    {phone2 ? (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="a"
                        href={`tel:${phone2}`}
                        className={classes.link}
                      >
                        {phone2}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="textSecondary" className={classes.link}>
                        -
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.rowWithPicto}>
                <div className={classes.row}>
                  <div className={classes.rowPicto}>
                    <RoomIcon fontSize="small" />
                  </div>
                  <div>
                    <a
                      href={`http://maps.google.com/?q=${address} ${zipCode} ${city}`}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography variant="body2" color="textSecondary" component="span">
                        {address}
                      </Typography>
                      <br />
                      <Typography variant="body2" color="textSecondary" component="span">
                        {zipCode} {city}
                      </Typography>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Dates
            </Typography>
            <Grid container>
              <Grid item xs={6} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Date d&apos;installation
                </Typography>
                <div className={classes.chipWrapper}>
                  <ClientDateChip date={installationDate} avatar={<EventAvailableIcon />} />
                </div>
              </Grid>
              <Grid item xs={6} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Date de relance
                </Typography>
                <div className={classes.chipWrapper}>
                  <ClientDateChip date={reminderDate} avatar={<EventIcon />} />
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Commentaire
            </Typography>
            {comments ? (
              <Typography variant="body1" color="textPrimary" style={{ whiteSpace: "pre-line" }}>
                {comments}
              </Typography>
            ) : (
              <Typography variant="body1" color="textSecondary">
                <span style={{ color: "gray", fontStyle: "italic" }}>Aucun commentaire</span>
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
export default React.memo(ClientInformations);
