import React from "react";
import { Chip, withStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";
import { INVOICE_STATUS } from "../../utils/status";

const styles = () => ({ chip: { color: "white" } });

const InvoiceStatusLabel = ({ invoice, invoiceStatus, classes, handleChangeStatus, canBeManaged }) => {
  const status = invoiceStatus || invoice.status;
  switch (status) {
    case "VALIDATED":
      return (
        <Chip
          className={classes.chip}
          label={INVOICE_STATUS.VALIDATED.label}
          onDelete={canBeManaged ? handleChangeStatus : null}
          onClick={canBeManaged ? handleChangeStatus : null}
          deleteIcon={canBeManaged ? <EditIcon style={{ color: "white" }} fontSize="small" /> : null}
          style={{
            backgroundColor: INVOICE_STATUS.VALIDATED.color
          }}
        />
      );
    case "SENT":
      return (
        <Chip
          className={classes.chip}
          label={INVOICE_STATUS.SENT.label}
          onDelete={canBeManaged ? handleChangeStatus : null}
          onClick={canBeManaged ? handleChangeStatus : null}
          deleteIcon={canBeManaged ? <EditIcon style={{ color: "white" }} fontSize="small" /> : null}
          style={{
            backgroundColor: INVOICE_STATUS.SENT.color
          }}
        />
      );
    case "PAID":
      return (
        <Chip
          className={classes.chip}
          label={INVOICE_STATUS.PAID.label}
          style={{
            backgroundColor: INVOICE_STATUS.PAID.color
          }}
        />
      );
    case "PARTIALLY_PAID":
      return (
        <Chip
          className={classes.chip}
          label={INVOICE_STATUS.PARTIALLY_PAID.label}
          style={{
            backgroundColor: INVOICE_STATUS.PARTIALLY_PAID.color
          }}
        />
      );
    case "CANCEL":
      return (
        <Chip
          className={classes.chip}
          label={INVOICE_STATUS.CANCEL.label}
          style={{
            backgroundColor: INVOICE_STATUS.CANCEL.color
          }}
        />
      );
    case "CREDIT_NOTE":
      return (
        <Chip
          className={classes.chip}
          label={INVOICE_STATUS.CREDIT_NOTE.label}
          style={{
            backgroundColor: INVOICE_STATUS.CREDIT_NOTE.color
          }}
        />
      );
    default:
      if (handleChangeStatus) {
        return (
          <ConfirmationDialog
            key="menuitem-validate"
            situationText="Pour pouvoir changer le statut de cette facture, vous devez avant tout la valider. Attention, cette action est irréversible et celle-ci ne sera plus modifiable. Êtes-vous sûr ?"
          >
            {confirmBeforeAct => (
              <Chip
                className={classes.chip}
                label={INVOICE_STATUS.DRAFT.label}
                onDelete={
                  canBeManaged
                    ? e =>
                        stopPropagationForConfirmation(
                          e,
                          confirmBeforeAct(() => handleChangeStatus())
                        )
                    : null
                }
                onClick={
                  canBeManaged
                    ? e =>
                        stopPropagationForConfirmation(
                          e,
                          confirmBeforeAct(() => handleChangeStatus())
                        )
                    : null
                }
                deleteIcon={canBeManaged ? <EditIcon style={{ color: "white" }} fontSize="small" /> : null}
                style={{
                  backgroundColor: INVOICE_STATUS.DRAFT.color
                }}
              />
            )}
          </ConfirmationDialog>
        );
      }
      return (
        <Chip
          className={classes.chip}
          label={INVOICE_STATUS.DRAFT.label}
          onDelete={canBeManaged ? handleChangeStatus : null}
          onClick={canBeManaged ? handleChangeStatus : null}
          deleteIcon={canBeManaged ? <EditIcon style={{ color: "white" }} fontSize="small" /> : null}
          style={{
            backgroundColor: INVOICE_STATUS.DRAFT.color
          }}
        />
      );
  }
};

export default withStyles(styles)(InvoiceStatusLabel);
