// import React from "react";

// function YousignSignatureFrame({ url }) {
//   const handleMessage = event => {
//     if (event.origin === "https://yousign.app" && event.data.type === "yousign" && event.data.event === "success") {
//       // onSign();
//       // enqueueSnackbar("Signature appliquée avec succès", { variant: "success" });
//       console.log("FINISH");
//     }
//   };

//   React.useEffect(() => {
//     window.addEventListener("message", handleMessage);
//     return () => {
//       window.removeEventListener("message", handleMessage);
//     };
//   }, [url]);

//   return (
//     <div style={{ position: "relative", height: "100%" }}>
//       <iframe src={`${url}&disable_domain_validation=1`} style={{ width: "100%", height: "100%" }} />
//     </div>
//   );
// }

// export default React.memo(YousignSignatureFrame);

import React, { memo, useEffect } from "react";
import Yousign from "../utils/yousign";

function YousignSignatureFrame({ url, onSuccess }) {
  useEffect(() => {
    const yousign = new Yousign({
      signatureLink: url,
      iframeContainerId: "iframe-container",
      isSandbox: true
    });

    yousign.onStarted(() => {
      console.log(" STARTED ! ");
    });

    yousign.onSuccess(() => {
      onSuccess();
    });
  }, []);

  return (
    <div id="iframe-container" style={{ width: "100%", height: "100%" }}>
      <iframe id="yousign-iframe" style={{ width: "100%", height: "100%" }} />
    </div>
  );
}

export default memo(YousignSignatureFrame);
