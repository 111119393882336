import React from "react";
import { Badge, Chip, Tooltip, withStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";

import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";
import { ESTIMATE_STATUS } from "../../utils/status";
import EstimateService from "../../services/EstimateService";

const styles = () => ({
  chip: {
    color: "white"
  }
});

function EstimateStatusChip({ estimate, classes, handleChangeStatus, canBeManaged }) {
  switch (estimate.status) {
    case "DRAFT":
      return (
        <Chip
          className={classes.chip}
          label={ESTIMATE_STATUS.DRAFT.label}
          onDelete={canBeManaged ? handleChangeStatus : null}
          onClick={canBeManaged ? handleChangeStatus : null}
          deleteIcon={canBeManaged ? <EditIcon style={{ color: "white" }} fontSize="small" /> : null}
          style={{
            backgroundColor: ESTIMATE_STATUS.DRAFT.color
          }}
        />
      );
    case "VALIDATED":
      return (
        <Chip
          className={classes.chip}
          label={ESTIMATE_STATUS.VALIDATED.label}
          onDelete={canBeManaged ? handleChangeStatus : null}
          onClick={canBeManaged ? handleChangeStatus : null}
          deleteIcon={canBeManaged ? <EditIcon style={{ color: "white" }} fontSize="small" /> : null}
          style={{
            backgroundColor: ESTIMATE_STATUS.VALIDATED.color
          }}
        />
      );
    case "SENT":
      if ((estimate.transactionId || estimate.signatureRequestId) && EstimateService.signatureExpired(estimate)) {
        return (
          <Chip
            className={classes.chip}
            label={ESTIMATE_STATUS.SENT_EXPIRED.label}
            onDelete={canBeManaged ? handleChangeStatus : null}
            onClick={canBeManaged ? handleChangeStatus : null}
            deleteIcon={canBeManaged ? <EditIcon style={{ color: "white" }} fontSize="small" /> : null}
            style={{
              backgroundColor: ESTIMATE_STATUS.SENT_EXPIRED.color
            }}
          />
        );
      }
      return (
        <Chip
          className={classes.chip}
          label={ESTIMATE_STATUS.SENT.label}
          onDelete={canBeManaged ? handleChangeStatus : null}
          onClick={canBeManaged ? handleChangeStatus : null}
          deleteIcon={canBeManaged ? <EditIcon style={{ color: "white" }} fontSize="small" /> : null}
          style={{
            backgroundColor: ESTIMATE_STATUS.SENT.color
          }}
        />
      );
    case "SIGNED":
      return (
        <Chip
          className={classes.chip}
          label={ESTIMATE_STATUS.SIGNED.label}
          style={{
            backgroundColor: ESTIMATE_STATUS.SIGNED.color
          }}
        />
      );
    case "REFUSED":
      return (
        <Chip
          className={classes.chip}
          label={ESTIMATE_STATUS.REFUSED.label}
          style={{
            backgroundColor: ESTIMATE_STATUS.REFUSED.color
          }}
        />
      );
    case "CANCEL":
      return (
        <Chip
          className={classes.chip}
          label={ESTIMATE_STATUS.CANCEL.label}
          style={{
            backgroundColor: ESTIMATE_STATUS.CANCEL.color
          }}
        />
      );
    case "INVOICED":
      return (
        <Chip
          className={classes.chip}
          label={ESTIMATE_STATUS.INVOICED.label}
          style={{
            backgroundColor: ESTIMATE_STATUS.INVOICED.color
          }}
        />
      );
    default:
      if (handleChangeStatus) {
        return (
          <ConfirmationDialog
            key="menuitem-validate"
            situationText="Pour pouvoir changer le statut de ce devis, vous devez avant tout le valider. Attention, cette action est irréversible et celle-ci ne sera plus modifiable. Êtes-vous sûr ?"
          >
            {confirmBeforeAct => (
              <Chip
                className={classes.chip}
                label={ESTIMATE_STATUS.DRAFT.label}
                onDelete={
                  canBeManaged
                    ? e =>
                        stopPropagationForConfirmation(
                          e,
                          confirmBeforeAct(() => handleChangeStatus())
                        )
                    : null
                }
                onClick={
                  canBeManaged
                    ? e =>
                        stopPropagationForConfirmation(
                          e,
                          confirmBeforeAct(() => handleChangeStatus())
                        )
                    : null
                }
                deleteIcon={canBeManaged ? <EditIcon style={{ color: "white" }} fontSize="small" /> : null}
                style={{
                  backgroundColor: ESTIMATE_STATUS.DRAFT.color
                }}
              />
            )}
          </ConfirmationDialog>
        );
      }
      return (
        <Chip
          className={classes.chip}
          label={ESTIMATE_STATUS.DRAFT.label}
          onDelete={canBeManaged ? handleChangeStatus : null}
          onClick={canBeManaged ? handleChangeStatus : null}
          deleteIcon={canBeManaged ? <EditIcon style={{ color: "white" }} fontSize="small" /> : null}
          style={{
            backgroundColor: ESTIMATE_STATUS.DRAFT.color
          }}
        />
      );
  }
}

function EstimateStatusLabel({ estimate, classes, handleChangeStatus, canBeManaged }) {
  if (estimate.transactionId || estimate.signatureRequestId) {
    if (estimate.transactionCloseDate) {
      return (
        <Tooltip
          title={`Signature terminée le ${moment(estimate.transactionCloseDate).format("DD/MM/YYYY [à] HH:mm")}`}
        >
          <Badge color="error" badgeContent="SE">
            <EstimateStatusChip
              estimate={estimate}
              classes={classes}
              handleChangeStatus={handleChangeStatus}
              canBeManaged={canBeManaged}
            />
          </Badge>
        </Tooltip>
      );
    }
    if (estimate.signatureRequestDoneDate) {
      return (
        <Tooltip
          title={`Signature terminée le ${moment(estimate.signatureRequestDoneDate).format("DD/MM/YYYY [à] HH:mm")}`}
        >
          <Badge color="error" badgeContent="SE">
            <EstimateStatusChip
              estimate={estimate}
              classes={classes}
              handleChangeStatus={handleChangeStatus}
              canBeManaged={canBeManaged}
            />
          </Badge>
        </Tooltip>
      );
    }
    if (estimate.status === "SENT") {
      if (EstimateService.signatureExpired(estimate)) {
        return (
          <Tooltip title={ESTIMATE_STATUS.SENT_EXPIRED.tooltip}>
            <Badge color="error" badgeContent="SE">
              <EstimateStatusChip
                estimate={estimate}
                classes={classes}
                handleChangeStatus={handleChangeStatus}
                canBeManaged={canBeManaged}
              />
            </Badge>
          </Tooltip>
        );
      }

      return (
        <Tooltip
          title={`Ce devis peut être signé jusqu'au ${EstimateService.signatureDeadline(estimate).format(
            "DD/MM/YYYY [à] HH:mm"
          )}`}
        >
          <Badge color="error" badgeContent="SE">
            <EstimateStatusChip
              estimate={estimate}
              classes={classes}
              handleChangeStatus={handleChangeStatus}
              canBeManaged={canBeManaged}
            />
          </Badge>
        </Tooltip>
      );
    }
  }
  return (
    <EstimateStatusChip
      estimate={estimate}
      classes={classes}
      handleChangeStatus={handleChangeStatus}
      canBeManaged={canBeManaged}
    />
  );
}

export default withStyles(styles)(EstimateStatusLabel);
