import React, { useEffect, useState } from "react";
import { InputAdornment, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";

import { MIN_DATE } from "../../utils/dashboard";

const useStyles = makeStyles(theme => ({
  head: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(),
    marginTop: theme.spacing()
  },
  tabscontainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tab: {
    borderBottom: "1px solid #E6E6E6"
  },
  picker: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing()
    },
    width: 160
  },
  pickersContainer: {
    display: "flex",
    alignItems: "center"
  },
  separator: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  }
}));

export default function RangePicker({ onChange }) {
  const [from, setFrom] = useState(moment().startOf("month"));
  const [to, setTo] = useState(moment().endOf("month"));
  const classes = useStyles();

  useEffect(() => {
    onChange({ from, to });
  }, [from, to]);

  return (
    <div className={classes.pickersContainer}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          format="DD/MM/YYYY"
          className={classes.picker}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          label="Du"
          minDate={MIN_DATE}
          maxDate={to}
          onChange={setFrom}
          value={from}
          variant="inline"
          inputVariant="standard"
        />
      </MuiPickersUtilsProvider>
      <span className={classes.separator}>-</span>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          format="DD/MM/YYYY"
          className={classes.picker}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          label="au"
          minDate={from}
          maxDate={moment().endOf("month")}
          onChange={setTo}
          value={to}
          variant="inline"
          inputVariant="standard"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
