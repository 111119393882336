import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withStyles, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { inject, observer } from "mobx-react";
import moment from "moment";

import { privateRoutes } from "../routes";
import NotFoundPage from "./NotFoundPage";
import SignaturesList from "../components/signature/SignaturesList";

const styles = theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
});

@inject("sessionStore")
@observer
class SignaturesPage extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const filter = {};
    if (location.state && location.state.from) {
      filter.from = moment(location.state.from);
    }
    if (location.state && location.state.to) {
      filter.to = moment(location.state.to);
    }
    if (location.state && location.state.client) {
      filter.client = location.state.client;
    }
    this.state = {
      filter
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.replace({ state: null });
  }

  render() {
    const { classes, sessionStore } = this.props;
    const { filter } = this.state;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["DOC_SIGNATURE_MY", "DOC_SIGNATURE_READ"],
        aclType: "oneof",
        requiredFeatures: ["DOC_SIGNATURE"]
      })
    )
      return <NotFoundPage />;

    return (
      <Fragment>
        <SignaturesList
          title="Signatures PDF"
          filter={filter}
          disableCreation={
            !sessionStore.userHasAccess({
              requiredAcls: ["DOC_SIGNATURE_MY"],
              requiredFeatures: ["DOC_SIGNATURE"]
            })
          }
        />
        {sessionStore.userHasAccess({
          requiredAcls: ["DOC_SIGNATURE_MY"],
          requiredFeatures: ["DOC_SIGNATURE"]
        }) && (
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            component={Link}
            to={privateRoutes.admin.signatures.creationForm.path}
          >
            <AddIcon />
          </Fab>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(SignaturesPage);
