import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
  CircularProgress
} from "@material-ui/core";

const statusToIndex = status => {
  switch (status) {
    case "DRAFT":
      return 0;
    case "SENT":
      return 1;
    case "SIGNED":
      return 2;
    default:
      return 0;
  }
};

const steps = [
  { label: "En attente", isDisabled: true, contentSub: "Impossible de revenir à cet état", key: "DRAFT" },
  { label: "Envoyé", isDisabled: false, key: "SENT" },
  { label: "Signé", isDisabled: false, key: "SIGNED" }
];

export default function EstimateStatusManager({ onClose, open, handleSubmit, estimate }) {
  const [processing, setProcessing] = useState(false);
  const [currentValue, setCurrentValue] = useState(0);

  const handleFormSubmit = async e => {
    e.preventDefault();
    setProcessing(true);
    handleSubmit({ estimate, status: steps[currentValue].key }).then(() => {
      setProcessing(false);
      onClose();
    });
  };

  useEffect(() => {
    if (open && estimate) {
      setCurrentValue(statusToIndex(estimate.status));
    }
  }, [open, estimate]);

  return (
    <Dialog
      modal="false"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Gestion du statut</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        {estimate && (
          <Fragment>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Devis {estimate.number}
            </Typography>
            <Typography variant="body1" style={{ textAlign: "center" }}>
              Cliquez sur le nouveau statut pour le changer
            </Typography>
          </Fragment>
        )}
        <Stepper activeStep={currentValue} orientation="vertical">
          {steps.map((step, index) => (
            <Step
              disabled={step.isDisabled}
              key={step.label}
              style={{ cursor: index === 0 ? "default" : "pointer" }}
              completed={currentValue >= index}
              onClick={e => {
                e.preventDefault();
                if (index > 0) {
                  setCurrentValue(index);
                }
              }}
            >
              <StepLabel optional={step.contentSub}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Button
          onClick={handleFormSubmit}
          color="primary"
          variant="outlined"
          autoFocus
          disabled={processing}
          style={{ position: "relative" }}
        >
          {processing && (
            <div style={{ position: "absolute", top: "50%", marginTop: -10, marginLeft: -10, left: "50%", zIndex: 2 }}>
              <CircularProgress size={20} />
            </div>
          )}
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
