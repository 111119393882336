import { Button, CircularProgress, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { withRouter } from "react-router";
import ClientService, { ClientSchema } from "../../services/ClientService";
import ClientForm from "../form/ClientForm";

const useStyles = makeStyles(theme => ({
  back: { flexDirection: "column" },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  btnWrapper: {
    marginTop: theme.spacing(2)
  }
}));

function EditClientDetails({ client, history }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.header}>
          Modification du client : {client.firstname} {client.lastname.toUpperCase()}
        </Typography>
      </Grid>
      <Formik
        initialValues={client}
        validationSchema={ClientSchema}
        onSubmit={values => {
          setIsSubmitting(true);
          ClientService.patchClient({ ...values })
            .then(() => {
              enqueueSnackbar("Le client a été modifié", { variant: "success" });
              setIsSubmitting(false);
              history.push(`/clients/${client.id}`);
            })
            .catch(error => {
              enqueueSnackbar(
                error.status === 400
                  ? "Les informations du client sont incorrectes"
                  : "Une erreur est survenue lors de la mise à jour du client",
                {
                  variant: "error"
                }
              );
              setIsSubmitting(false);
            });
        }}
      >
        {formikProps => (
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item xs={12} sm={10} md={10} lg={10} xl={8}>
                <Paper className={classes.paper}>
                  <ClientForm
                    woDOM
                    formik={formikProps}
                    handleSubmit={formikProps.handleSubmit}
                    isSubmitting={isSubmitting}
                  />
                  <Grid justify="flex-end" container className={classes.btnWrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={formikProps.handleSubmit}
                      disabled={isSubmitting || !formikProps.isValid}
                    >
                      Enregistrer
                      {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Grid>
  );
}

export default withRouter(EditClientDetails);
