import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import React from "react";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  contentWrapper: {
    display: "inline-block"
  },
  contentClickable: {
    cursor: "pointer",
    userSelect: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

const TableSortableHeader = ({ children, onClick, currentSort, attribute, classes }) => {
  const handleClick = () => {
    if (currentSort && currentSort.column === attribute) {
      if (currentSort.order === "ASC") {
        onClick({ column: attribute, order: "DESC" });
      } else if (currentSort.order === "DESC") {
        onClick(null);
      }
    } else {
      onClick({ column: attribute, order: "ASC" });
    }
  };

  const renderCaret = () => (currentSort.order === "ASC" ? <ArrowDropDown /> : <ArrowDropUp />);

  return (
    <div className={classes.contentWrapper}>
      <span onClick={handleClick} className={classes.contentClickable}>
        {children} {currentSort && currentSort.column === attribute && renderCaret()}
      </span>
    </div>
  );
};

export default withStyles(styles)(TableSortableHeader);
