import React from "react";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded";
import { ButtonBase, Chip, makeStyles } from "@material-ui/core";
import moment from "moment";

const eventStatusToIcon = status => {
  switch (status) {
    case "DONE":
      return <DoneRoundedIcon style={{ color: "rgb(60, 60, 60,0.8)" }} />;
    case "PENDING":
    default:
      return <HourglassEmptyRoundedIcon style={{ color: "rgb(60, 60, 60,0.8)" }} />;
  }
};

const eventStatusToColor = event => {
  switch (event.status) {
    case "DONE":
      return { color: "#3C3C3C", backgroundColor: "rgb(67, 160, 71,0.5)" };
    case "PENDING":
    default:
      return {
        color: "#3C3C3C",
        backgroundColor:
          event.dueDate && moment(event.dueDate).isBefore(moment()) ? "rgb(255, 0, 0,0.5)" : "rgb(255, 165, 50,0.5)"
      };
  }
};

const useStyles = makeStyles(() => ({
  fakechip: {
    display: "flex",
    alignItems: "center",
    padding: 2
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 24,
    height: 32,
    width: 32
  }
}));

function ClientEventChip({ event, allowEdition = false, onClick }) {
  // const [showDialog, setShowDialog] = React.useState(false);
  const [colorSet, setColorSet] = React.useState(eventStatusToColor(event));
  const classes = useStyles();

  React.useEffect(() => {
    setColorSet(eventStatusToColor(event));
  }, [event]);

  const handleClick = e => {
    e.stopPropagation();
    onClick(event);
  };

  if (allowEdition && onClick) {
    if (event.dueDate) {
      return (
        <Chip
          avatar={eventStatusToIcon(event.status)}
          label={event.dueDate ? moment(event.dueDate).format("DD/MM/YYYY") : ""}
          style={colorSet}
          onClick={handleClick}
        />
      );
    }
    return (
      <div className={classes.fakechip}>
        <ButtonBase className={classes.button} style={colorSet} onClick={handleClick}>
          {eventStatusToIcon(event.status)}
        </ButtonBase>
      </div>
    );
  }

  if (event.dueDate)
    return (
      <Chip
        avatar={eventStatusToIcon(event.status)}
        label={event.dueDate ? moment(event.dueDate).format("DD/MM/YYYY") : ""}
        style={colorSet}
      />
    );
  return (
    <div className={classes.fakechip}>
      <div className={classes.button} style={colorSet}>
        {eventStatusToIcon(event.status)}
      </div>
    </div>
  );
}

export default React.memo(ClientEventChip);
