import { Checkbox, Divider, FormControlLabel, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useFormikContext } from "formik";
import React from "react";
import { FEATURES, OPTIONS } from "../../../utils/matrix";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: 0
  }
}));

export default function CompanyFeaturesForm() {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  const handleToggleFeature = feature => {
    const { features } = values;
    if (features.includes(feature)) {
      const filtered = features.filter(f => f !== feature);
      setFieldValue("features", filtered);
    } else {
      features.push(feature);
      setFieldValue("features", features);
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        {FEATURES.map(feature => (
          <Grid container spacing={1} alignItems="center" key={feature.key}>
            <Grid item xs={2} />
            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.features.includes(feature.key)}
                    disabled={feature.isRootFeature}
                    onChange={() => handleToggleFeature(feature.key)}
                    name={`FEATURE_${feature.key}`}
                    color="primary"
                  />
                }
                label={feature.label}
              />
            </Grid>
          </Grid>
        ))}
        <Divider />
        {OPTIONS.map(option => (
          <Grid container spacing={1} alignItems="center" key={option.key}>
            <Grid item xs={2} />
            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.features.includes(option.key)}
                    onChange={() => handleToggleFeature(option.key)}
                    name={option.key}
                    color="primary"
                  />
                }
                label={option.label}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
