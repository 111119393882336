import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import { withStyles, Button, Card, CardActions, CardContent, CircularProgress, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import green from "@material-ui/core/colors/green";
import { withSnackbar } from "notistack";

import Information from "../../classes/Information";
import Invoice from "../../classes/Invoice";
import InvoiceService from "../../services/InvoiceService";
import { privateRoutes } from "../../routes";
import { formatCurrency } from "../../utils/numberFormat";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing()
  },
  rightIcon: {
    marginLeft: theme.spacing()
  },
  wrapper: {
    margin: theme.spacing(),
    position: "relative",
    display: "inline"
  },
  button: {
    marginRight: theme.spacing()
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  noProductText: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  }
});

@inject("newInvoiceStore")
@observer
class BottomConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = { isButtonLoading: false };
  }

  saveInvoice = () => {
    const { history, newInvoiceStore } = this.props;

    const invoice = new Invoice({
      ...newInvoiceStore,
      amount: newInvoiceStore.amount,
      currency: newInvoiceStore.currency,
      totalPriceWithoutTVA: newInvoiceStore.totalPriceWithoutTVA,
      totalPriceWithTVA: newInvoiceStore.totalPriceWithTVA,
      computedDiscount: newInvoiceStore.computedDiscount,
      information: new Information(newInvoiceStore),
      paymentTerms: newInvoiceStore.paymentMentions.paymentTerms,
      paymentDelay: newInvoiceStore.paymentMentions.paymentDelay,
      additionalInformation: newInvoiceStore.additionalInformation.defined,
      extraAdditionalInformation: newInvoiceStore.additionalInformation.extra,
      useSpecificDeliveryAddress: newInvoiceStore.useSpecificDeliveryAddress,
      deliveryAddress: newInvoiceStore.useSpecificDeliveryAddress ? newInvoiceStore.deliveryAddress : null
    });

    this.setState({ isButtonLoading: true }, () => {
      const promise = invoice.id ? InvoiceService.patchInvoice(invoice) : InvoiceService.postInvoice(invoice);
      const { enqueueSnackbar } = this.props;
      promise
        .then(() => {
          enqueueSnackbar(invoice.id ? "Facture modifiée avec succès" : "Facture créée avec succès", {
            variant: "success"
          });
          this.setState({ isButtonLoading: false }, () => {
            history.push({ pathname: privateRoutes.admin.invoices.listing.path, hash: "#brouillons" });
          });
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de l'enregistrement de la facture", { variant: "error" });
          this.setState({ isButtonLoading: false });
        });
    });
  };

  getFinalButtons = () => {
    const { classes, newInvoiceStore } = this.props;
    const { isButtonLoading } = this.state;

    return (
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={
            isButtonLoading ||
            !newInvoiceStore.isNewInvoiceClientOK ||
            !newInvoiceStore.isNewInvoiceDeliveryAddressOK ||
            !newInvoiceStore.isNewInvoicePaymentMentionsOK
          }
          onClick={this.saveInvoice}
        >
          Enregistrer
        </Button>
        {isButtonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  render() {
    const {
      classes,
      newInvoiceStore,
      handleNext,
      handleBack,
      activeStep,
      steps,
      disableNext,
      disableBack
    } = this.props;

    return (
      <Card>
        <CardContent>
          {newInvoiceStore.numberDetails > 1 ? (
            <Typography variant="h5">Total pour les {newInvoiceStore.numberDetails} prestations</Typography>
          ) : (
            <Typography variant="h5">Total pour la prestation</Typography>
          )}
          <div style={{ display: "flex" }}>
            <div style={{ paddingLeft: 5, textAlign: "right" }}>
              <Typography component="p" variant="body1">
                {formatCurrency(newInvoiceStore.totalPriceWithoutTVA, newInvoiceStore.currency)}
              </Typography>
              <Typography component="p" variant="body1">
                {formatCurrency(
                  newInvoiceStore.totalPriceWithTVA - newInvoiceStore.totalPriceWithoutTVA,
                  newInvoiceStore.currency
                )}
              </Typography>
              <Typography component="p" variant="body1">
                {formatCurrency(newInvoiceStore.amount, newInvoiceStore.currency)}
              </Typography>
            </div>
            <div style={{ paddingLeft: 5 }}>
              <Typography component="p" variant="body1">
                HT
              </Typography>
              <Typography component="p" variant="body1">
                TVA
              </Typography>
              <Typography component="p" variant="body1">
                TTC
              </Typography>
            </div>
          </div>
        </CardContent>
        <CardActions style={{ display: "block" }}>
          <Button disabled={disableBack} variant="outlined" color="primary" onClick={() => handleBack()}>
            <ChevronLeftIcon className={classes.leftIcon} /> Revenir
          </Button>
          {activeStep === steps.length - 1 ? (
            this.getFinalButtons()
          ) : (
            <Button variant="contained" color="primary" onClick={() => handleNext()} disabled={disableNext}>
              Passer à l&apos;étape suivante <ChevronRightIcon className={classes.rightIcon} />
            </Button>
          )}
          <div style={{ display: "block", marginLeft: 0 }}>
            {activeStep === 0 && newInvoiceStore.numberDetails === 1 && !newInvoiceStore.isFirstDetailOk && (
              <Typography component="p" variant="body2" className={classes.noProductText}>
                Vous devez valider au moins une prestation avant de continuer
              </Typography>
            )}
          </div>
        </CardActions>
      </Card>
    );
  }
}

export default withSnackbar(withRouter(withStyles(styles)(BottomConfirmation)));
