import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MomentUtils from "@date-io/moment";
import React, { useEffect, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Grid, Button, TextField, Badge, FormControlLabel, Checkbox, useTheme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

let timeoutSearch;

export const defaultListFilter = {
  q: "",
  from: null,
  to: null,
  showDeleted: false,
  nextInvoiceDateFrom: null,
  nextInvoiceDateTo: null
};

const useStyles = makeStyles(theme => ({
  woMarginTop: {
    marginTop: 0
  },
  advancedContainer: {
    marginBottom: theme.spacing(2)
  },
  advancedFieldsContainer: {
    paddingBottom: theme.spacing(),
    backgroundColor: "#FCFCFC"
  },
  advancedActionContainer: {
    backgroundColor: "#FCFCFC"
  },
  advancedInput: {
    marginTop: theme.spacing(2)
  },
  advancedInputContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  expWrapper: {
    overflow: "hidden"
  },
  chip: {
    margin: 2
  },
  chipContainer: { display: "flex", flexWrap: "wrap" },
  searchContainer: {
    padding: theme.spacing()
  }
}));

function CompaniesListFilter({ filter, updateFilter, defaultFilter }) {
  const [tmpFilter, setTmpFilter] = useState({ ...defaultListFilter, ...filter });
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    let _activeFiltersCount = 0;
    if (filter.from) {
      _activeFiltersCount += 1;
    }
    if (filter.to) {
      _activeFiltersCount += 1;
    }
    if (filter.showDeleted === true) _activeFiltersCount += 1;
    setActiveFiltersCount(_activeFiltersCount);
  }, [filter]);

  const handleQueryChange = e => {
    const updatedFilter = { ...tmpFilter, q: e.target.value };
    setTmpFilter(updatedFilter);
    clearTimeout(timeoutSearch);
    timeoutSearch = setTimeout(() => {
      updateFilter(updatedFilter);
    }, 500);
  };

  const handleDateChange = (attr, date) => {
    setTmpFilter({ ...tmpFilter, [attr]: date });
  };

  const handleExpansion = (e, expanded) => {
    setIsExpanded(expanded);
  };

  const handleSearch = () => {
    clearTimeout(timeoutSearch);
    setIsExpanded(false);
    updateFilter(tmpFilter);
  };

  const handleResetAdvanced = () => {
    setTmpFilter(defaultFilter);
    updateFilter(defaultFilter);
  };

  const toggleShowDeleted = () => {
    setTmpFilter({ ...tmpFilter, showDeleted: !tmpFilter.showDeleted });
  };

  return (
    <Grid container className={classes.searchContainer}>
      <Grid item xs={12} className={classes.advancedContainer}>
        <ExpansionPanel onChange={handleExpansion} expanded={isExpanded} className={classes.expWrapper}>
          <ExpansionPanelSummary
            expandIcon={
              !isExpanded && activeFiltersCount > 0 ? (
                <Badge badgeContent={activeFiltersCount} color="primary">
                  <ExpandMoreIcon />
                </Badge>
              ) : (
                <ExpandMoreIcon />
              )
            }
          >
            <TextField
              className={classes.woMarginTop}
              onClick={e => e.stopPropagation()}
              name="q"
              placeholder={matches ? "Recherche" : "Raison sociale, siret, ville, code postal"}
              label={matches ? "Recherche" : "Raison sociale, siret, ville, code postal"}
              margin="normal"
              fullWidth
              value={tmpFilter.q}
              onChange={handleQueryChange}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.advancedFieldsContainer}>
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tmpFilter.showDeleted}
                      name="show-deleted"
                      color="primary"
                      onChange={toggleShowDeleted}
                    />
                  }
                  label="Afficher uniquement les entreprises supprimées"
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3} className={classes.advancedInputContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                  <DatePicker
                    className={classes.advancedInput}
                    id="from"
                    cancelLabel="Annuler"
                    clearLabel="Réinitialiser"
                    clearable
                    format="D MMM YYYY"
                    fullWidth
                    label="Créée après le"
                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                    rightArrowIcon={<KeyboardArrowRightIcon />}
                    maxDate={tmpFilter.to ? tmpFilter.to : undefined}
                    value={tmpFilter.from}
                    onChange={date => handleDateChange("from", date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} className={classes.advancedInputContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                  <DatePicker
                    className={classes.advancedInput}
                    id="to"
                    cancelLabel="Annuler"
                    format="D MMM YYYY"
                    fullWidth
                    clearable
                    clearLabel="Réinitialiser"
                    label="Créée avant le"
                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                    rightArrowIcon={<KeyboardArrowRightIcon />}
                    minDate={tmpFilter.from ? tmpFilter.from : undefined}
                    value={tmpFilter.to}
                    onChange={date => handleDateChange("to", date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} className={classes.advancedInputContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                  <DatePicker
                    className={classes.advancedInput}
                    id="nextInvoiceDateFrom"
                    cancelLabel="Annuler"
                    clearLabel="Réinitialiser"
                    clearable
                    format="D MMM YYYY"
                    fullWidth
                    label="A facturer après le"
                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                    rightArrowIcon={<KeyboardArrowRightIcon />}
                    maxDate={tmpFilter.nextInvoiceDateTo ? tmpFilter.nextInvoiceDateTo : undefined}
                    value={tmpFilter.nextInvoiceDateFrom}
                    onChange={date => handleDateChange("nextInvoiceDateFrom", date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} className={classes.advancedInputContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                  <DatePicker
                    className={classes.advancedInput}
                    id="nextInvoiceDateTo"
                    cancelLabel="Annuler"
                    format="D MMM YYYY"
                    fullWidth
                    clearable
                    clearLabel="Réinitialiser"
                    label="A facturer avant le"
                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                    rightArrowIcon={<KeyboardArrowRightIcon />}
                    minDate={tmpFilter.nextInvoiceDateFrom ? tmpFilter.nextInvoiceDateFrom : undefined}
                    value={tmpFilter.nextInvoiceDateTo}
                    onChange={date => handleDateChange("nextInvoiceDateTo", date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
          <ExpansionPanelActions className={classes.advancedActionContainer}>
            <Button size="small" color="primary" onClick={handleResetAdvanced}>
              Réinitialiser
            </Button>
            <Button size="small" variant="contained" color="primary" onClick={handleSearch}>
              Filtrer
            </Button>
          </ExpansionPanelActions>
        </ExpansionPanel>
      </Grid>
    </Grid>
  );
}

export default CompaniesListFilter;
