import React from "react";
import { Button, Typography } from "@material-ui/core";

const LightRow = ({ client, showDetails, handleShowDetails, handleSelect, disabled }) => {
  const handleClick = e => {
    e.stopPropagation();
    handleSelect(client);
  };
  return (
    <div
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: "1px solid #E2E2E2",
        paddingRight: 15,
        paddingLeft: 15
      }}
    >
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row"
        }}
        onClick={() => handleShowDetails(client.id)}
      >
        <div style={{ flex: 2, alignItems: "center" }}>
          <Typography variant="h6">
            <span className="noselect">
              {client.lastname.toUpperCase()} {client.firstname}
            </span>
          </Typography>
          <Typography variant="subtitle1">{client.businessName || <span>&nbsp;</span>}</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button variant="contained" color="primary" onClick={handleClick} disabled={disabled}>
            Sélectionner
          </Button>
        </div>
      </div>

      {showDetails && (
        <div>
          <Typography variant="body2">{client.email}</Typography>
          <Typography variant="body2">
            {client.address}
            <br />
            {client.zipCode} {client.city}
          </Typography>
          <Typography variant="body2">
            {client.phone}
            {client.phone && client.phone2 && " - "}
            {client.phone2}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default LightRow;
