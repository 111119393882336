import React from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
  FormHelperText,
  TableRow,
  TableCell,
  ButtonGroup
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Formik } from "formik";
import * as Yup from "yup";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import { useSnackbar } from "notistack";

import { PAYMENT_TYPE } from "../../utils/status";

export default function EditPaymentRow({ maxPrice, onEditPayment, onCancelEditPayment, payment, editPayment }) {
  const { enqueueSnackbar } = useSnackbar();
  const _maxPrice = +maxPrice + payment.amount;

  const PaymentSchema = () =>
    Yup.object().shape({
      date: Yup.string().required("Ce champ est requis"),
      paymentMode: Yup.string().required("Ce champ est requis"),
      comments: Yup.string()
        .nullable()
        .max(500, "Le commentaire ne peut excéder 500 caractères"),
      amount: Yup.number()
        .max(_maxPrice, `Ne peut excéder ${_maxPrice} €`)
        .required("Ce champ est requis")
    });

  const patchPayment = values => {
    editPayment({ ...values, id: payment.id })
      .then(res => {
        const { date, amount, id, paymentMode, comments } = res;
        onEditPayment({ date, amount, id, paymentMode, comments });
        enqueueSnackbar("Paiement modifié", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la modification du paiement", { variant: "error" });
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        date: payment.date,
        amount: payment.amount,
        paymentMode: payment.paymentMode,
        comments: payment.comments
      }}
      validationSchema={PaymentSchema}
      onSubmit={patchPayment}
    >
      {({ handleChange, values, setFieldValue, handleSubmit, errors, setFieldTouched, touched }) => (
        <TableRow>
          <TableCell>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
              <DatePicker
                id="newPaymentDate"
                cancelLabel="Annuler"
                format="D MMM YYYY"
                inputVariant="outlined"
                fullWidth
                leftArrowIcon={<KeyboardArrowLeftIcon />}
                rightArrowIcon={<KeyboardArrowRightIcon />}
                value={values.date}
                onChange={date => setFieldValue("date", date)}
              />
            </MuiPickersUtilsProvider>
          </TableCell>
          <TableCell>
            <FormControl variant="outlined" fullWidth error={errors.paymentMode && touched.paymentMode}>
              <Select
                value={values.paymentMode}
                onChange={e => setFieldValue("paymentMode", e.target.value)}
                onBlur={() => setFieldTouched("paymentMode")}
                input={<OutlinedInput name="paymentMode" id="paymentMode" />}
              >
                {Object.keys(PAYMENT_TYPE).map(type => (
                  <MenuItem key={`payment-type-${type}`} value={type}>
                    {PAYMENT_TYPE[type]}
                  </MenuItem>
                ))}
              </Select>
              {errors.paymentMode && touched.paymentMode && <FormHelperText>{errors.paymentMode}</FormHelperText>}
            </FormControl>
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              inputProps={{ min: "0", step: "0.1", max: _maxPrice }}
              name="amount"
              onChange={handleChange}
              required
              type="number"
              value={values.amount}
              variant="outlined"
              onBlur={() => setFieldTouched("amount")}
              error={errors.amount && touched.amount}
              helperText={errors.amount && touched.amount && errors.amount}
            />
          </TableCell>
          <TableCell>
            <TextField
              multiline
              rows={2}
              fullWidth
              name="comments"
              onChange={handleChange}
              required
              value={values.comments}
              variant="outlined"
              onBlur={() => setFieldTouched("comments")}
              error={errors.comments && touched.comments}
              helperText={errors.comments && touched.comments && errors.comments}
            />
          </TableCell>
          <TableCell align="center">
            <ButtonGroup size="small" color="primary" variant="contained">
              <Button onClick={onCancelEditPayment}>
                <UndoIcon />
              </Button>
              <Button onClick={handleSubmit}>
                <SaveIcon />
              </Button>
            </ButtonGroup>
          </TableCell>
        </TableRow>
      )}
    </Formik>
  );
}
