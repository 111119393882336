import AddIcon from "@material-ui/icons/Add";
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import {
  Button,
  Fab,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  CircularProgress,
  TablePagination,
  withWidth
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import ConfirmationDialog, { stopPropagationForConfirmation } from "../components/dialog/ConfirmationDialog";
import ProductService from "../services/ProductService";
import TableAction from "../components/TableAction";
import AddEditProductCategoryDialog from "../components/dialog/AddEditProductCategoryDialog";

const ITEMS_PER_PAGE = 25;

@inject("sessionStore")
@observer
class ProductCategoryListingPage extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      productCategories: null,
      openModal: false,
      currentProductCategory: null,
      currentPage: 0,
      gonnaBeRefreshed: false,
      menuOpenFor: null
    };
  }

  componentDidMount() {
    this.getAllProductCategories();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  hasEditAccess = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["PRODUCT_WRITE"],
      requiredFeatures: ["PRODUCT"]
    });
  };

  getAllProductCategories = () => {
    this.setState({ gonnaBeRefreshed: true }, () => {
      ProductService.getProductCategories()
        .then(response => {
          if (this._isMounted) {
            this.setState({
              productCategories: response.content,
              gonnaBeRefreshed: false,
              currentPage: 0
            });
          }
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la récupération des catégories", {
            variant: "error"
          });
          if (this._isMounted) {
            this.setState({
              gonnaBeRefreshed: false
            });
          }
        });
    });
  };

  handleClickOpen = () => {
    this.setState({ openModal: true, currentProductCategory: null });
  };

  handleClickClose = () => {
    this.setState({ openModal: false });
  };

  handleEdit = productCategory => {
    this.setState({ currentProductCategory: productCategory, openModal: true });
  };

  handleRedirect = productCategoryId => {
    const { history } = this.props;

    const searchParams = new URLSearchParams({
      categoryId: productCategoryId
    });
    history.push({
      pathname: "/catalogue",
      search: searchParams.toString()
    });
  };

  handleDeletion = productCategoryId => {
    ProductService.deleteProductCategory(productCategoryId)
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Catégorie supprimée", {
          variant: "success"
        });
        this.getAllProductCategories();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la suppression de la catégorie", {
          variant: "error"
        });
      });
  };

  handleChangePage = (event, page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { classes, width } = this.props;
    const {
      openModal,
      currentProductCategory,
      gonnaBeRefreshed,
      currentPage,
      menuOpenFor,
      productCategories
    } = this.state;

    const slicedList = productCategories
      ? productCategories.slice(currentPage * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE)
      : null;

    return (
      <Fragment>
        <div className={classes.head}>
          <Typography variant="h4">Catégories du catalogue</Typography>
        </div>

        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Produits</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gonnaBeRefreshed === false && slicedList ? (
                    slicedList.length > 0 ? (
                      slicedList.map(productCategory => (
                        <TableRow key={productCategory.id} selected={productCategory.id === menuOpenFor}>
                          <TableCell>{productCategory.name}</TableCell>
                          {productCategory.productsCount > 0 ? (
                            <TableCell>
                              <Button
                                onClick={() => {
                                  this.handleRedirect(productCategory.id);
                                }}
                                variant="text"
                                size="small"
                                endIcon={<ArrowForwardIcon />}
                              >
                                {productCategory.productsCount} produit{productCategory.productsCount > 1 && "s"}
                              </Button>
                            </TableCell>
                          ) : (
                            <TableCell>Aucun produit</TableCell>
                          )}
                          <TableCell>
                            <TableAction
                              onOpen={() => {
                                this.setState({ menuOpenFor: productCategory.id });
                              }}
                              onClose={() => {
                                this.setState({ menuOpenFor: null });
                              }}
                            >
                              <MenuItem onClick={() => this.handleEdit(productCategory)}>Modifier</MenuItem>
                              <ConfirmationDialog>
                                {confirmBeforeAct => (
                                  <MenuItem
                                    onClick={e => {
                                      stopPropagationForConfirmation(
                                        e,
                                        confirmBeforeAct(() => this.handleDeletion(productCategory.id))
                                      );
                                    }}
                                  >
                                    Supprimer
                                  </MenuItem>
                                )}
                              </ConfirmationDialog>
                            </TableAction>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={width === "xs" ? 5 : 6} align="center" className={classes.loadingCell}>
                          Vous n&apos;avez pas encore saisi de catégorie
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={width === "xs" ? 5 : 6} align="center" className={classes.loadingCell}>
                        <CircularProgress size={40} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {slicedList && slicedList.length > 0 && (
                <TablePagination
                  component="div"
                  count={productCategories.length}
                  rowsPerPage={ITEMS_PER_PAGE}
                  rowsPerPageOptions={[ITEMS_PER_PAGE]}
                  page={currentPage}
                  backIconButtonProps={{
                    "aria-label": "Page précédente"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Page suivante"
                  }}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                />
              )}
            </Paper>
          </Grid>
          <AddEditProductCategoryDialog
            open={openModal}
            getAllProductCategories={this.getAllProductCategories}
            onSuccess={() => this.setState({ openModal: false })}
            currentProductCategory={currentProductCategory}
            handleClose={this.handleClickClose}
          />
          {this.hasEditAccess() && (
            <Fab
              color="primary"
              aria-label="Ajouter une prestation"
              className={classes.fab}
              onClick={this.handleClickOpen}
            >
              <AddIcon />
            </Fab>
          )}
        </Grid>
      </Fragment>
    );
  }
}

const styles = theme => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    marginBottom: 80
  },
  table: {
    minWidth: 700
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  head: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`
  },
  loadingCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  woMarginTop: {
    marginTop: 0
  },
  advancedContainer: {
    marginTop: theme.spacing(3)
  }
});

export default withRouter(withWidth()(withSnackbar(withStyles(styles)(ProductCategoryListingPage))));
