import React from "react";
import moment from "moment";
import { Chip, withStyles, Tooltip, Badge } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { ORDERFROM_STATUS } from "../../utils/status";
import OrderFormService from "../../services/OrderFormService";

const styles = () => ({
  chip: {
    color: "white"
  }
});

function OrderFormStatusChip({ orderForm, classes, handleChangeStatus }) {
  switch (orderForm.status) {
    case "DRAFT":
      if ((orderForm.transactionId || orderForm.signatureRequestId) && OrderFormService.signatureExpired(orderForm)) {
        return (
          <Chip
            className={classes.chip}
            label={ORDERFROM_STATUS.DRAFT_EXPIRED.label}
            onDelete={handleChangeStatus}
            onClick={handleChangeStatus}
            deleteIcon={<EditIcon style={{ color: "white" }} fontSize="small" />}
            style={{ backgroundColor: ORDERFROM_STATUS.DRAFT_EXPIRED.color }}
          />
        );
      }
      if (orderForm.transactionId || orderForm.signatureRequestId) {
        return (
          <Chip
            className={classes.chip}
            label={ORDERFROM_STATUS.DRAFT_PENDING.label}
            onDelete={handleChangeStatus}
            onClick={handleChangeStatus}
            deleteIcon={<EditIcon style={{ color: "white" }} fontSize="small" />}
            style={{ backgroundColor: ORDERFROM_STATUS.DRAFT_PENDING.color }}
          />
        );
      }
      return (
        <Chip
          className={classes.chip}
          label={ORDERFROM_STATUS.DRAFT.label}
          onDelete={handleChangeStatus}
          onClick={handleChangeStatus}
          deleteIcon={<EditIcon style={{ color: "white" }} fontSize="small" />}
          style={{ backgroundColor: ORDERFROM_STATUS.DRAFT.color }}
        />
      );

    case "SIGNED":
      return (
        <Chip
          className={classes.chip}
          label={ORDERFROM_STATUS.SIGNED.label}
          onDelete={handleChangeStatus}
          onClick={handleChangeStatus}
          deleteIcon={<EditIcon style={{ color: "white" }} fontSize="small" />}
          style={{ backgroundColor: ORDERFROM_STATUS.SIGNED.color }}
        />
      );
    case "INVOICED":
      return (
        <Chip
          className={classes.chip}
          label={ORDERFROM_STATUS.INVOICED.label}
          onDelete={handleChangeStatus}
          onClick={handleChangeStatus}
          deleteIcon={<EditIcon style={{ color: "white" }} fontSize="small" />}
          style={{ backgroundColor: ORDERFROM_STATUS.INVOICED.color }}
        />
      );
    case "CANCEL":
      return (
        <Chip
          className={classes.chip}
          label={ORDERFROM_STATUS.CANCEL.label}
          onDelete={handleChangeStatus}
          onClick={handleChangeStatus}
          deleteIcon={<EditIcon style={{ color: "white" }} fontSize="small" />}
          style={{ backgroundColor: ORDERFROM_STATUS.CANCEL.color }}
        />
      );
    default:
      return (
        <Chip
          className={classes.chip}
          label={ORDERFROM_STATUS.DRAFT.label}
          onDelete={handleChangeStatus}
          onClick={handleChangeStatus}
          deleteIcon={<EditIcon style={{ color: "white" }} fontSize="small" />}
          style={{ backgroundColor: ORDERFROM_STATUS.DRAFT.color }}
        />
      );
  }
}

function OrderFormStatusLabel({ orderForm, classes, handleChangeStatus }) {
  if (
    (orderForm.transactionId || orderForm.signatureRequestId) &&
    OrderFormService.signatureExpired(orderForm) &&
    orderForm.status === "DRAFT"
  ) {
    return (
      <Tooltip title={ORDERFROM_STATUS.DRAFT_EXPIRED.tooltip}>
        <Badge color="error" badgeContent="SE">
          <OrderFormStatusChip orderForm={orderForm} classes={classes} handleChangeStatus={handleChangeStatus} />
        </Badge>
      </Tooltip>
    );
  }
  if (
    (orderForm.transactionId && orderForm.transactionCloseDate) ||
    (orderForm.signatureRequestId && orderForm.signatureRequestDoneDate)
  ) {
    return (
      <Tooltip
        title={`Signature terminée le ${moment(
          orderForm.transactionCloseDate || orderForm.signatureRequestDoneDate
        ).format("DD/MM/YYYY [à] HH:mm")}`}
      >
        <Badge color="error" badgeContent="SE">
          <OrderFormStatusChip orderForm={orderForm} classes={classes} handleChangeStatus={handleChangeStatus} />
        </Badge>
      </Tooltip>
    );
  }
  if ((orderForm.transactionId || orderForm.signatureRequestId) && orderForm.status === "DRAFT") {
    return (
      <Tooltip title={ORDERFROM_STATUS.DRAFT_PENDING.tooltip(orderForm)}>
        <Badge color="error" badgeContent="SE">
          <OrderFormStatusChip orderForm={orderForm} classes={classes} handleChangeStatus={handleChangeStatus} />
        </Badge>
      </Tooltip>
    );
  }
  if ((orderForm.transactionId || orderForm.signatureRequestId) && orderForm.status === "INVOICED") {
    return <OrderFormStatusChip orderForm={orderForm} classes={classes} handleChangeStatus={handleChangeStatus} />;
  }
  if (orderForm.transactionId || orderForm.signatureRequestId) {
    return (
      <Tooltip title="Signature terminée">
        <Badge color="error" badgeContent="SE">
          <OrderFormStatusChip orderForm={orderForm} classes={classes} handleChangeStatus={handleChangeStatus} />
        </Badge>
      </Tooltip>
    );
  }
  return <OrderFormStatusChip orderForm={orderForm} classes={classes} handleChangeStatus={handleChangeStatus} />;
}

export default withStyles(styles)(OrderFormStatusLabel);
