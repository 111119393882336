import React, { useEffect, useState, Fragment } from "react";
import { Grid, TextField, Button, Typography, CircularProgress } from "@material-ui/core";

const PlaceHolder = () => (
  <div
    style={{
      position: "absolute",
      top: 1,
      right: 1,
      left: 1,
      bottom: 1,
      backgroundColor: "rgba(255,255,255, 0.5)",
      zIndex: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    <CircularProgress size={50} />
  </div>
);

export default function InvoicePaymentComment({ comment, handleSubmit }) {
  const [showForm, setShowForm] = useState(false);
  const [ziComment, setZiComment] = useState(comment);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setZiComment(comment || "");
  }, [comment]);

  const handleChange = e => {
    setZiComment(e.target.value);
  };

  const onSumbit = async () => {
    setIsSubmitting(true);
    await handleSubmit(ziComment);
    setShowForm(false);
    setIsSubmitting(false);
  };

  if (showForm) {
    return (
      <Grid container style={{ paddingLeft: 16, paddingBottom: 16, paddingRight: 16, position: "relative" }}>
        <Grid item xs={12}>
          <TextField
            name="comment"
            placeholder="Le commentaire n'est pas visible par le client"
            label="Commentaire"
            margin="normal"
            variant="outlined"
            fullWidth
            multiline
            type="text"
            rows={3}
            value={ziComment}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            onClick={() => setShowForm(false)}
            color="primary"
            variant="text"
            size="small"
            style={{ marginTop: 8, marginRight: 16 }}
          >
            Annuler
          </Button>
          <Button onClick={onSumbit} color="primary" variant="contained" size="small" style={{ marginTop: 8 }}>
            Enregistrer
          </Button>
        </Grid>
        {isSubmitting && <PlaceHolder />}
      </Grid>
    );
  }

  return comment ? (
    <Fragment>
      <Typography variant="h5" style={{ textAlign: "center" }}>
        <span style={{ fontSize: "0.6em", opacity: "0.7" }}>{comment}</span>
      </Typography>
      <Button
        onClick={() => setShowForm(true)}
        color="primary"
        variant="outlined"
        size="small"
        style={{ marginTop: 8 }}
      >
        Modifier
      </Button>
    </Fragment>
  ) : (
    <Button onClick={() => setShowForm(true)} color="primary" variant="outlined" size="small">
      Ajouter un commentaire
    </Button>
  );
}
