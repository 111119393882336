import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  btnWrapper: {
    textAlign: "right"
  },
  prevBtn: {
    marginRight: theme.spacing(2)
  }
}));

export default function CompanyFormButtons({ activeStep, setActiveStep, steps }) {
  const [nextBtnDisabled, setNextBtnDisabled] = useState(false);
  const classes = useStyles();
  const { validateForm, isValid, submitForm, isSubmitting } = useFormikContext();

  useEffect(() => {
    (() => validateForm())();
  }, []);

  useEffect(() => {
    if (activeStep === 0) {
      setNextBtnDisabled(!isValid);
    }
  }, [activeStep, isValid]);

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    if (activeStep < steps - 1) {
      setActiveStep(activeStep + 1);
    } else {
      submitForm();
    }
  };

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={12} className={classes.btnWrapper}>
        <Button
          variant="outlined"
          className={classes.prevBtn}
          disabled={activeStep === 0 || isSubmitting}
          onClick={handlePrevious}
        >
          Précédent
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext} disabled={nextBtnDisabled || isSubmitting}>
          {activeStep === steps - 1 ? "Enregistrer" : "Suivant"}
        </Button>
      </Grid>
    </Grid>
  );
}
