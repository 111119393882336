import request, { METHOD } from "../utils/request";

class InvoiceService {
  static STATUS = {
    DRAFT: "Brouillon",
    VALIDATED: "Validée",
    SENT: "Envoyée",
    PAID: "Payée",
    PARTIALLY_PAID: "Partiellement payée",
    CANCEL: "Annulée par avoir",
    CREDIT_NOTE: "Avoir"
  };

  static getInvoices({ page = 0, sort = "invoice.number", order = "DESC", filter = {} }) {
    const {
      q = "",
      userId = "",
      status = "",
      from = "",
      to = "",
      client = "",
      rangeEvaluationAttr = "creation"
    } = filter;
    return request({
      url: `/invoices?page=${page}&sort=${sort}&order=${order}&q=${q}&userId=${userId}&status=${status}&from=${from}&to=${to}&contactId=${client}&rangeEvaluationAttr=${rangeEvaluationAttr}`,
      method: METHOD.GET
    });
  }

  static exportInvoices({ filter = {} }) {
    const { q = "", userId = "", status = "", from = "", to = "", client = "" } = filter;
    return request({
      url: `/invoices/exportPDF?q=${q}&userId=${userId}&status=${status}&from=${from}&to=${to}&contactId=${client}`,
      method: METHOD.GET,
      responseType: "arraybuffer"
    });
  }

  static exportInvoicesAsCsv({ filter = {} }) {
    const { q = "", userId = "", status = "", from = "", to = "", client = "" } = filter;
    return request({
      url: `/invoices/exportToCSV?q=${q}&userId=${userId}&status=${status}&from=${from}&to=${to}&contactId=${client}`,
      method: METHOD.GET
    });
  }

  static getInvoice(id) {
    return request({
      url: `/invoices/${id}`,
      method: METHOD.GET
    });
  }

  static intoCreditNote(invoice) {
    return request({
      url: `/invoices/${invoice.id}/credit`,
      method: METHOD.POST
    });
  }

  static postInvoice(invoice) {
    return request({
      url: "/invoices",
      method: METHOD.POST,
      data: invoice
    });
  }

  static patchInvoice(invoice) {
    return request({
      url: `/invoices/${invoice.id}`,
      method: METHOD.PATCH,
      data: invoice
    });
  }

  static postInvoiceComment({ invoiceId, paymentComments }) {
    return request({
      url: `/invoices/${invoiceId}/comments`,
      method: METHOD.POST,
      data: { paymentComments }
    });
  }

  static duplicateInvoice(invoiceId) {
    return request({
      url: `/invoices/${invoiceId}/duplicate`,
      method: METHOD.POST
    });
  }

  static deleteInvoice(invoice) {
    return request({
      url: `/invoices/${invoice.id}`,
      method: METHOD.DELETE
    });
  }

  static sendInvoice(invoice) {
    return request({
      url: `/invoices/${invoice.id}/mail`,
      method: METHOD.POST
    });
  }

  static validateInvoice(invoice) {
    return request({
      url: `/invoices/${invoice.id}/validate`,
      method: METHOD.POST
    });
  }

  static downloadPDF(invoice) {
    return request({
      url: `/invoices/${invoice.id}/downloadPDF`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static addAdditionalDocument(invoice, type) {
    return request({
      url: `/invoices/${invoice.id}/additionalDocuments`,
      method: METHOD.POST,
      data: { type }
    });
  }

  static convertIntoInvoiceObject(invoice) {
    const {
      amount,
      clientType,
      contact,
      computedDiscount,
      discount,
      discountMethod,
      information,
      nature,
      paymentCondition,
      paymentMode,
      totalPriceWithTVA,
      totalPriceWithoutTVA
    } = invoice;
    const invoiceInformation = JSON.parse(information);
    const convertedInvoice = {
      amount,
      client: { ...contact, type: clientType },
      computedDiscount,
      discount,
      discountMethod,
      information: invoiceInformation,
      nature,
      paymentCondition,
      paymentMode,
      totalPriceWithTVA,
      totalPriceWithoutTVA
    };
    return convertedInvoice;
  }
}

export default InvoiceService;
