import React, { useCallback, useState, useMemo } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  Grid,
  makeStyles,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import { red } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import SmsIcon from "@material-ui/icons/Sms";
import { useSnackbar } from "notistack";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReplayIcon from "@material-ui/icons/Replay";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CloseIcon from "@material-ui/icons/Close";

import SignatureStatusLabel from "./SignatureStatusLabel";
import SignaturesListItemSignatory from "./SignaturesListItemSignatory";
import { privateRoutes } from "../../routes";
import SignatureService from "../../services/SignatureService";
import EmbeddedContraliaDialog from "../dialog/EmbeddedContraliaDialog";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";
import YouSignDialog from "../dialog/YouSignDialog";

const useStyles = makeStyles(theme => ({
  root: {
    cursor: "pointer",
    "& > *": {
      borderBottom: "unset"
    }
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  gridButtons: {
    borderLeft: "1px solid rgb(224,224,244)",
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  gridSignatories: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center"
  },
  button: {
    minWidth: 150,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  detailsIntro: {
    paddingLeft: 57,
    paddingRight: 16,
    marginBottom: theme.spacing(2)
  },
  textBold: {
    fontWeight: 500
  }
}));

// signatureProvider
export default function SignaturesListItem({ signature, selected, onClick, onItemUpdated }) {
  const [signatureRequestDialogOpen, setSignatureRequestDialogOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [urlSignature, setUrlSignature] = useState("");
  const [signaturePdfDocument, setSignaturePdfDocument] = useState("");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const signStatus = useMemo(() => {
    if (signature.status === "WAITING_SIGN") {
      if (signature.signatureRequestId && moment(signature.signatureRequestExpirationDate).isBefore(moment())) {
        return "EXPIRED";
      }
      if (signature.transactionId && moment(signature.deadlineSignature).isBefore(moment())) {
        return "EXPIRED";
      }
    }
    return signature.status;
  }, [signature.status, signature.deadlineSignature]);

  const initSignature = useCallback(() => {
    setIsProcessing(true);
    SignatureService.initSignature(signature.id)
      .then(() => {
        enqueueSnackbar("La signature a été initialisée avec succès", { variant: "success" });
        setIsProcessing(false);
        onItemUpdated();
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de l'initialisation de la signature", { variant: "error" });
        setIsProcessing(false);
      });
  }, []);

  const launchPadSignature = useCallback(() => {
    setIsProcessing(true);
    SignatureService.getSignatureUrl(signature.id)
      .then(res => {
        setUrlSignature(res.urlSignature);
        setIsProcessing(false);
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la génération l'accès à la signature", { variant: "error" });
        setIsProcessing(false);
      });
  }, []);

  const handleClose = () => {
    setUrlSignature("");
    onItemUpdated();
  };

  const downloadDocument = () => {
    setIsProcessing(true);
    SignatureService.downloadDocument(signature.id)
      .then(res => {
        setSignaturePdfDocument(window.URL.createObjectURL(res));
        setIsProcessing(false);
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors du téléchargement du document", { variant: "error" });
        setIsProcessing(false);
      });
  };

  const simulateClick = e => {
    if (e !== null) {
      e.click();
    }
    setSignaturePdfDocument("");
  };

  const handleRecreate = () => {
    setIsProcessing(true);
    SignatureService.retrySignature(signature.id)
      .then(() => {
        enqueueSnackbar("La signature a bien été relancée", { variant: "success" });
        setIsProcessing(false);
        onItemUpdated();
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la relance de la signature", { variant: "error" });
        setIsProcessing(false);
      });
  };

  const handleDuplicate = () => {
    setIsProcessing(true);
    SignatureService.duplicateSignature(signature.id)
      .then(() => {
        enqueueSnackbar("La signature a bien été dupliquée", { variant: "success" });
        setIsProcessing(false);
        onItemUpdated();
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la duplication de la signature", { variant: "error" });
        setIsProcessing(false);
      });
  };

  const handleDelete = () => {
    setIsProcessing(true);
    SignatureService.deleteSignature(signature.id)
      .then(() => {
        enqueueSnackbar("La signature a bien été supprimée", { variant: "success" });
        setIsProcessing(false);
        onItemUpdated();
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la suppression de la signature", { variant: "error" });
        setIsProcessing(false);
      });
  };

  const handleCancel = () => {
    setIsProcessing(true);
    SignatureService.cancelSignature(signature.id)
      .then(() => {
        enqueueSnackbar("La signature a bien été annulée", { variant: "success" });
        setIsProcessing(false);
        onItemUpdated();
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de l'annulation de la signature", { variant: "error" });
        setIsProcessing(false);
      });
  };

  const countSigned = useMemo(() => signature.signatories.filter(signatory => signatory.hasSigned).length, [
    signature.signatories
  ]);

  const itemStatus = useMemo(() => {
    if (signStatus !== "DRAFT") {
      if (signature.signatureRequestId) {
        if (signature.signatureRequestDoneDate) {
          return `Terminée le ${moment(signature.signatureRequestDoneDate).format("DD/MM/YYYY [à] HH[h]mm")}`;
        }
        return `Expire le ${moment(signature.signatureRequestExpirationDate).format("DD/MM/YYYY [à] HH[h]mm")}`;
      }
      if (signature.transactionId) {
        if (signature.transactionCloseDate) {
          return `Terminée le ${moment(signature.transactionCloseDate).format("DD/MM/YYYY [à] HH[h]mm")}`;
        }
        return `Expire le ${moment(signature.deadlineSignature).format("DD/MM/YYYY [à] HH[h]mm")}`;
      }
      if (signStatus === "EXPIRED") {
        return "Délai dépassé";
      }
      if (signStatus === "CANCEL") {
        return "Annulée";
      }
    }
    return (
      <Typography variant="body2" style={{ fontStyle: "italic" }}>
        À envoyer
      </Typography>
    );
  }, [signature, signStatus]);

  return (
    <React.Fragment>
      <TableRow className={classes.root} onClick={() => onClick(signature.id)}>
        <TableCell style={{ width: 45, textAlign: "center", paddingRight: 16 }}>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Badge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              badgeContent={
                <Avatar
                  style={{ height: 22, width: 22, fontSize: 12, border: "1px solid white", backgroundColor: "#777" }}
                >
                  {signature.signatureType === "OTP" ? (
                    <SmsIcon fontSize="inherit" />
                  ) : (
                    <CreateIcon fontSize="inherit" />
                  )}
                </Avatar>
              }
            >
              <SignatureStatusLabel asIcon signature={{ ...signature, status: signStatus }} />
            </Badge>
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {signature.signatureRequestDoneDate || signature.transactionCloseDate ? (
              <span>
                {signature.signatories.length} / {signature.signatories.length}
              </span>
            ) : (
              <span>
                {countSigned} / {signature.signatories.length}
              </span>
            )}
          </Typography>
        </TableCell>
        <TableCell>{signature.subject}</TableCell>
        <TableCell>{itemStatus}</TableCell>
        <TableCell>
          {moment(signature.createAt).format("DD/MM/YYYY")}
          <br />à {moment(signature.createAt).format("HH[h]mm")}
        </TableCell>
        <TableCell align="center">{selected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={8} style={{ padding: 0 }}>
          <Collapse in={selected} timeout="auto" unmountOnExit>
            <Grid container className={classes.container}>
              <Grid item xs={8}>
                {signature.signatureRequestId && (
                  <Box className={classes.gridSignatories}>
                    <Typography variant="body1" className={classes.detailsIntro}>
                      Signature à destination des signataires :
                    </Typography>
                    <SignaturesListItemSignatory
                      signature={{ ...signature, status: signStatus }}
                      launchPadSignature={() => {
                        setSignatureRequestDialogOpen(true);
                      }}
                    />
                  </Box>
                )}
                {signature.transactionId && (
                  <Box className={classes.gridSignatories}>
                    <Typography variant="body1" className={classes.detailsIntro}>
                      Signature{" "}
                      <span className={classes.textBold}>
                        {signature.signatureType === "OTP" ? "par SMS" : "manuscrite"}
                      </span>{" "}
                      à destination des signataires :
                    </Typography>
                    <SignaturesListItemSignatory
                      signature={{ ...signature, status: signStatus }}
                      launchPadSignature={launchPadSignature}
                    />
                  </Box>
                )}
                {!signature.signatureRequestId && !signature.transactionId && (
                  <Box className={classes.gridSignatories}>
                    <Typography variant="body1" className={classes.detailsIntro}>
                      Signature à destination des signataires :
                    </Typography>
                    <SignaturesListItemSignatory
                      signature={{ ...signature, status: signStatus }}
                      launchPadSignature={launchPadSignature}
                    />
                  </Box>
                )}
              </Grid>
              <Grid className={classes.gridButtons} item xs={4}>
                {signStatus === "DRAFT" && (
                  <Button
                    size="small"
                    startIcon={<SendIcon />}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={initSignature}
                    disabled={isProcessing}
                  >
                    Envoyer
                  </Button>
                )}
                {signStatus === "DRAFT" && (
                  <Button
                    disabled={isProcessing}
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    className={classes.button}
                    component={Link}
                    to={privateRoutes.admin.signatures.editionForm.path.replace(":signatureId", signature.id)}
                  >
                    Modifier
                  </Button>
                )}
                {signStatus !== "DRAFT" && (
                  <Button
                    disabled={isProcessing}
                    size="small"
                    startIcon={<GetAppIcon />}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={downloadDocument}
                  >
                    Télécharger
                  </Button>
                )}
                {signStatus === "EXPIRED" && (
                  <ConfirmationDialog situationText="En relançant cette signature, vous rendrez la précédente nulle et invaliderez toutes les signatures déjà apposée. Êtes-vous sûr ?">
                    {confirmBeforeAct => (
                      <Button
                        disabled={isProcessing}
                        size="small"
                        startIcon={<ReplayIcon />}
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={e => stopPropagationForConfirmation(e, confirmBeforeAct(() => handleRecreate()))}
                      >
                        Relancer
                      </Button>
                    )}
                  </ConfirmationDialog>
                )}
                {signStatus !== "SIGNED" && (
                  <ConfirmationDialog situationText="Êtes-vous sûr de vouloir dupliquer cette signature ?">
                    {confirmBeforeAct => (
                      <Button
                        disabled={isProcessing}
                        size="small"
                        startIcon={<FileCopyIcon />}
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={e => stopPropagationForConfirmation(e, confirmBeforeAct(() => handleDuplicate()))}
                      >
                        Dupliquer
                      </Button>
                    )}
                  </ConfirmationDialog>
                )}
                {signStatus === "DRAFT" && (
                  <ConfirmationDialog situationText="Êtes-vous sûr de vouloir supprimer cette signature ? Cette action est irréversible.">
                    {confirmBeforeAct => (
                      <Button
                        disabled={isProcessing}
                        size="small"
                        startIcon={<DeleteIcon />}
                        className={classes.button}
                        style={{ color: red[500] }}
                        onClick={e => stopPropagationForConfirmation(e, confirmBeforeAct(() => handleDelete()))}
                      >
                        Supprimer
                      </Button>
                    )}
                  </ConfirmationDialog>
                )}
                {(signStatus === "EXPIRED" || signStatus === "WAITING_SIGN") && (
                  <ConfirmationDialog situationText="Êtes-vous sûr de vouloir annuler cette signature ? Il ne sera plus possible d'apposer des signatures ou de faire valoir ce document.">
                    {confirmBeforeAct => (
                      <Button
                        disabled={isProcessing}
                        size="small"
                        startIcon={<CloseIcon />}
                        className={classes.button}
                        style={{ color: red[500] }}
                        onClick={e => stopPropagationForConfirmation(e, confirmBeforeAct(() => handleCancel()))}
                      >
                        Annuler
                      </Button>
                    )}
                  </ConfirmationDialog>
                )}
              </Grid>
            </Grid>
            {signaturePdfDocument !== "" && (
              <a ref={simulateClick} href={signaturePdfDocument} download="Signature.pdf" style={{ display: "none" }}>
                Download
              </a>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      <EmbeddedContraliaDialog
        open={!!urlSignature}
        UrlSignature={urlSignature}
        transactionId={signature.transactionId}
        handleClose={handleClose}
        closeTransactionPromise={() => SignatureService.closeTransaction(signature.id)}
      />
      {signatureRequestDialogOpen && (
        <YouSignDialog
          open={signatureRequestDialogOpen}
          handleClose={() => {
            setSignatureRequestDialogOpen(false);
            onItemUpdated();
          }}
          docSignature={signature}
        />
      )}
    </React.Fragment>
  );
}
