import React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from "@material-ui/core";
import "moment/locale/fr";
import { Decimal } from "decimal.js-light";
import { makeStyles } from "@material-ui/styles";
import { useFormikContext } from "formik";

import { FINANCIAL_METHOD } from "../../services/DocumentService";
import { totalPriceWithTVA, getComputedFinancingFormDeposit } from "../../utils/numberFormat";

const useStyle = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  textfieldNoMarginTop: {
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.spacing()}px`
    }
  }
}));

function FinancingForm() {
  const classes = useStyle();
  const { handleChange, setFieldValue, values } = useFormikContext();

  const totalWithVates = values.details ? totalPriceWithTVA(values.details) : 0;
  const computedFinancingDeposit = getComputedFinancingFormDeposit(values);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={6} className={classes.item}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="deposit">Acompte à la commande</InputLabel>
          <OutlinedInput
            id="deposit"
            name="deposit"
            type="number"
            value={values.financingForm.deposit}
            onChange={e => setFieldValue("financingForm.deposit", e.target.value)}
            inputProps={{ min: "0" }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Basculer le mode d'acompte"
                  onClick={() => {
                    setFieldValue(
                      "financingForm.depositMethod",
                      values.financingForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE
                        ? FINANCIAL_METHOD.HARD_VALUE
                        : FINANCIAL_METHOD.PERCENTAGE
                    );
                  }}
                >
                  {values.financingForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE ? "%" : "€"}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={185}
          />
          <FormHelperText id="deposit-helper-text">
            soit un acompte de{" "}
            {values.financingForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE
              ? `${computedFinancingDeposit}€`
              : `${
                  values.financingForm.deposit > 0
                    ? new Decimal((values.financingForm.deposit / totalWithVates) * 100)
                        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
                        .toNumber()
                        .toFixed(2)
                    : 0
                }%`}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} className={classes.item}>
        <TextField
          label="Règlement fin de prestation"
          margin="normal"
          variant="outlined"
          type="number"
          fullWidth
          value={values.financingForm.endWorkPayment}
          name="financingForm.endWorkPayment"
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>
          }}
          className={classes.textfieldNoMarginTop}
        />
      </Grid>

      <Grid item xs={12} sm={6} className={classes.item}>
        <TextField
          name="totalAmount"
          label="Montant total du crédit"
          margin="normal"
          variant="outlined"
          disabled
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>
          }}
          value={
            Number(totalWithVates) - Number(computedFinancingDeposit) - Number(values.financingForm.endWorkPayment)
          }
        />
      </Grid>

      <Grid item xs={12} sm={6} className={classes.item}>
        <TextField
          label="Etablissement financier"
          margin="normal"
          variant="outlined"
          type="text"
          fullWidth
          value={values.financingForm.financingInstitution}
          name="financingForm.financingInstitution"
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} sm={6} className={classes.item}>
        <TextField
          label="Nombre de mensualités"
          margin="normal"
          variant="outlined"
          type="number"
          fullWidth
          value={values.financingForm.instalmentNumber}
          name="financingForm.instalmentNumber"
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} sm={6} className={classes.item}>
        <TextField
          label="Montant des mensualités"
          margin="normal"
          variant="outlined"
          type="number"
          fullWidth
          value={values.financingForm.instalmentAmount}
          name="financingForm.instalmentAmount"
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} className={classes.item}>
        <TextField
          label="Coût/mois de l'assurance"
          margin="normal"
          variant="outlined"
          type="number"
          fullWidth
          value={values.financingForm.monthlyCostInsurance}
          name="financingForm.monthlyCostInsurance"
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} className={classes.item}>
        <TextField
          label="TAEG"
          margin="normal"
          variant="outlined"
          type="number"
          fullWidth
          value={values.financingForm.taeg}
          name="financingForm.taeg"
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} className={classes.item}>
        <TextField
          label="Taux débiteur"
          margin="normal"
          variant="outlined"
          type="number"
          fullWidth
          value={values.financingForm.overdrawnRate}
          name="financingForm.overdrawnRate"
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} className={classes.item}>
        <TextField
          label="Montant total dû"
          margin="normal"
          variant="outlined"
          type="number"
          helperText="(hors assurance)"
          fullWidth
          value={values.financingForm.totalAmount}
          name="financingForm.totalAmount"
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>
          }}
        />
      </Grid>
    </Grid>
  );
}

export default FinancingForm;
