import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  InputAdornment,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Form, Formik } from "formik";
import AdminService from "../../../../services/AdminService";
import ArrayField from "./ArrayField";
import AdditionalInformationArrayField from "./AdditionalInformationArrayField";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block"
  },
  card: { height: "100%" },
  row: {
    display: "flex",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  rowPicto: {
    flexBasis: 40,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  companyItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  }
}));

export default function CompanyMentions({ company, onPatch }) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (
    { invoicePaymentTerms, invoicePaymentDelay, invoicePaymentPenalty, additionalInformation },
    onSuccess
  ) => {
    AdminService.patchCompany({
      ...company,
      invoicePaymentTerms,
      invoicePaymentDelay,
      invoicePaymentPenalty,
      additionalInformation
    })
      .then(updatedCompany => {
        onPatch(updatedCompany);
        enqueueSnackbar("L'entreprise a été mise à jour", { variant: "success" });
        onSuccess({
          invoicePaymentTerms: updatedCompany.invoicePaymentTerms || [],
          invoicePaymentDelay: updatedCompany.invoicePaymentDelay || [],
          invoicePaymentPenalty: updatedCompany.invoicePaymentPenalty || [],
          additionalInformation: updatedCompany.additionalInformation || []
        });
      })
      .catch(err => {
        console.log(" ERROR ", err);
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'entreprise", {
          variant: "error"
        });
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            invoicePaymentTerms: company.invoicePaymentTerms || [],
            invoicePaymentDelay: company.invoicePaymentDelay || [],
            invoicePaymentPenalty: company.invoicePaymentPenalty || [],
            additionalInformation: company.additionalInformation || []
          }}
          onSubmit={(values, formikBag) => {
            handleSubmit(
              {
                invoicePaymentTerms: values.invoicePaymentTerms.filter(value => !!value),
                invoicePaymentDelay: values.invoicePaymentDelay.filter(value => !!value),
                invoicePaymentPenalty: values.invoicePaymentPenalty.filter(value => !!value),
                additionalInformation: values.additionalInformation.filter(value => !!value.content)
              },
              data => {
                formikBag.setValues({ ...data });
              }
            );
          }}
        >
          {({ handleReset }) => (
            <Form>
              <Card className={classes.card}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h3">
                    Modalités de paiement facture et mentions complémentaires
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                    <ArrayField
                      title="Modes de paiement (facture)"
                      name="invoicePaymentTerms"
                      addLabel="Ajouter un mode de paiement"
                    />
                    <ArrayField
                      title="Délais de paiement (facture)"
                      name="invoicePaymentDelay"
                      addLabel="Ajouter un délai de paiement"
                      inputProps={{
                        type: "number",
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="caption">jours</Typography>
                            </InputAdornment>
                          )
                        }
                      }}
                    />
                    <ArrayField
                      title="Pénalités de retard (facture)"
                      name="invoicePaymentPenalty"
                      addLabel="Ajouter une pénalité de retard"
                    />
                    <AdditionalInformationArrayField />
                  </div>
                </CardContent>

                <CardActions style={{ justifyContent: "flex-end" }}>
                  <Button variant="text" onClick={handleReset}>
                    Annuler
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    Enregistrer
                  </Button>
                </CardActions>
              </Card>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
