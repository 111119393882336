import { Box, Button, TextField } from "@material-ui/core";
import React from "react";
import AdminService from "../../services/AdminService";

export default function AddDocumentTemplate() {
  const [name, setName] = React.useState("");
  const [path, setPath] = React.useState("");

  const handleSubmit = () => {
    AdminService.addDocumentTemplate({ name, path })
      .then(() => {
        setName("");
        setPath("");
      })
      .catch(e => {
        console.log(" ERROR ", e);
      });
  };

  return (
    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", gap: "12px" }}>
      <TextField
        name="name"
        label="Nom du template"
        margin="none"
        size="small"
        variant="outlined"
        fullWidth
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <TextField
        name="path"
        label="Fichier du template"
        margin="none"
        size="small"
        variant="outlined"
        fullWidth
        value={path}
        onChange={e => setPath(e.target.value)}
      />
      <Button variant="outlined" color="primary" onClick={handleSubmit}>
        Valider
      </Button>
    </Box>
  );
}
