import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  withStyles
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import "moment/locale/fr";
import { FINANCIAL_METHOD } from "../../../stores/NewInvoiceStore";

const styles = theme => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    padding: theme.spacing()
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  textfieldNoMarginTop: {
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.spacing()}px`
    }
  }
});

@inject("newInvoiceStore")
@observer
class FinancingForm extends Component {
  componentDidMount() {
    // Used for the ref to work.
    // https://v3-9-0.material-ui.com/demos/text-fields/#components
    // This will probably be better with V4 of MUI
    this.forceUpdate();
  }

  render() {
    const { classes, newInvoiceStore } = this.props;

    return (
      <Fragment>
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={6} className={classes.item}>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                htmlFor="deposit"
                ref={ref => {
                  // eslint-disable-next-line react/no-find-dom-node
                  this.labelDepositRef = ReactDOM.findDOMNode(ref);
                }}
              >
                Acompte à la commande
              </InputLabel>
              <OutlinedInput
                id="deposit"
                name="deposit"
                type="number"
                value={newInvoiceStore.financingForm.deposit}
                onChange={newInvoiceStore.handleChangeFinancingForm}
                inputProps={{ min: "0" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Basculer le mode d'acompte"
                      onClick={newInvoiceStore.handleChangeFinancingFormDepositMethod}
                    >
                      {newInvoiceStore.financingForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE ? "%" : "€"}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={this.labelDepositRef ? this.labelDepositRef.offsetWidth : 0}
              />
              <FormHelperText id="deposit-helper-text">
                soit un acompte de {newInvoiceStore.computedFinancingFormDeposit}€
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className={classes.item}>
            <TextField
              name="endWorkPayment"
              label="Règlement fin de prestation"
              margin="normal"
              variant="outlined"
              type="number"
              fullWidth
              value={newInvoiceStore.financingForm.endWorkPayment}
              onChange={newInvoiceStore.handleChangeFinancingForm}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
              className={classes.textfieldNoMarginTop}
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.item}>
            <TextField
              name="totalAmount"
              label="Montant total du crédit"
              margin="normal"
              variant="outlined"
              disabled
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
              value={
                Number(newInvoiceStore.amount) -
                Number(newInvoiceStore.computedFinancingFormDeposit) -
                Number(newInvoiceStore.financingForm.endWorkPayment)
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.item}>
            <TextField
              name="financingInstitution"
              label="Etablissement financier"
              margin="normal"
              variant="outlined"
              type="text"
              fullWidth
              value={newInvoiceStore.financingForm.financingInstitution}
              onChange={newInvoiceStore.handleChangeFinancingForm}
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.item}>
            <TextField
              name="instalmentNumber"
              label="Nombre de mensualités"
              margin="normal"
              variant="outlined"
              type="number"
              fullWidth
              value={newInvoiceStore.financingForm.instalmentNumber}
              onChange={newInvoiceStore.handleChangeFinancingForm}
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.item}>
            <TextField
              name="instalmentAmount"
              label="Montant des mensualités"
              margin="normal"
              variant="outlined"
              type="number"
              fullWidth
              value={newInvoiceStore.financingForm.instalmentAmount}
              onChange={newInvoiceStore.handleChangeFinancingForm}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.item}>
            <TextField
              name="monthlyCostInsurance"
              label="Coût/mois de l'assurance"
              margin="normal"
              variant="outlined"
              type="number"
              fullWidth
              value={newInvoiceStore.financingForm.monthlyCostInsurance}
              onChange={newInvoiceStore.handleChangeFinancingForm}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.item}>
            <TextField
              name="taeg"
              label="TAEG"
              margin="normal"
              variant="outlined"
              type="number"
              fullWidth
              value={newInvoiceStore.financingForm.taeg}
              onChange={newInvoiceStore.handleChangeFinancingForm}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.item}>
            <TextField
              name="overdrawnRate"
              label="Taux débiteur"
              margin="normal"
              variant="outlined"
              type="number"
              fullWidth
              value={newInvoiceStore.financingForm.overdrawnRate}
              onChange={newInvoiceStore.handleChangeFinancingForm}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.item}>
            <TextField
              name="totalAmount"
              label="Montant total dû"
              margin="normal"
              variant="outlined"
              type="number"
              helperText="(hors assurance)"
              fullWidth
              value={newInvoiceStore.financingForm.totalAmount}
              onChange={newInvoiceStore.handleChangeFinancingForm}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(FinancingForm);
