import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Tooltip,
  Chip
} from "@material-ui/core";
import { withRouter } from "react-router";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import UpdateIcon from "@material-ui/icons/Update";
import moment from "moment";

import AdminService from "../../../services/AdminService";
import TableAction from "../../TableAction";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../../dialog/ConfirmationDialog";
import CompaniesListFilter, { defaultListFilter } from "./CompaniesListFilter";
import TableSortableHeader from "../../table/TableSortableHeader";
import { getColorFromInvoiceDate, getColorFromSignatures } from "../../../utils/admin";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#F7F7F7"
    }
  }
}));

function CompaniesList({ history }) {
  const [companies, setCompanies] = useState();
  const [companiesCount, setCompaniesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState(defaultListFilter);
  const [loading, setLoading] = useState(true);
  const [currentSort, setCurrentSort] = useState(null);
  const [focusedCompany, setFocusedCompany] = useState();

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const fetchCompanies = () => {
    const ziFilter = Object.assign(
      {},
      filter,
      { from: filter.from ? filter.from.format("YYYY-MM-DD") : "" },
      { to: filter.to ? filter.to.format("YYYY-MM-DD") : "" },
      { nextInvoiceDateFrom: filter.nextInvoiceDateFrom ? filter.nextInvoiceDateFrom.format("YYYY-MM-DD") : "" },
      { nextInvoiceDateTo: filter.nextInvoiceDateTo ? filter.nextInvoiceDateTo.format("YYYY-MM-DD") : "" }
    );
    AdminService.getAllCompanies({
      page: currentPage,
      sort: currentSort ? currentSort.column : undefined,
      order: currentSort ? currentSort.order : undefined,
      filter: ziFilter
    })
      .then(resp => {
        setCompaniesCount(resp.totalCount);
        setCompanies(resp.content);
        setLoading(false);
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la récuparation des entreprises", {
          variant: "error"
        });
      });
  };

  useEffect(() => {
    fetchCompanies();
  }, [currentPage, filter, currentSort]);

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  const handleEdit = company => {
    history.push(`/superbg/entreprises/details/${company.id}/modification`);
  };

  const handleDeletion = companyId => {
    AdminService.deleteCompany(companyId)
      .then(() => {
        enqueueSnackbar("L'entreprise a été supprimée", { variant: "info" });
        fetchCompanies();
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la suppression de l'entreprise", { variant: "error" });
      });
  };

  const handleSort = arg => setCurrentSort(arg);

  const updateFilter = arg => setFilter(arg);

  const handleToggleRestriction = company => {
    AdminService.patchCompany({ ...company, restricted: !company.restricted })
      .then(() => {
        enqueueSnackbar("L'entreprise a été mise à jour", { variant: "info" });
        fetchCompanies();
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'entreprise", { variant: "error" });
      });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <CompaniesListFilter
          filter={filter}
          defaultFilter={defaultListFilter}
          updateFilter={updateFilter}
          onSubmit={fetchCompanies}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 25 }}>
                  <TableSortableHeader
                    onClick={handleSort}
                    className={classes.sortableHeader}
                    currentSort={currentSort}
                    attribute="company.nextInvoiceDate"
                  >
                    FA
                  </TableSortableHeader>
                </TableCell>
                <TableCell style={{ width: 20 }}>
                  <TableSortableHeader
                    onClick={handleSort}
                    className={classes.sortableHeader}
                    currentSort={currentSort}
                    attribute="company.signatures"
                  >
                    S
                  </TableSortableHeader>
                </TableCell>
                <TableCell>
                  <TableSortableHeader
                    onClick={handleSort}
                    className={classes.sortableHeader}
                    currentSort={currentSort}
                    attribute="company.name"
                  >
                    Raison sociale
                  </TableSortableHeader>{" "}
                  /{" "}
                  <TableSortableHeader
                    onClick={handleSort}
                    className={classes.sortableHeader}
                    currentSort={currentSort}
                    attribute="company.siret"
                  >
                    SIRET
                  </TableSortableHeader>
                </TableCell>
                {/* <TableCell>
                  <TableSortableHeader
                    onClick={handleSort}
                    className={classes.sortableHeader}
                    currentSort={currentSort}
                    attribute="company.email"
                  >
                    Email
                  </TableSortableHeader>
                </TableCell> */}
                <TableCell>
                  <TableSortableHeader
                    onClick={handleSort}
                    className={classes.sortableHeader}
                    currentSort={currentSort}
                    attribute="company.siret"
                  >
                    Téléphone
                  </TableSortableHeader>
                </TableCell>
                <TableCell>
                  <TableSortableHeader
                    onClick={handleSort}
                    className={classes.sortableHeader}
                    currentSort={currentSort}
                    attribute="company.zipCode"
                  >
                    Adresse
                  </TableSortableHeader>
                </TableCell>
                {!filter.showDeleted && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {companies && !loading ? (
                companies.map(company => (
                  <TableRow
                    key={company.id}
                    selected={company.id === focusedCompany}
                    onClick={() => {
                      history.push(`/superbg/entreprises/details/${company.id}`);
                    }}
                    className={classes.row}
                  >
                    <TableCell>
                      {company.nextInvoiceDate ? (
                        <Tooltip title={moment(company.nextInvoiceDate).format("DD/MM/YYYY")}>
                          <UpdateIcon style={{ color: getColorFromInvoiceDate(company.nextInvoiceDate) }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Aucune date renseignée">
                          <UpdateIcon style={{ color: getColorFromInvoiceDate(company.nextInvoiceDate) }} />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColorFromSignatures(company.signatures, company.plan)
                      }}
                    >
                      {company.signatures}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span style={{ fontWeight: "bold" }}>{company.name.toUpperCase()}</span>
                      {company.restricted && (
                        <Chip
                          variant="outlined"
                          style={{ marginLeft: 8, borderColor: "red" }}
                          size="small"
                          label="Bloqué 🖕"
                        />
                      )}
                      <br />
                      {company.siret}
                    </TableCell>
                    {/* <TableCell>{company.email}</TableCell> */}
                    <TableCell>{company.phone}</TableCell>
                    <TableCell>
                      <p>
                        {company.address}
                        <br />
                        {company.zipCode} {company.city}
                      </p>
                    </TableCell>
                    {!filter.showDeleted && (
                      <TableCell>
                        <TableAction
                          onOpen={() => {
                            setFocusedCompany(company.id);
                          }}
                          onClose={() => setFocusedCompany(null)}
                        >
                          <MenuItem onClick={() => handleEdit(company)}>Modifier</MenuItem>
                          <ConfirmationDialog>
                            {confirmBeforeAct => (
                              <MenuItem
                                onClick={e =>
                                  stopPropagationForConfirmation(e, confirmBeforeAct(() => handleDeletion(company.id)))
                                }
                              >
                                Supprimer
                              </MenuItem>
                            )}
                          </ConfirmationDialog>
                          <ConfirmationDialog>
                            {confirmBeforeAct => (
                              <MenuItem
                                onClick={e =>
                                  stopPropagationForConfirmation(
                                    e,
                                    confirmBeforeAct(() => handleToggleRestriction(company))
                                  )
                                }
                              >
                                {company.restricted ? "Débloquer" : "Bloquer"}
                              </MenuItem>
                            )}
                          </ConfirmationDialog>
                        </TableAction>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={filter.showDeleted ? 5 : 6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {companies && (
            <TablePagination
              component="div"
              count={companiesCount}
              page={currentPage}
              rowsPerPage={50}
              rowsPerPageOptions={[]}
              backIconButtonProps={{ "aria-label": "Page précédente" }}
              nextIconButtonProps={{ "aria-label": "Page suivante" }}
              onChangePage={handleChangePage}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withRouter(CompaniesList);
