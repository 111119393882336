import React, { Fragment, useState } from "react";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import CompaniesListLight from "./CompaniesListLight";

function LinkCompanyDialog({ defaultOpen = false, onPick, defaultPick = {} }) {
  const [showDialog, setShowDialog] = useState(defaultOpen);
  const [selectedCompany, setSelectedCompany] = useState(defaultPick.id ? defaultPick : null);

  const handlePick = company => {
    setSelectedCompany(company);
    onPick(company.id);
  };

  return (
    <Fragment>
      <Chip
        variant="outlined"
        icon={<BusinessIcon />}
        label={selectedCompany ? selectedCompany.name : "[ Sélectionner une entreprise ]"}
        onClick={() => {
          setShowDialog(true);
        }}
      />
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={showDialog}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth={false}
      >
        <DialogTitle id="simple-dialog-title">Sélection d&apos;une entreprise</DialogTitle>
        <DialogContent style={{ width: "70vw", maxWidth: 900, height: "65vh" }}>
          <CompaniesListLight
            compact
            handleSubmit={arg => {
              setShowDialog(false);
              handlePick(arg);
            }}
          />
        </DialogContent>
        {selectedCompany !== null && (
          <DialogActions>
            <Button
              onClick={() => {
                setShowDialog(false);
              }}
            >
              Annuler
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Fragment>
  );
}

export default LinkCompanyDialog;
