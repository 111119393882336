import React, { Component, Fragment } from "react";
import { Grid, Paper, Typography, withStyles, Button, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import "moment/locale/fr";
import LinkClientDialog from "../dialog/LinkClientDialog";
import InvoicePaymentMentions from "./InvoicePaymentMentions";
import DocumentAdditionnalInformations from "../DocumentAdditionalInformation";
import DeliveryAddressDialog from "../dialog/DeliveryAddressDialog";

const styles = theme => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    padding: theme.spacing()
  },
  item: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  radioGroup: {
    flexDirection: "row"
  },
  radio: {
    display: "flex",
    alignItems: "center"
  },
  radioIcon: {
    marginRight: theme.spacing()
  },
  fullWidth: {
    width: "auto"
  }
});

@inject("newInvoiceStore", "sessionStore")
@observer
class InvoiceClientStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClientDialog: !props.newInvoiceStore.isNewInvoiceClientOK,
      showDeliveryAddressDialog: false
    };
  }

  componentDidMount() {
    // Used for the ref to work.
    // https://v3-9-0.material-ui.com/demos/text-fields/#components
    // This will probably be better with V4 of MUI
    this.forceUpdate();
  }

  handleCloseClientDialog = res => {
    if (res) {
      const { newInvoiceStore } = this.props;
      newInvoiceStore.setClient({ ...res, type: res.businessName ? "morale" : "physique" });
      newInvoiceStore.toggleUseSpecificDeliveryAddress(false);
    }
    this.setState({ showClientDialog: false });
  };

  handleCloseDeliveryAddressDialog = res => {
    if (res) {
      const { newInvoiceStore } = this.props;
      newInvoiceStore.patchDeliveryAddress({ ...res });
    }
    this.setState({ showDeliveryAddressDialog: false });
  };

  render() {
    const { classes, newInvoiceStore, sessionStore } = this.props;
    const { showClientDialog, showDeliveryAddressDialog } = this.state;
    const hasAdditionalInformation = sessionStore.userHasAccess({
      requiredFeatures: ["OPTION_ADDITIONAL_INFORMATION"]
    });
    const hasDeliveryAddress = sessionStore.userHasAccess({
      requiredFeatures: ["OPTION_CONTACT_DELIVERY_ADDRESS"]
    });

    return (
      <Fragment>
        <Paper className={classes.paper}>
          <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.item}>
              <Typography variant="h5">
                {hasDeliveryAddress ? "Adresse de facturation" : "Coordonnées du client"}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <div
                style={{
                  display: "flex",
                  minHeight: 200,
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                {newInvoiceStore.isNewInvoiceClientOK ? (
                  <div>
                    <div style={{ marginBottom: 25, marginTop: 15, display: "flex", flexDirection: "row" }}>
                      <div style={{ fontWeight: "bold" }}>
                        <div>
                          <p>Entreprise </p>
                        </div>
                        <div>
                          <p>Prénom / Nom </p>
                        </div>
                        <div>
                          <p>Adresse </p>
                        </div>
                        <div>
                          <p>Code postal / Ville </p>
                        </div>
                        <div>
                          <p>Téléphone </p>
                        </div>
                        <div>
                          <p>Email </p>
                        </div>
                      </div>
                      <div style={{ paddingLeft: 15 }}>
                        <div>
                          <p>{newInvoiceStore.client.businessName}&nbsp;</p>
                        </div>
                        <div>
                          <p>
                            {newInvoiceStore.client.firstname} {newInvoiceStore.client.lastname}&nbsp;
                          </p>
                        </div>
                        <div>
                          <p>{newInvoiceStore.client.address}&nbsp;</p>
                        </div>
                        <div>
                          <p>
                            {newInvoiceStore.client.zipCode} {newInvoiceStore.client.city}&nbsp;
                          </p>
                        </div>
                        <div>
                          <p>{newInvoiceStore.client.phone}&nbsp;</p>
                        </div>
                        <div>
                          <p>{newInvoiceStore.client.email}&nbsp;</p>
                        </div>
                      </div>
                    </div>
                    <div style={{ justifyContent: "center", marginBottom: 15, display: "flex" }}>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={e => {
                          e.persist();
                          this.setState({ showClientDialog: true });
                        }}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({ showClientDialog: true })}
                  >
                    Choisir un client
                  </Button>
                )}
              </div>
              {showClientDialog && (
                <LinkClientDialog handleClose={this.handleCloseClientDialog} client={newInvoiceStore.client} />
              )}
            </Grid>
          </Grid>
        </Paper>
        {hasDeliveryAddress && newInvoiceStore.isNewInvoiceClientOK && (
          <Paper className={classes.paper}>
            <Grid container className={classes.container}>
              <Grid item xs={12} className={classes.item}>
                <Typography variant="h5">Adresse de livraison</Typography>
              </Grid>
              <Grid item xs={12} className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!newInvoiceStore.useSpecificDeliveryAddress}
                      color="primary"
                      onClick={() => newInvoiceStore.toggleUseSpecificDeliveryAddress()}
                    />
                  }
                  label="Adresse de livraison identique à l’adresse de facturation"
                />
              </Grid>
              {newInvoiceStore.useSpecificDeliveryAddress && (
                <Grid item xs={12} className={classes.item}>
                  <div
                    style={{
                      display: "flex",
                      minHeight: 200,
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    {newInvoiceStore.isNewInvoiceDeliveryAddressOK ? (
                      <div>
                        <div style={{ marginBottom: 25, marginTop: 15, display: "flex", flexDirection: "row" }}>
                          <div style={{ fontWeight: "bold" }}>
                            <div>
                              <p>Entreprise </p>
                            </div>
                            <div>
                              <p>Prénom / Nom </p>
                            </div>
                            <div>
                              <p>Adresse </p>
                            </div>
                            <div>
                              <p>Code postal / Ville </p>
                            </div>
                            <div>
                              <p>Téléphone </p>
                            </div>
                            <div>
                              <p>Email </p>
                            </div>
                          </div>
                          <div style={{ paddingLeft: 15 }}>
                            <div>
                              <p>{newInvoiceStore.deliveryAddress.businessName}&nbsp;</p>
                            </div>
                            <div>
                              <p>
                                {newInvoiceStore.deliveryAddress.firstname}&nbsp;
                                {newInvoiceStore.deliveryAddress.lastname}
                              </p>
                            </div>
                            <div>
                              <p>{newInvoiceStore.deliveryAddress.address}&nbsp;</p>
                            </div>
                            <div>
                              <p>
                                {newInvoiceStore.deliveryAddress.zipCode} {newInvoiceStore.deliveryAddress.city}&nbsp;
                              </p>
                            </div>
                            <div>
                              <p>{newInvoiceStore.deliveryAddress.phone}&nbsp;</p>
                            </div>
                            <div>
                              <p>{newInvoiceStore.deliveryAddress.email}&nbsp;</p>
                            </div>
                          </div>
                        </div>
                        <div style={{ justifyContent: "center", marginBottom: 15, display: "flex" }}>
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={e => {
                              e.persist();
                              this.setState({ showDeliveryAddressDialog: true });
                            }}
                          >
                            Modifier
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({ showDeliveryAddressDialog: true })}
                      >
                        Saisir l&apos;adresse
                      </Button>
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
            {showDeliveryAddressDialog && (
              <DeliveryAddressDialog
                handleClose={this.handleCloseDeliveryAddressDialog}
                deliveryAddress={newInvoiceStore.deliveryAddress}
                client={newInvoiceStore.client}
              />
            )}
          </Paper>
        )}
        <Paper className={classes.paper}>
          <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.item}>
              <Typography variant="h5">Commentaires</Typography>
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <TextField
                name="comments"
                placeholder="Les commentaires figureront sur la facture"
                label="Commentaires"
                margin="normal"
                variant="outlined"
                fullWidth
                multiline
                type="text"
                helperText="Les commentaires figureront sur la facture"
                value={newInvoiceStore.comments}
                onChange={newInvoiceStore.handleChange}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.item}>
              <Typography variant="h5">Modalités de paiement</Typography>
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <InvoicePaymentMentions
                company={sessionStore.user.company}
                onPatch={newInvoiceStore.patchPaymentMentions}
                defaultPaymentMentions={newInvoiceStore.paymentMentions}
              />
            </Grid>
          </Grid>
        </Paper>
        {hasAdditionalInformation && (
          <Paper className={classes.paper}>
            <Grid container className={classes.container}>
              <Grid item xs={12} className={classes.item}>
                <Typography variant="h5">Mentions complémentaires</Typography>
              </Grid>
              <Grid item xs={12} className={classes.item}>
                <DocumentAdditionnalInformations
                  company={sessionStore.user.company}
                  onPatch={newInvoiceStore.patchAdditionalInformation}
                  defaultAdditionalInformation={newInvoiceStore.additionalInformation}
                />
              </Grid>
            </Grid>
          </Paper>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(InvoiceClientStep);
