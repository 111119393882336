import React, { Component } from "react";
import { AppBar, Button, IconButton, Toolbar, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/styles";

import CookieService from "../../services/CookieService";

const styles = theme => ({
  alertAppBar: {
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: "#1a7ec2"
  }
});

@inject("sessionStore")
@observer
class ImpersonateManager extends Component {
  render() {
    const { sessionStore, classes } = this.props;
    if (CookieService.getCookie("logAs"))
      return (
        <AppBar position="fixed" id="topalert" className={classes.alertAppBar}>
          <Toolbar variant="dense" style={{ justifyContent: "center" }}>
            <IconButton className="menuIcon" color="inherit" aria-label="Menu" disabled>
              <WarningIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Connecté en tant que {sessionStore.user.email}
            </Typography>
            <IconButton className="menuIcon" color="inherit" aria-label="Menu" disabled>
              <WarningIcon />
            </IconButton>
            <Button
              variant="contained"
              onClick={() => {
                CookieService.deleteCookie("logAs");
                window.location.replace("/");
              }}
            >
              Deconnecter
            </Button>
          </Toolbar>
        </AppBar>
      );
    return <div />;
  }
}

export default withStyles(styles)(ImpersonateManager);
