import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Card, CardContent, FormControl, Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  root: { marginBottom: 0, marginTop: 8 },
  content: { padding: `${theme.spacing(2)} important` }
});

@inject("newOrderFormStore", "sessionStore")
@observer
class OrderFormCurrency extends Component {
  handleChange = event => {
    const { newOrderFormStore } = this.props;
    newOrderFormStore.handleChangeCurrency(event.target.value);
  };

  render() {
    const { classes, newOrderFormStore } = this.props;

    return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" style={{ marginTop: 8 }}>
                Devise du bon de commande
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <FormControl>
                <Select style={{ width: 200 }} value={newOrderFormStore.currency} onChange={this.handleChange}>
                  <MenuItem value="EUR">Euros (€)</MenuItem>
                  <MenuItem value="USD">Dollars ($)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(OrderFormCurrency);
