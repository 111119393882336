import React, { useMemo, useState } from "react";
import { Button, CircularProgress, Paper, TextField, Typography, Grid, makeStyles } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import { useSnackbar } from "notistack";

import UserService from "../services/UserService";
import { disconnected } from "../routes";

const useStyles = makeStyles(theme => ({
  buttons: {
    margin: theme.spacing()
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  }
}));

export default function ResetPasswordPage({ history, match }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [password, setPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const isPasswordValid = useMemo(() => password.length > 7, [password]);

  const handleChange = e => setPassword(e.target.value);

  const handleSubmit = e => {
    e.preventDefault();

    if (isPasswordValid) {
      setIsProcessing(true);
      UserService.resetPasswordByToken(match.params.token, password)
        .then(() => {
          enqueueSnackbar("Votre mot de passe a été modifié avec succès", {
            variant: "success"
          });
          setIsProcessing(false);
          history.push({ pathname: disconnected.signin.path });
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la réinitialisation du mot de passe", { variant: "error" });
          setIsProcessing(false);
        });
    }
  };

  return (
    <Grid container>
      <Grid item sm={1} md={2} lg={3} xl={4} />
      <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
        <Paper className={classes.paper}>
          <Typography align="center" variant="h4">
            Reinitialiser le mot de passe
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              name="password"
              placeholder="Mot de passe"
              label="Mot de passe"
              margin="normal"
              type="password"
              required
              fullWidth
              value={password}
              variant="outlined"
              onChange={handleChange}
              helperText="Votre mot de passe doit contenir au minimum 8 caractères"
            />
            <Grid container spacing={2} className={classes.buttons}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!isPasswordValid}
                >
                  Réinitialiser le mot de passe
                  {isProcessing && <CircularProgress size={24} className={classes.button} />}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}
