import React, { Component } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  withStyles,
  Typography,
  Grid,
  CircularProgress,
  Checkbox
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { withSnackbar } from "notistack";

import CompanyService from "../../services/CompanyService";
import UserService from "../../services/UserService";
import NotFoundPage from "../../pages/NotFoundPage";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  paper: {
    padding: theme.spacing(2)
  },
  wrapper: {
    marginTop: theme.spacing(3)
  },
  cardheader: {
    paddingBottom: 0
  },
  complexLabel: {
    display: "flex",
    alignItems: "center"
  },
  complexLabelInput: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 100
  }
});

const DEFAULT_PAYMENT_DELAY = 30;

const handleWording = type => {
  switch (type) {
    case "ORDER":
      return "du bon de commande";
    case "INVOICE":
      return "de la facture";
    case "ESTIMATE":
      return "du devis";
    default:
      return "";
  }
};

@inject("sessionStore")
@observer
class PaymentSettings extends Component {
  constructor(props) {
    super(props);
    const { company } = props.sessionStore.user;
    this.state = { company, variant: undefined };
  }

  componentDidMount() {
    const { sessionStore, match } = this.props;
    if (!sessionStore.user.company) {
      UserService.me().then(response => {
        const { company } = response;
        company.paymentDelay = company.paymentDelay >= 0 ? company.paymentDelay : DEFAULT_PAYMENT_DELAY;
        this.setState({ company });
      });
    }
    CompanyService.getCompanyVariantByType(match.params.variantType)
      .then(response => {
        if (response.content.length === 1) {
          const variant = response.content[0];
          const hasPaymentDelay = variant.paymentDelay !== undefined && variant.paymentDelay !== null;
          this.setState({
            variant,
            paymentDelayType: hasPaymentDelay ? (variant.paymentDelay > 0 ? "DAYS" : "NOW") : undefined,
            paymentDelayInputValue: hasPaymentDelay
              ? variant.paymentDelay > 0
                ? variant.paymentDelay
                : 30
              : undefined,
            enablePaymentDelayVariant: hasPaymentDelay,
            enablePaymentTermsVariant: !!variant.paymentTerms,
            enablePaymentPenaltyVariant: !!variant.paymentPenalty
          });
        } else {
          this.setState({
            variant: {
              type: match.params.variantType,
              paymentDelay: null,
              paymentPenalty: null,
              paymentTerms: null
            },
            paymentDelayType: undefined,
            paymentDelayInputValue: "",
            enablePaymentDelayVariant: false,
            enablePaymentTermsVariant: false,
            enablePaymentPenaltyVariant: false
          });
        }
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la récupération des paramètres", { variant: "error" });
      });
  }

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    const { variant } = this.state;
    this.setState({ variant: { ...variant, [name]: value } });
  };

  handleSubmit = () => {
    const { variant, enablePaymentDelayVariant, enablePaymentPenaltyVariant, enablePaymentTermsVariant } = this.state;
    const { type, paymentDelay, paymentTerms, paymentPenalty } = variant;
    const _variant = {
      type,
      paymentDelay: enablePaymentDelayVariant ? paymentDelay : null,
      paymentTerms: enablePaymentTermsVariant ? paymentTerms : null,
      paymentPenalty: enablePaymentPenaltyVariant ? paymentPenalty : null
    };
    if (variant.id) {
      this.handlePatchVariant(variant.id, { ..._variant });
    } else {
      this.handleAddVariant({ ..._variant });
    }
  };

  handlePaymentDelayChange = e => {
    const {
      target: { value }
    } = e;
    const { variant, paymentDelayInputValue } = this.state;
    this.setState({
      paymentDelayType: value,
      variant: { ...variant, paymentDelay: value === "DAYS" ? paymentDelayInputValue : 0 }
    });
  };

  handlePaymentDelayInputValueChange = e => {
    const {
      target: { value }
    } = e;
    const { variant, paymentDelayType } = this.state;
    this.setState({
      paymentDelayInputValue: value,
      variant: {
        ...variant,
        paymentDelay: paymentDelayType === "DAYS" ? parseInt(value, 10) : parseInt(variant.paymentDelay, 10)
      }
    });
  };

  handleCheckboxChange = e => {
    const {
      target: { name }
    } = e;
    this.setState(curr => ({ [name]: !curr[name] }));
  };

  handleAddVariant = data => {
    const { match, enqueueSnackbar } = this.props;
    CompanyService.addVariant(data)
      .then(() => {
        enqueueSnackbar(`Modalités de paiement ${handleWording(match.params.variantType)} enregistrées`, {
          variant: "success"
        });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de l'enregistrement", { variant: "error" });
      });
  };

  handlePatchVariant = (variantId, data) => {
    const { match, enqueueSnackbar } = this.props;
    CompanyService.patchVariant(variantId, data)
      .then(() => {
        enqueueSnackbar(`Modalités de paiement ${handleWording(match.params.variantType)} enregistrées`, {
          variant: "success"
        });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de l'enregistrement", { variant: "error" });
      });
  };

  render() {
    const { classes, sessionStore, match } = this.props;
    const {
      company,
      paymentDelayType,
      paymentDelayInputValue,
      variant,
      enablePaymentDelayVariant,
      enablePaymentTermsVariant,
      enablePaymentPenaltyVariant
    } = this.state;

    if (!company || !variant) {
      return <CircularProgress />;
    }

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["SETTING_WRITE", "SETTING_READ"],
        aclType: "oneof",
        requiredFeatures: ["SETTING"]
      }) ||
      ["ORDER", "ESTIMATE", "INVOICE"].indexOf(match.params.variantType) === -1
    ) {
      return <NotFoundPage />;
    }

    const isSubmissible =
      (enablePaymentDelayVariant
        ? paymentDelayType === "NOW" || (paymentDelayType === "DAYS" && paymentDelayInputValue > 0)
        : true) &&
      (enablePaymentPenaltyVariant ? variant.paymentPenalty : true) &&
      (enablePaymentTermsVariant ? variant.paymentTerms : true);

    const canEdit = sessionStore.userHasAccess({
      requiredAcls: ["SETTING_WRITE"],
      requiredFeatures: ["SETTING"]
    });

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Modalités de paiement {handleWording(match.params.variantType)}</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Card className={classes.wrapper}>
              <CardHeader title="Modes de paiement" className={classes.cardheader} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="enablePaymentTermsVariant"
                          checked={!enablePaymentTermsVariant}
                          onChange={this.handleCheckboxChange}
                          color="primary"
                          disabled={!canEdit}
                        />
                      }
                      label={
                        <Typography>
                          Utiliser la valeur par défaut : <strong>{company.paymentTerms}</strong>
                        </Typography>
                      }
                    />
                  </Grid>
                  {enablePaymentTermsVariant && (
                    <Grid item xs={12}>
                      <TextField
                        className={classes.input}
                        name="paymentTerms"
                        label="Modes de paiement"
                        placeholder="Mode de paiement : chèque, virement, espèces..."
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                        required={enablePaymentTermsVariant}
                        error={enablePaymentTermsVariant && !variant.paymentTerms}
                        value={variant.paymentTerms}
                        onChange={this.onChange}
                        disabled={!canEdit || !enablePaymentTermsVariant}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.wrapper}>
              <CardHeader title="Escompte" className={classes.cardheader} />
              <CardContent>
                <TextField
                  className={classes.input}
                  label="Escompte"
                  placeholder="Pas d'escompte pour paiement anticipé"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  disabled
                  value="Pas d'escompte pour paiement anticipé"
                />
              </CardContent>
            </Card>
            <Card className={classes.wrapper}>
              <CardHeader title="Délai de paiement" className={classes.cardheader} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="enablePaymentDelayVariant"
                          checked={!enablePaymentDelayVariant}
                          onChange={this.handleCheckboxChange}
                          color="primary"
                          disabled={!canEdit}
                        />
                      }
                      label={
                        <Typography>
                          Utiliser la valeur par défaut :{" "}
                          <strong>
                            {company.paymentDelay === 0
                              ? "À réception"
                              : `Dans un délai de ${company.paymentDelay} jours`}
                          </strong>
                        </Typography>
                      }
                    />
                  </Grid>
                  {enablePaymentDelayVariant && (
                    <Grid item xs={12}>
                      <RadioGroup
                        aria-label="paymentDelayType"
                        name="paymentDelayType"
                        className={classes.group}
                        value={paymentDelayType}
                        onChange={this.handlePaymentDelayChange}
                      >
                        <FormControlLabel
                          value="NOW"
                          control={<Radio color="primary" disabled={!canEdit || !enablePaymentDelayVariant} />}
                          label="À réception"
                        />
                        <FormControlLabel
                          value="DAYS"
                          control={<Radio color="primary" disabled={!canEdit || !enablePaymentDelayVariant} />}
                          label={
                            <div className={classes.complexLabel}>
                              <span>Dans un délai de </span>
                              <TextField
                                onFocus={() => this.setState({ paymentDelayType: "DAYS" })}
                                name="paymentDelayValue"
                                variant="outlined"
                                type="number"
                                error={paymentDelayType === "DAYS" && paymentDelayInputValue < 1}
                                inputProps={{ min: "1", step: "1" }}
                                className={classes.complexLabelInput}
                                value={paymentDelayInputValue}
                                onChange={this.handlePaymentDelayInputValueChange}
                                disabled={!canEdit || !enablePaymentDelayVariant}
                              />
                              <span> jours</span>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.wrapper}>
              <CardHeader title="Pénalités de retard" className={classes.cardheader} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="enablePaymentPenaltyVariant"
                          checked={!enablePaymentPenaltyVariant}
                          onChange={this.handleCheckboxChange}
                          color="primary"
                          disabled={!canEdit}
                        />
                      }
                      label={
                        <Typography>
                          Utiliser la valeur par défaut : <strong>{company.paymentPenalty}</strong>
                        </Typography>
                      }
                    />
                  </Grid>
                  {enablePaymentPenaltyVariant && (
                    <Grid item xs={12}>
                      <TextField
                        className={classes.input}
                        name="paymentPenalty"
                        label="Pénalités de retard"
                        placeholder="Pénalités de retard sans rappel préalable : taux BCE à son opération de refinancement la plus récente majoré de 10 %. Indemnité forfaitaire pour frais de recouvrement : 40 €. Décret n° 2012-1115 du 2 octobre 2012."
                        margin="normal"
                        variant="outlined"
                        multiline
                        required={enablePaymentPenaltyVariant}
                        error={enablePaymentPenaltyVariant && !variant.paymentPenalty}
                        rows={2}
                        fullWidth
                        value={variant.paymentPenalty}
                        onChange={this.onChange}
                        disabled={!canEdit || !enablePaymentPenaltyVariant}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {canEdit && (
          <div className="react-draft-button">
            <div>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
                disabled={!isSubmissible}
              >
                Enregistrer
              </Button>
            </div>
          </div>
        )}
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(PaymentSettings));
