import React, { useEffect, useState } from "react";
import { AppBar, Box, CircularProgress, Dialog, IconButton, Toolbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { TransitionUp } from "../utils/transitions/TransitionUp";

export default function PreviewFileFromApiDialog({ open, handleClose, promise }) {
  // const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      promise.then(res => {
        setIsLoading(false);
        const _blob = new Blob([res], { type: "application/pdf" });
        setFilePath(window.URL.createObjectURL(_blob));
      });
    } else {
      setFilePath("");
    }
  }, [open, promise]);

  return (
    <Dialog fullScreen open={Boolean(open && !!filePath)} onClose={handleClose} TransitionComponent={TransitionUp}>
      <AppBar style={{ position: "relative" }}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isLoading || !filePath ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <iframe src={filePath} style={{ width: "100%", height: "100%" }} />
      )}
    </Dialog>
  );
}
