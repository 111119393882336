import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core";
import moment from "moment";

import NotFoundPage from "../NotFoundPage";
import AdditionalDocumentsList from "../../components/additionalDocument/AdditionalDocumentsList";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  contentContainer: {
    marginTop: -theme.spacing(2)
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
});

@inject("sessionStore")
@observer
class AdditionalDocumentsPage extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const filter = {};
    if (location.state && location.state.from) {
      filter.from = moment(location.state.from);
    }
    if (location.state && location.state.to) {
      filter.to = moment(location.state.to);
    }
    if (location.state && location.state.client) {
      filter.client = location.state.client;
    }
    this.state = { filter };
  }

  render() {
    const { sessionStore } = this.props;
    const { filter } = this.state;

    if (
      !sessionStore.userHasAccess({
        requiredFeatures: ["ADDITIONAL_DOCUMENT"],
        requiredAcls: ["ADDITIONAL_DOCUMENT_READ", "ADDITIONAL_DOCUMENT_MY"],
        aclType: "oneof"
      })
    )
      return <NotFoundPage />;

    return (
      <AdditionalDocumentsList
        user={sessionStore.user}
        filter={filter}
        canSign={sessionStore.userHasAccess({
          requiredAcls: ["SIGN_WRITE"],
          requiredFeatures: ["SIGN"]
        })}
      />
    );
  }
}

export default withStyles(styles)(AdditionalDocumentsPage);
