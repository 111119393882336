import React from "react";
import { Checkbox, MenuItem, TableRow, TableCell, Hidden } from "@material-ui/core";

import { truncate } from "../../utils/textFormat";
import { formatCurrency, formatNumber } from "../../utils/numberFormat";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";
import TableAction from "../TableAction";

export default function ProductRow({
  product,
  selected,
  hasEditAccess,
  onDelete,
  onEdit,
  onDisable,
  onEnable,
  // patchMenuOpenFor,
  toggleSelectionFor
}) {
  const toggleSelection = () => {
    toggleSelectionFor(product.id);
  };

  const handleDelete = () => {
    toggleSelectionFor(product.id, false);
    onDelete(product.id);
  };

  const handleEdit = () => {
    toggleSelectionFor(product.id, false);
    onEdit(product);
  };

  const handleActivation = () => {
    toggleSelectionFor(product.id, false);
    onEnable(product);
  };

  const handleDeactivation = () => {
    toggleSelectionFor(product.id, false);
    onDisable(product);
  };

  return (
    <TableRow key={product.id} selected={selected}>
      <TableCell style={{ padding: 0 }}>
        <Checkbox size="small" checked={selected} color="primary" onChange={toggleSelection} />
      </TableCell>
      <TableCell>{product.category ? product.category.name : "-"}</TableCell>
      <TableCell>
        {product.title}
        {product.reference && (
          <span style={{ color: "#777", fontSize: "0.8em", fontStyle: "italic", display: "block" }}>
            Référence : {product.reference}
          </span>
        )}
      </TableCell>
      <Hidden>
        <TableCell>{truncate(product.description)}</TableCell>
      </Hidden>
      <TableCell align="right">{formatCurrency(product.price)}</TableCell>
      <TableCell align="right">{formatNumber(product.tva)}%</TableCell>
      {hasEditAccess && (
        <TableCell>
          <TableAction>
            {product.isActive ? (
              <MenuItem onClick={() => handleDeactivation()}>Désactiver</MenuItem>
            ) : (
              <MenuItem onClick={() => handleActivation()}>Activer</MenuItem>
            )}
            <MenuItem onClick={() => handleEdit()}>Modifier</MenuItem>
            <ConfirmationDialog>
              {confirmBeforeAct => (
                <MenuItem
                  onClick={e => {
                    stopPropagationForConfirmation(
                      e,
                      confirmBeforeAct(() => handleDelete())
                    );
                  }}
                >
                  Supprimer
                </MenuItem>
              )}
            </ConfirmationDialog>{" "}
          </TableAction>
        </TableCell>
      )}
    </TableRow>
  );
}
