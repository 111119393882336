import React, { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import GetApp from "@material-ui/icons/GetApp";
import { useSnackbar } from "notistack";
import { inject, observer } from "mobx-react";

import { formatCurrency } from "../../utils/numberFormat";
import InvoiceStatusLabel from "../invoice/InvoiceStatusLabel";
import InvoiceService from "../../services/InvoiceService";
import OrderFormStatusLabel from "../orderForm/OrderFormStatusLabel";
import EstimateStatusLabel from "../estimate/EstimateStatusLabel";
import EstimateService from "../../services/EstimateService";
import OrderFormService from "../../services/OrderFormService";

const getReferenceDocumentWithType = additionalDocument => {
  if (additionalDocument) {
    if (additionalDocument.orderForm) {
      return { type: "ORDER_FORM", content: additionalDocument.orderForm };
    }
    if (additionalDocument.estimate) {
      return { type: "ESTIMATE", content: additionalDocument.estimate };
    }
    if (additionalDocument.invoice) {
      return { type: "INVOICE", content: additionalDocument.invoice };
    }
  }
  return undefined;
};

function ADReferenceDocumentDialog({ additionalDocument, onClose, open, sessionStore }) {
  const [download, setDownload] = useState("");
  const [downloadHref, setDownloadHref] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const _document = getReferenceDocumentWithType(additionalDocument);

  const downloadPDF = () => {
    if (_document.type === "INVOICE") {
      InvoiceService.downloadPDF(_document.content)
        .then(res => {
          setDownload(`Facture - ${_document.content.number}.pdf`);
          setDownloadHref(window.URL.createObjectURL(res));
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF de la facture", { variant: "error" });
        });
    }
    if (_document.type === "ESTIMATE") {
      EstimateService.downloadPDF(_document.content)
        .then(res => {
          setDownload(`Devis - ${_document.content.number}.pdf`);
          setDownloadHref(window.URL.createObjectURL(res));
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF du devis", { variant: "error" });
        });
    }
    if (_document.type === "ORDER_FORM") {
      OrderFormService.downloadOrderFormPDF(_document.content)
        .then(res => {
          setDownload(`Bon de commande - ${_document.content.number}.pdf`);
          setDownloadHref(window.URL.createObjectURL(res));
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF du bon de commande", {
            variant: "error"
          });
        });
    }
  };

  const simulateClick = e => {
    if (e !== null) {
      e.click();
    }
    setDownloadHref("");
  };

  const userCanSeeReferenceDocument = () => {
    if (_document.type === "INVOICE") {
      return (
        sessionStore.userHasAccess({
          requiredFeatures: ["INVOICE"],
          requiredAcls: ["INVOICE_READ"]
        }) || _document.content.user.id === sessionStore.user.id
      );
    }
    if (_document.type === "ESTIMATE") {
      return (
        sessionStore.userHasAccess({
          requiredFeatures: ["ESTIMATE"],
          requiredAcls: ["ESTIMATE_READ"]
        }) || _document.content.user.id === sessionStore.user.id
      );
    }
    if (_document.type === "ORDER_FORM") {
      return (
        sessionStore.userHasAccess({
          requiredFeatures: ["ORDER"],
          requiredAcls: ["ORDER_READ"]
        }) || _document.content.user.id === sessionStore.user.id
      );
    }
    return false;
  };

  return (
    <Dialog
      modal="false"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {_document ? (
        <Fragment>
          {_document.type === "ORDER_FORM" && (
            <DialogTitle id="alert-dialog-title">Bon de commande lié au document additionnel</DialogTitle>
          )}
          {_document.type === "ESTIMATE" && (
            <DialogTitle id="alert-dialog-title">Devis lié au document additionnel</DialogTitle>
          )}
          {_document.type === "INVOICE" && (
            <DialogTitle id="alert-dialog-title">Facture liée au document additionnel</DialogTitle>
          )}

          <DialogContent style={{ minWidth: 300 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Numéro</TableCell>
                  <TableCell>Montant HT</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Télécharger</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>N°{_document.content.number}</TableCell>
                  <TableCell>{formatCurrency(_document.content.totalPriceWithoutTVA)}</TableCell>
                  <TableCell>
                    {_document.type === "INVOICE" && <InvoiceStatusLabel invoice={_document.content} />}
                    {_document.type === "ORDER_FORM" && <OrderFormStatusLabel orderForm={_document.content} />}
                    {_document.type === "ESTIMATE" && <EstimateStatusLabel estimate={_document.content} />}
                  </TableCell>
                  <TableCell>
                    {userCanSeeReferenceDocument() && (
                      <div
                        style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center" }}
                      >
                        <GetApp onClick={() => downloadPDF()} />
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
        </Fragment>
      ) : (
        <CircularProgress size={40} />
      )}
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined" autoFocus>
          Fermer
        </Button>
      </DialogActions>
      {downloadHref !== "" && (
        <a ref={simulateClick} href={downloadHref} download={download} style={{ display: "none" }}>
          Download
        </a>
      )}
    </Dialog>
  );
}

export default inject("sessionStore")(observer(props => <ADReferenceDocumentDialog {...props} />));
