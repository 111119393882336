import React from "react";
import { Grid, TextField, Paper, withStyles, Typography, Button, CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Formik } from "formik";
import * as Yup from "yup";

const ProductCategorySchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Ce champ est requis")
  });

function AddProductCategoryForm({ classes, productCategory, handleCancel, handleSubmitForm, isSubmitting }) {
  return (
    <Formik
      enableReinitialize
      initialValues={productCategory}
      validationSchema={ProductCategorySchema}
      onSubmit={values => {
        handleSubmitForm({ ...values });
      }}
    >
      {({ values, touched, errors, isValid, handleChange, handleBlur, handleSubmit }) => (
        <form>
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
              <Paper className={classes.paper}>
                <Typography variant="h4">Informations sur la catégorie</Typography>
                <TextField
                  required
                  name="name"
                  placeholder="Nom"
                  label="Nom"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name && errors.name}
                />
                <Grid justify="flex-end" container className={classes.btnWrapper}>
                  <Button variant="outlined" color="primary" className={classes.leftBtn} onClick={handleCancel}>
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(values)}
                    disabled={isSubmitting || !isValid}
                  >
                    Enregistrer
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  customFieldsHeader: {
    marginTop: theme.spacing(2)
  },
  customFieldsSubHeader: {
    marginBottom: theme.spacing(2),
    opacity: 0.7
  },
  leftBtn: {
    marginRight: theme.spacing(2)
  },
  btnWrapper: {
    paddingTop: theme.spacing(2)
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

export default withStyles(styles)(AddProductCategoryForm);
