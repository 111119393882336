import { Divider, MenuItem, TableCell, TableRow, withStyles } from "@material-ui/core";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { withRouter } from "react-router-dom";

import { inject, observer } from "mobx-react";
import AdditionalDocumentService from "../../services/AdditionalDocumentService";
import TableAction from "../TableAction";
import AdditionalDocumentRowDocumentCell from "./AdditionalDocumentRowDocumentCell";
import { privateRoutes } from "../../routes";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";
import AdditionalDocumentStatusLabel from "./AdditionalDocumentStatusLabel";
import ADReferenceDocumentDialog from "../dialog/ADReferenceDocumentDialog";

const styles = () => ({ chip: { color: "white" } });

function AdditionalDocumentsListRow({
  onUpdate,
  additionalDocument,
  canSign,
  handleDownload,
  history,
  classes,
  handlePreview,
  initAdditionalDocumentSignature,
  sessionStore
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [openReferenceDocumentDialog, setOpenReferenceDocumentDialog] = React.useState(false);

  const handleHideReferenceDocumentDialog = () => {
    setOpenReferenceDocumentDialog(false);
  };

  const validateAdditionalDocument = additionalDocumentId => {
    AdditionalDocumentService.patchAdditionalDocumentStatus(additionalDocumentId, "VALIDATED")
      .then(() => {
        onUpdate();
        enqueueSnackbar("Le document a été validé avec succès", {
          variant: "success"
        });
      })
      .catch(err => {
        if (err && err.status && err.status === 406) {
          enqueueSnackbar("La saisie ne permet pas de valider le document, veuillez modifier celui-ci", {
            variant: "error"
          });
        } else {
          enqueueSnackbar("Une erreur est survenue lors de la validation du document", {
            variant: "error"
          });
        }
      });
  };

  const markAdditionalDocumentAsSent = additionalDocumentId => {
    AdditionalDocumentService.patchAdditionalDocumentStatus(additionalDocumentId, "SENT")
      .then(() => {
        onUpdate();
        enqueueSnackbar("Le document a été marqué comme envoyé avec succès", {
          variant: "success"
        });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors du marquage du document comme envoyé", {
          variant: "error"
        });
      });
  };

  const markAdditionalDocumentAsSigned = additionalDocumentId => {
    AdditionalDocumentService.patchAdditionalDocumentStatus(additionalDocumentId, "SIGNED")
      .then(() => {
        onUpdate();
        enqueueSnackbar("Le document a été marqué comme signé avec succès", {
          variant: "success"
        });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors du marquage du document comme signé", {
          variant: "error"
        });
      });
  };

  const handleCancel = additionalDocumentId => {
    AdditionalDocumentService.patchAdditionalDocumentStatus(additionalDocumentId, "CANCEL")
      .then(() => {
        onUpdate();
        enqueueSnackbar("Le document a été annulé avec succès", {
          variant: "success"
        });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de l'annulation du document", {
          variant: "error"
        });
      });
  };

  const sendAdditionalDocument = additionalDocumentId => {
    AdditionalDocumentService.sendAdditionalDocument(additionalDocumentId)
      .then(() => {
        enqueueSnackbar("Le document a été envoyé avec succès", {
          variant: "success"
        });
      })
      .catch(err => {
        if (err && err.status && err.status === 406) {
          enqueueSnackbar("La saisie ne permet pas d'envoyer le document, veuillez modifier celui-ci", {
            variant: "error"
          });
        } else {
          enqueueSnackbar("Une erreur est survenue lors de l'envoi du document", {
            variant: "error"
          });
        }
      });
  };

  const handleArchive = additionalDocumentId => {
    AdditionalDocumentService.patchAdditionalDocumentStatus(additionalDocumentId, "ARCHIVED")
      .then(() => {
        onUpdate();
        enqueueSnackbar("Le document a été archivé avec succès", {
          variant: "success"
        });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de l'archivage du document", {
          variant: "error"
        });
      });
  };

  const showLinkedDocument = () => {
    setOpenReferenceDocumentDialog(true);
  };

  const canUserEdit = () => {
    if (additionalDocument.user) {
      return (
        additionalDocument.user.id === sessionStore.user.id ||
        sessionStore.userHasAccess({
          requiredAcls: ["ADDITIONAL_DOCUMENT_WRITE"],
          requiredFeatures: ["ADDITIONAL_DOCUMENT"]
        })
      );
    }
    return false;
  };

  const canEdit = canUserEdit();

  return (
    <TableRow>
      <TableCell>{moment(additionalDocument.createAt).format("DD/MM/YYYY")}</TableCell>
      <TableCell style={{ width: "20%" }}>
        <AdditionalDocumentRowDocumentCell
          additionalDocument={additionalDocument}
          handleLinkedDocumentClick={showLinkedDocument}
        />
      </TableCell>
      <TableCell>
        {additionalDocument.contact.businessName ? (
          <React.Fragment>
            {additionalDocument.contact.businessName}
            <br />({additionalDocument.contact.firstname} {additionalDocument.contact.lastname.toUpperCase()})
          </React.Fragment>
        ) : (
          <React.Fragment>
            {additionalDocument.contact.firstname} {additionalDocument.contact.lastname.toUpperCase()}
          </React.Fragment>
        )}
      </TableCell>

      <TableCell component="th" scope="row">
        <AdditionalDocumentStatusLabel additionalDocument={additionalDocument} classes={classes} />
      </TableCell>
      <TableCell>
        <TableAction>
          {canEdit && additionalDocument.status === "DRAFT" && (
            <MenuItem
              onClick={() => {
                history.push(
                  privateRoutes.common.additionalDocuments.worksAcceptanceDetails.path.replace(
                    ":additionalDocumentId",
                    additionalDocument.id
                  )
                );
              }}
            >
              Modifier
            </MenuItem>
          )}
          {canEdit && additionalDocument.status === "DRAFT" && (
            <MenuItem
              onClick={() => {
                validateAdditionalDocument(additionalDocument.id);
              }}
            >
              Valider
            </MenuItem>
          )}
          {canEdit && additionalDocument.status === "DRAFT" && <Divider />}
          {canEdit && canSign && (additionalDocument.status === "VALIDATED" || additionalDocument.status === "SENT") && (
            <MenuItem
              onClick={() => {
                initAdditionalDocumentSignature(additionalDocument);
              }}
            >
              {additionalDocument.signature ? "Gérer la signature" : "Envoyer pour signature"}
            </MenuItem>
          )}
          {canEdit && (
            <MenuItem
              onClick={() => {
                sendAdditionalDocument(additionalDocument.id);
              }}
            >
              Envoyer par e-mail
            </MenuItem>
          )}
          {canEdit && additionalDocument.status === "VALIDATED" && (
            <MenuItem
              onClick={() => {
                markAdditionalDocumentAsSent(additionalDocument.id);
              }}
            >
              Marquer comme envoyé
            </MenuItem>
          )}
          {canEdit && (additionalDocument.status === "VALIDATED" || additionalDocument.status === "SENT") && (
            <MenuItem
              onClick={() => {
                markAdditionalDocumentAsSigned(additionalDocument.id);
              }}
            >
              Marquer comme signé
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handlePreview(additionalDocument);
            }}
          >
            Prévisualiser
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownload(additionalDocument.id);
            }}
          >
            Télécharger
          </MenuItem>
          <Divider />
          {(additionalDocument.invoice || additionalDocument.estimate || additionalDocument.orderForm) && (
            <MenuItem onClick={showLinkedDocument}>
              Voir {additionalDocument.invoice && "la facture"}
              {additionalDocument.estimate && "le devis"}
              {additionalDocument.orderForm && "le bon de commande"}
            </MenuItem>
          )}
          {canEdit && additionalDocument.status !== "CANCEL" && <Divider />}
          {canEdit && additionalDocument.status !== "CANCEL" && (
            <ConfirmationDialog>
              {confirmBeforeAct => (
                <MenuItem
                  onClick={e =>
                    stopPropagationForConfirmation(
                      e,
                      confirmBeforeAct(() => handleCancel(additionalDocument.id))
                    )
                  }
                >
                  Annuler
                </MenuItem>
              )}
            </ConfirmationDialog>
          )}
          {canEdit && (
            <ConfirmationDialog>
              {confirmBeforeAct => (
                <MenuItem
                  onClick={e =>
                    stopPropagationForConfirmation(
                      e,
                      confirmBeforeAct(() => handleArchive(additionalDocument.id))
                    )
                  }
                >
                  Archiver
                </MenuItem>
              )}
            </ConfirmationDialog>
          )}
        </TableAction>
        <ADReferenceDocumentDialog
          onClose={handleHideReferenceDocumentDialog}
          open={openReferenceDocumentDialog}
          additionalDocument={additionalDocument}
        />
      </TableCell>
    </TableRow>
  );
}

export default withStyles(styles)(
  withRouter(inject("sessionStore")(observer(props => <AdditionalDocumentsListRow {...props} />)))
);
