import * as Yup from "yup";

import moment from "moment";
import request, { METHOD } from "../utils/request";
import ValidatorService from "./ValidatorService";

class ClientService {
  static getClients({ page = 0, sort = "lastname", order = "ASC", filter = {}, contactsStatus = "ACTIVE" }) {
    const { q = "", userId = "", installationDateFrom, installationDateTo, reminderDateFrom, reminderDateTo } = filter;
    return request({
      url: `/contacts?page=${page}&sort=${sort}&order=${order}&q=${q}&userId=${
        userId && userId !== "ALL" ? userId : ""
      }&installationDateFrom=${installationDateFrom || ""}&installationDateTo=${installationDateTo ||
        ""}&reminderDateFrom=${reminderDateFrom || ""}&reminderDateTo=${reminderDateTo ||
        ""}&contactsStatus=${contactsStatus}`,
      method: METHOD.GET
    });
  }

  static getClient(clientId) {
    return request({
      url: `/contacts/${clientId}`,
      method: METHOD.GET
    });
  }

  static getClientEvents(clientId, page = 0, limit = 15) {
    return request({
      url: `/contacts/${clientId}/events?page=${page}&limit=${limit}`,
      method: METHOD.GET
    });
  }

  static getEstimateDashboard(
    clientId,
    from = moment()
      .startOf("month")
      .format("YYYY-MM"),
    to = moment()
      .endOf("month")
      .format("YYYY-MM")
  ) {
    return request({
      url: `/contacts/${clientId}/estimates?from=${from}&to=${to}`,
      method: METHOD.GET
    });
  }

  static getOrderDashboard(
    clientId,
    from = moment()
      .startOf("month")
      .format("YYYY-MM"),
    to = moment()
      .endOf("month")
      .format("YYYY-MM")
  ) {
    return request({
      url: `/contacts/${clientId}/orders?from=${from}&to=${to}`,
      method: METHOD.GET
    });
  }

  static getInvoiceDashboard(
    clientId,
    from = moment()
      .startOf("month")
      .format("YYYY-MM"),
    to = moment()
      .endOf("month")
      .format("YYYY-MM")
  ) {
    return request({
      url: `/contacts/${clientId}/invoices?from=${from}&to=${to}`,
      method: METHOD.GET
    });
  }

  static addClientEvent(clientId, { title, content = "", dueDate = null, status = "PENDING" }) {
    return request({
      url: `/contacts/${clientId}/events`,
      method: METHOD.POST,
      data: {
        title,
        content,
        dueDate,
        status
      }
    });
  }

  static patchClientEvent(clientId, eventId, { title, content = "", dueDate = null, status = "PENDING" }) {
    return request({
      url: `/contacts/${clientId}/events/${eventId}`,
      method: METHOD.PATCH,
      data: {
        title,
        content,
        dueDate,
        status
      }
    });
  }

  static deleteClientEvent(clientId, eventId) {
    return request({
      url: `/contacts/${clientId}/events/${eventId}`,
      method: METHOD.DELETE
    });
  }

  static postClient(client) {
    return request({
      url: "/contacts",
      method: METHOD.POST,
      data: client
    });
  }

  static patchClient(client) {
    return request({
      url: `/contacts/${client.id}`,
      method: METHOD.PATCH,
      data: client
    });
  }

  static manageClient(client, action) {
    return request({
      url: `/contacts/${client.id}/actions`,
      method: METHOD.POST,
      data: { action }
    });
  }

  static deleteClient(client) {
    return request({
      url: `/contacts/${client.id}`,
      method: METHOD.DELETE
    });
  }
}

export const ClientSchema = () =>
  Yup.lazy(values =>
    Yup.object().shape({
      firstname: Yup.string()
        .max(100, "Saisie incorrecte")
        .test("is-compliant-first", "La saisie comporte au moins un caractère invalide", value =>
          value ? value.match(/([^a-z0-9'-\sÀ-ÖØ-öø-ÿ]+)/gi) === null : true
        )
        .test("can-be-empty-first", "La saisie est incorrecte", value =>
          value ? true : !value && !values.businessName
        )
        .required("Ce champ est requis"),
      lastname: Yup.string()
        .max(100, "Saisie incorrecte")
        .test("is-compliant-last", "La saisie comporte au moins un caractère invalide", value =>
          value ? value.match(/([^a-z0-9'-\sÀ-ÖØ-öø-ÿ]+)/gi) === null : true
        )
        .test("can-be-empty-last", "La saisie est incorrecte", value =>
          value ? true : !value && !!values.businessName
        )
        .required("Ce champ est requis"),
      businessName: Yup.string()
        .max(100, "Saisie incorrecte")
        .nullable(),
      email: Yup.string()
        .max(100, "Saisie incorrecte")
        .email("L'e-mail n'est pas valide")
        .required("Ce champ est requis"),
      address: Yup.string()
        .max(100, "Saisie incorrecte")
        .required("Ce champ est requis"),
      city: Yup.string()
        .max(100, "Saisie incorrecte")
        .required("Ce champ est requis"),
      zipCode: Yup.string()
        .test("is-compliant-first", "Code postal invalide", value =>
          value ? value.match(/^[0-9]{5}$/) !== null : true
        )
        .required("Ce champ est requis"),
      phone: Yup.mixed()
        .test("check-phone", "Format invalide", value => (value ? ValidatorService.validateMobilePhone(value) : true))
        .nullable(),
      phone2: Yup.string()
        .max(15, "Format invalide")
        .nullable(),
      installationDate: Yup.string().nullable(),
      reminderDate: Yup.string().nullable()
    })
  );

export const ClientEventSchema = () =>
  Yup.object().shape({
    title: Yup.string()
      .max(100, "Saisie incorrecte")
      .required("Ce champ est requis"),
    content: Yup.string(),
    dueDate: Yup.string().nullable(),
    status: Yup.string().required("Champ obligatoire")
  });

export default ClientService;
