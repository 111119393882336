import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  withStyles,
  FormControl,
  TextField,
  InputAdornment,
  InputLabel,
  IconButton,
  FormHelperText,
  OutlinedInput
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Decimal } from "decimal.js-light";
import { PAYMENT_MODES, FINANCIAL_METHOD } from "../../../stores/NewInvoiceStore";

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  group: {
    flexDirection: "row"
  }
});

@inject("newInvoiceStore")
@observer
class CashForm extends Component {
  componentDidMount() {
    // Used for the ref to work.
    // https://v3-9-0.material-ui.com/demos/text-fields/#components
    // This will probably be better with V4 of MU
    this.forceUpdate();
  }

  /**
   * Compute the data for the TextField.
   */
  getTextFieldValues = amount => ({
    cashPrice: amount,
    deposit: new Decimal(amount)
      .mul(0.3)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2),
    balance: new Decimal(amount)
      .mul(0.7)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2)
  });

  render() {
    const { classes, newInvoiceStore } = this.props;

    return (
      <Fragment>
        <Grid item xs={12} className={classes.item}>
          <TextField
            name="cashPrice"
            label="Prix au comptant"
            margin="normal"
            variant="outlined"
            disabled
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
            value={newInvoiceStore.amount}
          />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              htmlFor="discount"
              ref={ref => {
                // eslint-disable-next-line react/no-find-dom-node
                this.labelDepositRef = ReactDOM.findDOMNode(ref);
              }}
            >
              Acompte déjà versé
            </InputLabel>
            <OutlinedInput
              id="deposit"
              name="deposit"
              type="number"
              value={newInvoiceStore.cashForm.deposit}
              onChange={newInvoiceStore.handleChangeCashForm}
              inputProps={{ min: "0" }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Basculer le mode d'acompte"
                    onClick={newInvoiceStore.handleChangeDepositMethod}
                  >
                    {newInvoiceStore.cashForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE ? "%" : "€"}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={this.labelDepositRef ? this.labelDepositRef.offsetWidth : 0}
            />
            <FormHelperText id="discount-helper-text">
              soit un acompte de {newInvoiceStore.computedDeposit}€
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <TextField
            name="balance"
            label="Reste à payer"
            margin="normal"
            variant="outlined"
            disabled
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
            value={newInvoiceStore.computedCashFormBalance}
          />
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(CashForm);
