import React, { Fragment } from "react";
import moment from "moment";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

import { formatCurrency } from "../../../utils/numberFormat";
import EstimateStatusLabel from "../../estimate/EstimateStatusLabel";

const ITEMS_PER_PAGE = 15;
const CURRENT_PAGE = 0;

function EstimatesList({ estimates, hideContactColumn = false }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Numéro</TableCell>
          {!hideContactColumn && <TableCell>Client / Société</TableCell>}
          <TableCell>Objet</TableCell>
          <TableCell>Utilisateur</TableCell>
          <TableCell>Montant HT</TableCell>
          <TableCell>Statut</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {estimates.length > 0 ? (
          estimates
            .slice(CURRENT_PAGE * ITEMS_PER_PAGE, CURRENT_PAGE * ITEMS_PER_PAGE + ITEMS_PER_PAGE)
            .map(estimate => (
              <TableRow key={estimate.id}>
                <TableCell>{moment(estimate.createAt).format("DD/MM/YYYY")}</TableCell>
                <TableCell>N°{estimate.number}</TableCell>
                {!hideContactColumn && (
                  <TableCell>
                    {estimate.clientBusinessName && (
                      <Fragment>
                        <b>{estimate.clientBusinessName}</b>
                        <br />
                      </Fragment>
                    )}
                    {`${estimate.contact.firstname} ${estimate.contact.lastname.toUpperCase()}`}
                  </TableCell>
                )}
                <TableCell>
                  <b>{estimate.nature}</b>
                </TableCell>
                <TableCell>
                  {estimate.user && `${estimate.user.firstname} ${estimate.user.lastname.toUpperCase()}`}
                </TableCell>
                <TableCell>{formatCurrency(estimate.totalPriceWithoutTVA, estimate.currency)}</TableCell>
                <TableCell>
                  <EstimateStatusLabel estimate={estimate} />
                </TableCell>
              </TableRow>
            ))
        ) : (
          <TableRow>
            <TableCell colSpan={7} align="center">
              Aucun devis établi sur la période
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default EstimatesList;
