import React, { Component } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  withStyles,
  Typography,
  Grid
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { withSnackbar } from "notistack";

import CompanyService from "../../services/CompanyService";
import UserService from "../../services/UserService";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./Wysiswyg.css";
import ValidatorService from "../../services/ValidatorService";
import NotFoundPage from "../../pages/NotFoundPage";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  paper: {
    padding: theme.spacing(2)
  },
  wrapper: {
    marginTop: theme.spacing(3)
  },
  cardheader: {
    paddingBottom: 0
  },
  complexLabel: {
    display: "flex",
    alignItems: "center"
  },
  complexLabelInput: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 100
  },
  invoicePaper: {
    borderRadius: 0,
    padding: 10,
    height: 250,
    paddingTop: 50,
    alignItems: "flex-end",
    display: "flex"
  },
  invoiceLogosWrapper: {
    justifyContent: "center",
    alignItems: "flex-end"
  },
  invoiceLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    minHeight: 40,
    padding: 5,
    margin: 2,
    border: "1px solid #E6E6E6"
  },
  invoiceLogoPicture: {
    maxWidth: "100%",
    maxHeight: 200
  },
  invoiceLogoName: {
    display: "inline-block",
    marginLeft: theme.spacing()
  },
  invoiceLogoRemoveButton: {
    borderColor: "red",
    color: "red"
  }
});

const DEFAULT_PAYMENT_DELAY = 30;

@inject("sessionStore")
@observer
class PaymentSettings extends Component {
  constructor(props) {
    super(props);
    const { company } = props.sessionStore.user;
    company.paymentDelay = company.paymentDelay >= 0 ? company.paymentDelay : DEFAULT_PAYMENT_DELAY;
    company.iban = company.iban || "";
    company.bic = company.bic || "";
    company.paymentTerms = company.paymentTerms || "";
    company.paymentPenalty = company.paymentPenalty || "";
    this.state = {
      company,
      paymentDelayType: company.paymentDelay > 0 ? "DAYS" : "NOW",
      paymentDelayInputValue: company.paymentDelay > 0 ? company.paymentDelay : 30
    };
  }

  componentDidMount() {
    const { sessionStore } = this.props;
    if (!sessionStore.user.company) {
      UserService.me().then(response => {
        const { company } = response;
        company.paymentDelay = company.paymentDelay >= 0 ? company.paymentDelay : DEFAULT_PAYMENT_DELAY;
        company.iban = company.iban || "";
        company.bic = company.bic || "";
        this.setState({ company });
      });
    }
  }

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    const { company } = this.state;
    this.setState({ company: { ...company, [name]: value } });
  };

  handleSubmit = () => {
    const { company } = this.state;
    const { sessionStore } = this.props;
    CompanyService.patchCompany(company)
      .then(() => {
        sessionStore.user.company = company;
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Les paramètres relatifs au paiement pour les factures ont été mis à jour", {
          variant: "success"
        });
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue pendant la mise à jour des paramètres pour les factures", {
          variant: "error"
        });
      });
  };

  handlePaymentDelayChange = e => {
    const {
      target: { value }
    } = e;
    const { company, paymentDelayInputValue } = this.state;
    this.setState({
      paymentDelayType: value,
      company: { ...company, paymentDelay: value === "DAYS" ? paymentDelayInputValue : 0 }
    });
  };

  handlePaymentDelayInputValueChange = e => {
    const {
      target: { value }
    } = e;
    const { company, paymentDelayType } = this.state;
    this.setState({
      paymentDelayInputValue: value,
      company: {
        ...company,
        paymentDelay: paymentDelayType === "DAYS" ? parseInt(value, 10) : parseInt(company.paymentDelay, 10)
      }
    });
  };

  render() {
    const { classes, sessionStore } = this.props;
    const { company, paymentDelayType, paymentDelayInputValue } = this.state;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["SETTING_WRITE", "SETTING_READ"],
        aclType: "oneof",
        requiredFeatures: ["SETTING"]
      })
    ) {
      return <NotFoundPage />;
    }

    const isSubmissible =
      (paymentDelayType === "NOW" || (paymentDelayType === "DAYS" && paymentDelayInputValue > 0)) &&
      company.paymentPenalty &&
      company.paymentTerms &&
      (company.iban ? ValidatorService.validateIBAN(company.iban, { allowEmpty: true }) : true);

    const canEdit = sessionStore.userHasAccess({
      requiredAcls: ["SETTING_WRITE"],
      requiredFeatures: ["SETTING"]
    });

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Modalités de paiement</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Card className={classes.wrapper}>
              <CardHeader title="Modes de paiement" className={classes.cardheader} />
              <CardContent>
                <TextField
                  className={classes.input}
                  name="paymentTerms"
                  label="Modes de paiement"
                  multiline
                  rows={2}
                  placeholder="Mode de paiement : chèque, virement, espèces..."
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                  error={!company.paymentTerms}
                  value={company.paymentTerms}
                  onChange={this.onChange}
                  disabled={!canEdit}
                />
              </CardContent>
            </Card>
            <Card className={classes.wrapper}>
              <CardHeader title="Escompte" className={classes.cardheader} />
              <CardContent>
                <TextField
                  className={classes.input}
                  name="discount"
                  label="Escompte"
                  placeholder="Pas d'escompte pour paiement anticipé"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  disabled
                  value="Pas d'escompte pour paiement anticipé"
                />
              </CardContent>
            </Card>
            <Card className={classes.wrapper}>
              <CardHeader title="Délai de paiement" className={classes.cardheader} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <RadioGroup
                      aria-label="paymentDelayType"
                      name="paymentDelayType"
                      className={classes.group}
                      value={paymentDelayType}
                      onChange={this.handlePaymentDelayChange}
                    >
                      <FormControlLabel value="NOW" control={<Radio disabled={!canEdit} />} label="À réception" />
                      <FormControlLabel
                        value="DAYS"
                        control={<Radio disabled={!canEdit} />}
                        label={
                          <div className={classes.complexLabel}>
                            <span>Dans un délai de </span>
                            <TextField
                              onFocus={() => this.setState({ paymentDelayType: "DAYS" })}
                              name="paymentDelayValue"
                              variant="outlined"
                              type="number"
                              error={paymentDelayType === "DAYS" && paymentDelayInputValue < 1}
                              inputProps={{ min: "1", step: "1" }}
                              className={classes.complexLabelInput}
                              value={paymentDelayInputValue}
                              onChange={this.handlePaymentDelayInputValueChange}
                              disabled={!canEdit}
                            />
                            <span> jours</span>
                          </div>
                        }
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.wrapper}>
              <CardHeader title="Pénalités de retard" className={classes.cardheader} />
              <CardContent>
                <TextField
                  className={classes.input}
                  name="paymentPenalty"
                  label="Pénalités de retard"
                  placeholder="Pénalités de retard sans rappel préalable : taux BCE à son opération de refinancement la plus récente majoré de 10 %. Indemnité forfaitaire pour frais de recouvrement : 40 €. Décret n° 2012-1115 du 2 octobre 2012."
                  margin="normal"
                  variant="outlined"
                  multiline
                  required
                  error={!company.paymentPenalty}
                  rows={2}
                  fullWidth
                  value={company.paymentPenalty}
                  onChange={this.onChange}
                  disabled={!canEdit}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {canEdit && (
          <div className="react-draft-button">
            <div>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
                disabled={!isSubmissible}
              >
                Enregistrer
              </Button>
            </div>
          </div>
        )}
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(PaymentSettings));
