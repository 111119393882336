import React from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { COLORS } from "../../utils/dashboard";
import { formatNumber } from "../../utils/numberFormat";

function SellersChart({ sellersData }) {
  if (sellersData.length > 0) {
    return (
      <ResponsiveContainer height={250} width="100%">
        <BarChart data={sellersData} margin={{ top: 20, right: 15, left: 15, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={data => formatNumber(data, 0)} tick />
          <Tooltip formatter={data => `${formatNumber(data, 2)} € HT`} />
          <Bar dataKey="montant" fill={COLORS[0]} maxBarSize={50} />
        </BarChart>
      </ResponsiveContainer>
    );
  }

  return <span>Pas de données</span>;
}

export default SellersChart;
