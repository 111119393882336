import React, { Fragment, useState } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 300
  },
  input: { marginTop: 0, marginBottom: theme.spacing(2) },
  sendbtn: { marginTop: theme.spacing(2) },
  errorSent: { marginTop: theme.spacing(2), fontStyle: "italic" },
  successContainer: { display: "flex", justifyContent: "center", alignItems: "center", height: 50 }
}));

export default function EstimateSignatureForm({ estimateSignature, onSignatureComplete, overrideDocumentType = "" }) {
  const [codeSent, setCodeSent] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(null);
  const [code, setCode] = useState("");

  const classes = useStyle();

  const handleAskCode = async () => {
    setIsProcessing(true);
    const _codeSent = await estimateSignature.askForCode();
    setCodeSent(_codeSent);
    setIsProcessing(false);
  };

  const handleSubmit = async () => {
    if (code) {
      setIsProcessing(true);
      const isOk = await estimateSignature.checkCode(code);
      setIsCodeValid(isOk);
      setIsProcessing(false);
      if (isOk) {
        onSignatureComplete();
      }
    }
  };

  const handleChange = e => {
    if (isCodeValid === false) {
      setIsCodeValid(null);
    }
    setCode(e.target.value);
  };

  return (
    <div className={classes.container}>
      <Typography variant="body1">
        Afin de signer numériquement {overrideDocumentType || "ce devis"}, vous devez saisir le code qui vous sera
        envoyé par SMS.
      </Typography>
      <div className={classes.form}>
        {codeSent && (
          <Fragment>
            <TextField
              className={classes.input}
              name="code"
              placeholder="XXXXXX"
              label="Code de validation"
              margin="none"
              value={code}
              fullWidth
              disabled={isProcessing}
              error={isCodeValid === false}
              helperText={isCodeValid === false && "Le code saisi est invalide"}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={isProcessing || code === ""}
              onClick={handleSubmit}
            >
              Valider
            </Button>
          </Fragment>
        )}
        <Button
          className={classes.sendbtn}
          fullWidth
          variant={codeSent ? "text" : "outlined"}
          color="primary"
          disabled={isProcessing}
          onClick={handleAskCode}
        >
          {codeSent ? "Je n'ai pas reçu mon code" : "Recevoir un code"}
        </Button>
      </div>
      {codeSent === false && (
        <Typography variant="body2" className={classes.errorSent}>
          Une erreur est survenue lors de l&apos;envoi du code, veuillez réessayer ultérieurement.
        </Typography>
      )}
    </div>
  );
}
