import React, { useState } from "react";
import { Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";

import RangePicker from "../dashboard/RangePicker";
import ContactOrderFormsDashboard from "../dashboard/contactOrderForms";

const useStyles = makeStyles(theme => ({
  range: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3)
  }
}));

function ClientOrderFormsList({ clientId, history }) {
  const [range, setRange] = useState({ from: null, to: null });
  const classes = useStyles();
  const onChange = _range => {
    setRange(_range);
  };

  const handleDetailsNavigation = path => {
    history.push({
      pathname: path,
      state: { from: range.from.format("YYYY-MM-DD"), to: range.to.format("YYYY-MM-DD"), client: clientId }
    });
  };

  return (
    <Box>
      <Paper className={classes.range}>
        <RangePicker onChange={onChange} />
      </Paper>
      {range.from && range.to ? (
        <ContactOrderFormsDashboard
          from={range.from}
          to={range.to}
          handleDetailsNavigation={handleDetailsNavigation}
          clientId={clientId}
        />
      ) : (
        <div />
      )}
    </Box>
  );
}

export default withRouter(ClientOrderFormsList);
