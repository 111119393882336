import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { withStyles, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";

import { privateRoutes } from "../routes";
import EstimatesList from "../components/estimate/EstimatesList";
import NotFoundPage from "./NotFoundPage";

const styles = theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
});

@inject("sessionStore")
@observer
class EstimatesPage extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const filter = {};
    if (location.state && location.state.from) {
      filter.from = moment(location.state.from);
    }
    if (location.state && location.state.to) {
      filter.to = moment(location.state.to);
    }
    if (location.state && location.state.client) {
      filter.client = location.state.client;
    }
    this.state = { filter };
  }

  componentDidMount() {
    const { history } = this.props;
    history.replace({ state: null });
  }

  render() {
    const { classes, sessionStore } = this.props;
    const { filter } = this.state;
    if (
      sessionStore.userHasAccess({
        requiredAcls: ["ESTIMATE_READ", "ESTIMATE_MY"],
        aclType: "oneof",
        requiredFeatures: ["ESTIMATE"]
      })
    ) {
      return (
        <Fragment>
          <EstimatesList title="Devis" filter={filter} />
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            component={Link}
            to={privateRoutes.common.estimates.creationForm.path}
          >
            <AddIcon />
          </Fab>
        </Fragment>
      );
    }
    return <NotFoundPage />;
  }
}

export default withStyles(styles)(EstimatesPage);
