import { Checkbox, FormControlLabel, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const useStyles = makeStyles(() => ({
  checkbox: { display: "flex" }
}));

function SimplifiedClientEventForm({ formik }) {
  const classes = useStyles();
  const handleChange = event => {
    formik.setFieldValue("status", event.target.checked ? "DONE" : "PENDING");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
          <DatePicker
            id="dueDate"
            name="dueDate"
            cancelLabel="Annuler"
            format="D MMM YYYY"
            inputVariant="outlined"
            fullWidth
            label="Date limite"
            clearable
            clearLabel="Réinitialiser"
            leftArrowIcon={<KeyboardArrowLeftIcon />}
            rightArrowIcon={<KeyboardArrowRightIcon />}
            value={formik.values.dueDate}
            onChange={value => {
              formik.setFieldValue("dueDate", value);
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} className={classes.checkbox}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.status === "DONE"}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label="Marquer comme fait"
        />
      </Grid>
    </Grid>
  );
}

export default SimplifiedClientEventForm;
