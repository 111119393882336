import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  backDrop: {
    backdropFilter: "blur(7px)",
    backgroundColor: "rgba(0,0,30,0.4)"
  }
}));

function ContraliaConfirmationDialog({ open, onCancel, onConfirm, contact }) {
  const [consentMail, setconsentMail] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (open && contact) {
      setconsentMail(false);
    }
  }, [open, contact]);

  const toggleConsent = () => {
    setconsentMail(curr => !curr);
  };

  if (!open || !contact) {
    return null;
  }

  return (
    <Dialog className={classes.backDrop} modal="false" open>
      <DialogTitle id="alert-dialog-title">Consentement utilisateur</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={<Checkbox checked={consentMail} name="show-deleted" color="primary" onChange={toggleConsent} />}
          label={
            <Typography>
              Je soussigné(e){" "}
              <strong>
                {contact.firstname} {contact.lastname}
              </strong>{" "}
              reconnais avoir expressément donné mon accord afin de recevoir uniquement par mail mes documents
              contractuels de vente – devis, bon de commande, et conditions générales de vente -.
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained" disabled={!consentMail}>
          Signer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ContraliaConfirmationDialog;
