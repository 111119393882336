import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import FinancingService, { INSURANCE_TYPE } from "../../../../../services/FinancingService";
import FranfinanceEquipmentCodeSelect from "../../../../financing/FranfinanceEquipmentCodeSelect";

export default function InputForm({
  onFetch,
  amount,
  totalDeposit = 0,
  initialDueNumber,
  initialEquipmentCode,
  // initialMonthlyAmount,
  initialInsuranceType
}) {
  const [insuranceType, setInsuranceType] = React.useState(initialInsuranceType);
  const [dueNumber, setDueNumber] = React.useState(initialDueNumber);
  const [equipmentCode, setEquipmentCode] = React.useState(initialEquipmentCode || "040");
  // const [monthlyAmount, setMonthlyAmount] = React.useState(initialMonthlyAmount);
  // const [scaleCodeBasedOn, setScaleCodeBasedOn] = React.useState("DUE_NUMBER"); // DUE_NUMBER | MONTHLY_AMOUNT
  const [scaleCodes, setScaleCodes] = React.useState();
  const [scaleCode, setScaleCode] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleResetForm = () => {
    setScaleCode("");
    setScaleCodes();
  };

  const handleEquipmentCodeChange = newEquipmentCode => {
    setEquipmentCode(newEquipmentCode);
    handleResetForm();
  };

  useEffect(() => {
    handleResetForm();
  }, [amount, totalDeposit]);

  const handleDueNumberChange = e => {
    setDueNumber(e.target.value);
    // setMonthlyAmount("");
    handleResetForm();
  };

  // const handleMonthlyAmountChange = e => {
  //   setMonthlyAmount(e.target.value);
  //   setDueNumber("");
  //   handleResetForm();
  // };

  const handleInsuranceTypeChange = e => {
    setInsuranceType(e.target.value);
    handleResetForm();
  };

  // const handleScaleCodeBasedOnChange = e => {
  //   setScaleCodeBasedOn(e.target.value);
  // };

  const handleScaleCodeChange = e => {
    setScaleCode(e.target.value);
  };

  // const hasAmountError =
  //   scaleCodeBasedOn === "MONTHLY_AMOUNT" &&
  //   (parseFloat(monthlyAmount, 10) > amount - totalDeposit || parseFloat(monthlyAmount, 10) === 0);

  const fetchScaleCodes = () => {
    FinancingService.getFranfinanceScaleCodes({
      amount,
      totalDeposit,
      dueNumber,
      equipmentCode,
      // dueNumber: scaleCodeBasedOn === "DUE_NUMBER" ? dueNumber : undefined,
      // monthlyAmount: scaleCodeBasedOn === "MONTHLY_AMOUNT" ? monthlyAmount : undefined,
      insuranceType
    })
      .then(data => {
        setScaleCodes(data.result.baremes);
        if (data.result.baremes.length === 0) {
          enqueueSnackbar("Les données fournies ne permettent pas d'obtenir un barème", {
            variant: "warning"
          });
        }
      })
      .catch(err => {
        console.log(" ERROR ", err);
        enqueueSnackbar("Une erreur est survenue lors de la récupération des barèmes", {
          variant: "error"
        });
      });
  };

  const fetchSimulation = () => {
    const _scaleCode = scaleCodes.find(({ idBareme }) => idBareme === scaleCode);
    if (_scaleCode) {
      FinancingService.getFranfinanceSimulation({
        amount,
        totalDeposit,
        dueNumber,
        equipmentCode,
        // dueNumber: scaleCodeBasedOn === "DUE_NUMBER" ? dueNumber : undefined,
        // monthlyAmount: scaleCodeBasedOn === "MONTHLY_AMOUNT" ? monthlyAmount : undefined,
        insuranceType,
        scaleCode: _scaleCode.reports[0].codeBareme,
        scaleCodeExpirationDate: _scaleCode.dateFinRattachementBareme
      })
        .then(data => {
          if (data && data.result && data.result.simulations && data.result.simulations.length > 0) {
            onFetch({
              ...data.result.simulations[0],
              _input: {
                equipmentCode: data.result.inCodeMateriel,
                scaleCode: data.result.simulations[0].codeBareme,
                askedAmount: data.result.inMontantAchat,
                askedDeposit: data.result.inMontantApport,
                askedLoanAmount: data.result.inMontantFinancement,
                askedDueNumber: dueNumber,
                askedInsuranceType: insuranceType
                // askedDueNumber: scaleCodeBasedOn === "DUE_NUMBER" ? dueNumber : undefined
                // askedMonthlyAmount: scaleCodeBasedOn === "MONTHLY_AMOUNT" ? monthlyAmount : undefined
              }
            });
          } else {
            enqueueSnackbar("Les données fournies ne permettent pas d'obtenir une simulation", {
              variant: "warning"
            });
          }
        })
        .catch(err => {
          console.log(" ERROR ", err);
          enqueueSnackbar("Une erreur est survenue lors de la simulation", {
            variant: "error"
          });
        });
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={4}>
        <FranfinanceEquipmentCodeSelect value={equipmentCode} onChange={handleEquipmentCodeChange} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          required
          fullWidth
          name="dueNumber"
          label="Nombre de mensualités"
          variant="outlined"
          type="number"
          inputProps={{ min: 0, step: 1 }}
          value={dueNumber}
          onChange={handleDueNumberChange}
          error={parseInt(dueNumber, 10) < 2 || parseInt(dueNumber, 10) > 185}
          helperText={
            parseInt(dueNumber, 10) < 2 || parseInt(dueNumber, 10) > 185
              ? "Le nombre de mensualités doit être compris entre 2 et 185"
              : " "
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Type d&apos;assurance *</InputLabel>
          <Select
            fullWidth
            input={<OutlinedInput label="Type d'assurance *" />}
            value={insuranceType}
            onChange={handleInsuranceTypeChange}
            variant="outlined"
            style={{ textAlign: "left" }}
            required
          >
            {INSURANCE_TYPE.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText> </FormHelperText>
        </FormControl>
      </Grid>
      {(!scaleCodes || scaleCodes.length === 0) && (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchScaleCodes}
            disabled={!dueNumber || dueNumber < 1 || dueNumber > 300}
          >
            Valider
          </Button>
        </Grid>
      )}

      {scaleCodes && scaleCodes.length > 0 && (
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Barème *</InputLabel>
            <Select
              value={scaleCode}
              onChange={handleScaleCodeChange}
              // displayEmpty
              variant="outlined"
              fullWidth
              style={{ textAlign: "left" }}
              input={<OutlinedInput label="Barème *" />}
              placeholder=""
            >
              <MenuItem value="" disabled>
                <em> -- Sélectionnez un barème --</em>
              </MenuItem>
              {scaleCodes.map(({ libelleBareme, idBareme }) => (
                <MenuItem key={idBareme} value={idBareme}>
                  {libelleBareme}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {scaleCodes && scaleCodes.length > 0 && (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchSimulation}
            disabled={!scaleCode}
            style={{ marginTop: 8 }}
          >
            Lancer la simulation
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

/*
<Select native value={scaleCode} onChange={e => setScaleCode(e.target.value)}>
{scaleCodes.map(({ reports, libelleBareme }) => (
  <MenuItem key={reports[0].codeBareme} value={reports[0].codeBareme}>
    {libelleBareme}
  </MenuItem>
))}
</Select>
*/

/*
  <TextField
    style={{ width: 250 }}
    size="small"
    onFocus={() => setScaleCodeBasedOn("MONTHLY_AMOUNT")}
    name="monthlyAmount"
    label=""
    variant="outlined"
    type="number"
    inputProps={{ min: 0 }}
    value={monthlyAmount}
    onChange={handleMonthlyAmountChange}
    error={hasAmountError}
    helperText={hasAmountError ? `Ne peut excéder ${Number(amount - totalDeposit).toFixed(2)}` : ""}
    InputProps={{
      endAdornment: <InputAdornment position="end">€</InputAdornment>
    }}
  />
 */
