import React, { Component } from "react";
import { withStyles, Button, CircularProgress, TextField, Grid, Hidden } from "@material-ui/core";
import { inject } from "mobx-react";
import classNames from "classnames";
import { withSnackbar } from "notistack";

import CookieService from "../../services/CookieService";
import UserService from "../../services/UserService";
import { disconnected, privateRoutes } from "../../routes";

const styles = theme => ({
  buttonError: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    }
  },
  buttons: {
    margin: theme.spacing()
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
});

@inject("sessionStore")
class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      disabled: false,
      loading: false,
      error: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleKeyPress = event => {
    const { disabled } = this.state;
    if (event.key === "Enter" && !disabled) {
      this.handleSubmit();
    }
  };

  isDisabled() {
    const { email, password } = this.state;
    return email === "" || password === "";
  }

  handleSubmit() {
    const { history, sessionStore } = this.props;
    const { email, password } = this.state;

    if (email !== "" && password !== "") {
      this.setState({ loading: true, disabled: true, error: false }, () => {
        UserService.signIn(email, password)
          .then(async response => {
            CookieService.setCookie("token", response.token, 1);
            sessionStore.initLogin().then(() => {
              history.push({ pathname: privateRoutes.common.home.path });
            });
          })
          .catch(error => {
            const { enqueueSnackbar } = this.props;
            enqueueSnackbar(
              error.status === 401
                ? "Email ou mot de passe incorrect"
                : "Une erreur est survenue lors de l'authentification",
              { variant: "error" }
            );
            this.setState({
              error: error.status === 401,
              loading: false,
              disabled: this.isDisabled()
            });
          });
      });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.setState({
        disabled: this.isDisabled()
      });
    });
  }

  render() {
    const { classes, history } = this.props;
    const { email, password, disabled, loading, error } = this.state;
    const buttonClassname = classNames({
      [classes.buttonError]: error
    });

    // We declare the forgotten password button here so the change is available on the two prints
    const passwordForgottenButton = (
      <Button color="primary" fullWidth onClick={() => history.push({ pathname: disconnected.lostpass.path })}>
        Mot de passe oublié
      </Button>
    );

    return (
      <form className={classes.form}>
        <TextField
          name="email"
          placeholder="john.doe@email.org"
          label="Email"
          type="email"
          margin="normal"
          required
          fullWidth
          value={email}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          name="password"
          placeholder="p@ssw0rd"
          label="Mot de passe"
          type="password"
          margin="normal"
          required
          fullWidth
          value={password}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
        />

        {/* Button Row */}
        <Grid container spacing={2} className={classes.buttons}>
          <Grid item md={6}>
            <Hidden smDown>{passwordForgottenButton}</Hidden>
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              disabled={disabled}
              color="primary"
              className={buttonClassname}
              onClick={this.handleSubmit}
              fullWidth
            >
              Identifiez-vous
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Hidden mdUp>{passwordForgottenButton}</Hidden>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withSnackbar(withStyles(styles)(SignInForm));
