import React from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import CompaniesSelect from "../../components/admin/CompaniesSelect";
import AdminService from "../../services/AdminService";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  card: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(),
    "&:hover": {
      cursor: "pointer"
    }
  },
  cardNoClick: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing()
  },
  cardIcon: {
    flex: 2,
    color: "#3C3C3C"
  }
}));

export default function SuperAdminReplicateCatalogPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [fromCompanyId, setFromCompanyId] = React.useState("");
  const [toCompanyId, setToCompanyId] = React.useState("");

  const handleFromCompanyChange = _company => {
    setFromCompanyId(_company.id);
  };

  const handleToCompanyChange = _company => {
    setToCompanyId(_company.id);
  };

  const handleSubmit = () => {
    AdminService.replicateProducts(fromCompanyId, toCompanyId)
      .then(() => {
        enqueueSnackbar("Duplication OK", { variant: "success" });
      })
      .catch(e => {
        enqueueSnackbar("Duplication KO", { variant: "error" });
        console.log(" ERROR ", e);
      });
  };

  return (
    <div>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Duplication d&apos;un catalogue</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 16 }}>
            <CompaniesSelect
              placeholder=" -- Dupliquer depuis l'entreprise --"
              onChange={handleFromCompanyChange}
              selectedValue={fromCompanyId}
            />
          </Paper>
        </Grid>
        {fromCompanyId && (
          <React.Fragment>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <ArrowDownwardIcon />
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 16 }}>
                <CompaniesSelect
                  placeholder=" -- Dupliquer vers l'entreprise --"
                  onChange={handleToCompanyChange}
                  selectedValue={toCompanyId}
                  disabledValues={[fromCompanyId]}
                />
              </Paper>
            </Grid>
          </React.Fragment>
        )}

        {fromCompanyId && toCompanyId && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              disabled={!fromCompanyId || !toCompanyId || fromCompanyId === toCompanyId}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Dupliquer
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
