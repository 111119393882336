import request, { METHOD } from "../utils/request";

class UserService {
  static signIn(email, password) {
    return request({
      url: "/users/login",
      method: METHOD.POST,
      data: {
        email,
        password
      }
    });
  }

  static me() {
    return request({
      url: "/users/me",
      method: METHOD.GET
    });
  }

  static patchUser(user) {
    return request({
      url: "/users/me",
      method: METHOD.PATCH,
      data: user
    });
  }

  static lostPassword(email) {
    return request({
      url: "/users/lost_password",
      method: METHOD.POST,
      data: {
        email
      }
    });
  }

  static resetPasswordByToken(token, password) {
    return request({
      url: `/users/change_password/${token}`,
      method: METHOD.POST,
      data: {
        password
      }
    });
  }

  static changePasswordByUser(password) {
    return request({
      url: `/users/change_password`,
      method: METHOD.POST,
      data: {
        password
      }
    });
  }
}

export default UserService;
