import request, { METHOD } from "../utils/request";

class PaymentService {
  static createPayment({ invoiceId, payment }) {
    return request({
      url: `/payments`,
      method: METHOD.POST,
      data: { ...payment, invoiceId }
    });
  }

  static deletePayment(invoiceId) {
    return request({
      url: `/payments/${invoiceId}`,
      method: METHOD.DELETE
    });
  }

  static patchPayment({ invoiceId, payment }) {
    return request({
      url: `/payments/${payment.id}`,
      method: METHOD.PATCH,
      data: { ...payment, invoiceId }
    });
  }
}

export default PaymentService;
