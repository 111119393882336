import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { makeStyles } from "@material-ui/core/styles";
import { ACLS } from "../../utils/matrix";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  matchContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex"
  },
  matchHigh: {
    opacity: 1,
    fontSize: theme.typography.pxToRem(12),
    color: "#000"
  },
  matchLabel: {
    fontSize: theme.typography.pxToRem(10),
    paddingRight: 4,
    opacity: 0.5
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  checkLabel: {
    paddingTop: 4,
    paddingBottom: 4
  }
}));

export default function ManageUserFeaturesRow({ feature, toggleAcl, acls, focusedFeature, toggleFocusedFeature }) {
  const classes = useStyles();
  const [featureAcls, setFeatureAcls] = useState([]);
  const [matches, setMatches] = useState(0);

  const handleMatches = () => {
    let _matches = 0;
    featureAcls.forEach(acl => {
      if (acls.includes(acl.key)) {
        _matches += 1;
      }
    });
    setMatches(_matches);
  };

  useEffect(() => {
    if (feature.key) {
      setFeatureAcls(ACLS.filter(acl => acl.relatedFeature === feature.key));
    }
  }, [feature.key]);

  useEffect(() => {
    handleMatches();
  }, [featureAcls]);

  useEffect(() => {
    handleMatches();
  }, [acls]);

  return (
    <ExpansionPanel
      expanded={focusedFeature === feature.key}
      onChange={() => {
        toggleFocusedFeature(feature.key);
      }}
      square
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
        <Grid container>
          <Grid item xs={10}>
            <Typography className={classes.heading}>{feature.label}</Typography>
          </Grid>
          <Grid item xs={2} className={classes.matchContainer}>
            <Typography className={classes.headingMatch}>
              <span
                className={classes.matchHigh}
                style={{ opacity: matches > 0 ? 1 : 0.5, fontWeight: matches > 0 ? "bold" : "normal" }}
              >
                {matches}{" "}
              </span>
              <span className={classes.matchLabel}>/ {featureAcls.length}</span>
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container>
          {featureAcls &&
            featureAcls.map(acl => (
              <Grid item xs={12} key={`acl-${acl.key}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acls.includes(acl.key)}
                      onChange={() => {
                        toggleAcl(acl.key);
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={acl.label}
                  classes={{ root: classes.checkLabel }}
                />
              </Grid>
            ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
