import React, { Component } from "react";
import { SketchPicker } from "react-color";
import { Paper, withStyles, Button, Grid, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { withSnackbar } from "notistack";

import CompanyService from "../../services/CompanyService";
import NotFoundPage from "../../pages/NotFoundPage";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  centerizedContainer: {
    padding: theme.spacing(2),
    justifyContent: "center"
  },
  paper: {
    width: "100%",
    marginTop: theme.spacing(3),
    position: "relative"
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  colors: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonsWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around"
  }
});

@inject("sessionStore")
@observer
class ColorCompany extends Component {
  handleChangePrimary = color => {
    const { sessionStore } = this.props;

    sessionStore.setTheme({ primaryColor: color.hex });
  };

  handleChangeSecondary = color => {
    const { sessionStore } = this.props;

    sessionStore.setTheme({ secondaryColor: color.hex });
  };

  handleSubmit = () => {
    const {
      sessionStore: {
        theme: { palette }
      }
    } = this.props;

    const primaryColor = palette.primary.main;
    const secondaryColor = palette.secondary.main;

    CompanyService.setColors({ primaryColor, secondaryColor })
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Les couleurs ont été mises à jour", { variant: "success" });
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de l'enregistrement des couleurs", { variant: "error" });
      });
  };

  resetTheme = () => {
    const { sessionStore } = this.props;
    sessionStore.resetTheme();
  };

  render() {
    const { classes, sessionStore } = this.props;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["SETTING_WRITE", "SETTING_READ"],
        aclType: "oneof",
        requiredFeatures: ["SETTING"]
      })
    ) {
      return <NotFoundPage />;
    }

    const canEdit = sessionStore.userHasAccess({
      requiredAcls: ["SETTING_WRITE"],
      requiredFeatures: ["SETTING"]
    });

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Couleurs</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container direction="row" className={classes.centerizedContainer}>
              <Grid item xs={12} sm={5} md={4} className={classes.colors}>
                <Typography variant="h4" gutterBottom>
                  Principale
                </Typography>
                <SketchPicker
                  disableAlpha
                  color={sessionStore.theme.palette.primary.main}
                  onChange={this.handleChangePrimary}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={4} className={classes.colors}>
                <Typography variant="h4" gutterBottom>
                  Secondaire
                </Typography>
                <SketchPicker
                  disableAlpha
                  color={sessionStore.theme.palette.secondary.main}
                  onChange={this.handleChangeSecondary}
                />
              </Grid>
            </Grid>
            {!canEdit && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} />}
          </Paper>
        </Grid>
        {canEdit && (
          <div className={classes.buttonsWrapper}>
            <div>
              <Button className={classes.button} variant="contained" color="primary" onClick={this.handleSubmit}>
                Enregistrer
              </Button>
            </div>
            <div>
              <Button className={classes.button} variant="outlined" color="primary" onClick={this.resetTheme}>
                Valeurs par défaut
              </Button>
            </div>
          </div>
        )}
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(ColorCompany));
