import React, { Fragment } from "react";

export const truncate = string => (string.length > 150 ? `${string.substring(0, 149)}...` : string);

export const additionalDocumentLinkedDocumentToText = additionalDocument => {
  if (additionalDocument.invoice) {
    return (
      <Fragment>
        au titre du marché faisant objet de la <strong>facture</strong> n°
        <strong>{additionalDocument.invoice.number}</strong> relative aux travaux{" "}
        <strong>&quot;{additionalDocument.invoice.nature}&quot;</strong>
      </Fragment>
    );
  }
  if (additionalDocument.estimate) {
    return (
      <Fragment>
        au titre du marché faisant objet du <strong>devis</strong> n°
        <strong>{additionalDocument.estimate.number}</strong> relatif aux travaux{" "}
        <strong>&quot;{additionalDocument.estimate.nature}&quot;</strong>
      </Fragment>
    );
  }
  return "";
};
