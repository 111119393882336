import React, { useEffect, useState } from "react";
import { AppBar, Dialog, IconButton, Toolbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { TransitionUp } from "../utils/transitions/TransitionUp";

export default function PreviewFileDialog({ open, handleClose, file }) {
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");

  useEffect(() => {
    if (open) {
      setFileName(file.name);
      setFilePath(URL.createObjectURL(file));
    } else {
      setFileName("");
      setFilePath("");
    }
  }, [open, file]);

  return (
    <Dialog
      fullScreen
      open={Boolean(open && !!fileName && !!filePath)}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
    >
      <AppBar style={{ position: "relative" }}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {file && filePath ? <iframe src={filePath} style={{ width: "100%", height: "100%" }} /> : <div>LOADING</div>}
    </Dialog>
  );
}
