import React, { Component } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";

import NotFoundPage from "../NotFoundPage";
import EditAdditionalDocumentContainer from "../../components/additionalDocument/EditAdditionalDocumentContainer";
import { privateRoutes } from "../../routes";

@inject("sessionStore")
@observer
class AdditionalDocumentPage extends Component {
  render() {
    const { sessionStore, match, history } = this.props;

    if (
      !sessionStore.userHasAccess({
        requiredFeatures: ["ADDITIONAL_DOCUMENT"],
        requiredAcls: ["ADDITIONAL_DOCUMENT_WRITE", "ADDITIONAL_DOCUMENT_MY"],
        aclType: "oneof"
      })
    )
      return <NotFoundPage />;

    return (
      <EditAdditionalDocumentContainer
        additionalDocumentId={match.params.additionalDocumentId}
        handleCancel={() => {
          history.push(privateRoutes.common.additionalDocuments.worksAcceptanceListing.path);
        }}
        onPatchSuccess={() => {
          history.push(privateRoutes.common.additionalDocuments.worksAcceptanceListing.path);
        }}
      />
    );
  }
}

export default withRouter(AdditionalDocumentPage);
