import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Button,
  CircularProgress,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import { withSnackbar } from "notistack";

import SignaturesListFilter from "./SignaturesListFilter";
import TableSortableHeader from "../table/TableSortableHeader";
import { privateRoutes } from "../../routes";
import SignatureService from "../../services/SignatureService";
import SignaturesListItem from "./SignaturesListItem";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  loadingCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  buttonsContainer: {
    flex: 2,
    justifyContent: "flex-end",
    display: "flex"
  },
  rightAlign: {
    textAlign: "right"
  },
  centerAlign: {
    textAlign: "center"
  },
  rightMargin: {
    marginRight: theme.spacing()
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "rgb(224,224,244)"
  }
});

const ROWS_PER_PAGE = 50;
const defaultFilter = {
  q: "",
  hasSigned: "ALL",
  userId: "",
  status: [],
  from: null,
  to: null,
  client: ""
};

@inject("sessionStore")
@observer
class SignaturesList extends Component {
  constructor(props) {
    super(props);
    const { filter, sort } = props;
    this.state = {
      currentPage: 0,
      currentSort: sort || null,
      filter: { ...defaultFilter, ...(filter || {}) },
      gonnaBeRefreshed: false,
      signatures: null,
      totalCount: 0,
      menuOpenFor: null
    };
  }

  componentDidMount() {
    this.fetchDocSignatures();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChangePage = (event, page) => {
    if (this._isMounted) {
      this.setState({ currentPage: page }, () => {
        this.fetchDocSignatures();
      });
    }
  };

  handleItemClick = signatureId => {
    const { menuOpenFor } = this.state;
    if (menuOpenFor === null) {
      this.setState({ menuOpenFor: signatureId });
    } else if (signatureId === menuOpenFor) {
      this.setState({ menuOpenFor: null });
    } else {
      this.setState({ menuOpenFor: signatureId });
    }
  };

  handleSort = arg => {
    if (this._isMounted) {
      this.setState({ currentSort: arg }, () => {
        this.fetchDocSignatures();
      });
    }
  };

  updateFilter = async filter => {
    if (this._isMounted) {
      await this.setState({ filter });
    }
  };

  fetchDocSignatures = () => {
    const { currentSort, filter, currentPage } = this.state;
    const { sessionStore } = this.props;
    this.setState({ gonnaBeRefreshed: true }, () => {
      const ziFilter = {
        ...filter,
        // { status: filter.status.length === Object.keys(INVOICE_STATUS).length ? "" : filter.status.join(",") },
        from: filter.from ? filter.from.format("YYYY-MM-DD") : "",
        to: filter.to ? filter.to.format("YYYY-MM-DD") : ""
      };
      SignatureService.getSignatures({
        page: currentPage,
        sort: currentSort ? currentSort.column : undefined,
        order: currentSort ? currentSort.order : undefined,
        filter: ziFilter,
        realUserId: sessionStore.user.id
      })
        .then(response => {
          if (this._isMounted) {
            this.setState({
              signatures: response.docSignatures,
              totalCount: response.docSignaturesCount,
              gonnaBeRefreshed: false
            });
          }
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la récupération des signatures", { variant: "error" });
          if (this._isMounted) {
            this.setState({ gonnaBeRefreshed: false });
          }
        });
    });
  };

  render() {
    const { classes, title, disableFilter = false, disableCreation = false, sessionStore } = this.props;
    const { currentPage, currentSort, filter, gonnaBeRefreshed, signatures, totalCount, menuOpenFor } = this.state;
    return (
      <Grid container className={classes.container}>
        <Grid item xs={12} style={{ display: "flex", marginBottom: 24 }}>
          {title && <Typography variant="h4">{title}</Typography>}
          {!disableCreation && (
            <div className={classes.buttonsContainer}>
              {!disableCreation && (
                <Hidden xsDown>
                  <Button
                    component={Link}
                    to={privateRoutes.admin.signatures.creationForm.path}
                    variant="outlined"
                    color="primary"
                  >
                    Nouvelle signature
                  </Button>
                </Hidden>
              )}
            </div>
          )}
        </Grid>

        {!disableFilter && (
          <SignaturesListFilter
            filter={filter}
            defaultFilter={defaultFilter}
            updateFilter={this.updateFilter}
            onSubmit={this.fetchDocSignatures}
          />
        )}
        <Grid item xs={12}>
          <Paper className={classes.root}>
            {signatures && signatures.length >= 15 && (
              <TablePagination
                component="div"
                count={totalCount}
                rowsPerPage={ROWS_PER_PAGE}
                rowsPerPageOptions={[ROWS_PER_PAGE]}
                page={currentPage}
                backIconButtonProps={{
                  "aria-label": "Page précédente"
                }}
                nextIconButtonProps={{
                  "aria-label": "Page suivante"
                }}
                onChangePage={this.handleChangePage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                className={classes.borderBottom}
              />
            )}
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 45, textAlign: "center", paddingRight: 16 }}>Statut</TableCell>
                  <TableCell>Signataires</TableCell>
                  <TableCell>
                    <TableSortableHeader
                      onClick={this.handleSort}
                      className={classes.sortableHeader}
                      currentSort={currentSort}
                      attribute="signature.subject"
                    >
                      Objet
                    </TableSortableHeader>
                  </TableCell>
                  <TableCell>État</TableCell>
                  <TableCell>
                    <TableSortableHeader
                      onClick={this.handleSort}
                      className={classes.sortableHeader}
                      currentSort={currentSort}
                      attribute="signature.createAt"
                    >
                      Créée le
                    </TableSortableHeader>
                  </TableCell>
                  <TableCell style={{ width: 50 }}>Détails</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {signatures && signatures.length >= 0 && gonnaBeRefreshed === false ? (
                  signatures.length > 0 ? (
                    signatures.map(signature => (
                      <SignaturesListItem
                        selected={signature.id === menuOpenFor}
                        signature={signature}
                        key={signature.id}
                        renderMenu={this.renderMenu}
                        onClick={this.handleItemClick}
                        onItemUpdated={this.fetchDocSignatures}
                        signatureProvider={sessionStore.user.company.signatureProvider}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        Aucune signature
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center" className={classes.loadingCell}>
                      <CircularProgress size={40} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {signatures && signatures.length >= 0 && (
              <TablePagination
                component="div"
                count={totalCount}
                rowsPerPage={ROWS_PER_PAGE}
                rowsPerPageOptions={[ROWS_PER_PAGE]}
                page={currentPage}
                backIconButtonProps={{
                  "aria-label": "Page précédente"
                }}
                nextIconButtonProps={{
                  "aria-label": "Page suivante"
                }}
                onChangePage={this.handleChangePage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withSnackbar(withRouter(withStyles(styles)(SignaturesList)));
