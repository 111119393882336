import { Box, Button, TextField } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import AdminService from "../../services/AdminService";

export default function ResetInvoiceToDraft() {
  const [invoiceId, setInvoiceId] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = e => {
    setInvoiceId(e.target.value);
  };

  const handleSubmitInvoiceId = async () => {
    await AdminService.resetInvoiceToDraft(invoiceId)
      .then(() => {
        enqueueSnackbar("Facture remise en brouillon", { variant: "success" });
      })
      .catch(e => {
        console.log(" FAILED ", e);
        enqueueSnackbar("Une erreur est survenue", { variant: "error" });
      });
    setInvoiceId("");
  };

  return (
    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", gap: "12px" }}>
      <TextField
        name="invoiceId"
        placeholder="Identifiant facture"
        label="Identifiant facture"
        margin="none"
        fullWidth
        value={invoiceId}
        onChange={handleChange}
      />
      <Button variant="outlined" color="primary" onClick={handleSubmitInvoiceId}>
        Valider
      </Button>
    </Box>
  );
}
