import { observable } from "mobx";
import Client from "./Client";
import FINANCIAL_METHODS from "../stores/NewInvoiceStore";

class Invoice {
  constructor({
    amount,
    client,
    computedDiscount,
    currency = "EUR",
    discount,
    discountMethod,
    id,
    information,
    nature,
    number,
    paymentCondition,
    paymentMode,
    totalPriceWithTVA,
    totalPriceWithoutTVA,
    comments,
    paymentTerms,
    paymentDelay,
    additionalInformation,
    extraAdditionalInformation,
    useSpecificDeliveryAddress,
    deliveryAddress
  }) {
    this.amount = amount;
    this.client = client;
    this.computedDiscount = computedDiscount;
    this.currency = currency;
    this.discount = discount;
    this.discountMethod = discountMethod;
    this.id = id;
    this.information = information;
    this.nature = nature;
    this.number = number;
    this.paymentCondition = paymentCondition;
    this.paymentMode = paymentMode;
    this.totalPriceWithTVA = totalPriceWithTVA;
    this.totalPriceWithoutTVA = totalPriceWithoutTVA;
    this.comments = comments;
    this.paymentTerms = paymentTerms;
    this.paymentDelay = paymentDelay;
    this.additionalInformation = additionalInformation;
    this.extraAdditionalInformation = extraAdditionalInformation;
    this.useSpecificDeliveryAddress = useSpecificDeliveryAddress;
    this.deliveryAddress = useSpecificDeliveryAddress ? deliveryAddress : null;
  }

  @observable
  id = "";

  @observable
  number = "";

  @observable
  currency = "EUR";

  @observable
  nature = "";

  @observable
  amount = 0;

  @observable
  totalPriceWithoutTVA = 0;

  @observable
  totalPriceWithTVA = 0;

  @observable
  paymentCondition = "";

  @observable
  paymentMode = "";

  @observable
  discount = "";

  @observable
  discountMethod = FINANCIAL_METHODS.PERCENTAGE;

  @observable
  computedDiscount = 0;

  @observable
  client = new Client();

  @observable
  information = "";

  @observable
  comments = "";
}

export default Invoice;
