import React, { Fragment } from "react";
import moment from "moment";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

import { formatCurrency } from "../../../utils/numberFormat";
import InvoiceStatusLabel from "../../invoice/InvoiceStatusLabel";

const ITEMS_PER_PAGE = 15;
const CURRENT_PAGE = 0;

function InvoicesList({ invoices, hideContactColumn = false, view = "default" }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{view === "default" ? "Date de validation" : "Date de création"}</TableCell>
          {view === "default" && <TableCell>Numéro</TableCell>}
          {!hideContactColumn && <TableCell>Client / Société</TableCell>}
          <TableCell>Objet</TableCell>
          <TableCell>Utilisateur</TableCell>
          <TableCell>Montant HT</TableCell>
          {view === "default" && <TableCell>Statut</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {invoices.length > 0 ? (
          invoices.slice(CURRENT_PAGE * ITEMS_PER_PAGE, CURRENT_PAGE * ITEMS_PER_PAGE + ITEMS_PER_PAGE).map(invoice => (
            <TableRow key={invoice.id}>
              <TableCell>
                {view === "default"
                  ? moment(invoice.validationDate).format("DD/MM/YYYY")
                  : moment(invoice.createAt).format("DD/MM/YYYY")}
              </TableCell>
              {view === "default" && <TableCell>N°{invoice.number}</TableCell>}
              {!hideContactColumn && (
                <TableCell>
                  {invoice.clientBusinessName && (
                    <Fragment>
                      <b>{invoice.clientBusinessName}</b>
                      <br />
                    </Fragment>
                  )}
                  {`${invoice.clientFirstname} ${invoice.clientLastname.toUpperCase()}`}
                </TableCell>
              )}
              <TableCell>
                <b>{invoice.nature}</b>
              </TableCell>
              <TableCell>
                {invoice.user && `${invoice.user.firstname} ${invoice.user.lastname.toUpperCase()}`}
              </TableCell>
              <TableCell>{formatCurrency(invoice.totalPriceWithoutTVA, invoice.currency)}</TableCell>
              {view === "default" && (
                <TableCell>
                  <InvoiceStatusLabel invoice={invoice} />
                </TableCell>
              )}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={7} align="center">
              Aucune facture établie sur la période
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default InvoicesList;
