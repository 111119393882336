import React from "react";
import { inject, observer } from "mobx-react";
import {
  Button,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import HistoryIcon from "@material-ui/icons/History";
import { Link } from "react-router-dom";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import ClientInformations from "./ClientInformations";
import BackButton from "../button/BackButton";
import { privateRoutes } from "../../routes";
import ClientEventsList from "./ClientEventsList";
import AddEditClientEventDialog from "../dialog/AddEditClientEventDialog";
import ClientEstimatesList from "./ClientEstimatesList";
import ClientOrderFormsList from "./ClientOrderFormsList";
import ClientInvoicesList from "./ClientInvoicesList";

const useStyles = makeStyles(theme => ({
  back: { marginTop: theme.spacing(), marginBottom: theme.spacing(2) },
  header: { paddingBottom: theme.spacing(2) },
  list: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginRight: -theme.spacing(3)
  },
  itemSelected: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  itemBtn: {
    "&:hover": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5
    }
  },
  tabscontainer: {
    marginBottom: theme.spacing(2)
  },
  tab: {
    borderBottom: "1px solid #E6E6E6"
  },
  button: {
    display: "flex",
    justifyContent: "center"
  },
  edit: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center"
  },
  editbtn: {
    width: 200
  }
}));

const MENU = [
  { label: "Informations", key: "INFORMATIONS", icon: <PermContactCalendarIcon /> },
  { label: "Historique", key: "HISTORY", icon: <HistoryIcon /> }
];

function ClientDetails({ client, sessionStore }) {
  const [selectedItem, setSelectedItem] = React.useState(MENU[0].key);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [eventAddedKey, setEventAddedKey] = React.useState(null);
  const [eventForDialog, setEventForDialog] = React.useState(null);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = key => {
    setSelectedItem(key);
  };

  const canEstimate = React.useMemo(
    () =>
      sessionStore.userHasAccess({
        requiredAcls: ["ESTIMATE_MY", "ESTIMATE_READ"],
        requiredFeatures: ["ESTIMATE"],
        aclType: "oneof"
      }),
    [sessionStore.user]
  );

  const canInvoice = React.useMemo(
    () =>
      sessionStore.userHasAccess({
        requiredAcls: ["INVOICE_MY", "INVOICE_READ"],
        requiredFeatures: ["INVOICE"],
        aclType: "oneof"
      }),
    [sessionStore.user]
  );

  const canOrder = React.useMemo(
    () =>
      sessionStore.userHasAccess({
        requiredAcls: ["ORDER_MY", "ORDER_READ"],
        requiredFeatures: ["ORDER"],
        aclType: "oneof"
      }),
    [sessionStore.user]
  );

  const canEvent = React.useMemo(
    () =>
      sessionStore.userHasAccess({
        requiredAcls: ["CONTACT_WRITE"],
        requiredFeatures: ["CONTACT"]
      }) || client.user.id === sessionStore.user.id,
    [sessionStore.user]
  );

  const handleCreateEvent = () => {
    setAnchorEl(null);
    setEventForDialog({
      title: "",
      content: "",
      dueDate: null,
      status: "PENDING"
    });
    // ENFORCE LISTING REFRESH IF COMPONENT DISPLAYED
  };

  const onDialogClose = added => {
    if (added) {
      setEventAddedKey(Date.now());
    }
    setEventForDialog(null);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <BackButton to="/clients" textLong="Retour aux clients" />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography variant="h4" className={classes.header}>
            {client.firstname} {client.lastname.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.button}>
          <div>
            {client.isActive && (canEstimate || canInvoice || canOrder || canEvent) ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                Créer...
              </Button>
            ) : null}
            <Menu
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              getContentAnchorEl={null}
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {canEvent && <MenuItem onClick={handleCreateEvent}>Créer un événement</MenuItem>}
              {canEstimate && (
                <MenuItem
                  component={Link}
                  to={{ pathname: privateRoutes.common.estimates.creationForm.path, state: { client } }}
                >
                  Créer un devis
                </MenuItem>
              )}
              {canOrder && (
                <MenuItem
                  component={Link}
                  to={{ pathname: privateRoutes.common.orderForms.creationForm.path, state: { client } }}
                >
                  Créer un bon de commande
                </MenuItem>
              )}
              {canInvoice && (
                <MenuItem
                  component={Link}
                  to={{ pathname: privateRoutes.admin.invoices.creationForm.path, state: { client } }}
                >
                  Créer une facture
                </MenuItem>
              )}
            </Menu>
          </div>
        </Grid>
        <Hidden mdUp>
          <Grid item xs={12}>
            <Tabs
              value={selectedItem}
              onChange={(e, selectedTab) => handleMenuClick(selectedTab)}
              variant="fullWidth"
              className={classes.tabscontainer}
            >
              {MENU.map(item => (
                <Tab key={item.key} value={item.key} label={item.label} className={classes.tab} />
              ))}
            </Tabs>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={9}>
          {selectedItem === "INFORMATIONS" && (
            <React.Fragment>
              <ClientInformations client={client} />
              {client.isActive && (
                <Grid item xs={12} className={classes.edit}>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/clients/${client.id}/modification`}
                      className={classes.editbtn}
                    >
                      Modifier
                    </Button>
                  </div>
                </Grid>
              )}
            </React.Fragment>
          )}
          {selectedItem === "HISTORY" && (
            <ClientEventsList refreshKey={eventAddedKey} clientId={client.id} allowEdition={canEvent} />
          )}
          {selectedItem === "ESTIMATES" && <ClientEstimatesList clientId={client.id} />}
          {selectedItem === "ORDERS" && <ClientOrderFormsList clientId={client.id} />}
          {selectedItem === "INVOICES" && <ClientInvoicesList clientId={client.id} />}
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={3}>
            <List className={classes.list}>
              {MENU.map(item => (
                <ListItem
                  button
                  key={item.key}
                  selected={selectedItem === item.key}
                  onClick={() => handleMenuClick(item.key)}
                  classes={{ selected: classes.itemSelected, button: classes.itemBtn }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
              {canEstimate && (
                <ListItem
                  button
                  selected={selectedItem === "ESTIMATES"}
                  onClick={() => handleMenuClick("ESTIMATES")}
                  classes={{ selected: classes.itemSelected, button: classes.itemBtn }}
                >
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Devis" />
                </ListItem>
              )}
              {canOrder && (
                <ListItem
                  button
                  selected={selectedItem === "ORDERS"}
                  onClick={() => handleMenuClick("ORDERS")}
                  classes={{ selected: classes.itemSelected, button: classes.itemBtn }}
                >
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Bons de commande" />
                </ListItem>
              )}
              {canInvoice && (
                <ListItem
                  button
                  selected={selectedItem === "INVOICES"}
                  onClick={() => handleMenuClick("INVOICES")}
                  classes={{ selected: classes.itemSelected, button: classes.itemBtn }}
                >
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="Factures" />
                </ListItem>
              )}
            </List>
          </Grid>
        </Hidden>
      </Grid>
      {canEvent && (
        <AddEditClientEventDialog open={Boolean(eventForDialog)} onClose={onDialogClose} clientId={client.id} />
      )}
    </React.Fragment>
  );
}

export default inject("sessionStore")(observer(props => <ClientDetails {...props} />));
