import React, { Component } from "react";
import {
  withStyles,
  MenuItem,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Fab,
  CircularProgress,
  TablePagination
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { inject, observer } from "mobx-react";
import { withSnackbar } from "notistack";

import TableAction from "./TableAction";
import ConfirmationDialog, { stopPropagationForConfirmation } from "./dialog/ConfirmationDialog";
import CompanyService from "../services/CompanyService";
import AddEditProfileDialog from "./dialog/AddEditProfileDialog";

const ITEMS_PER_PAGE = 25;

@inject("sessionStore")
@observer
class ProfilesList extends Component {
  state = {
    profiles: null,
    open: false,
    selectedProfile: null,
    gonnaBeRefreshed: false,
    currentPage: 0,
    menuOpenFor: null
  };

  componentDidMount() {
    this.fetchListing();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchListing = () => {
    this.setState({ gonnaBeRefreshed: true }, () => {
      CompanyService.getProfiles()
        .then(res => {
          if (this._isMounted) {
            this.setState({
              gonnaBeRefreshed: false,
              profiles: res.content,
              currentPage: 0
            });
          }
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la récupération des profils utilisateurs", {
            variant: "error"
          });
        });
    });
  };

  handleDeletion = profile => {
    const { sessionStore } = this.props;
    CompanyService.deleteProfile(profile)
      .then(() => {
        this.fetchListing();
      })
      .catch(e => {
        if (this._isMounted && e.status === 400) {
          if (
            sessionStore.userHasAccess({
              requiredAcls: ["USER_READ"],
              requiredFeatures: ["USER"]
            })
          ) {
            const { users, usersCount } = e.data;
            let usersToStr = "";
            users.forEach((user, index) => {
              usersToStr += `${user.firstname} ${user.lastname}`;
              if (index < users.length - 2) {
                usersToStr += ", ";
              } else if (index < users.length - 1) {
                usersToStr += " et ";
              }
            });
            const { enqueueSnackbar } = this.props;
            enqueueSnackbar(
              `Impossible de supprimer le profil sélectionné car celui-ci est associé ${
                usersCount > 1 ? "aux utilisateurs" : "à l'utilisateur"
              } : ${usersToStr}`,
              { variant: "info" }
            );
            this.setState({ gonnaBeRefreshed: false });
          } else {
            const { enqueueSnackbar } = this.props;
            enqueueSnackbar(
              `Impossible de supprimer le profil sélectionné car celui-ci est associé à ${e.data.usersCount} ${
                e.data.usersCount > 1 ? "utilisateurs" : "utilisateur"
              }`,
              { variant: "info" }
            );
            this.setState({ gonnaBeRefreshed: false });
          }
        } else if (this._isMounted) {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la suppression du profil utilisateur", { variant: "error" });
          this.setState({ gonnaBeRefreshed: false });
        }
      });
  };

  handleClickOpen = () => {
    this.setState({ open: true, selectedProfile: null });
  };

  handleProfileAddedOrEdited = () => {
    this.setState({ open: false, selectedProfile: null });
    this.fetchListing();
  };

  handleEditProfile = profile => {
    this.setState({ selectedProfile: profile, open: true });
  };

  handleChangePage = (event, page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { classes, disableAddFn, sessionStore } = this.props;
    const { open, selectedProfile, gonnaBeRefreshed, profiles, currentPage, menuOpenFor } = this.state;

    const slicedList = profiles
      ? profiles.slice(currentPage * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE)
      : null;

    const canEdit = sessionStore.userHasAccess({
      requiredAcls: ["SETTING_WRITE"],
      requiredFeatures: ["SETTING"]
    });

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Profils utilisateurs</Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  {canEdit && (
                    <TableCell style={{ width: 100 }} align="center">
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {profiles && gonnaBeRefreshed === false ? (
                  slicedList.length > 0 ? (
                    slicedList.map(profile => (
                      <TableRow
                        key={profile.id}
                        className={profile.deleted ? classes.rowDeleted : null}
                        selected={profile.id === menuOpenFor}
                      >
                        <TableCell component="th" scope="row">
                          <p>{profile.name}</p>
                        </TableCell>
                        {canEdit && (
                          <TableCell align="center">
                            {profile.deleted ? null : (
                              <TableAction
                                onOpen={() =>
                                  this.setState({
                                    menuOpenFor: profile.id
                                  })
                                }
                                onClose={() =>
                                  this.setState({
                                    menuOpenFor: null
                                  })
                                }
                              >
                                <MenuItem onClick={() => this.handleEditProfile(profile)}>Modifier</MenuItem>
                                <ConfirmationDialog>
                                  {confirmBeforeAct => (
                                    <MenuItem
                                      onClick={e =>
                                        stopPropagationForConfirmation(
                                          e,
                                          confirmBeforeAct(() => this.handleDeletion(profile))
                                        )
                                      }
                                    >
                                      Supprimer
                                    </MenuItem>
                                  )}
                                </ConfirmationDialog>
                              </TableAction>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center" className={classes.loadingCell}>
                        Vous n&apos;avez pas encore créé de profil utilisateur
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center" className={classes.loadingCell}>
                      <CircularProgress size={40} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {profiles && profiles.length > 0 && (
              <TablePagination
                component="div"
                count={profiles.length}
                rowsPerPage={ITEMS_PER_PAGE}
                rowsPerPageOptions={[ITEMS_PER_PAGE]}
                page={currentPage}
                backIconButtonProps={{
                  "aria-label": "Page précédente"
                }}
                nextIconButtonProps={{
                  "aria-label": "Page suivante"
                }}
                onChangePage={this.handleChangePage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
              />
            )}
          </Paper>
        </Grid>
        {canEdit && (
          <AddEditProfileDialog
            open={open}
            onAddOrUpdate={this.handleProfileAddedOrEdited}
            selectedProfile={selectedProfile}
            onClose={() => {
              this.setState({
                open: false
              });
            }}
          />
        )}
        {canEdit && (
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            onClick={this.handleClickOpen}
            disabled={disableAddFn}
          >
            <AddIcon />
          </Fab>
        )}
      </Grid>
    );
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    marginBottom: 80
  },
  table: {
    minWidth: 700
  },
  rowDeleted: {
    background: theme.palette.action.disabled,
    color: theme.palette.text.disabled,
    textDecoration: "line-through"
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  loadingCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  woMarginTop: {
    marginTop: 0
  },
  advancedContainer: {
    marginTop: theme.spacing(3)
  },
  advancedFieldsContainer: {
    paddingBottom: theme.spacing(),
    backgroundColor: "#FCFCFC"
  },
  advancedActionContainer: {
    backgroundColor: "#FCFCFC"
  },
  advancedInput: {
    marginTop: theme.spacing(2)
  },
  advancedInputContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  expWrapper: {
    overflow: "hidden"
  }
});

export default withSnackbar(withStyles(styles)(ProfilesList));
