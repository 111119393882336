import EstimateService from "../services/EstimateService";

class EstimateSignature {
  constructor(estimateId, companyId) {
    this.estimateId = estimateId;
    this.companyId = companyId;
  }

  getCompanyLogoPath() {
    return `${process.env.API_URL}/public/logo/${this.companyId}.png`;
  }

  getEstimatePublicUrl() {
    return `${process.env.API_URL}/estimates/${this.estimateId}/company/${this.companyId}/downloadPDF`;
  }

  async canBeSign() {
    const canBe = await EstimateService.getSignStatus(this.estimateId)
      .then(() => true)
      .catch(e => e.status);
    return canBe;
  }

  async askForCode() {
    const sent = await EstimateService.sendSignCode(this.estimateId)
      .then(() => true)
      .catch(() => false);
    return sent;
  }

  async checkCode(code) {
    const isValid = await EstimateService.checkSignCode(this.estimateId, code)
      .then(() => true)
      .catch(() => false);
    return isValid;
  }
}

export default EstimateSignature;
