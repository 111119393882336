import {
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import React, { useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { additionalDocumentLinkedDocumentToText } from "../../utils/textFormat";
import AdditionalDocumentService from "../../services/AdditionalDocumentService";

const styles = theme => ({
  container: { padding: theme.spacing(2) }
});

function WorksAcceptanceForm({ additionalDocument, classes, handleCancel, onPatchSuccess }) {
  const queryClient = useQueryClient();
  const [fields, setFields] = useState(additionalDocument.fields || {});
  const { enqueueSnackbar } = useSnackbar();

  const handleFieldChange = event => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handleEffectiveDateChange = date => {
    setFields({ ...fields, effectiveDate: date });
  };

  const handleSubmit = event => {
    event.preventDefault();
    AdditionalDocumentService.patchAdditionalDocumentFields(additionalDocument.id, {
      ...fields,
      effectiveDate: fields.effectiveDate ? fields.effectiveDate : moment().toISOString()
    })
      .then(() => {
        queryClient.invalidateQueries(["additional_document", { additionalDocumentId: additionalDocument.id }]);
        enqueueSnackbar("Le document a été modifié avec succès", { variant: "success" });
        onPatchSuccess();
      })
      .catch(err => {
        console.log(err);
        if (err && err.status && err.status === 406) {
          enqueueSnackbar("La saisie ne permet pas de valider le document, veuillez compléter celui-ci", {
            variant: "error"
          });
        } else {
          enqueueSnackbar("Une erreur est survenue lors de l'enregistrement du document", {
            variant: "error"
          });
        }
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.container} variant="h4">
            Procès verbal de fin de travaux
          </Typography>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper>
              <Grid container style={{ padding: 24 }} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Je, soussigné(e){" "}
                    <strong>
                      {additionalDocument.contact.firstname} {additionalDocument.contact.lastname}
                    </strong>{" "}
                    maitre d&apos;ouvrage, après avoir procédé à la visite des travaux effectués par l&apos;entreprise{" "}
                    <strong>{additionalDocument.company.name}</strong>{" "}
                    {additionalDocumentLinkedDocumentToText(additionalDocument)} en présence du représentant de
                    l&apos;entreprise{" "}
                    <strong>
                      {additionalDocument.user.firstname} {additionalDocument.user.lastname}
                    </strong>{" "}
                    déclare que :
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    name="reservationsStatus"
                    value={fields.reservationsStatus || ""}
                    onChange={handleFieldChange}
                  >
                    <FormControlLabel
                      value="NONE"
                      control={<Radio color="primary" />}
                      label="La réception est prononcée sans réserve"
                    />
                    {fields.reservationsStatus === "NONE" && (
                      <Grid container spacing={2} style={{ paddingTop: 16, paddingBottom: 16 }}>
                        <Grid item xs={12}>
                          <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                            <DatePicker
                              name="effectiveDate"
                              id="limitDate"
                              cancelLabel="Annuler"
                              format="D MMM YYYY"
                              inputVariant="outlined"
                              fullWidth
                              label="Date d'effet"
                              leftArrowIcon={<KeyboardArrowLeftIcon />}
                              rightArrowIcon={<KeyboardArrowRightIcon />}
                              minDate={moment(additionalDocument.createAt)}
                              value={fields.effectiveDate || moment()}
                              onChange={handleEffectiveDateChange}
                              required
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                    )}
                    <FormControlLabel
                      value="LISTED"
                      control={<Radio color="primary" />}
                      label="La réception est prononcée avec réserve(s)"
                    />
                    {fields.reservationsStatus === "LISTED" && (
                      <Grid container spacing={2} style={{ paddingTop: 16, paddingBottom: 16 }}>
                        <Grid item xs={12}>
                          <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                            <DatePicker
                              name="effectiveDate"
                              id="limitDate"
                              cancelLabel="Annuler"
                              format="D MMM YYYY"
                              inputVariant="outlined"
                              fullWidth
                              label="Date d'effet"
                              leftArrowIcon={<KeyboardArrowLeftIcon />}
                              rightArrowIcon={<KeyboardArrowRightIcon />}
                              minDate={moment(additionalDocument.createAt)}
                              value={fields.effectiveDate || moment()}
                              onChange={handleEffectiveDateChange}
                              required
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Nature des réserves"
                            name="reservationsDetails"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            value={fields.reservationsDetails}
                            onChange={handleFieldChange}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Travaux à exécuter"
                            name="reservationsFixDescription"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            value={fields.reservationsFixDescription}
                            onChange={handleFieldChange}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Délai d'exécution"
                            name="reservationsFixDelay"
                            variant="outlined"
                            type="number"
                            fullWidth
                            value={fields.reservationsFixDelay}
                            onChange={handleFieldChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography variant="caption">jours</Typography>
                                </InputAdornment>
                              )
                            }}
                            required
                          />
                        </Grid>
                      </Grid>
                    )}
                    <FormControlLabel
                      value="REFUSED"
                      control={<Radio color="primary" />}
                      label="La réception est refusée - différée"
                    />
                    {fields.reservationsStatus === "REFUSED" && (
                      <Grid container spacing={2} style={{ paddingTop: 16, paddingBottom: 16 }}>
                        <Grid item xs={12}>
                          <TextField
                            label="Motif(s)"
                            name="rejectionDetails"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            value={fields.rejectionDetails}
                            onChange={handleFieldChange}
                            required
                          />
                        </Grid>
                      </Grid>
                    )}
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Garanties</strong> : les garanties découlant des articles 1792, 1792-2 et 1792-3 du Code
                    Civil commencent à courir à compter de la signature du présent procès-verbal.
                  </Typography>
                  <br />
                  <Typography>
                    La signature du procès-verbal de réception et le règlement des travaux autorisent le client
                    soussigné à prendre possession de l’ouvrage.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", gap: 12, justifyContent: "center" }}>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Annuler
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={!fields.reservationsStatus}>
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default withStyles(styles)(WorksAcceptanceForm);
