import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useMemo } from "react";

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing()
  }
}));

export default function SignatureStepperManager({ currentStep, updateStep, steps, onSuccess, isProcessing }) {
  const classes = useStyles();

  const handleGoPrevious = useCallback(() => {
    if (currentStep - 1 >= 0) updateStep(currentStep - 1);
  }, [updateStep, currentStep]);

  const handleGoNext = useCallback(() => {
    if (currentStep === steps.length - 1) {
      onSuccess();
    } else {
      updateStep(currentStep + 1);
    }
  }, [updateStep, currentStep]);

  const canPrevious = useMemo(steps[currentStep].canPrevious, [currentStep, steps]);
  const canNext = useMemo(steps[currentStep].canNext, [currentStep, steps]);

  const previousLabel = useMemo(() => steps[currentStep].previousLabel || "Étape précédente", [steps, currentStep]);
  const nextLabel = useMemo(() => steps[currentStep].nextLabel || "Étape suivante", [steps, currentStep]);

  return (
    <Grid container>
      <Grid item xs={12} sm={6} className={classes.buttonContainer}>
        <Button variant="outlined" color="primary" disabled={!canPrevious || isProcessing} onClick={handleGoPrevious}>
          {previousLabel}
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.buttonContainer}>
        <Button variant="contained" color="primary" disabled={!canNext || isProcessing} onClick={handleGoNext}>
          {nextLabel}
        </Button>
      </Grid>
    </Grid>
  );
}
