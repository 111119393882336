import request, { METHOD } from "../utils/request";

class ContraliaService {
  /**
   * Use this method before downloading a PDF or to end a Contralia transaction.
   * @param {string} transactionId The Contralia transation identifier
   */
  static getTerminateTransaction(transactionId) {
    return request({
      url: `/contralia/terminate/${transactionId}`,
      method: METHOD.GET
    });
  }

  static getDownloadPDF(transactionId) {
    return request({
      url: `/contralia/download/${transactionId}`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }
}

export default ContraliaService;
