import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  makeStyles
} from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import AdminService from "../../../services/AdminService";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block"
  },
  card: { height: "100%" },
  row: {
    display: "flex",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  rowPicto: {
    flexBasis: 40,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  companyItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  }
}));

export default function CompanySignature({ company, onPatch }) {
  const classes = useStyles();
  const [signatureProvider, setSignatureProvider] = React.useState(company.signatureProvider);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = event => {
    setSignatureProvider(event.target.value);
  };

  const handleReset = () => {
    setSignatureProvider(company.signatureProvider);
  };

  const handleSubmit = () => {
    AdminService.patchCompany({
      ...company,
      signatureProvider
    })
      .then(updatedCompany => {
        onPatch(updatedCompany);
        setSignatureProvider(updatedCompany.signatureProvider);
        enqueueSnackbar("L'entreprise a été mise à jour", { variant: "success" });
      })
      .catch(err => {
        console.log(" ERROR ", err);
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'entreprise", {
          variant: "error"
        });
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Signatures
            </Typography>
            <FormControl variant="outlined" size="small" fullWidth>
              <Typography variant="body2" gutterBottom>
                Opérateur
              </Typography>
              <Select
                fullWidth
                value={signatureProvider}
                onChange={handleChange}
                variant="outlined"
                style={{ textAlign: "left" }}
              >
                <MenuItem key="YOUSIGN" value="YOUSIGN">
                  YOUSIGN
                </MenuItem>
                <MenuItem key="CONTRALIA" value="CONTRALIA">
                  CONTRALIA
                </MenuItem>
              </Select>
            </FormControl>
          </CardContent>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Button variant="text" onClick={handleReset}>
              Annuler
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Enregistrer
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
