import React from "react";
import { ListItemIcon } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EditIcon from "@material-ui/icons/Edit";
import HomeIcon from "@material-ui/icons/Home";
import LockIcon from "@material-ui/icons/Lock";
import ShoppingCart from "@material-ui/icons/ShoppingCartOutlined";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

const variantIcon = {
  account: AccountCircleIcon,
  company: BusinessIcon,
  dashboard: DashboardIcon,
  default: ArrowForwardIcon,
  edit: EditIcon,
  home: HomeIcon,
  lock: LockIcon,
  order: AssignmentIcon,
  products: ShoppingCart,
  register: AddCircleOutlineIcon,
  sellers: SupervisorAccountIcon
};

const renderIcon = (variant = "default") => {
  const Icon = variantIcon[variant];
  return (
    <ListItemIcon>
      <Icon />
    </ListItemIcon>
  );
};

export default renderIcon;
