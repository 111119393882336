import React, { Component } from "react";
import {
  withStyles,
  MenuItem,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Fab,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelSummary,
  TextField,
  TablePagination,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Button,
  withWidth
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { inject, observer } from "mobx-react";
import { withSnackbar } from "notistack";

import TableAction from "./TableAction";
import ConfirmationDialog, { stopPropagationForConfirmation } from "./dialog/ConfirmationDialog";
import ManageUserFeaturesDialog from "./dialog/ManageUserFeaturesDialog";
import CompanyService from "../services/CompanyService";
import EditSellerDialog from "./dialog/EditSellerDialog";
import AddSellerDialog from "./dialog/AddSellerDialog";

const ITEMS_PER_PAGE = 25;

@inject("sessionStore")
@observer
class SellerList extends Component {
  state = {
    sellers: null,
    filteredSellersList: null,
    open: false,
    openEditDialog: false,
    openManager: false,
    selectedUser: null,
    gonnaBeRefreshed: false,
    filter: {
      q: "",
      status: "ALL"
    },
    currentPage: 0,
    expanded: false,
    menuOpenFor: null,
    profiles: null
  };

  componentDidMount() {
    this.fetchListing();
    if (this.hasEditAccess()) {
      this.fetchProfiles();
    }
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchProfiles = () => {
    CompanyService.getProfiles().then(res => {
      this.setState({ profiles: res.content });
    });
  };

  fetchListing = () => {
    this.setState({ gonnaBeRefreshed: true }, async () => {
      const updated = await CompanyService.getUsers()
        .then(res => {
          if (this._isMounted) {
            this.setState({
              sellers: res.content,
              gonnaBeRefreshed: false,
              filteredSellersList: res.content,
              currentPage: 0
            });
          }
          return true;
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la récupération des utilisateurs", { variant: "error" });
          if (this._isMounted) {
            this.setState({ gonnaBeRefreshed: false });
          }
          return false;
        });
      return updated;
    });
  };

  filterSellers = ({ q, status }) => {
    const {
      sellers,
      filter: { q: query, status: statouss }
    } = this.state;
    const _q = q || q === "" ? q : query;
    const _status = status || statouss;

    const queried = _q
      ? sellers.filter(
          seller =>
            seller.firstname.toLowerCase().includes(_q.toLowerCase()) ||
            seller.lastname.toLowerCase().includes(_q.toLowerCase()) ||
            seller.job.toLowerCase().includes(_q.toLowerCase())
        )
      : sellers;

    if (_status === "ENABLED") {
      return queried.filter(seller => seller.deleted === false);
    }
    if (_status === "DISABLED") {
      return queried.filter(seller => seller.deleted === true);
    }

    return queried;
  };

  handleChangeQuery = e => {
    const { value } = e.target;
    const { filter } = this.state;
    this.setState({
      filteredSellersList: this.filterSellers({ q: value }),
      filter: Object.assign({}, filter, { q: value }),
      currentPage: 0
    });
  };

  handleDeletion = seller => {
    CompanyService.deleteUser(seller).then(() => {
      this.fetchListing();
    });
  };

  handleClickOpen = () => {
    this.setState({ open: true, selectedUser: null });
  };

  handleSellerAddedOrEdited = async () => {
    await this.fetchListing();
    this.setState({ openEditDialog: false, open: false, selectedUser: null });
  };

  handleEditSeller = seller => {
    this.setState({ selectedUser: seller, openEditDialog: true });
  };

  handleManageSeller = seller => {
    this.setState({ selectedUser: seller, openManager: true });
  };

  handleChangePage = (event, page) => {
    this.setState({ currentPage: page });
  };

  handleExpansion = (e, expanded) => {
    this.setState({ expanded });
  };

  handleInputChange = e => {
    const { filter } = this.state;
    const { value } = e.target;
    this.setState({
      filter: Object.assign({}, filter, { status: value }),
      filteredSellersList: this.filterSellers({ status: value })
    });
  };

  handleResetAdvanced = () => {
    this.setState({
      filter: { q: "", status: "ALL" },
      filteredSellersList: this.filterSellers({ status: "ALL", q: "" })
    });
  };

  hasEditAccess = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["USER_WRITE"],
      requiredFeatures: ["USER"]
    });
  };

  render() {
    const { classes, disableAddFn, sessionStore, width } = this.props;
    const {
      currentPage,
      expanded,
      filter,
      filteredSellersList,
      gonnaBeRefreshed,
      menuOpenFor,
      open,
      openEditDialog,
      openManager,
      profiles,
      selectedUser
    } = this.state;

    const slicedList = filteredSellersList
      ? filteredSellersList.slice(currentPage * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE)
      : null;

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Utilisateurs</Typography>
        </Grid>

        <Grid item xs={12} className={classes.advancedContainer}>
          <ExpansionPanel onChange={this.handleExpansion} expanded={expanded} className={classes.expWrapper}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <TextField
                className={classes.woMarginTop}
                onClick={e => e.stopPropagation()}
                name="qSeller"
                placeholder={width === "xs" ? "Recherche" : "Nom, prénom, poste"}
                label={width === "xs" ? "Recherche" : "Nom, prénom, poste"}
                margin="normal"
                fullWidth
                value={filter.q}
                onChange={this.handleChangeQuery}
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.advancedFieldsContainer}>
              <Grid container>
                <Grid item xs={12} sm={6} lg={3} className={classes.advancedInputContainer}>
                  <TextField
                    select
                    className={classes.advancedInput}
                    id="status"
                    name="status"
                    type="text"
                    placeholder="Statut"
                    label="Statut"
                    value={filter.status}
                    margin="normal"
                    fullWidth
                    onChange={this.handleInputChange}
                  >
                    <MenuItem key="ALL" value="ALL">
                      Tous
                    </MenuItem>
                    <MenuItem key="ENABLED" value="ENABLED">
                      Activé
                    </MenuItem>
                    <MenuItem key="DISABLED" value="DISABLED">
                      Supprimé
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
            <ExpansionPanelActions className={classes.advancedActionContainer}>
              <Button size="small" color="primary" onClick={this.handleResetAdvanced}>
                Réinitialiser
              </Button>
            </ExpansionPanelActions>
          </ExpansionPanel>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Prénom / Nom / Poste</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Téléphone</TableCell>
                  {this.hasEditAccess() && <TableCell>Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSellersList && gonnaBeRefreshed === false ? (
                  slicedList.length > 0 ? (
                    slicedList.map(seller => (
                      <TableRow
                        key={seller.id}
                        className={seller.deleted ? classes.rowDeleted : null}
                        selected={seller.id === menuOpenFor}
                      >
                        <TableCell component="th" scope="row">
                          <p>
                            <span style={{ fontWeight: "bold" }}>
                              {seller.firstname} {seller.lastname.toUpperCase()}
                            </span>
                            <br />
                            <span>{seller.job}</span>
                          </p>
                        </TableCell>
                        <TableCell>{seller.email}</TableCell>
                        <TableCell>{seller.phone}</TableCell>
                        {this.hasEditAccess() && (
                          <TableCell>
                            {seller.deleted ? null : (
                              <TableAction
                                onOpen={() =>
                                  this.setState({
                                    menuOpenFor: seller.id
                                  })
                                }
                                onClose={() =>
                                  this.setState({
                                    menuOpenFor: null
                                  })
                                }
                              >
                                <MenuItem onClick={() => this.handleEditSeller(seller)}>Modifier</MenuItem>
                                <MenuItem
                                  disabled={profiles === null || seller.id === sessionStore.user.id}
                                  onClick={() => this.handleManageSeller(seller)}
                                >
                                  Gérer les droits
                                </MenuItem>
                                <ConfirmationDialog>
                                  {confirmBeforeAct => (
                                    <MenuItem
                                      onClick={e =>
                                        stopPropagationForConfirmation(
                                          e,
                                          confirmBeforeAct(() => this.handleDeletion(seller))
                                        )
                                      }
                                    >
                                      Supprimer
                                    </MenuItem>
                                  )}
                                </ConfirmationDialog>
                              </TableAction>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center" className={classes.loadingCell}>
                        {filter.q
                          ? "Aucun utilisateur ne correspond à la recherche"
                          : "Vous n'avez pas encore saisi d'utilisateur"}
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center" className={classes.loadingCell}>
                      <CircularProgress size={40} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {filteredSellersList && filteredSellersList.length > 0 && (
              <TablePagination
                component="div"
                count={filteredSellersList.length}
                rowsPerPage={ITEMS_PER_PAGE}
                rowsPerPageOptions={[ITEMS_PER_PAGE]}
                page={currentPage}
                backIconButtonProps={{
                  "aria-label": "Page précédente"
                }}
                nextIconButtonProps={{
                  "aria-label": "Page suivante"
                }}
                onChangePage={this.handleChangePage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
              />
            )}
          </Paper>
        </Grid>
        <EditSellerDialog
          open={openEditDialog}
          onAddOrUpdate={this.handleSellerAddedOrEdited}
          seller={selectedUser}
          onClose={() => {
            this.setState({ openEditDialog: false, selectedUser: null });
          }}
        />
        <AddSellerDialog
          open={open}
          onAddOrUpdate={this.handleSellerAddedOrEdited}
          onClose={() => {
            this.setState({ open: false, selectedUser: null });
          }}
        />
        <ManageUserFeaturesDialog
          onClose={() => {
            this.setState({ openManager: false, selectedUser: null });
          }}
          onUpdateSuccess={() => {
            this.setState({ openManager: false, selectedUser: null }, () => {
              this.fetchListing();
            });
          }}
          onUpdateFail={() => {
            const { enqueueSnackbar } = this.props;
            enqueueSnackbar("Une erreur est survenue lors de la mise à jour des droits de l'utilisateur", {
              variant: "error"
            });
          }}
          profiles={profiles}
          user={selectedUser}
          open={openManager}
        />
        {this.hasEditAccess() && (
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            onClick={this.handleClickOpen}
            disabled={disableAddFn}
          >
            <AddIcon />
          </Fab>
        )}
      </Grid>
    );
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    marginBottom: 80
  },
  table: {
    minWidth: 700
  },
  rowDeleted: {
    background: theme.palette.action.disabled,
    color: theme.palette.text.disabled,
    textDecoration: "line-through"
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  loadingCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  woMarginTop: {
    marginTop: 0
  },
  advancedContainer: {
    marginTop: theme.spacing(3)
  },
  advancedFieldsContainer: {
    paddingBottom: theme.spacing(),
    backgroundColor: "#FCFCFC"
  },
  advancedActionContainer: {
    backgroundColor: "#FCFCFC"
  },
  advancedInput: {
    marginTop: theme.spacing(2)
  },
  advancedInputContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  expWrapper: {
    overflow: "hidden"
  }
});

export default withWidth()(withSnackbar(withStyles(styles)(SellerList)));
