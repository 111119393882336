import React, { Fragment } from "react";
import { Typography, withStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
  groupCaret: {
    height: 40,
    borderLeftWidth: 1,
    borderLeftColor: "#E6E6E6",
    borderLeftStyle: "solid"
  }
});

function NavigateButtonWMenu({ mainNavigation }) {
  return (
    <Button component={Link} to={mainNavigation.path} color="secondary" variant="contained" style={{ marginRight: 8 }}>
      {mainNavigation.icon && <Fragment>{mainNavigation.icon}&nbsp;&nbsp;&nbsp;</Fragment>}
      <Typography>{mainNavigation.label}</Typography>
    </Button>
  );
}

export default withStyles(styles)(NavigateButtonWMenu);
