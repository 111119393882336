import React, { Fragment, useEffect, useState } from "react";
import {
  withStyles,
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  DialogContent,
  Button,
  Badge
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import ReplayIcon from "@material-ui/icons/Replay";

import { TransitionUp } from "../utils/transitions/TransitionUp";
import YouSignSignerFrame from "./YouSignSignerFrame";
import YouSignService from "../../services/YouSignService";
import SignatureService from "../../services/SignatureService";
import YousignInstructionsCard from "../signature/YousignInstructionsCard";

const styles = {
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
};

// signers
function YouSignDialog({ open, handleClose, classes, docSignature }) {
  const [isOpen, setIsOpen] = useState(false);
  const [downloadHref, setDownloadHref] = useState(undefined);
  const [selectedSigner, setSelectedSigner] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const simulateClick = e => {
    if (e !== null) {
      e.click();
    }
    setDownloadHref(undefined);
  };

  const queryClient = useQueryClient();

  const { isLoading, data, isFetching, refetch } = useQuery({
    queryKey: ["signature", { id: docSignature.signatureRequestId }],
    queryFn: () => SignatureService.getYouSignSignature(docSignature.id).then(res => res),
    enabled: !!docSignature.signatureRequestId && !selectedSigner,
    refetchInterval: _data => (_data && _data.signatureRequest.status !== "done" ? 20000 : false)
  });

  const handleManualClose = () => {
    handleClose(data && data.signatureRequest && data.signatureRequest.status === "done" ? data : null);
  };

  const remainingSignersCount = data ? data.signers.filter(signer => signer.status !== "signed").length : undefined;

  const handleDownload = () => {
    YouSignService.getDownloadDocSignaturePDF(docSignature.signatureRequestId)
      .then(res => {
        setDownloadHref(window.URL.createObjectURL(res));
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF du bon de commande", {
          variant: "error"
        });
      });
  };

  useEffect(() => {
    setSelectedSigner(undefined);
    setIsOpen(open);
  }, [open]);

  return (
    <Dialog fullScreen open={isOpen} onClose={handleManualClose} TransitionComponent={TransitionUp}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleManualClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Signature
          </Typography>
        </Toolbar>
      </AppBar>
      {open && data && data.signers && (
        <DialogContent style={{ display: "flex", flexDirection: "column", backgroundColor: "#F7F7F7" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginTop: 16,
              marginBottom: 40
            }}
          >
            {data &&
              data.signers.map(signer => (
                <Badge invisible={signer.status === "signed"} variant="dot" color="error" key={signer.id}>
                  <Button
                    disabled={signer.status === "signed" || data.signatureRequest.status === "expired"}
                    onClick={() => {
                      if (selectedSigner && signer.id === selectedSigner.id) {
                        setSelectedSigner(undefined);
                      } else {
                        setSelectedSigner(signer);
                      }
                    }}
                    color="primary"
                    variant={selectedSigner && selectedSigner.id === signer.id ? "contained" : "outlined"}
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    {signer.info.first_name} {signer.info.last_name}{" "}
                    {signer.status === "signed" && <VerifiedUserIcon color="primary" style={{ marginLeft: 10 }} />}
                  </Button>
                </Badge>
              ))}
          </div>
          <div
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column"
            }}
          >
            {isLoading && <div>Chargement...</div>}
            {data && data.signatureRequest && !selectedSigner && (
              <div style={{ textAlign: "center" }}>
                {data.signatureRequest.status === "done" ? (
                  <Fragment>
                    <p>Félicitations, le document est signé !</p>
                    <Button variant="contained" color="primary" onClick={handleDownload}>
                      Télécharger
                    </Button>
                  </Fragment>
                ) : remainingSignersCount === 0 ? (
                  <p>Finalisation de la signature...</p>
                ) : (
                  <Fragment>
                    <Button
                      startIcon={<ReplayIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => refetch()}
                      disabled={isFetching}
                    >
                      Rafraichir
                    </Button>
                    {data.signatureRequest.status === "expired" ? (
                      <p style={{ fontStyle: "italic", marginBottom: 32 }}>
                        Le délai alloué à la signature est dépassé
                      </p>
                    ) : (
                      <p style={{ fontStyle: "italic", marginBottom: 32 }}>
                        Il manque {remainingSignersCount} signature{remainingSignersCount > 1 && "s"}
                      </p>
                    )}
                    <YousignInstructionsCard />
                  </Fragment>
                )}
              </div>
            )}
            {!isLoading && selectedSigner !== undefined && (
              <YouSignSignerFrame
                signatureUrl={selectedSigner.signature_link}
                onSign={() => {
                  setSelectedSigner(undefined);
                  queryClient.invalidateQueries(["signature", { id: docSignature.signatureRequestId }]);
                }}
              />
            )}
          </div>
          {downloadHref !== "" && (
            <a ref={simulateClick} href={downloadHref} download={docSignature.document} style={{ display: "none" }}>
              Download
            </a>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
}

export default withStyles(styles)(YouSignDialog);
