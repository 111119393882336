import request, { METHOD } from "../utils/request";

class YouSignService {
  static getDownloadOrderPDF(signatureRequestId) {
    return request({
      url: `/yousign/${signatureRequestId}/downloadOrder`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static getDownloadEstimatePDF(signatureRequestId) {
    return request({
      url: `/yousign/${signatureRequestId}/downloadEstimate`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static getDownloadDocSignaturePDF(signatureRequestId) {
    return request({
      url: `/yousign/${signatureRequestId}/downloadDocSignature`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static getDownloadAdditionalDocumentPDF(signatureRequestId) {
    return request({
      url: `/yousign/${signatureRequestId}/downloadAdditionalDocument`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static getSignatureUrl(companyId, signatureRequestId, contentId, contentType, signatoryId) {
    return request({
      url: `/yousign/${signatoryId}/signatureUrl`,
      method: METHOD.POST,
      data: {
        companyId,
        signatureRequestId,
        contentType,
        contentId
      }
    });
  }
}

export default YouSignService;
