import { Button, Card, CardActions, CardContent, Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { FieldArray, Form, Formik } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";
import AdminService from "../../../services/AdminService";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block"
  },
  card: { height: "100%" },
  row: {
    display: "flex",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  rowPicto: {
    flexBasis: 40,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  companyItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  }
}));

export default function CompanyAllowances({ company, onPatch }) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (allowances, onSuccess) => {
    AdminService.patchCompany({
      ...company,
      allowances
    })
      .then(updatedCompany => {
        onPatch(updatedCompany);
        enqueueSnackbar("L'entreprise a été mise à jour", { variant: "success" });
        onSuccess({ allowances: updatedCompany.allowances || [] });
      })
      .catch(err => {
        console.log(" ERROR ", err);
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'entreprise", {
          variant: "error"
        });
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Formik
          initialValues={{ allowances: company.allowances || [] }}
          onSubmit={(values, formikBag) => {
            handleSubmit(
              values.allowances.filter(allowance => !!allowance),
              data => {
                formikBag.setValues({ ...data });
              }
            );
          }}
        >
          {({ values, handleChange, handleBlur, handleReset }) => (
            <Form>
              <FieldArray
                name="allowances"
                render={arrayHelpers => (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h3">
                        Primes
                      </Typography>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 8 }}>
                        {values.allowances &&
                          values.allowances.length > 0 &&
                          values.allowances.map((allowance, index) => (
                            <div key={`${index + 1}`} style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                              <TextField
                                fullWidth
                                id={`allowances.${index}`}
                                name={`allowances.${index}`}
                                value={allowance}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                                size="small"
                              />
                              <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon />
                              </Button>
                            </div>
                          ))}
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => arrayHelpers.push("")}
                          style={{ alignSelf: "center", marginTop: 8 }}
                        >
                          Ajouter une prime
                        </Button>
                      </div>
                    </CardContent>
                    <CardActions style={{ justifyContent: "flex-end" }}>
                      <Button variant="text" onClick={handleReset}>
                        Annuler
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        Enregistrer
                      </Button>
                    </CardActions>
                  </Card>
                )}
              />
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
