import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  AppBar,
  Drawer,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Toolbar,
  Typography,
  withStyles
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MenuIcon from "@material-ui/icons/Menu";
import WarningIcon from "@material-ui/icons/Warning";

import NavigateButtonWMenu from "./button/NavigateButtonWMenu";
import { disconnected, privateRoutes, superAdmin } from "../routes";
import AppMenu from "./menu";
import ImpersonateManager from "./admin/ImpersonateManager";

@inject("sessionStore")
@observer
class AppShell extends Component {
  constructor(props) {
    super(props);
    this.state = { openMenu: false, anchorEl: null, fromTop: 0 };
  }

  componentDidMount() {
    this.assignHeightAsTop();
    window.addEventListener("resize", this.assignHeightAsTop);
  }

  componentDidUpdate() {
    // Insure header's update on login/logout
    this.assignHeightAsTop();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.assignHeightAsTop);
  }

  assignHeightAsTop = () => {
    const { fromTop } = this.state;
    const height = document.getElementById("topalert") ? document.getElementById("topalert").clientHeight : 0;
    if (height !== fromTop) {
      this.setState({ fromTop: height });
    }
  };

  // getInitials = user => {
  //   if (user.firstname) {
  //     return `${user.firstname.charAt(0).toUpperCase()}${user.lastname.charAt(0).toUpperCase()}`;
  //   }
  //   return null;
  // };

  handleToggle = () => {
    const { openMenu } = this.state;
    this.setState({ openMenu: !openMenu });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, sessionStore, children, location } = this.props;
    const { anchorEl, fromTop, openMenu } = this.state;
    const openProfil = Boolean(anchorEl);
    const { logo, CGV, withdrawal } = sessionStore.user.company;
    const needToFixSettings = CGV === null || withdrawal === null;

    return (
      <Fragment>
        {/* <AppBar position="fixed" id="topalert" className={classes.warningAppBar}>
          <Toolbar variant="dense" style={{ justifyContent: "center" }}>
            <IconButton className="menuIcon" color="inherit" aria-label="Menu" disabled>
              <WarningIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Un incident Contralia est en cours. La signature électronique est temporairement indisponible. Veuillez
              nous excuser pour la gêne occasionnée.
            </Typography>
            <IconButton className="menuIcon" color="inherit" aria-label="Menu" disabled>
              <WarningIcon />
            </IconButton>
          </Toolbar>
        </AppBar> */}
        {sessionStore.siggnedIn && needToFixSettings && (
          <Link
            to={CGV === null ? privateRoutes.admin.settings.terms.path : privateRoutes.admin.settings.withdrawal.path}
            className="Link"
          >
            <AppBar position="fixed" id="topalert" className={classes.alertAppBar}>
              <Toolbar variant="dense" style={{ justifyContent: "center" }}>
                <IconButton className="menuIcon" color="inherit" aria-label="Menu" disabled>
                  <WarningIcon />
                </IconButton>
                <Typography variant="h6" color="inherit">
                  Veuillez remplir les CGV de l&apos;entreprise et/ou les conditions de rétractation et/ou les mentions
                  légales
                </Typography>
                <IconButton className="menuIcon" color="inherit" aria-label="Menu" disabled>
                  <WarningIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </Link>
        )}
        {sessionStore.siggnedIn &&
          sessionStore.user &&
          sessionStore.user.company &&
          sessionStore.user.company.restricted === true && (
            <AppBar position="fixed" id="topalert" className={classes.alertAppBar}>
              <Toolbar variant="dense" style={{ justifyContent: "center" }}>
                <IconButton className="menuIcon" color="inherit" disabled>
                  <WarningIcon />
                </IconButton>
                <Typography variant="h6" style={{ color: "black" }}>
                  Veuillez contacter votre administrateur
                </Typography>

                <IconButton className="menuIcon" color="inherit" disabled>
                  <WarningIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          )}
        <ImpersonateManager />
        <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar} style={{ top: fromTop }}>
            <Toolbar style={{ paddingLeft: 0 }}>
              {sessionStore.siggnedIn ? (
                <Fragment>
                  <Hidden lgUp>
                    <IconButton onClick={this.handleToggle} className="menuIcon" color="inherit" aria-label="Menu">
                      <MenuIcon />
                    </IconButton>
                  </Hidden>
                  <Hidden xsDown>
                    <Link to="/" className={classes.grow}>
                      <Typography variant="h6" color="inherit" className="ziHeader">
                        KingKang
                      </Typography>
                    </Link>
                  </Hidden>
                </Fragment>
              ) : (
                <Link to="/" className={classes.grow}>
                  <Typography variant="h6" color="inherit" className="ziHeader">
                    KingKang
                  </Typography>
                </Link>
              )}

              {logo && (
                <Hidden xsDown>
                  <Link
                    to="/"
                    style={{
                      justifySelf: "center",
                      display: "flex"
                    }}
                  >
                    <img src={`${process.env.API_URL}/public/logo/${logo}`} height="50" />
                  </Link>
                </Hidden>
              )}
              <div style={{ display: "flex", flex: 2, justifyContent: "flex-end" }}>
                {sessionStore.siggnedIn &&
                  (sessionStore.user.isSuperAdmin && location.pathname.startsWith(superAdmin.admin.path) ? (
                    <Fragment>
                      <NavigateButtonWMenu
                        key="bg-company"
                        mainNavigation={{
                          label: "Entreprise",
                          path: "/superbg/entreprises/creation",
                          icon: <AddCircleIcon />
                        }}
                      />
                      <NavigateButtonWMenu
                        key="bg-user"
                        mainNavigation={{
                          label: "Utilisateur",
                          path: "/superbg/utilisateurs/creation",
                          icon: <AddCircleIcon />
                        }}
                      />
                    </Fragment>
                  ) : (
                    CGV !== null &&
                    CGV !== undefined &&
                    withdrawal !== null &&
                    withdrawal !== undefined && [
                      sessionStore.userHasAccess({
                        requiredAcls: ["ESTIMATE_MY"],
                        requiredFeatures: ["ESTIMATE"]
                      }) && (
                        <NavigateButtonWMenu
                          key="estimate-dropdown"
                          mainNavigation={{
                            label: "Devis",
                            path: privateRoutes.common.estimates.creationForm.path,
                            icon: <AddCircleIcon />
                          }}
                        />
                      ),
                      sessionStore.userHasAccess({
                        requiredAcls: ["ORDER_MY"],
                        requiredFeatures: ["ORDER"]
                      }) && (
                        <NavigateButtonWMenu
                          key="orderForm-dropdown"
                          mainNavigation={{
                            label: "Bon",
                            path: privateRoutes.common.orderForms.creationForm.path,
                            icon: <AddCircleIcon />
                          }}
                        />
                      )
                    ]
                  ))}

                {sessionStore.siggnedIn && (
                  <IconButton
                    aria-owns={openProfil ? "menu-appbar" : null}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                  >
                    <AccountCircleIcon />
                  </IconButton>
                )}
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={openProfil}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose} component={Link} to={privateRoutes.common.account.path}>
                    Mon Profil
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.handleClose();
                      sessionStore.signOut();
                    }}
                    component={Link}
                    to={disconnected.signin.path}
                  >
                    Déconnexion
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
          {sessionStore.siggnedIn && sessionStore.user && (
            <Fragment>
              <Hidden mdDown>
                <Drawer
                  className={classes.drawer}
                  variant="permanent"
                  classes={{
                    paper: classes.drawerPaper
                  }}
                >
                  <div className={classes.toolbar} style={{ marginBottom: fromTop }} />
                  <AppMenu handleToggle={() => true} />
                </Drawer>
              </Hidden>
              <Hidden lgUp>
                <SwipeableDrawer open={openMenu} onOpen={this.handleToggle} onClose={this.handleToggle}>
                  <AppMenu handleToggle={this.handleToggle} />
                </SwipeableDrawer>
              </Hidden>
            </Fragment>
          )}
          <main className={classes.content} style={{ marginTop: fromTop }}>
            <div className={classes.toolbar} />
            {children}
          </main>
        </div>
      </Fragment>
    );
  }
}

const drawerWidth = 250;
const styles = theme => ({
  button: {
    margin: theme.spacing(2)
  },
  row: {
    display: "flex",
    justifyContent: "center"
  },
  grow: {
    color: "inherit !important",
    textDecoration: "none",
    width: 250,
    textAlign: "center"
  },
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  alertAppBar: {
    zIndex: theme.zIndex.drawer + 2,
    // backgroundColor: "#C62828"
    backgroundColor: "#f0ad4e"
  },
  warningAppBar: {
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: "#eece00",
    color: "black"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
    paddingTop: theme.spacing(),
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0
    }
  },
  toolbar: theme.mixins.toolbar
});

export default withRouter(withStyles(styles)(AppShell));
