import { Decimal } from "decimal.js-light";
import { FINANCIAL_METHOD } from "../services/DocumentService";

export const formatNumber = (number, fractionDigits = 2) => {
  const parsedNumber = parseFloat(number);
  if (!Number.isNaN(parsedNumber)) {
    return new Intl.NumberFormat("fr-FR", { minimumFractionDigits: fractionDigits }).format(parsedNumber);
  }
  return "";
};

export const formatCurrency = (number, currency = "EUR") => {
  const parsedNumber = parseFloat(number);
  if (!Number.isNaN(parsedNumber)) {
    return new Intl.NumberFormat("fr-FR", {
      currencyDisplay: "symbol", // "narrowSymbol",
      style: "currency",
      currency,
      minimumFractionDigits: 2
    }).format(parsedNumber);
  }
  return "";
};

export const getDetailPriceWithoutVat = detail => {
  const { discount, discountType, price, quantity, tva } = detail;
  if (discountType === "HT" || discount === 0 || discount === "") {
    return new Decimal(Number(price))
      .mul(Number(quantity))
      .minus(Number(discount || 0))
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2);
  }
  // We need to get the w/ vates amount before all
  return new Decimal(Number(price))
    .mul(Number(quantity))
    .mul((100 + Number(tva)) / 100)
    .minus(Number(discount || 0))
    .div((100 + Number(tva)) / 100)
    .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
    .toNumber()
    .toFixed(2);
};

export const getDetailPriceWithVat = detail => {
  const { tva } = detail;

  return new Decimal(getDetailPriceWithoutVat(detail))
    .mul(Number(tva))
    .div(100)
    .add(getDetailPriceWithoutVat(detail))
    .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
    .toNumber()
    .toFixed(2);
};

export const totalPriceWithoutTVA = details => {
  const pricesWithoutTVA = details.map(detail => getDetailPriceWithoutVat(detail));
  return pricesWithoutTVA.reduce((accumulator, price) => Number(accumulator) + Number(price));
};

export const totalPriceWithTVA = details => {
  const pricesWithTVA = details.map(detail => getDetailPriceWithVat(detail));
  return pricesWithTVA.reduce((accumulator, price) => Number(accumulator) + Number(price));
};

export const getMaxDiscountForItem = detail => {
  if (detail.discountType === "TTC") {
    return new Decimal(Number(detail.price))
      .mul(Number(detail.quantity))
      .mul(100 + Number(detail.tva))
      .div(100)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  }
  return new Decimal(Number(detail.price))
    .mul(Number(detail.quantity))
    .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
    .toNumber();
};

export const getComputedDeposit = document => {
  if (document.cashForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE) {
    return new Decimal(Number(totalPriceWithTVA(document.details)))
      .mul(Number(document.cashForm.deposit))
      .div(100)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2);
  }

  return Number(document.cashForm.deposit).toFixed(2);
};

export const getComputedFinancingFormDeposit = document => {
  if (document.financingForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE) {
    return new Decimal(Number(totalPriceWithTVA(document.details)))
      .mul(Number(document.financingForm.deposit))
      .div(100)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2);
  }

  return Number(document.financingForm.deposit).toFixed(2);
};

export const ensureTwoDigits = number => {
  const decimal = new Decimal(number).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN).toNumber();
  return decimal;
};
