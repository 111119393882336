import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { makeStyles } from "@material-ui/styles";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";

const useStyles = makeStyles(theme => ({
  back: { marginTop: theme.spacing(), marginBottom: theme.spacing(2) }
}));

function BackButton({ to, textLong, textShort, history, withConfirmation }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  const handleClick = () => {
    history.push(to);
  };

  if (withConfirmation) {
    return (
      <ConfirmationDialog
        key="menuitem-delete"
        situationText="Êtes-vous sûr de vouloir changer de page ? Les modifications non sauvegardées seront perdues."
      >
        {confirmBeforeAct => (
          <Button
            onClick={handleClick}
            className={classes.back}
            onClick={e => stopPropagationForConfirmation(e, confirmBeforeAct(() => handleClick()))}
          >
            <ArrowBackIosIcon fontSize="small" /> {matches ? textShort || "Retour" : textLong || "Retour"}
          </Button>
        )}
      </ConfirmationDialog>
    );
  }

  return (
    <Button onClick={handleClick} className={classes.back}>
      <ArrowBackIosIcon fontSize="small" /> {matches ? textShort || "Retour" : textLong || "Retour"}
    </Button>
  );
}

export default withRouter(BackButton);
