import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import React, { useState } from "react";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import AdditionalDocumentService from "../../services/AdditionalDocumentService";

const styles = theme => ({
  container: { padding: theme.spacing(2) }
});

const formatDefaultFields = additionalDocument => {
  return {
    clientLastname:
      (additionalDocument.fields && additionalDocument.fields.clientLastname) || additionalDocument.contact.lastname,
    clientFirstname:
      (additionalDocument.fields && additionalDocument.fields.clientFirstname) || additionalDocument.contact.firstname,
    clientAddress:
      (additionalDocument.fields && additionalDocument.fields.clientAddress) || additionalDocument.contact.address,
    clientZipCode:
      (additionalDocument.fields && additionalDocument.fields.clientZipCode) || additionalDocument.contact.zipCode,
    clientCity: (additionalDocument.fields && additionalDocument.fields.clientCity) || additionalDocument.contact.city,
    locationType: (additionalDocument.fields && additionalDocument.fields.locationType) || "",
    locationTypeDetails: (additionalDocument.fields && additionalDocument.fields.locationTypeDetails) || "",
    locationUse: (additionalDocument.fields && additionalDocument.fields.locationUse) || "",
    locationUseDetails: (additionalDocument.fields && additionalDocument.fields.locationUseDetails) || "",
    locationAddress:
      (additionalDocument.fields && additionalDocument.fields.locationAddress) || additionalDocument.contact.address,
    locationZipCode:
      (additionalDocument.fields && additionalDocument.fields.locationZipCode) || additionalDocument.contact.zipCode,
    locationCity:
      (additionalDocument.fields && additionalDocument.fields.locationCity) || additionalDocument.contact.city,
    locationUserRelation: (additionalDocument.fields && additionalDocument.fields.locationUserRelation) || "",
    locationUserRelationDetails:
      (additionalDocument.fields && additionalDocument.fields.locationUserRelationDetails) || "",
    worksNoStructureUpdates: (additionalDocument.fields && additionalDocument.fields.worksNoStructureUpdates) || false,
    worksLimitedCategories: (additionalDocument.fields && additionalDocument.fields.worksLimitedCategories) || false,
    worksCategoryFloor: (additionalDocument.fields && additionalDocument.fields.worksCategoryFloor) || false,
    worksCategoryExteriorFrames:
      (additionalDocument.fields && additionalDocument.fields.worksCategoryExteriorFrames) || false,
    worksCategoryInteriorWalls:
      (additionalDocument.fields && additionalDocument.fields.worksCategoryInteriorWalls) || false,
    worksCategoryPlumbing: (additionalDocument.fields && additionalDocument.fields.worksCategoryPlumbing) || false,
    worksCategoryElectricity:
      (additionalDocument.fields && additionalDocument.fields.worksCategoryElectricity) || false,
    worksCategoryHeating: (additionalDocument.fields && additionalDocument.fields.worksCategoryHeating) || false,
    worksNoFloorIncrease: (additionalDocument.fields && additionalDocument.fields.worksNoFloorIncrease) || false,
    worksNoBuildingAddition: (additionalDocument.fields && additionalDocument.fields.worksNoBuildingAddition) || false,
    worksEnergyImprovement: (additionalDocument.fields && additionalDocument.fields.worksEnergyImprovement) || false,
    worksEnergyImprovementWithVat:
      (additionalDocument.fields && additionalDocument.fields.worksEnergyImprovementWithVat) || false
  };
};

function VatDeclarationForm({ additionalDocument, classes, handleCancel, onPatchSuccess }) {
  const queryClient = useQueryClient();
  const [fields, setFields] = useState(formatDefaultFields(additionalDocument));
  const { enqueueSnackbar } = useSnackbar();

  const handleFieldChange = event => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handleCheckboxChange = event => {
    setFields({ ...fields, [event.target.name]: event.target.checked });
  };

  const handleSubmit = event => {
    event.preventDefault();
    AdditionalDocumentService.patchAdditionalDocumentFields(additionalDocument.id, {
      ...fields,
      effectiveDate: fields.effectiveDate ? fields.effectiveDate : moment().toISOString()
    })
      .then(() => {
        queryClient.invalidateQueries(["additional_document", { additionalDocumentId: additionalDocument.id }]);
        enqueueSnackbar("Le document a été modifié avec succès", { variant: "success" });
        onPatchSuccess();
      })
      .catch(err => {
        console.log(err);
        if (err && err.status && err.status === 406) {
          enqueueSnackbar("La saisie ne permet pas de valider le document, veuillez compléter celui-ci", {
            variant: "error"
          });
        } else {
          enqueueSnackbar("Une erreur est survenue lors de l'enregistrement du document", {
            variant: "error"
          });
        }
      });
  };

  const canSubmit = fields.locationZipCode.match(/^\d{5}$/) && fields.clientZipCode.match(/^\d{5}$/);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.container} variant="h4">
            Attestation simplifiée TVA
          </Typography>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5">Identité du client ou de son représentant</Typography>
                <Grid container spacing={2} style={{ marginTop: 12 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Nom"
                      name="clientLastname"
                      variant="outlined"
                      fullWidth
                      value={fields.clientLastname}
                      onChange={handleFieldChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Prénom"
                      name="clientFirstname"
                      variant="outlined"
                      fullWidth
                      value={fields.clientFirstname}
                      onChange={handleFieldChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Adresse"
                      name="clientAddress"
                      variant="outlined"
                      fullWidth
                      value={fields.clientAddress}
                      onChange={handleFieldChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Code postal"
                      name="clientZipCode"
                      variant="outlined"
                      fullWidth
                      value={fields.clientZipCode}
                      onChange={handleFieldChange}
                      required
                      error={!fields.clientZipCode.match(/^\d{5}$/)}
                      helperText={fields.clientZipCode.match(/^\d{5}$/) ? "" : "Code postal invalide"}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label="Ville"
                      name="clientCity"
                      variant="outlined"
                      fullWidth
                      value={fields.clientCity}
                      onChange={handleFieldChange}
                      required
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Nature des locaux</Typography>
                <Grid container spacing={2} style={{ marginTop: 12 }}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Nature des locaux *</InputLabel>
                      <Select
                        value={fields.locationType}
                        onChange={handleFieldChange}
                        name="locationType"
                        displayEmpty
                        variant="outlined"
                        fullWidth
                        style={{ textAlign: "left" }}
                        placeholder=""
                        label="Nature des locaux *"
                      >
                        <MenuItem value="0">Maison ou immeuble individuel</MenuItem>
                        <MenuItem value="1">Immeuble collectif</MenuItem>
                        <MenuItem value="2">Appartement individuel</MenuItem>
                        <MenuItem value="3">Autre</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {fields.locationType === "3" && (
                    <Grid item xs={12}>
                      <TextField
                        label="Nature des locaux - Précisez"
                        name="locationTypeDetails"
                        variant="outlined"
                        fullWidth
                        value={fields.locationTypeDetails}
                        onChange={handleFieldChange}
                        required
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Type d&apos;usage *</InputLabel>
                      <Select
                        value={fields.locationUse}
                        onChange={handleFieldChange}
                        name="locationUse"
                        variant="outlined"
                        fullWidth
                        style={{ textAlign: "left" }}
                        placeholder=""
                        label="Type d'usage *"
                      >
                        <MenuItem value="0">Local affecté exclusivement ou principalement à l&apos;habitation</MenuItem>
                        <MenuItem value="1">
                          Pièces affectées exclusivement à l’habitation situées dans un local affecté pour moins de 50 %
                          à cet usage
                        </MenuItem>
                        <MenuItem value="2">
                          Parties communes de locaux affectés exclusivement ou principalement à l’habitation
                        </MenuItem>
                        <MenuItem value="3">
                          Local antérieurement affecté à un usage autre que d’habitation et transformé à cet usage
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {fields.locationUse === "2" && (
                    <Grid item xs={12}>
                      <TextField
                        label="Type d'usage - Proportion de millièmes de l'immeuble"
                        name="locationUseDetails"
                        variant="outlined"
                        fullWidth
                        value={fields.locationUseDetails}
                        onChange={handleFieldChange}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      label="Adresse des locaux"
                      name="locationAddress"
                      variant="outlined"
                      fullWidth
                      value={fields.locationAddress}
                      onChange={handleFieldChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Code postal"
                      name="locationZipCode"
                      variant="outlined"
                      fullWidth
                      value={fields.locationZipCode}
                      onChange={handleFieldChange}
                      required
                      error={!fields.locationZipCode.match(/^\d{5}$/)}
                      helperText={fields.locationZipCode.match(/^\d{5}$/) ? "" : "Code postal invalide"}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label="Ville"
                      name="locationCity"
                      variant="outlined"
                      fullWidth
                      value={fields.locationCity}
                      onChange={handleFieldChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Qualité du client sur les locaux *</InputLabel>
                      <Select
                        value={fields.locationUserRelation}
                        onChange={handleFieldChange}
                        name="locationUserRelation"
                        variant="outlined"
                        fullWidth
                        style={{ textAlign: "left" }}
                        placeholder=""
                        label="Qualité du client sur les locaux *"
                      >
                        <MenuItem value="0">Propriétaire</MenuItem>
                        <MenuItem value="1">Locataire</MenuItem>
                        <MenuItem value="2">Autre</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {fields.locationUserRelation === "2" && (
                    <Grid item xs={12}>
                      <TextField
                        label="Qualité du client sur les locaux - Précisez"
                        name="locationUserRelationDetails"
                        variant="outlined"
                        fullWidth
                        value={fields.locationUserRelationDetails}
                        onChange={handleFieldChange}
                        required
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Nature des travaux</Typography>
                <Grid container spacing={2} style={{ marginTop: 12 }}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksNoStructureUpdates}
                              onChange={handleCheckboxChange}
                              name="worksNoStructureUpdates"
                              color="primary"
                            />
                          }
                          label="Travaux n'ayant pas d'incidence sur les fondations ou la rigidité de l'ouvrage"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksLimitedCategories}
                              onChange={handleCheckboxChange}
                              name="worksLimitedCategories"
                              color="primary"
                            />
                          }
                          label="Travaux n'affectant pas plus de 5 des 6 catégories suivantes"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Éléments impactés</Typography>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksCategoryFloor}
                              onChange={handleCheckboxChange}
                              name="worksCategoryFloor"
                              color="primary"
                            />
                          }
                          label="Planchers (sans impact rigidité ou resistance de l'ouvrage)"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksCategoryExteriorFrames}
                              onChange={handleCheckboxChange}
                              name="worksCategoryExteriorFrames"
                              color="primary"
                            />
                          }
                          label="Huisseries extérieures"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksCategoryInteriorWalls}
                              onChange={handleCheckboxChange}
                              name="worksCategoryInteriorWalls"
                              color="primary"
                            />
                          }
                          label="Cloisons intérieures"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksCategoryPlumbing}
                              onChange={handleCheckboxChange}
                              name="worksCategoryPlumbing"
                              color="primary"
                            />
                          }
                          label="Installations sanitaires et de plomberie"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksCategoryElectricity}
                              onChange={handleCheckboxChange}
                              name="worksCategoryElectricity"
                              color="primary"
                            />
                          }
                          label="Installations électriques"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksCategoryHeating}
                              onChange={handleCheckboxChange}
                              name="worksCategoryHeating"
                              color="primary"
                            />
                          }
                          label="Système de chauffage"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksNoFloorIncrease}
                              onChange={handleCheckboxChange}
                              name="worksNoFloorIncrease"
                              color="primary"
                            />
                          }
                          label="Travaux n'augmentant pas la surface de plancher de plus de 10%"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksNoBuildingAddition}
                              onChange={handleCheckboxChange}
                              name="worksNoBuildingAddition"
                              color="primary"
                            />
                          }
                          label="Travaux n'ajoutant pas une construction ou une surélévation"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksEnergyImprovement}
                              onChange={handleCheckboxChange}
                              name="worksEnergyImprovement"
                              color="primary"
                            />
                          }
                          label="Travaux visant à améliorer la qualité énergétique"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!fields.worksEnergyImprovementWithVat}
                              onChange={handleCheckboxChange}
                              name="worksEnergyImprovementWithVat"
                              color="primary"
                            />
                          }
                          label="Travaux visant à améliorer la qualité énergétique soumis à la TVA 5.5% (Métropole) ou 2.1% (DROM)"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", gap: 12, justifyContent: "center" }}>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Annuler
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={!canSubmit}>
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default withStyles(styles)(VatDeclarationForm);
