import React from "react";
import { Stepper, Step, StepLabel, withStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function FormStepper({ activeStep, classes, steps }) {
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Stepper activeStep={activeStep} orientation={matchXs ? "vertical" : "horizontal"} className={classes.stepper}>
      {steps.map(step => (
        <Step key={step} className={classes.step}>
          <StepLabel>{step}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

const styles = theme => ({
  stepper: {
    borderRadius: `${theme.shape.borderRadius}px`,
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(2)
    }
  },
  step: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing()
    }
  }
});

export default withStyles(styles)(FormStepper);
