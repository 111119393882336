import React, { useEffect, useState } from "react";
import { Breadcrumbs, Link } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import HomeIcon from "@material-ui/icons/Home";

const useStyles = makeStyles(theme => ({
  link: {
    display: "flex",
    "&:hover": {
      cursor: "pointer"
    }
  },
  currentLink: {
    display: "flex"
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  }
}));

function AdminBreadcrumb({ routes, match, history }) {
  const [items, setItems] = useState();
  const classes = useStyles();

  const handleClick = path => {
    history.push(path);
  };

  useEffect(() => {
    const _items = [];
    Object.keys(routes).forEach(key => {
      if (match.path.startsWith(routes[key].path)) {
        let formattedLabel = routes[key].breadText;
        let { path } = routes[key];
        if (match.params) {
          Object.keys(match.params).forEach(param => {
            formattedLabel = formattedLabel.replace(`:${param}`, match.params[param]);
            path = path.replace(`:${param}`, match.params[param]);
          });
        }
        _items.push({
          path,
          label: formattedLabel,
          current: routes[key].path === match.path
        });
      }
    });
    setItems(_items);
  }, []);

  if (items) {
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 16 }}>
          {items.map((item, index) => (
            <Link
              key={item.path}
              color="inherit"
              onClick={() => (item.current ? null : handleClick(item.path))}
              className={item.current ? classes.currentLink : classes.link}
            >
              {index === 0 && <HomeIcon className={classes.icon} />}
              {item.label}
            </Link>
          ))}
        </Breadcrumbs>
      </div>
    );
  }
  return <div />;
}

export default withRouter(AdminBreadcrumb);
