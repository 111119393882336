import React, { useMemo, useState } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { inject, observer } from "mobx-react";

import UserService from "../services/UserService";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  }
}));

function ProfilPage({ sessionStore }) {
  const [user, setUser] = useState(sessionStore.user);
  const [password, setPassword] = useState("");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isPasswordValid = useMemo(() => password.length > 7, [password]);

  const handleInputChange = event => {
    const {
      target: { value, name }
    } = event;
    setUser(_user => ({ ..._user, [name]: value }));
  };

  const handleChangePassword = e => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    UserService.patchUser(user)
      .then(() => {
        sessionStore.patchSession({ ...sessionStore.user, ...user });
        enqueueSnackbar("Les informations ont été mises à jour", {
          variant: "success"
        });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la modification des informations", {
          variant: "error"
        });
      });
  };

  const handleSubmitPassword = () => {
    if (isPasswordValid) {
      UserService.changePasswordByUser(password)
        .then(() => {
          enqueueSnackbar("Mot de passe modifié", {
            variant: "success"
          });
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la modification du mot de passe", {
            variant: "error"
          });
        });
    }
  };

  const hasSofinco = sessionStore.userHasAccess({ requiredFeatures: ["OPTION_SOFINCO"] });

  return (
    <Grid container>
      <Grid item xs={12} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Mon Profil</Typography>
          </Grid>
          <Grid item xs={12} md={7} lg={8}>
            <Card>
              <CardHeader title="Informations générales" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={!user.isAdminCompany}
                      fullWidth
                      label="Prénom"
                      name="firstname"
                      onChange={handleInputChange}
                      placeholder="Prénom"
                      value={user.firstname}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={!user.isAdminCompany}
                      fullWidth
                      label="Nom"
                      name="lastname"
                      onChange={handleInputChange}
                      placeholder="Nom"
                      value={user.lastname}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      onChange={handleInputChange}
                      placeholder="Email"
                      type="email"
                      value={user.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Téléphone"
                      name="phone"
                      onChange={handleInputChange}
                      placeholder="Téléphone"
                      type="tel"
                      value={user.phone}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button color="primary" onClick={handleSubmit}>
                  Enregistrer
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Card>
              <CardHeader title="Entreprise" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      label="Nom de l'entreprise"
                      name="companyName"
                      placeholder="Entreprise"
                      value={user.company.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={!user.isAdminCompany}
                      fullWidth
                      label="Poste à afficher"
                      name="job"
                      onChange={handleInputChange}
                      placeholder="Chargé d'affaires"
                      value={user.job || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={!user.isAdminCompany}
                      fullWidth
                      label="Matricule"
                      name="internalId"
                      onChange={handleInputChange}
                      placeholder="Matricule"
                      value={user.internalId || ""}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              {user.isAdminCompany && (
                <CardActions>
                  <Button color="primary" onClick={handleSubmit}>
                    Enregistrer
                  </Button>
                </CardActions>
              )}
            </Card>
          </Grid>
          {hasSofinco && (
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Sofinco" />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Identifiant"
                        name="sofincoAssociationKey"
                        placeholder="PAXXXXXX"
                        value={user.sofincoAssociationKey}
                        variant="outlined"
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button color="primary" onClick={handleSubmit}>
                    Enregistrer
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          <Grid item xs={12}>
            <Card>
              <CardHeader title="Changement de mot de passe" />
              <CardContent>
                <TextField
                  fullWidth
                  label="Nouveau mot de passe"
                  name="password"
                  onChange={handleChangePassword}
                  placeholder="p@ssw0rd"
                  type="password"
                  value={password}
                  variant="outlined"
                  helperText="Votre nouveau de passe doit contenir au minimum 8 caractères"
                />
              </CardContent>
              <CardActions>
                <Button color="primary" onClick={handleSubmitPassword} disabled={!isPasswordValid}>
                  Enregistrer le mot de passe
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default inject("sessionStore")(observer(props => <ProfilPage {...props} />));
