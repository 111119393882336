import React from "react";
import { inject, observer } from "mobx-react";
import { MuiThemeProvider, CircularProgress } from "@material-ui/core";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ThemeProvider, withStyles } from "@material-ui/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import AppShell from "./components/AppShell";
import CookieService from "./services/CookieService";
import ErrorBoundary from "./components/ErrorBoundary";
import { disconnected, privateRoutes, superAdmin } from "./routes";
import NotFoundPage from "./pages/NotFoundPage";
import ResetPassPage from "./pages/ResetPassPage";
import { isTokenValid } from "./stores/SessionStore";

const style = {
  centerLoader: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
};

const queryClient = new QueryClient();

@inject("sessionStore")
@observer
class App extends React.Component {
  state = { loading: true };

  componentDidMount() {
    const { sessionStore } = this.props;
    sessionStore.init().finally(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;
    const { sessionStore, classes } = this.props;
    const { theme } = sessionStore;

    // Restrict routes based on user feats

    if (loading) {
      return (
        <div className={classes.centerLoader}>
          <CircularProgress size={80} />
        </div>
      );
    }

    return (
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <AppShell>
                <ErrorBoundary>
                  <Switch>
                    {Object.keys(disconnected).map(key => (
                      <Route key={key} exact path={disconnected[key].path} component={disconnected[key].component} />
                    ))}

                    {sessionStore.user.isSuperAdmin &&
                      Object.keys(superAdmin).map(key => (
                        <SuperadminRoute
                          key={key}
                          exact
                          path={superAdmin[key].path}
                          component={superAdmin[key].component}
                        />
                      ))}

                    {Object.keys(privateRoutes.admin).map(key => {
                      if (privateRoutes.admin[key].path) {
                        return (
                          <PrivateRoute
                            exact
                            key={key}
                            path={privateRoutes.admin[key].path}
                            component={privateRoutes.admin[key].component}
                          />
                        );
                      }
                      return Object.keys(privateRoutes.admin[key]).map(subKey => (
                        <PrivateRoute
                          exact
                          key={subKey}
                          path={privateRoutes.admin[key][subKey].path}
                          component={privateRoutes.admin[key][subKey].component}
                        />
                      ));
                    })}

                    {Object.keys(privateRoutes.common).map(key => {
                      if (privateRoutes.common[key].path) {
                        return (
                          <PrivateRoute
                            exact
                            key={key}
                            path={privateRoutes.common[key].path}
                            component={privateRoutes.common[key].component}
                          />
                        );
                      }
                      return Object.keys(privateRoutes.common[key]).map(subKey => (
                        <PrivateRoute
                          exact
                          key={subKey}
                          path={privateRoutes.common[key][subKey].path}
                          component={privateRoutes.common[key][subKey].component}
                        />
                      ));
                    })}
                    <Route path="/reset-password/:token" component={ResetPassPage} />
                    <Route component={NotFoundPage} />
                  </Switch>
                </ErrorBoundary>
                <ReactQueryDevtools initialIsOpen={false} />
              </AppShell>
            </BrowserRouter>
          </ThemeProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    );
  }
}

const PrivateRoute = inject("sessionStore")(({ component: Component, sessionStore, ...rest }) => {
  sessionStore.updateSignIn();

  return (
    <Route
      {...rest}
      exact
      render={props =>
        isTokenValid(CookieService.getCookie("token")) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: disconnected.signin.path,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
});

const SuperadminRoute = inject("sessionStore")(({ component: Component, sessionStore, ...rest }) => {
  sessionStore.updateSignIn();

  return (
    <Route
      {...rest}
      exact
      render={props =>
        sessionStore.user.isSuperAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: disconnected.signin.path,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
});

export default withStyles(style)(App);
