import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { InputAdornment, IconButton, makeStyles, Grid, CircularProgress, Typography } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import { LazyLog } from "react-lazylog";

import AdminService from "../../services/AdminService";

const useStyles = makeStyles(theme => ({
  picker: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing()
    },
    width: 160
  },
  container: {
    padding: theme.spacing(2)
  },
  datePicker: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}));

function LogsViewer() {
  const [logDate, setLogDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState();
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    AdminService.getLogs(logDate.format("YYYY-MM-DD"))
      .then(res => {
        setLogs(res.content);
        setLoading(false);
      })
      .catch(() => {
        setLogs(null);
        setLoading(false);
      });
  }, [logDate]);

  const handleLogDateChange = newLogDate => {
    setLogDate(newLogDate);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h4">Visualiser les logs</Typography>
      </Grid>
      <Grid container direction="column" alignItems="center" justify="center">
        <Grid item xs={12} className={classes.datePicker}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              format="DD/MM/YYYY"
              className={classes.picker}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <EventIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              maxDate={moment()}
              onChange={handleLogDateChange}
              value={logDate}
              variant="inline"
              inputVariant="standard"
              autoOk
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : logs ? (
          <LazyLog enableSearch height={400} text={logs} selectableLines />
        ) : (
          <Typography align="center">Aucune donnée à afficher pour le {logDate.format("DD/MM/YYYY")}</Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default LogsViewer;
