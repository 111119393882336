import React from "react";
import { Typography, Grid, TextField, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { inject, observer } from "mobx-react";

import { FEATURES } from "../../utils/matrix";
import ManageUserFeaturesRow from "../dialog/ManageUserFeaturesRow";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  center: {
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  btnContainer: {
    marginTop: theme.spacing(2)
  },
  nameInput: { marginTop: theme.spacing(), marginBottom: theme.spacing(2) }
});

@inject("sessionStore")
@observer
class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    const { profile } = props;
    this.state = {
      name: profile.name,
      acl: profile.acl,
      focusedFeature: ""
    };
  }

  handleNameChanged = e => {
    this.setState({ name: e.target.value });
  };

  toggleAcl = async targettedAcl => {
    const { acl } = this.state;
    const indexOf = acl.indexOf(targettedAcl);
    if (indexOf > -1) {
      acl.splice(indexOf, 1);
    } else {
      acl.push(targettedAcl);
    }
    await this.setState({ acl: [...acl] });
  };

  toggleFocusedFeature = feature => {
    const { focusedFeature } = this.state;
    this.setState({ focusedFeature: feature === focusedFeature ? "" : feature });
  };

  submitProfile = () => {
    const { profile, handleSubmit } = this.props;
    const { name, acl } = this.state;
    handleSubmit({ ...profile, name, acl });
  };

  render() {
    const { classes, sessionStore, isSubmitting } = this.props;
    const { name, acl, focusedFeature } = this.state;

    const {
      user: { company }
    } = sessionStore;

    const filteredFeatures = FEATURES.filter(
      feature => company.features.includes(feature.key) || feature.isRootFeature
    );

    return (
      <form>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h4">Informations du profil utilisateur</Typography>
          </Grid>
          <Grid item xs={12} className={classes.nameInput}>
            <TextField
              name="name"
              placeholder="Nom du profil"
              label="Nom"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={name}
              onChange={this.handleNameChanged}
              error={!name && acl.length > 0}
              helperText={!name && acl.length > 0 && "Le nom est obligatoire"}
            />
          </Grid>
          <Grid item xs={12}>
            {filteredFeatures.map(feature => (
              <ManageUserFeaturesRow
                key={`feat-cat-${feature.key}`}
                feature={feature}
                acls={acl}
                toggleAcl={this.toggleAcl}
                focusedFeature={focusedFeature}
                toggleFocusedFeature={this.toggleFocusedFeature}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="flex-end" className={classes.btnContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.submitProfile}
                disabled={isSubmitting || acl.length === 0 || !name}
              >
                Enregistrer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(ProfileForm);
