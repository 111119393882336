import React from "react";
import { Paper, Typography, withStyles, Grid } from "@material-ui/core";
import SignInForm from "../components/form/SignInForm";

const styles = theme => ({
  img: {
    display: "block",
    margin: "auto"
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  }
});

const SignInPage = ({ history, location, classes }) => (
  <Grid container justify="center">
    <Grid item xs={12} sm={8} md={6} lg={5} xl={3}>
      <Paper className={classes.paper}>
        <img className={classes.img} width="250px" src="/images/kingkang.png" />
        <Typography variant="h4" align="center">
          Connexion
        </Typography>
        <SignInForm history={history} location={location} />
      </Paper>
    </Grid>
  </Grid>
);

export default withStyles(styles)(SignInPage);
