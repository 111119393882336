import { observable, action } from "mobx";

class AddSellerStore {
  @observable
  seller = {
    firstname: "",
    lastname: "",
    password: "",
    job: "",
    email: "",
    phone: ""
  };

  @action
  handleSellerChange = event => {
    this.seller[event.target.name] = event.target.value;
  };

  @action
  clear = () => {
    this.seller = {
      firstname: "",
      lastname: "",
      password: "",
      job: "",
      email: "",
      phone: ""
    };
  };
}

const addSellerStore = new AddSellerStore();

export default addSellerStore;
