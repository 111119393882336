import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { memo } from "react";

import { useSnackbar } from "notistack";
import { FEATURES, OPTIONS } from "../../../utils/matrix";
import AdminService from "../../../services/AdminService";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block"
  },
  card: { height: "100%" },
  row: {
    display: "flex",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  rowPicto: {
    flexBasis: 40,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  companyItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

function CompanyFeatures({ company, onPatch }) {
  const classes = useStyles();
  const [companyFeatures, setCompanyFeatures] = React.useState(company.features);
  const { enqueueSnackbar } = useSnackbar();

  const handleReset = () => {
    setCompanyFeatures(company.features);
  };

  const handleToggleFeature = feature => {
    if (companyFeatures.includes(feature)) {
      setCompanyFeatures(companyFeatures.filter(f => f !== feature));
    } else {
      setCompanyFeatures([...companyFeatures, feature]);
    }
  };

  const handleSubmit = () => {
    AdminService.patchCompany({
      ...company,
      features: [...companyFeatures]
    })
      .then(updatedCompany => {
        onPatch(updatedCompany);
        setCompanyFeatures([...updatedCompany.features]);
        enqueueSnackbar("L'entreprise a été mise à jour", { variant: "success" });
      })
      .catch(err => {
        console.log(" ERROR ", err);
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'entreprise", {
          variant: "error"
        });
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Fonctionnalités
            </Typography>
            <Grid container>
              {FEATURES.map(feature => (
                <Grid container spacing={1} alignItems="center" key={feature.key}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={companyFeatures.includes(feature.key)}
                          name={`FEATURE_${feature.key}`}
                          color="primary"
                          onChange={() => handleToggleFeature(feature.key)}
                        />
                      }
                      label={feature.label}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Typography gutterBottom variant="h6" component="h3">
              Options
            </Typography>
            <Grid container>
              {OPTIONS.map(option => (
                <Grid container spacing={1} alignItems="center" key={option.key}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={companyFeatures.includes(option.key)} name={option.key} color="primary" />
                      }
                      label={option.label}
                      onChange={() => handleToggleFeature(option.key)}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Button variant="text" onClick={handleReset}>
              Annuler
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Enregistrer
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export default memo(CompanyFeatures);
