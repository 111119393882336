import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";

export default function YouSignSignerFrame({ onSign, signatureUrl }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleMessage = useCallback(
    event => {
      if (event.origin === "https://yousign.app" && event.data.type === "yousign" && event.data.event === "success") {
        onSign();
        enqueueSnackbar("Signature appliquée avec succès", { variant: "success" });
      }
    },
    [onSign]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [signatureUrl]);

  const iframeUrl = new URL(signatureUrl);
  iframeUrl.searchParams.append("disable_domain_validation", "true");

  return <iframe src={iframeUrl} style={{ width: "100%", height: "100%" }} />;
}
