import React from "react";
import { Badge, Chip, Tooltip, withStyles } from "@material-ui/core";
import moment from "moment";

import { ADDITIONAL_DOCUMENT_STATUS, ESTIMATE_STATUS } from "../../utils/status";
import AdditionalDocumentService from "../../services/AdditionalDocumentService";

const styles = () => ({
  chip: {
    color: "white"
  }
});

function AdditionalDocumentStatusChip({ additionalDocument, classes }) {
  switch (additionalDocument.status) {
    case "DRAFT":
      return (
        <Chip
          className={classes.chip}
          label={ADDITIONAL_DOCUMENT_STATUS.DRAFT.label}
          style={{ backgroundColor: ADDITIONAL_DOCUMENT_STATUS.DRAFT.color }}
        />
      );
    case "VALIDATED":
      return (
        <Chip
          className={classes.chip}
          label={ADDITIONAL_DOCUMENT_STATUS.VALIDATED.label}
          style={{ backgroundColor: ADDITIONAL_DOCUMENT_STATUS.VALIDATED.color }}
        />
      );
    case "SENT":
      if (additionalDocument.signature && AdditionalDocumentService.signatureExpired(additionalDocument)) {
        return (
          <Chip
            className={classes.chip}
            label={ADDITIONAL_DOCUMENT_STATUS.SENT_EXPIRED.label}
            style={{ backgroundColor: ADDITIONAL_DOCUMENT_STATUS.SENT_EXPIRED.color }}
          />
        );
      }
      return (
        <Chip
          className={classes.chip}
          label={ADDITIONAL_DOCUMENT_STATUS.SENT.label}
          style={{ backgroundColor: ADDITIONAL_DOCUMENT_STATUS.SENT.color }}
        />
      );
    case "SIGNED":
      return (
        <Chip
          className={classes.chip}
          label={ADDITIONAL_DOCUMENT_STATUS.SIGNED.label}
          style={{ backgroundColor: ADDITIONAL_DOCUMENT_STATUS.SIGNED.color }}
        />
      );
    case "REFUSED":
      return (
        <Chip
          className={classes.chip}
          label={ADDITIONAL_DOCUMENT_STATUS.REFUSED.label}
          style={{
            backgroundColor: ADDITIONAL_DOCUMENT_STATUS.REFUSED.color
          }}
        />
      );
    case "CANCEL":
      return (
        <Chip
          className={classes.chip}
          label={ADDITIONAL_DOCUMENT_STATUS.CANCEL.label}
          style={{
            backgroundColor: ADDITIONAL_DOCUMENT_STATUS.CANCEL.color
          }}
        />
      );
    default:
      return (
        <Chip
          className={classes.chip}
          label={ADDITIONAL_DOCUMENT_STATUS.DRAFT.label}
          style={{ backgroundColor: ADDITIONAL_DOCUMENT_STATUS.DRAFT.color }}
        />
      );
  }
}

function AdditionalDocumentStatusLabel({ additionalDocument, classes }) {
  if (additionalDocument.signature) {
    if (additionalDocument.signature.signatureDoneDate) {
      return (
        <Tooltip
          title={`Signature terminée le ${moment(additionalDocument.signature.signatureDoneDate).format(
            "DD/MM/YYYY [à] HH:mm"
          )}`}
        >
          <Badge color="error" badgeContent="SE">
            <AdditionalDocumentStatusChip additionalDocument={additionalDocument} classes={classes} />
          </Badge>
        </Tooltip>
      );
    }

    if (additionalDocument.status === "SENT") {
      if (AdditionalDocumentService.signatureExpired(additionalDocument)) {
        return (
          <Tooltip title={ESTIMATE_STATUS.SENT_EXPIRED.tooltip}>
            <Badge color="error" badgeContent="SE">
              <AdditionalDocumentStatusChip additionalDocument={additionalDocument} classes={classes} />
            </Badge>
          </Tooltip>
        );
      }

      return (
        <Tooltip
          title={`Ce document peut être signé jusqu'au ${AdditionalDocumentService.signatureDeadline(
            additionalDocument
          ).format("DD/MM/YYYY [à] HH:mm")}`}
        >
          <Badge color="error" badgeContent="SE">
            <AdditionalDocumentStatusChip additionalDocument={additionalDocument} classes={classes} />
          </Badge>
        </Tooltip>
      );
    }
  }
  return <AdditionalDocumentStatusChip additionalDocument={additionalDocument} classes={classes} />;
}

export default withStyles(styles)(AdditionalDocumentStatusLabel);
