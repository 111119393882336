import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useFormikContext } from "formik";

import { Decimal } from "decimal.js-light";
import { PAYMENT_MODES, FINANCIAL_METHOD } from "../../services/DocumentService";
import { totalPriceWithTVA, getComputedDeposit } from "../../utils/numberFormat";

const useStyle = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  group: {
    flexDirection: "row"
  }
}));

function CashForm() {
  const classes = useStyle();
  const { handleChange, setFieldValue, values } = useFormikContext();

  const totalWithVates = values.details ? totalPriceWithTVA(values.details) : 0;
  const computedDeposit = getComputedDeposit(values);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={6} className={classes.item}>
        <FormControl component="fieldset" required>
          <FormLabel component="legend">Moyen de paiement</FormLabel>
          <RadioGroup
            aria-label="paymentMode"
            className={classes.group}
            value={values.paymentMode}
            onChange={handleChange}
            name="paymentMode"
          >
            {PAYMENT_MODES.map(method => (
              <FormControlLabel
                key={method.value}
                value={method.value}
                control={<Radio color="primary" />}
                label={method.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.item}>
        <TextField
          name="cashPrice"
          label="Prix au comptant"
          margin="normal"
          variant="outlined"
          disabled
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>
          }}
          value={totalWithVates}
        />
      </Grid>
      <Grid item xs={12} className={classes.item}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="discount">Acompte à la commande</InputLabel>
          <OutlinedInput
            id="deposit"
            type="number"
            value={values.cashForm.deposit}
            name="cashForm.deposit"
            onChange={e => {
              setFieldValue("cashForm.deposit", e.target.value);
            }}
            inputProps={{ min: "0" }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Basculer le mode d'acompte"
                  onClick={() => {
                    setFieldValue(
                      "cashForm.depositMethod",
                      values.cashForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE
                        ? FINANCIAL_METHOD.HARD_VALUE
                        : FINANCIAL_METHOD.PERCENTAGE
                    );
                  }}
                >
                  {values.cashForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE ? "%" : "€"}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={185}
          />
          <FormHelperText id="discount-helper-text">
            soit un acompte de{" "}
            {values.cashForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE
              ? `${getComputedDeposit(values)}€`
              : `${
                  values.cashForm.deposit > 0
                    ? new Decimal((values.cashForm.deposit / totalWithVates) * 100)
                        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
                        .toNumber()
                        .toFixed(2)
                    : 0
                }%`}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.item}>
        <TextField
          name="balance"
          label="Solde à la réception de chantier"
          margin="normal"
          variant="outlined"
          disabled
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>
          }}
          value={Number(totalWithVates - computedDeposit).toFixed(2)}
        />
      </Grid>
    </Grid>
  );
}

export default CashForm;
