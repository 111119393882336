import { useQuery } from "@tanstack/react-query";
import React from "react";
import { CircularProgress, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

import YouSignService from "../services/YouSignService";
import YousignSignatureFrame from "../components/signature/YousignSignatureFrame";
import NotFoundPage from "./NotFoundPage";

const useStyle = makeStyles(theme => ({
  img: {
    display: "block",
    margin: "auto"
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  centerz: {
    textAlign: "center"
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 300
  },
  iframe: { width: "100%", height: 400, marginTop: theme.spacing(2.5) },
  successTitle: { margin: theme.spacing(1, 0) },
  successContainer: {
    textAlign: "center",
    padding: theme.spacing(3, 2),
    margin: theme.spacing(2)
  },
  pub: {
    textAlign: "center",
    marginTop: theme.spacing(2)
  }
}));

const DOCUMENT_TYPE = {
  e: "ESTIMATE",
  o: "ORDER",
  d: "DOC_SIGNATURE",
  a: "ADDITIONAL_DOCUMENT"
};

const getDocumentType = contentId => DOCUMENT_TYPE[contentId[0]];

const pageTitle = contentId => {
  const type = getDocumentType(contentId);
  switch (type) {
    case "ESTIMATE":
      return "Signature d'un devis";
    case "ORDER":
      return "Signature d'un bon de commande";
    case "DOC_SIGNATURE":
    case "ADDITIONAL_DOCUMENT":
      return "Signature d'un document";
    default:
      return "Signature";
  }
};

const documentType = contentId => {
  const type = getDocumentType(contentId);
  switch (type) {
    case "ESTIMATE":
      return "votre devis";
    case "ORDER":
      return "votre bon de commande";
    case "DOC_SIGNATURE":
    case "ADDITIONAL_DOCUMENT":
      return "votre document";
    default:
      return "";
  }
};

const formatContent = contentId => ({
  type: getDocumentType(contentId),
  realId: contentId.substring(1)
});

export default function YousignSignaturePage({ match }) {
  const { params } = match;
  const [signatureDone, setSignatureDone] = React.useState(false);
  const classes = useStyle();

  const _content = formatContent(params.contentId);

  const { isLoading, data, error } = useQuery({
    queryKey: ["signature", { id: params.signatoryId }],
    queryFn: () =>
      YouSignService.getSignatureUrl(
        params.companyId,
        params.signatureRequestId,
        _content.realId,
        _content.type,
        params.signatoryId
      ),
    staleTime: 1000 * 60, // 1 minute
    retry: false
  });

  if (data === "") {
    return <NotFoundPage />;
  }

  if (isLoading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid container>
      <Grid container>
        <Grid item sm={1} md={2} />
        <Grid item xs={12} sm={10} md={8}>
          <Paper className={`${classes.paper} ${classes.centerz}`}>
            <object
              data={`${process.env.API_URL}/public/logo/${params.companyId}.png`}
              style={{ height: 100 }}
              type="image/png"
            >
              <img src="/images/default_logo.png" height="1" />
            </object>

            <Typography variant="h4" component="h1" align="center">
              {pageTitle(params.contentId)}
            </Typography>
            {signatureDone ? (
              <React.Fragment>
                {signatureDone && (
                  <div className={classes.loaderContainer}>
                    <Grid container>
                      <Grid container>
                        <Grid container>
                          <Grid item sm={1} md={2} lg={3} xl={4} />
                          <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                            <div className={classes.successContainer}>
                              <DoneIcon fontSize="large" style={{ fontSize: 50 }} />
                              <Typography variant="h4" className={classes.successTitle}>
                                Félicitations
                              </Typography>
                              <Typography variant="body1">
                                Vous venez de signer numériquement {documentType(params.contentId)} !
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div style={{ marginTop: 24 }}>
                {data && data.url && (
                  <div style={{ height: "500px" }}>
                    <YousignSignatureFrame url={data.url} onSuccess={() => setSignatureDone(true)} />
                  </div>
                )}
                {error && <p>Une erreur est survenue lors du chargement de la signature</p>}
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={1} md={2} lg={3} xl={4} />
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Typography variant="body2" className={classes.pub}>
            Un service proposé par{" "}
            <a href="https://www.kingkang.fr/" target="_blank" rel="noopener noreferrer">
              KingKang
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
