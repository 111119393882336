import React from "react";

import { getMaxDiscountForItem } from "../utils/numberFormat";
import ValidatorService from "./ValidatorService";
import CashForm from "../components/documentForm/CashForm";
import FinancingForm from "../components/documentForm/FinancingForm";

export const isDocumentDetailsValid = document =>
  document.details.every(value => {
    const { title, discount, description, price, tva, quantity, customFields } = value;
    let customsOK = true;
    if (customFields && customFields.length > 0) {
      customFields.forEach(field => {
        if (field.required && field.value === "") {
          customsOK = false;
        }
      });
    }

    return (
      title !== "" &&
      description !== "" &&
      price !== "" &&
      tva !== "" &&
      quantity !== "" &&
      document.nature !== "" &&
      customsOK &&
      discount >= 0 &&
      discount <= getMaxDiscountForItem(value)
    );
  });

export const isDocumentClientValid = document => {
  const {
    client: { lastname, firstname, email, address, zipCode, city, type, phone }
  } = document;

  return (
    lastname !== "" &&
    firstname !== "" &&
    email !== "" &&
    ValidatorService.validateEmail(email) &&
    address !== "" &&
    zipCode !== "" &&
    city !== "" &&
    type !== "" &&
    (phone ? ValidatorService.validatePhone(phone) : true)
  );
};

const PAYMENT_CONDITIONS_VALUES = Object.freeze({ FINANCING: "FINANCING", CASH: "CASH" });

export const PAYMENT_CONDITIONS = [
  {
    label: "Financement",
    value: PAYMENT_CONDITIONS_VALUES.FINANCING,
    component: <FinancingForm />
  },
  {
    label: "Paiement comptant",
    value: PAYMENT_CONDITIONS_VALUES.CASH,
    component: <CashForm />
  }
];

export const selectedPaymentMethod = paymentCondition =>
  PAYMENT_CONDITIONS.find(condition => condition.value === paymentCondition).component;

export const PAYMENT_MODES = [
  {
    value: "cheque",
    label: "Chèque"
  },
  {
    value: "transfer",
    label: "Virement"
  },
  {
    value: "cash",
    label: "Espèces"
  }
];

export const FINANCIAL_METHOD = { PERCENTAGE: 1, HARD_VALUE: 2 };
