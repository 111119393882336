import { Box, Paper, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: "#FFF",
    maxWidth: 600
  },
  img: {
    width: 100,
    [theme.breakpoints.up("md")]: {
      width: 150
    }
  },
  wrapper: {
    padding: 16,
    display: "flex",
    alignItems: "center",
    gap: 20,
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  separator: {
    backgroundColor: "#E3E3E3",
    order: 2,
    height: 2,
    width: 80,
    [theme.breakpoints.up("md")]: {
      width: 3,
      height: 100
    }
  },
  textContainer: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    order: 1,

    [theme.breakpoints.up("md")]: {
      order: 3
    }
  },
  imgContainer: {
    order: 3,
    [theme.breakpoints.up("md")]: {
      order: 1
    }
  }
}));

export default function YousignInstructionsCard() {
  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.root}>
      <Box className={classes.wrapper}>
        <Box className={classes.imgContainer}>
          <img className={classes.img} src="/images/kingkang.png" />
        </Box>
        <Box className={classes.separator} />
        <Box className={classes.textContainer}>
          <Typography variant="subtitle1">Instructions d&apos;utilisation :</Typography>
          <Typography variant="body2">
            Lorsque vous êtes prêt à apposer votre signature électronique, veuillez cliquer sur votre nom situé
            ci-dessus.
          </Typography>
          <Typography variant="body2">
            Une fois que tous les signataires auront signé le document, vous recevrez une copie par e-mail de votre
            exemplaire signé.
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}
