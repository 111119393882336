import { Chip, makeStyles } from "@material-ui/core";
import moment from "moment";
import React from "react";

const useStyles = makeStyles(() => ({
  chip: {
    minWidth: 120
  },
  chipLabel: {
    flex: 2,
    textAlign: "center"
  }
}));

export default function ClientDateChip({ date, avatar }) {
  const classes = useStyles();

  return (
    <Chip
      variant="outlined"
      className={classes.chip}
      classes={{ label: classes.chipLabel }}
      avatar={avatar}
      label={date ? moment(date).format("DD/MM/YYYY") : "-"}
    />
  );
}
