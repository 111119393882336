import { CircularProgress, makeStyles } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { useSnackbar } from "notistack";
import React from "react";
import ClientDetails from "../components/client/ClientDetails";
import ClientService from "../services/ClientService";

const useStyles = makeStyles(() => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200
  }
}));

function ClientPage(props) {
  const [client, setClient] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  React.useEffect(() => {
    ClientService.getClient(props.match.params.clientId)
      .then(response => {
        setClient(response.contact);
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la récupération des informations du client", {
          variant: "error"
        });
      });
  }, []);

  if (client === null) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }
  return <ClientDetails client={client} />;
}

export default inject("sessionStore")(observer(props => <ClientPage {...props} />));
