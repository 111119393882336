import { observable } from "mobx";
import Client from "./Client";
import FINANCIAL_METHODS from "../stores/NewOrderFormStore";

class Estimate {
  constructor({
    nature,
    amount,
    currency = "EUR",
    totalPriceWithoutTVA,
    totalPriceWithTVA,
    paymentCondition,
    paymentMode,
    discount,
    discountMethod,
    computedDiscount,
    client,
    information,
    id,
    order,
    comments,
    includePaymentBlock,
    additionalInformation,
    extraAdditionalInformation,
    useSpecificDeliveryAddress,
    deliveryAddress
  }) {
    this.nature = nature;
    this.currency = currency;
    this.amount = amount;
    this.id = id;
    this.totalPriceWithoutTVA = totalPriceWithoutTVA;
    this.totalPriceWithTVA = totalPriceWithTVA;
    this.paymentCondition = paymentCondition;
    this.paymentMode = paymentMode;
    this.discount = discount;
    this.discountMethod = discountMethod;
    this.computedDiscount = computedDiscount;
    this.client = client;
    this.information = information;
    this.order = order;
    this.comments = comments;
    this.includePaymentBlock = includePaymentBlock || false;
    this.additionalInformation = additionalInformation;
    this.extraAdditionalInformation = extraAdditionalInformation;
    this.useSpecificDeliveryAddress = useSpecificDeliveryAddress;
    this.deliveryAddress = useSpecificDeliveryAddress ? deliveryAddress : null;
  }

  @observable
  id = "";

  @observable
  nature = "";

  @observable
  currency = "EUR";

  @observable
  amount = 0;

  @observable
  totalPriceWithoutTVA = 0;

  @observable
  totalPriceWithTVA = 0;

  @observable
  paymentCondition = "";

  @observable
  paymentMode = "";

  @observable
  discount = "";

  @observable
  discountMethod = FINANCIAL_METHODS.PERCENTAGE;

  @observable
  computedDiscount = 0;

  @observable
  client = new Client();

  @observable
  information = "";

  @observable
  order = null;

  @observable
  comments = "";
}

export default Estimate;
