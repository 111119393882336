import React, { Component, Fragment } from "react";
import { Box, Paper, Tab, Tabs, withStyles } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { PAYMENT_CONDITIONS } from "../../stores/NewEstimateStore";

const styles = theme => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    padding: theme.spacing()
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  group: { flexDirection: "row" },
  paper: { padding: theme.spacing(2), marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  formControl: {
    marginTop: theme.spacing(2)
  },
  contentContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  tabs: {
    borderBottom: "1px solid #E6E6E6"
  }
});

@inject("newEstimateStore")
@observer
class EstimateComplementaryStep extends Component {
  componentDidMount() {
    // Used for the ref to work.
    // https://v3-9-0.material-ui.com/demos/text-fields/#components
    // This will probably be better with V4 of MUI
    this.forceUpdate();
  }

  handleTabChange = (e, value) => {
    const { newEstimateStore } = this.props;
    newEstimateStore.handleChangePaymentCondition(value);
  };

  render() {
    const { classes, newEstimateStore } = this.props;

    return (
      <Fragment>
        <Paper className={classes.paper}>
          <Tabs
            value={newEstimateStore.paymentCondition}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleTabChange}
            classes={{ root: classes.tabs }}
          >
            {PAYMENT_CONDITIONS.map(condition => (
              <Tab label={condition.label} value={condition.value} key={condition.value} />
            ))}
          </Tabs>
          <Box className={classes.contentContainer}>{newEstimateStore.selectedPaymentMethod}</Box>
        </Paper>
      </Fragment>
    );
  }
}

export default withStyles(styles)(EstimateComplementaryStep);
