import React, { Fragment } from "react";
import { Badge, Button, TextField, Typography, InputAdornment, makeStyles } from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { v4 as uuidv4 } from "uuid";

import PictureManager from "./PictureManager";

const useStyles = makeStyles(() => ({
  cameraIcon: { "&:hover": { cursor: "pointer" } }
}));

export default function AdditionalInformationArrayField({ inputProps = {} }) {
  const classes = useStyles();
  const [showPictureManagerForIndex, setShowPictureManagerForIndex] = React.useState(undefined);
  const { handleChange, values, handleBlur } = useFormikContext();

  const handlePicture = index => {
    setShowPictureManagerForIndex(index);
  };

  const handleClosePictureManager = () => {
    setShowPictureManagerForIndex(undefined);
  };

  return (
    <Fragment>
      <FieldArray
        name="additionalInformation"
        render={arrayHelpers => (
          <div>
            <Typography gutterBottom variant="subtitle1">
              Mentions complémentaires
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 8 }}>
              {values.additionalInformation &&
                values.additionalInformation.length > 0 &&
                values.additionalInformation.map((row, index) => (
                  <div key={`${index + 1}`} style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            className={classes.cameraIcon}
                            onClick={() => handlePicture(index)}
                            position="start"
                          >
                            <Badge variant="dot" badgeContent={row.picture ? 1 : 0} color="primary">
                              <CameraAltIcon color="secondary" />
                            </Badge>
                          </InputAdornment>
                        )
                      }}
                      fullWidth
                      id={`additionalInformation.${index}.content`}
                      name={`additionalInformation.${index}.content`}
                      value={row.content || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      size="small"
                      multiline
                      {...inputProps}
                    />
                    <Button size="small" variant="outlined" color="primary" onClick={() => arrayHelpers.remove(index)}>
                      <DeleteIcon />
                    </Button>
                  </div>
                ))}
              <Button
                variant="outlined"
                color="primary"
                onClick={() => arrayHelpers.push({ content: "", picture: "", id: uuidv4() })}
                style={{ alignSelf: "center", marginTop: 8 }}
              >
                Ajouter une mention complémentaire
              </Button>
            </div>
          </div>
        )}
      />
      <PictureManager index={showPictureManagerForIndex} onClose={handleClosePictureManager} />
    </Fragment>
  );
}
