import React, { Component } from "react";
import { withStyles, Button, Grid, Typography, Paper } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import fileDownload from "js-file-download";
import { withSnackbar } from "notistack";

import CompanyService from "../../services/CompanyService";
import UserService from "../../services/UserService";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./Wysiswyg.css";
import NotFoundPage from "../../pages/NotFoundPage";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  paper: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  }
});

@inject("sessionStore")
@observer
class Withdrawal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {
        withdrawal: ""
      },
      editorState: EditorState.createEmpty(),
      loading: false,
      disabledDownload:
        props.sessionStore.user && props.sessionStore.user.company
          ? props.sessionStore.user.company.withdrawal === null
          : true
    };
  }

  componentDidMount() {
    const { sessionStore } = this.props;
    if (sessionStore.user.company) {
      const blocksFromHtml = htmlToDraft(
        sessionStore.user.company.withdrawal ? sessionStore.user.company.withdrawal : ""
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ company: sessionStore.user.company, editorState });
    } else {
      UserService.me().then(response => {
        this.setState({ company: response.company });
      });
    }
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
      disabledDownload: true
    });
  };

  handleSubmit = () => {
    const { company, editorState } = this.state;
    company.withdrawal = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    CompanyService.patchCompany(company)
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Les Conditions de rétractation ont été mises à jour", {
          variant: "success"
        });
        this.setState({ disabledDownload: false });
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour des conditions de rétractation", {
          variant: "error"
        });
      });
  };

  downloadPDF = () => {
    const { company } = this.state;
    CompanyService.downloadPDF("withdrawal.pdf")
      .then(res => {
        fileDownload(res, `${company.name} - withdrawal.pdf`);
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF", {
          variant: "error"
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { classes, sessionStore } = this.props;
    const { editorState, loading, disabledDownload } = this.state;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["SETTING_WRITE", "SETTING_READ"],
        aclType: "oneof",
        requiredFeatures: ["SETTING"]
      })
    ) {
      return <NotFoundPage />;
    }

    const contentIsNotValid =
      convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
      convertToRaw(editorState.getCurrentContent()).blocks[0].text === "";

    const canEdit = sessionStore.userHasAccess({
      requiredAcls: ["SETTING_WRITE"],
      requiredFeatures: ["SETTING"]
    });

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Conditions de rétractation</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div className="react-draft-root">
              <Editor
                editorState={editorState}
                toolbarClassName="react-draft-toolbar"
                editorClassName="react-draft-editor"
                onEditorStateChange={this.onEditorStateChange}
                localization={{
                  translations: {
                    "components.controls.blocktype.h1": "Titre 1",
                    "components.controls.blocktype.h2": "Titre 2",
                    "components.controls.blocktype.h3": "Titre 3",
                    "components.controls.blocktype.h4": "Titre 4",
                    "components.controls.blocktype.h5": "Titre 5",
                    "components.controls.blocktype.h6": "Titre 6",
                    "components.controls.blocktype.blockquote": "Citation",
                    "components.controls.blocktype.code": "Saut de page",
                    "components.controls.blocktype.blocktype": "Block Type",
                    "components.controls.blocktype.normal": "Paragraphe"
                  }
                }}
                toolbar={{
                  inline: { options: ["bold", "italic", "underline", "superscript"] },
                  blockType: {
                    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Code"]
                  },
                  fontSize: {
                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
                  },
                  list: {
                    options: ["unordered"]
                  },
                  link: {
                    defaultTargetOption: "_self",
                    options: ["unlink"]
                  },
                  options: ["inline", "blockType", "fontSize", "list", "textAlign", "link", "remove"]
                }}
                readOnly={!canEdit}
              />
            </div>
          </Paper>
        </Grid>
        <div className="react-draft-button">
          {canEdit && (
            <div>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
                disabled={contentIsNotValid}
              >
                Enregistrer les conditions
              </Button>
            </div>
          )}
          <div>
            <Button
              disabled={canEdit ? disabledDownload || loading : false}
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={this.downloadPDF}
            >
              Télécharger le PDF de rétractation
            </Button>
          </div>
        </div>
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(Withdrawal));
