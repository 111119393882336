import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { Grid, Button, TextField, Badge, FormControlLabel, Checkbox, useTheme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  woMarginTop: {
    marginTop: 0
  },
  advancedContainer: {
    marginBottom: theme.spacing(2)
  },
  advancedFieldsContainer: {
    paddingBottom: theme.spacing(),
    backgroundColor: "#FCFCFC"
  },
  advancedActionContainer: {
    backgroundColor: "#FCFCFC"
  },
  advancedInput: {
    marginTop: theme.spacing(2)
  },
  advancedInputContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  expWrapper: {
    overflow: "hidden"
  },
  chip: {
    margin: 2
  },
  chipContainer: { display: "flex", flexWrap: "wrap" },
  searchContainer: {
    padding: theme.spacing()
  }
}));

let timeoutSearch;

export const defaultListFilter = {
  q: "",
  company: {},
  showDeleted: false
};

function UsersListFilter({ filter, updateFilter, defaultFilter }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [tmpFilter, setTmpFilter] = useState({ ...defaultListFilter, ...filter });
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    let _activeFiltersCount = 0;
    if (filter.showDeleted === true) _activeFiltersCount += 1;
    setActiveFiltersCount(_activeFiltersCount);
  }, [filter]);

  const handleQueryChange = e => {
    const updatedFilter = { ...tmpFilter, q: e.target.value };
    setTmpFilter(updatedFilter);
    clearTimeout(timeoutSearch);
    timeoutSearch = setTimeout(() => {
      updateFilter(updatedFilter);
    }, 500);
  };

  const handleExpansion = (e, expanded) => {
    setIsExpanded(expanded);
  };

  const handleSearch = () => {
    clearTimeout(timeoutSearch);
    setIsExpanded(false);
    updateFilter(tmpFilter);
  };

  const toggleShowDeleted = () => {
    setTmpFilter({ ...tmpFilter, showDeleted: !tmpFilter.showDeleted });
  };

  const handleResetAdvanced = () => {
    setTmpFilter(defaultFilter);
    updateFilter(defaultFilter);
  };

  return (
    <Grid container className={classes.searchContainer}>
      <Grid item xs={12} className={classes.advancedContainer}>
        <ExpansionPanel onChange={handleExpansion} expanded={isExpanded} className={classes.expWrapper}>
          <ExpansionPanelSummary
            expandIcon={
              !isExpanded && activeFiltersCount > 0 ? (
                <Badge badgeContent={activeFiltersCount} color="primary">
                  <ExpandMoreIcon />
                </Badge>
              ) : (
                <ExpandMoreIcon />
              )
            }
          >
            <TextField
              className={classes.woMarginTop}
              onClick={e => e.stopPropagation()}
              name="q"
              placeholder={matches ? "Recherche" : "Nom, prénom, email"}
              label={matches ? "Recherche" : "Nom, prénom, email"}
              margin="normal"
              fullWidth
              value={tmpFilter.q}
              onChange={handleQueryChange}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.advancedFieldsContainer}>
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tmpFilter.showDeleted}
                      name="show-deleted"
                      color="primary"
                      onChange={toggleShowDeleted}
                    />
                  }
                  label="Afficher uniquement les comptes supprimés"
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
          <ExpansionPanelActions className={classes.advancedActionContainer}>
            <Button size="small" color="primary" onClick={handleResetAdvanced}>
              Réinitialiser
            </Button>
            <Button size="small" variant="contained" color="primary" onClick={handleSearch}>
              Filtrer
            </Button>
          </ExpansionPanelActions>
        </ExpansionPanel>
      </Grid>
    </Grid>
  );
}

export default UsersListFilter;
