import React from "react";

import CompanyForm from "../../components/form/CompanyForm";
import AdminBreadcrumb from "../../components/admin/AdminBreadcrumb";
import { superAdmin } from "../../routes";

export default function SuperAdminEditCompanyPage({ match }) {
  return (
    <div>
      <AdminBreadcrumb routes={superAdmin} match={match} />
      <CompanyForm companyId={match.params.companyId} />
    </div>
  );
}
