import { withRouter } from "react-router-dom";
import React, { Fragment, useEffect } from "react";
import { Typography, Tabs, Tab, withStyles } from "@material-ui/core";

import ClientsList from "../components/client/ClientsList";

const hashToTab = hash => {
  if (hash === "#inactifs") return "INACTIVE";
  return "ACTIVE";
};

function ClientsPage({ history, location, classes }) {
  const [selectedItem, setSelectedItem] = React.useState(hashToTab(location.hash)); // ACTIVE | INACTIVE

  useEffect(() => {
    setSelectedItem(hashToTab(location.hash));
  }, [location]);

  return (
    <Fragment>
      <div className={classes.head}>
        <Typography variant="h4">Annuaire</Typography>
      </div>
      <Tabs
        value={selectedItem}
        onChange={(e, selectedTab) => {
          if (selectedTab === "INACTIVE") {
            history.push({ pathname: "/clients", hash: "#inactifs" });
          } else {
            history.push({ pathname: "/clients", hash: "" });
          }
        }}
        variant="fullWidth"
        className={classes.tabscontainer}
      >
        <Tab value="ACTIVE" label="Clients" className={classes.tab} />
        <Tab value="INACTIVE" label="Clients désactivés" className={classes.tab} />
      </Tabs>
      {selectedItem === "ACTIVE" && <ClientsList contactsStatus="ACTIVE" />}
      {selectedItem === "INACTIVE" && <ClientsList contactsStatus="INACTIVE" />}
    </Fragment>
  );
}

const styles = theme => ({
  head: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`
  },
  tabscontainer: { marginBottom: theme.spacing(2) },
  tab: { borderBottom: "1px solid #E6E6E6" }
});

export default withStyles(styles)(withRouter(ClientsPage));
