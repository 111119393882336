import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core";

import NotFoundPage from "./NotFoundPage";
import ProfilesList from "../components/ProfilesList";

const styles = theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
});

@inject("sessionStore")
@observer
class ProfilePage extends Component {
  render() {
    const { sessionStore } = this.props;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["SETTING_READ", "SETTING_WRITE"],
        aclType: "oneof",
        requiredFeatures: ["SETTING"]
      })
    )
      return <NotFoundPage />;

    const { CGV } = sessionStore.user.company;

    return <ProfilesList disableAddFn={!CGV} />;
  }
}

export default withStyles(styles)(ProfilePage);
