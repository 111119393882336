import React from "react";
import { Card, CardContent, Fab, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router";

import UsersList from "../user/UsersList";

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

function CompanyUsers({ company, history }) {
  const classes = useStyles();

  const handleCreateUser = () => {
    history.push(`/superbg/entreprises/details/${company.id}/nouvel-utilisateur`);
  };

  if (company && company.id) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h6" component="h3">
                Utilisateurs
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <UsersList defaultFilter={{ companyId: company.id }} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={handleCreateUser}
          title="Ajouter un utilisateur à l'entreprise"
        >
          <AddIcon />
        </Fab>
      </Grid>
    );
  }
  return (
    <div>
      <Typography>Aucune donnée</Typography>
    </div>
  );
}

export default withRouter(CompanyUsers);
