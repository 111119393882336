import { Typography } from "@material-ui/core";
import React, { Fragment } from "react";

const typeToString = additionalDocumentType => {
  switch (additionalDocumentType) {
    case "WORKS_ACCEPTANCE":
      return "PV fin de travaux";
    case "VAT_DECLARATION":
      return "Attestation de TVA";
    default:
      return "Document additionnel";
  }
};

const linkedDocumentToString = additionalDocument => {
  if (additionalDocument.invoice) {
    return `Facture ${additionalDocument.invoice.number}`;
  }
  if (additionalDocument.estimate) {
    return `Devis ${additionalDocument.estimate.number}`;
  }
  if (additionalDocument.orderForm) {
    return `Bon de commande ${additionalDocument.orderForm.number}`;
  }
  return "";
};

export default function AdditionalDocumentRowDocumentCell({ additionalDocument, handleLinkedDocumentClick }) {
  const linkedDocument = linkedDocumentToString(additionalDocument);
  return (
    <div>
      {typeToString(additionalDocument.type)}
      {linkedDocument && (
        <Fragment>
          <br />
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleLinkedDocumentClick}
          >
            {linkedDocument}
          </Typography>
        </Fragment>
      )}
    </div>
  );
}
