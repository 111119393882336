import moment from "moment";

class Information {
  details = [];

  discount = "";

  discountMethod = 1;

  limitDate = moment().add(250, "days");

  financingForm = {
    deposit: "",
    computedDeposit: "",
    financingInstitution: "",
    instalmentNumber: "",
    instalmentAmount: "",
    monthlyCostInsurance: "",
    endWorkPayment: "",
    taeg: "",
    overdrawnRate: "",
    totalAmount: ""
  };

  cashForm = {};

  optimus = [];

  constructor({
    details,
    discount,
    discountMethod,
    limitDate,
    financingForm,
    cashForm,
    computedCashFormBalance,
    computedDeposit,
    computedFinancingFormDeposit,
    computedFinancingFormBalance,
    optimus,
    financingType,
    loanAmount
  }) {
    this.details = details;
    this.discount = discount;
    this.discountMethod = discountMethod;
    this.limitDate = limitDate;
    this.financingForm = financingForm;
    this.financingForm.balance = computedFinancingFormBalance;
    this.financingForm.computedDeposit = computedFinancingFormDeposit;
    this.financingForm.financingType = financingType;
    this.financingForm.loanAmount = loanAmount;
    this.cashForm = cashForm;
    this.cashForm.balance = computedCashFormBalance;
    this.cashForm.computedDeposit = computedDeposit;
    this.optimus = optimus;
  }
}

export default Information;
