import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
  FormHelperText
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import { PAYMENT_TYPE } from "../../utils/status";
import { formatNumber } from "../../utils/numberFormat";

export default function NewPaymentForm({ maxPrice, onPaymentSubmitted, payment, submitPayment }) {
  const paymentTypeLabel = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [paymentTypeLabelWidth, setPaymentTypeLabelWidth] = useState(0);
  const PaymentSchema = () =>
    Yup.object().shape({
      date: Yup.string().required("Ce champ est requis"),
      paymentMode: Yup.string().required("Ce champ est requis"),
      comments: Yup.string()
        .nullable()
        .max(500, "Le commentaire ne peut excéder 500 caractères"),
      amount: Yup.number()
        .max(maxPrice, `Ne peut excéder ${formatNumber(maxPrice)} €`)
        .required("Ce champ est requis")
    });

  useEffect(() => {
    setPaymentTypeLabelWidth(paymentTypeLabel.current.offsetWidth);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        date: payment ? payment.date : moment(),
        amount: payment ? payment.amount + maxPrice : maxPrice,
        paymentMode: payment ? payment.paymentMode : "",
        comments: payment ? payment.comments : ""
      }}
      validationSchema={PaymentSchema}
      onSubmit={values => {
        submitPayment(values)
          .then(res => {
            const { date, amount, id, paymentMode, comments } = res;
            onPaymentSubmitted({ date, amount, id, paymentMode, comments });
            enqueueSnackbar("Paiement créé", { variant: "success" });
          })
          .catch(() => {
            enqueueSnackbar("Une erreur est survenue lors de la création du paiement", { variant: "error" });
          });
      }}
    >
      {({ handleChange, values, setFieldValue, handleSubmit, errors, setFieldTouched, touched, isSubmitting }) => (
        <Grid container style={{ paddingTop: 8 }} spacing={1}>
          <Grid item xs={12} sm={4}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
              <DatePicker
                id="newPaymentDate"
                cancelLabel="Annuler"
                format="D MMM YYYY"
                inputVariant="outlined"
                fullWidth
                label="Date *"
                leftArrowIcon={<KeyboardArrowLeftIcon />}
                rightArrowIcon={<KeyboardArrowRightIcon />}
                value={values.date}
                onChange={date => setFieldValue("date", date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth error={errors.paymentMode && touched.paymentMode}>
              <InputLabel ref={paymentTypeLabel}>Sélectionnez un type *</InputLabel>
              <Select
                labelWidth={paymentTypeLabelWidth}
                value={values.paymentMode}
                onChange={e => setFieldValue("paymentMode", e.target.value)}
                onBlur={() => setFieldTouched("paymentMode")}
                input={<OutlinedInput name="paymentMode" id="paymentMode" labelWidth={160} />}
              >
                {Object.keys(PAYMENT_TYPE).map(type => (
                  <MenuItem key={`payment-type-${type}`} value={type}>
                    {PAYMENT_TYPE[type]}
                  </MenuItem>
                ))}
              </Select>
              {errors.paymentMode && touched.paymentMode && <FormHelperText>{errors.paymentMode}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              inputProps={{ min: "0", step: "0.1", max: maxPrice }}
              name="amount"
              label="Montant (TTC)"
              onChange={handleChange}
              required
              type="number"
              value={values.amount}
              variant="outlined"
              onBlur={() => setFieldTouched("amount")}
              error={errors.amount && touched.amount}
              helperText={errors.amount && touched.amount && errors.amount}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={2}
              fullWidth
              name="comments"
              label="Commentaire"
              onChange={handleChange}
              required
              value={values.comments}
              variant="outlined"
              onBlur={() => setFieldTouched("comments")}
              error={errors.comments && touched.comments}
              helperText={errors.comments && touched.comments && errors.comments}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 16 }}>
            <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting}>
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
