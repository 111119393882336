import React, { useEffect, useState } from "react";
import { Paper, Typography, Grid, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

import EstimateSignatureForm from "../components/estimate/EstimateSignatureForm";
import EstimateSignature from "../classes/EstimateSignature";
import NotFoundPage from "./NotFoundPage";

const useStyle = makeStyles(theme => ({
  img: {
    display: "block",
    margin: "auto"
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  centerz: {
    textAlign: "center"
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 300
  },
  iframe: { width: "100%", height: 400, marginTop: theme.spacing(2.5) },
  successTitle: { margin: theme.spacing(1, 0) },
  successContainer: {
    textAlign: "center",
    padding: theme.spacing(3, 2),
    margin: theme.spacing(2)
  },
  pub: {
    textAlign: "center",
    marginTop: theme.spacing(2)
  }
}));

export default function PublicSignaturePage({ match }) {
  const classes = useStyle();
  const [estimateSignature, setEstimateSignature] = useState();
  const [isSignatureComplete, setIsSignatureComplete] = useState(false);
  const [deadlinedSignature, setDeadlinedSignature] = useState(false);

  const getEstimateSignatureSetup = async () => {
    const { params } = match;
    const _estimateSignature = new EstimateSignature(params.estimateId, params.companyId);
    const isValid = await _estimateSignature.canBeSign();
    if (isValid === true) {
      setEstimateSignature(_estimateSignature);
    } else if (isValid === 410) {
      setDeadlinedSignature(true);
    } else {
      setEstimateSignature(null);
    }
  };

  useEffect(() => {
    getEstimateSignatureSetup();
  }, []);

  if (deadlinedSignature) {
    return (
      <div className={classes.loaderContainer}>
        <Grid container>
          <Grid container>
            <Grid container>
              <Grid item sm={1} md={2} lg={3} xl={4} />
              <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                <Paper className={classes.successContainer}>
                  <ClearIcon fontSize="large" style={{ fontSize: 50 }} />
                  <Typography variant="h4" className={classes.successTitle}>
                    Délai dépassé
                  </Typography>
                  <Typography variant="body1">
                    Malheureusement, vous arrivez trop tard pour signer ce document.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={1} md={2} lg={3} xl={4} />
            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
              <Typography variant="body2" className={classes.pub}>
                En savoir plus sur{" "}
                <a href="https://www.kingkang.fr/" target="_blank" rel="noopener noreferrer">
                  KingKang
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
  if (estimateSignature === null) {
    return <NotFoundPage />;
  }
  if (estimateSignature === undefined) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }
  if (isSignatureComplete) {
    return (
      <div className={classes.loaderContainer}>
        <Grid container>
          <Grid container>
            <Grid container>
              <Grid item sm={1} md={2} lg={3} xl={4} />
              <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                <Paper className={classes.successContainer}>
                  <DoneIcon fontSize="large" style={{ fontSize: 50 }} />
                  <Typography variant="h4" className={classes.successTitle}>
                    Félicitations
                  </Typography>
                  <Typography variant="body1">Vous venez de signer numériquement votre devis !</Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={1} md={2} lg={3} xl={4} />
            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
              <Typography variant="body2" className={classes.pub}>
                En savoir plus sur{" "}
                <a href="https://www.kingkang.fr/" target="_blank" rel="noopener noreferrer">
                  KingKang
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <Grid container>
      <Grid container>
        <Grid item sm={1} md={2} lg={3} xl={4} />
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Paper className={`${classes.paper} ${classes.centerz}`}>
            <object data={estimateSignature.getCompanyLogoPath()} style={{ height: 100 }} type="image/png">
              <img src="/images/default_logo.png" height="1" />
            </object>

            <Typography variant="h4" align="center">
              Signature d&apos;un devis
            </Typography>
            <iframe src={estimateSignature.getEstimatePublicUrl()} className={classes.iframe} />
          </Paper>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={1} md={2} lg={3} xl={4} />
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Paper className={`${classes.paper} ${classes.centerz}`}>
            <EstimateSignatureForm
              estimateSignature={estimateSignature}
              onSignatureComplete={() => setIsSignatureComplete(true)}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={1} md={2} lg={3} xl={4} />
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Typography variant="body2" className={classes.pub}>
            Un service proposé par{" "}
            <a href="https://www.kingkang.fr/" target="_blank" rel="noopener noreferrer">
              KingKang
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
