import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withSnackbar } from "notistack";

import CompanyService from "../../services/CompanyService";
import UserService from "../../services/UserService";
import NotFoundPage from "../../pages/NotFoundPage";
import InfoForm from "./InfoForm";

const formatCompany = company => ({
  ...company,
  mainAddressTitle: company.mainAddressTitle || "",
  secondaryAddress: company.secondaryAddress || "",
  secondaryAddressEmail: company.secondaryAddressEmail || "",
  secondaryAddressName: company.secondaryAddressName || "",
  secondaryAddressPhone: company.secondaryAddressPhone || "",
  secondaryAdressTitle: company.secondaryAdressTitle || "",
  secondaryAddressCity: company.secondaryAddressCity || "",
  secondaryAddressZipCode: company.secondaryAddressZipCode || ""
});

@inject("sessionStore")
@observer
class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {
        name: "",
        status: "",
        rcs: "",
        capital: "",
        siret: "",
        tvaNumber: "",
        address: "",
        zipCode: "",
        city: "",
        email: "",
        phone: "",
        insuranceArtisan: false,
        insuranceType: "",
        insuranceName: "",
        insuranceAddress: "",
        mainAddressTitle: "",
        useSecondaryAddress: false,
        secondaryAddress: "",
        secondaryAddressEmail: "",
        secondaryAddressName: "",
        secondaryAddressPhone: "",
        secondaryAddressTitle: "",
        secondaryAddressCity: "",
        secondaryAddressZipCode: ""
      }
    };
  }

  componentDidMount() {
    const { sessionStore } = this.props;
    if (sessionStore.user.company) {
      this.setState({ company: formatCompany(sessionStore.user.company) });
    } else {
      UserService.me().then(response => {
        this.setState({ company: formatCompany(response.company) });
      });
    }
  }

  // handleInputChange = event => {
  //   const { company } = this.state;

  //   const { target } = event;
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   const { name } = target;

  //   this.setState({ company: { ...company, [name]: value } });
  // };

  handleSubmit = formData => {
    const { company } = this.state;
    const { sessionStore } = this.props;
    CompanyService.patchCompany({ ...company, ...formData, id: sessionStore.user.company.id })
      .then(res => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Les informations ont été mises à jour", { variant: "success" });
        sessionStore.patchSessionCompany(res.company);
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour des informations", { variant: "error" });
      });
  };

  render() {
    const { sessionStore } = this.props;
    const { company } = this.state;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["SETTING_WRITE", "SETTING_READ"],
        aclType: "oneof",
        requiredFeatures: ["SETTING"]
      })
    ) {
      return <NotFoundPage />;
    }

    const hasSecondary = sessionStore.userHasAccess({
      requiredFeatures: ["OPTION_SECONDARY_ADDRESS"]
    });

    // const formIsSubmissible =
    //   company.email !== "" &&
    //   ValidatorService.validateEmail(company.email) &&
    //   company.phone !== "" &&
    //   ValidatorService.validatePhone(company.phone) &&
    //   (company.insuranceArtisan === false ||
    //     (company.insuranceArtisan === true &&
    //       company.insuranceType !== "" &&
    //       company.insuranceName !== "" &&
    //       company.insuranceAddress !== "")) &&
    //   (company.useSecondaryAddress === false ||
    //     (company.useSecondaryAddress === true &&
    //       company.secondaryAddressName !== "" &&
    //       company.secondaryAddress !== "" &&
    //       company.secondaryAddressCity !== "" &&
    //       company.secondaryAddressZipCode !== "" &&
    //       company.secondaryAddressEmail !== "" &&
    //       ValidatorService.validateEmail(company.secondaryAddressEmail) &&
    //       company.secondaryAddressPhone !== "" &&
    //       ValidatorService.validatePhone(company.secondaryAddressPhone)));

    const canEdit = sessionStore.userHasAccess({
      requiredAcls: ["SETTING_WRITE"],
      requiredFeatures: ["SETTING"]
    });

    return <InfoForm company={company} hasSecondary={hasSecondary} canEdit={canEdit} onSubmit={this.handleSubmit} />;
    // return (
    //   <Grid container className={classes.container}>
    //     <Grid item xs={12}>
    //       <Typography variant="h4">Général</Typography>
    //     </Grid>
    //     <Grid item xs={12} className={classes.mainWrapper}>
    //       <Grid container spacing={SPACING}>
    //         <Grid item xs={12}>
    //           <Card>
    //             <CardHeader title="Informations générales" />
    //             <CardContent>
    //               <Grid container spacing={SPACING}>
    //                 <Grid item xs={12} sm={6} lg={4}>
    //                   <Typography variant="body2" color="textSecondary" component="p">
    //                     Forme de la société
    //                   </Typography>
    //                   <Typography variant="body1" color="textPrimary" component="p">
    //                     {company.status || ""}
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item xs={12} sm={6} lg={4}>
    //                   <Typography variant="body2" color="textSecondary" component="p">
    //                     Nom
    //                   </Typography>
    //                   <Typography variant="body1" color="textPrimary" component="p">
    //                     {company.name || ""}
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item xs={12} sm={6} lg={4}>
    //                   <Typography variant="body2" color="textSecondary" component="p">
    //                     Capital
    //                   </Typography>
    //                   <Typography variant="body1" color="textPrimary" component="p">
    //                     {company.capital || ""} €
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item xs={12} sm={6} lg={4}>
    //                   <Typography variant="body2" color="textSecondary" component="p">
    //                     Numéro de TVA Intracommunautaire
    //                   </Typography>
    //                   <Typography variant="body1" color="textPrimary" component="p">
    //                     {company.tvaNumber || ""}
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item xs={12} sm={6} lg={4}>
    //                   <Typography variant="body2" color="textSecondary" component="p">
    //                     RCS
    //                   </Typography>
    //                   <Typography variant="body1" color="textPrimary" component="p">
    //                     {company.rcs || ""}
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item xs={12} sm={6} lg={4}>
    //                   <Typography variant="body2" color="textSecondary" component="p">
    //                     SIRET
    //                   </Typography>
    //                   <Typography variant="body1" color="textPrimary" component="p">
    //                     {company.siret || ""}
    //                   </Typography>
    //                 </Grid>
    //               </Grid>
    //             </CardContent>
    //           </Card>
    //         </Grid>

    //         <Grid item xs={12}>
    //           <Card>
    //             <CardHeader title="Coordonnées principales de l'entreprise" />
    //             <CardContent>
    //               <Grid container spacing={SPACING}>
    //                 <Grid item xs={12}>
    //                   <TextField
    //                     name="mainAddressTitle"
    //                     placeholder="Entreprise"
    //                     label="Titre de l'adresse"
    //                     fullWidth
    //                     variant="outlined"
    //                     value={company.mainAddressTitle}
    //                     onChange={this.handleInputChange}
    //                     disabled={!canEdit}
    //                     helperText="Par défaut : Entreprise"
    //                   />
    //                 </Grid>
    //                 <Grid item xs={12}>
    //                   <Typography variant="body2" color="textSecondary" component="p">
    //                     Nom
    //                   </Typography>
    //                   <Typography variant="body1" color="textPrimary" component="p">
    //                     {company.name || ""}
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item xs={12}>
    //                   <Typography variant="body2" color="textSecondary" component="p">
    //                     Adresse de l&apos;entreprise
    //                   </Typography>
    //                   <Typography variant="body1" color="textPrimary" component="p">
    //                     {company.address || ""}
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item xs={12} sm={4}>
    //                   <Typography variant="body2" color="textSecondary" component="p">
    //                     Code postal
    //                   </Typography>
    //                   <Typography variant="body1" color="textPrimary" component="p">
    //                     {company.zipCode || ""}
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item xs={12} sm={8}>
    //                   <Typography variant="body2" color="textSecondary" component="p">
    //                     Commune
    //                   </Typography>
    //                   <Typography variant="body1" color="textPrimary" component="p">
    //                     {company.city || ""}
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item xs={12} sm={6}>
    //                   <TextField
    //                     name="email"
    //                     placeholder="john.doe@example.org"
    //                     label="Adresse email"
    //                     required
    //                     fullWidth
    //                     variant="outlined"
    //                     value={company.email}
    //                     onChange={this.handleInputChange}
    //                     error={company.email.length > 0 && !ValidatorService.validateEmail(company.email)}
    //                     type="email"
    //                     disabled={!canEdit}
    //                   />
    //                 </Grid>
    //                 <Grid item xs={12} sm={6}>
    //                   <TextField
    //                     name="phone"
    //                     placeholder="06XXXXXXXX"
    //                     label="Téléphone"
    //                     required
    //                     fullWidth
    //                     variant="outlined"
    //                     value={company.phone}
    //                     onChange={this.handleInputChange}
    //                     error={company.phone.length > 0 && !ValidatorService.validatePhone(company.phone)}
    //                     type="tel"
    //                     disabled={!canEdit}
    //                   />
    //                 </Grid>
    //               </Grid>
    //             </CardContent>
    //           </Card>
    //         </Grid>
    //         {hasSecondary && (
    //           <Grid item xs={12}>
    //             <Card>
    //               <CardHeader title="Coordonnées secondaires" />
    //               <CardContent>
    //                 <Grid container spacing={SPACING}>
    //                   <Grid item xs={12} sm={6}>
    //                     <FormControlLabel
    //                       control={
    //                         <Checkbox
    //                           name="useSecondaryAddress"
    //                           checked={company.useSecondaryAddress}
    //                           onChange={this.handleInputChange}
    //                           color="primary"
    //                           disabled={!canEdit}
    //                         />
    //                       }
    //                       label="Afficher une adresse secondaire sur les documents"
    //                     />
    //                   </Grid>
    //                   {company.useSecondaryAddress && (
    //                     <Fragment>
    //                       <Grid item xs={12}>
    //                         <TextField
    //                           name="secondaryAddressTitle"
    //                           placeholder="Entreprise"
    //                           label="Titre de l'adresse"
    //                           fullWidth
    //                           variant="outlined"
    //                           value={company.secondaryAddressTitle}
    //                           onChange={this.handleInputChange}
    //                           disabled={!canEdit}
    //                           helperText="Par défaut : Entreprise"
    //                         />
    //                       </Grid>
    //                       <Grid item xs={12}>
    //                         <TextField
    //                           name="secondaryAddressName"
    //                           label="Nom"
    //                           fullWidth
    //                           variant="outlined"
    //                           value={company.secondaryAddressName}
    //                           onChange={this.handleInputChange}
    //                           disabled={!canEdit}
    //                           required
    //                           error={!company.secondaryAddressName}
    //                         />
    //                       </Grid>
    //                       <Grid item xs={12}>
    //                         <TextField
    //                           name="secondaryAddress"
    //                           label="Adresse"
    //                           fullWidth
    //                           variant="outlined"
    //                           required
    //                           error={!company.secondaryAddress}
    //                           value={company.secondaryAddress}
    //                           onChange={this.handleInputChange}
    //                           disabled={!canEdit}
    //                         />
    //                       </Grid>
    //                       <Grid item xs={12} sm={4}>
    //                         <TextField
    //                           name="secondaryAddressZipCode"
    //                           label="Code postal"
    //                           fullWidth
    //                           variant="outlined"
    //                           value={company.secondaryAddressZipCode}
    //                           onChange={this.handleInputChange}
    //                           disabled={!canEdit}
    //                           required
    //                           error={!company.secondaryAddressZipCode}
    //                         />
    //                       </Grid>
    //                       <Grid item xs={12} sm={8}>
    //                         <TextField
    //                           name="secondaryAddressCity"
    //                           label="Commune"
    //                           fullWidth
    //                           variant="outlined"
    //                           value={company.secondaryAddressCity}
    //                           onChange={this.handleInputChange}
    //                           disabled={!canEdit}
    //                           required
    //                           error={!company.secondaryAddressCity}
    //                         />
    //                       </Grid>
    //                       <Grid item xs={12} sm={6}>
    //                         <TextField
    //                           name="secondaryAddressEmail"
    //                           label="Adresse email"
    //                           fullWidth
    //                           variant="outlined"
    //                           value={company.secondaryAddressEmail}
    //                           onChange={this.handleInputChange}
    //                           error={
    //                             !company.secondaryAddressEmail ||
    //                             (company.secondaryAddressEmail.length > 0 &&
    //                               !ValidatorService.validateEmail(company.secondaryAddressEmail))
    //                           }
    //                           type="email"
    //                           disabled={!canEdit}
    //                           required
    //                         />
    //                       </Grid>
    //                       <Grid item xs={12} sm={6}>
    //                         <TextField
    //                           name="secondaryAddressPhone"
    //                           label="Téléphone"
    //                           fullWidth
    //                           variant="outlined"
    //                           value={company.secondaryAddressPhone}
    //                           onChange={this.handleInputChange}
    //                           error={
    //                             !company.secondaryAddressPhone ||
    //                             (company.secondaryAddressPhone.length > 0 &&
    //                               !ValidatorService.validatePhone(company.secondaryAddressPhone))
    //                           }
    //                           type="tel"
    //                           disabled={!canEdit}
    //                           required
    //                         />
    //                       </Grid>
    //                     </Fragment>
    //                   )}
    //                 </Grid>
    //               </CardContent>
    //             </Card>
    //           </Grid>
    //         )}

    //         <Grid item xs={12}>
    //           <Card>
    //             <CardHeader title="Assurance obligatoire" />
    //             <CardContent>
    //               <Grid container spacing={SPACING}>
    //                 <Grid item xs={12} sm={6}>
    //                   <FormControlLabel
    //                     control={
    //                       <Checkbox
    //                         name="insuranceArtisan"
    //                         checked={company.insuranceArtisan}
    //                         onChange={this.handleInputChange}
    //                         color="primary"
    //                         disabled={!canEdit}
    //                       />
    //                     }
    //                     label="Je suis soumis à l'assurance obligatoire, au sens de la réglementation"
    //                   />
    //                 </Grid>
    //                 <Grid item xs={12} sm={6}>
    //                   <TextField
    //                     name="insuranceType"
    //                     placeholder="Type d'assurance"
    //                     label="Type d'assurance"
    //                     fullWidth
    //                     variant="outlined"
    //                     value={company.insuranceType || ""}
    //                     onChange={this.handleInputChange}
    //                     required={company.insuranceArtisan}
    //                     disabled={!company.insuranceArtisan || !canEdit}
    //                   />
    //                 </Grid>
    //                 <Grid item xs={12} sm={6}>
    //                   <TextField
    //                     name="insuranceName"
    //                     placeholder="Assureur"
    //                     label="Nom de l'assureur"
    //                     fullWidth
    //                     variant="outlined"
    //                     value={company.insuranceName || ""}
    //                     onChange={this.handleInputChange}
    //                     required={company.insuranceArtisan}
    //                     disabled={!company.insuranceArtisan || !canEdit}
    //                   />
    //                 </Grid>
    //                 <Grid item xs={12} sm={6}>
    //                   <TextField
    //                     name="insuranceAddress"
    //                     placeholder="Rue de Rouen, 76000 ROUEN"
    //                     label="Adresse de l'assurance"
    //                     fullWidth
    //                     variant="outlined"
    //                     value={company.insuranceAddress || ""}
    //                     onChange={this.handleInputChange}
    //                     required={company.insuranceArtisan}
    //                     disabled={!company.insuranceArtisan || !canEdit}
    //                   />
    //                 </Grid>
    //               </Grid>
    //             </CardContent>
    //           </Card>
    //         </Grid>
    //       </Grid>

    //       {canEdit && (
    //         <Grid container className={classes.container} justify="center">
    //           <Grid item xs={12} sm={2}>
    //             <Button
    //               className={classes.button}
    //               variant="contained"
    //               color="primary"
    //               onClick={this.handleSubmit}
    //               disabled={!formIsSubmissible}
    //             >
    //               Enregistrer
    //             </Button>
    //           </Grid>
    //         </Grid>
    //       )}
    //     </Grid>
    //   </Grid>
    // );
  }
}

export default withSnackbar(Info);
