import moment from "moment";

import request, { METHOD } from "../utils/request";

class OrderFormService {
  static STATUS = {
    PROGRESS: "En cours",
    SIGNED: "Signé",
    REFUSE: "Annulé"
  };

  static signatureDeadline = orderForm => {
    if (orderForm.signatureRequestExpirationDate) {
      return moment(orderForm.signatureRequestExpirationDate);
    }
    if (orderForm.deadlineSignature) {
      return moment(orderForm.deadlineSignature);
    }
    return moment(orderForm.createAt).add(100, "hours");
  };

  static signatureExpired = orderForm => OrderFormService.signatureDeadline(orderForm).isBefore(moment(), "second");

  static getOrderForms({ page = 0, sort = "number", order = "DESC", filter = {} }) {
    const { q = "", userId = "", status = "", from = "", to = "", client = "" } = filter;
    return request({
      url: `/orders?page=${page}&sort=${sort}&order=${order}&q=${q}&userId=${userId}&status=${status}&from=${from}&to=${to}&contactId=${client}`,
      method: METHOD.GET
    });
  }

  static getOrderForm(id) {
    return request({
      url: `/orders/${id}`,
      method: METHOD.GET
    });
  }

  static postOrderForm(orderForm) {
    return request({
      url: "/orders",
      method: METHOD.POST,
      data: orderForm
    });
  }

  static signOrderForm(orderFormId) {
    return request({
      url: `/orders/${orderFormId}/initSign`,
      method: METHOD.POST
    });
  }

  static sendForSignatureWithYouSign(orderForm, data) {
    return request({
      url: `/orders/${orderForm.id}/initYouSign`,
      method: METHOD.POST,
      data
    });
  }

  static getOrderFormSignature(orderFormId) {
    return request({
      url: `/orders/${orderFormId}/signature`,
      method: METHOD.GET
    });
  }

  static cancelOrderForm(orderForm) {
    return request({
      url: `/orders/${orderForm.id}`,
      method: METHOD.PATCH,
      data: {
        deleted: true,
        status: "CANCEL"
      }
    });
  }

  static deleteOrderForm(orderForm) {
    return request({
      url: `/orders/${orderForm.id}`,
      method: METHOD.DELETE
    });
  }

  static downloadPDF(href) {
    return request({
      url: href,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static downloadOrderFormPDF(orderForm) {
    return request({
      url: `/orders/${orderForm.id}/downloadPDF`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static downloadRawPDF(orderId) {
    return request({
      url: `/orders/${orderId}/downloadRawPDF`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static intoInvoice(orderForm) {
    return request({
      url: `/orders/${orderForm.id}/invoice`,
      method: METHOD.POST
    });
  }

  static addAdditionalDocument(orderForm, type) {
    return request({
      url: `/orders/${orderForm.id}/additionalDocuments`,
      method: METHOD.POST,
      data: { type }
    });
  }
}

export default OrderFormService;
