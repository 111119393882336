import request, { METHOD } from "../utils/request";

class CompanyService {
  static upload(logo) {
    return request({
      url: "/companies/upload",
      method: METHOD.POST,
      data: logo
    });
  }

  static uploadLogo(logo) {
    return request({
      url: "/companies/upload",
      method: METHOD.POST,
      data: logo
    });
  }

  static deleteLogo({ type, name }) {
    return request({
      url: `/companies/${type}/${name}`,
      method: METHOD.DELETE
    });
  }

  static patchCompany(company) {
    return request({
      url: `/companies/${company.id}`,
      method: METHOD.PATCH,
      data: company
    });
  }

  static downloadPDF(documentName) {
    return request({
      url: `/companies/download/${documentName}`,
      method: METHOD.GET,
      responseType: "arraybuffer"
    });
  }

  static setColors(colors) {
    return request({
      url: `/companies/colors`,
      method: METHOD.POST,
      data: colors
    });
  }

  static getUsers() {
    return request({
      url: "/companies/users",
      method: METHOD.GET
    });
  }

  static getDocumentTemplates() {
    return request({
      url: "/companies/templates",
      method: METHOD.GET
    });
  }

  static addUser(user) {
    return request({
      url: "/companies/users",
      method: METHOD.POST,
      data: { ...user, acl: [] }
    });
  }

  static updateUser(user) {
    return request({
      url: `/companies/users/${user.id}`,
      method: METHOD.PATCH,
      data: user
    });
  }

  static deleteUser(user) {
    return request({
      url: `/companies/users/${user.id}`,
      method: METHOD.DELETE
    });
  }

  static getProfiles() {
    return request({
      url: `/profils`,
      method: METHOD.GET
    });
  }

  static addProfile(profile) {
    return request({
      url: `/profils`,
      method: METHOD.POST,
      data: profile
    });
  }

  static updateProfile(profile) {
    return request({
      url: `/profils/${profile.id}`,
      method: METHOD.PATCH,
      data: profile
    });
  }

  static deleteProfile(profile) {
    return request({
      url: `/profils/${profile.id}`,
      method: METHOD.DELETE
    });
  }

  static setUserProfile(userId, profilId) {
    return request({
      url: `/companies/users/${userId}/setProfil`,
      method: METHOD.PATCH,
      data: { profilId }
    });
  }

  static setUserAcls(userId, acl) {
    return request({
      url: `/companies/users/${userId}/acl`,
      method: METHOD.PATCH,
      data: { acl }
    });
  }

  static getCompanyVariantByType(type) {
    return request({
      url: `/companies/variants?type=${type}&limit=1`,
      method: METHOD.GET
    });
  }

  static addVariant({ type, paymentDelay, paymentPenalty, paymentTerms, contactId }) {
    return request({
      url: "/companies/variants",
      method: METHOD.POST,
      data: { type, paymentDelay, paymentPenalty, paymentTerms, contactId }
    });
  }

  static patchVariant(variantId, { type, paymentDelay, paymentPenalty, paymentTerms, contactId }) {
    return request({
      url: `/companies/variants/${variantId}`,
      method: METHOD.PATCH,
      data: { type, paymentDelay, paymentPenalty, paymentTerms, contactId }
    });
  }

  static deleteVariant(variantId) {
    return request({
      url: `/companies/variants/${variantId}`,
      method: METHOD.DELETE
    });
  }
}

export default CompanyService;
