import React, { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  withStyles
} from "@material-ui/core";
import GetApp from "@material-ui/icons/GetApp";
import { useSnackbar, withSnackbar } from "notistack";
import moment from "moment";
import { inject, observer } from "mobx-react";

import AdditionalDocumentService from "../../services/AdditionalDocumentService";
import AdditionalDocumentStatusLabel from "../additionalDocument/AdditionalDocumentStatusLabel";

const styles = () => ({ chip: { color: "white" } });

const typeToString = additionalDocumentType => {
  switch (additionalDocumentType) {
    case "WORKS_ACCEPTANCE":
      return "PV fin de travaux";
    case "VAT_DECLARATION":
      return "Attestation de TVA";
    default:
      return "Document additionnel";
  }
};

function LinkedAdditionalDocumentsDialog({ onClose, open, orderForm, estimate, invoice, classes, sessionStore }) {
  const [downloadHref, setDownloadHref] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const userCanSeeAdditionalDocument = sessionStore.userHasAccess({
    requiredFeatures: ["ADDITIONAL_DOCUMENT"],
    requiredAcls: ["ADDITIONAL_DOCUMENT_READ"]
  });

  const downloadPDF = additionalDocument => {
    AdditionalDocumentService.getAdditionalDocumentPDF(additionalDocument.id)
      .then(res => {
        const _downloadHref = window.URL.createObjectURL(res);
        setDownloadHref(_downloadHref);
      })
      .catch(err => {
        if (err && err.status && err.status === 406) {
          enqueueSnackbar("La saisie ne permet pas de télécharger le document, veuillez modifier celui-ci", {
            variant: "error"
          });
        } else {
          enqueueSnackbar("Une erreur est survenue lors du téléchargement du document", {
            variant: "error"
          });
        }
      });
  };

  const _document = orderForm || estimate || invoice;

  const simulateClick = e => {
    // Avoid a blank page by checking if e is null or not.
    // Just remove the if condition to check the behavior.
    if (e !== null) {
      e.click();
    }
    setDownloadHref("");
  };

  return (
    <Dialog modal="false" open={open} onClose={onClose}>
      {_document && _document.additionalDocuments && _document.additionalDocuments.length > 0 ? (
        <Fragment>
          {orderForm && (
            <DialogTitle>
              {_document.additionalDocuments.length > 1
                ? `${_document.additionalDocuments.length} documents liés`
                : "1 document lié"}{" "}
              au bon {orderForm.number}
            </DialogTitle>
          )}
          {estimate && (
            <DialogTitle>
              {_document.additionalDocuments.length > 1
                ? `${_document.additionalDocuments.length} documents liés`
                : "1 document lié"}{" "}
              au devis {estimate.number}
            </DialogTitle>
          )}
          {invoice && (
            <DialogTitle>
              {_document.additionalDocuments.length > 1
                ? `${_document.additionalDocuments.length} documents liés`
                : "1 document lié"}{" "}
              à la facture {invoice.number}
            </DialogTitle>
          )}

          <DialogContent style={{ minWidth: 300 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Télécharger</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_document.additionalDocuments.map(additionalDocument => (
                  <TableRow key={additionalDocument.id}>
                    <TableCell>{moment(additionalDocument.createAt).format("DD/MM/YYYY")}</TableCell>
                    <TableCell>{typeToString(additionalDocument.type)}</TableCell>
                    <TableCell>
                      <AdditionalDocumentStatusLabel additionalDocument={additionalDocument} classes={classes} />
                    </TableCell>
                    <TableCell>
                      {(additionalDocument.user.id === sessionStore.user.id || userCanSeeAdditionalDocument) && (
                        <div
                          style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center" }}
                        >
                          <GetApp onClick={() => downloadPDF(additionalDocument)} />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
        </Fragment>
      ) : (
        <CircularProgress size={40} />
      )}
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined" autoFocus>
          Fermer
        </Button>
      </DialogActions>
      {downloadHref !== "" && (
        <a ref={simulateClick} href={downloadHref} download="Document.pdf" style={{ display: "none" }}>
          Download
        </a>
      )}
    </Dialog>
  );
}

export default withStyles(styles)(
  withSnackbar(inject("sessionStore")(observer(props => <LinkedAdditionalDocumentsDialog {...props} />)))
);
