import React, { memo, useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Chip, CircularProgress, Fab, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MailIcon from "@material-ui/icons/Mail";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import RoomIcon from "@material-ui/icons/Room";
import { grey } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import { withRouter } from "react-router";
import moment from "moment";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import UpdateIcon from "@material-ui/icons/Update";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useSnackbar } from "notistack";

import AdminService from "../../../services/AdminService";
import { getColorFromInvoiceDate, getColorFromSignatures } from "../../../utils/admin";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block",
    textDecoration: "none"
  },
  card: { height: "100%" },
  row: {
    display: "flex",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  rowPicto: {
    flexBasis: 40,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  companyItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  chip: {
    minWidth: 120
  },
  chipLabel: {
    flex: 2,
    textAlign: "center"
  },
  chipWrapper: {
    paddingTop: theme.spacing(),
    display: "flex",
    alignItems: "center"
  }
}));

function CompanyInformations({ company, history }) {
  const [nextInvoiceDate, setNextInvoiceDate] = useState(company.nextInvoiceDate);
  const [showInvoiceDatePicker, setShowInvoiceDatePicker] = useState(false);
  const [isUpdatingInvoiceDate, setIsUpdatingInvoiceDate] = useState(false);
  const [invoiceDateBg, setInvoiceDateBg] = useState("#e0e0e0");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleEditCompany = () => {
    history.push(`/superbg/entreprises/details/${company.id}/modification`);
  };

  const handleNextInvoiceDateChange = newLogDate => {
    setIsUpdatingInvoiceDate(true);
    AdminService.patchCompany({ ...company, nextInvoiceDate: newLogDate })
      .then(() => {
        setNextInvoiceDate(newLogDate);
        setIsUpdatingInvoiceDate(false);
        enqueueSnackbar("La date de prochaine facture a été modifiée", { variant: "success" });
      })
      .catch(() => {
        setIsUpdatingInvoiceDate(false);
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de la date de prochaine facture", {
          variant: "error"
        });
      });
  };

  useEffect(() => {
    setInvoiceDateBg(getColorFromInvoiceDate(nextInvoiceDate));
  }, [nextInvoiceDate]);

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Contact
            </Typography>
            <div>
              <div className={classes.row}>
                <div className={classes.rowPicto}>
                  <MailIcon fontSize="small" />
                </div>
                <div>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="a"
                    href={`mailto:${company.email}`}
                    className={classes.link}
                  >
                    {company.email}
                  </Typography>
                </div>
              </div>
              <div className={classes.rowWithPicto}>
                <div className={classes.row}>
                  <div className={classes.rowPicto}>
                    <PermPhoneMsgIcon fontSize="small" />
                  </div>
                  <div>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="a"
                      href={`tel:${company.phone}`}
                      className={classes.link}
                    >
                      {company.phone}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={classes.rowWithPicto}>
                <div className={classes.row}>
                  <div className={classes.rowPicto}>
                    <RoomIcon fontSize="small" />
                  </div>
                  <div>
                    <a
                      href={`http://maps.google.com/?q=${company.address} ${company.zipCode} ${company.city}`}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography variant="body2" color="textSecondary" component="span">
                        {company.address}
                      </Typography>
                      <br />
                      <Typography variant="body2" color="textSecondary" component="span">
                        {company.zipCode} {company.city}
                      </Typography>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Entreprise
            </Typography>
            <Grid container>
              <Grid item xs={6} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  SIRET
                </Typography>
                <Typography variant="body1" color="textPrimary" component="p">
                  {company.siret}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  TVA
                </Typography>
                <Typography variant="body1" color="textPrimary" component="p">
                  {company.tvaNumber}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  RCS
                </Typography>
                <Typography variant="body1" color="textPrimary" component="p">
                  {company.rcs}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Capital
                </Typography>
                <Typography variant="body1" color="textPrimary" component="p">
                  {company.capital} €
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Dates
            </Typography>
            <Grid container>
              <Grid item xs={6} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Souscription
                </Typography>
                <div className={classes.chipWrapper}>
                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    avatar={<EventAvailableIcon />}
                    label={moment(company.createAt).format("DD/MM/YYYY")}
                  />
                </div>
              </Grid>
              <Grid item xs={6} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Facturation
                </Typography>
                <div className={classes.chipWrapper}>
                  <Chip
                    onClick={() => {
                      setShowInvoiceDatePicker(true);
                    }}
                    style={{ backgroundColor: invoiceDateBg }}
                    className={classes.chip}
                    classes={{ label: classes.chipLabel }}
                    avatar={<UpdateIcon style={{ color: "rgba(0,0,0,0.5)" }} />}
                    label={nextInvoiceDate ? moment(nextInvoiceDate).format("DD/MM/YYYY") : "-"}
                  />
                  {isUpdatingInvoiceDate && <CircularProgress size={20} style={{ marginLeft: 16 }} />}
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      className={classes.picker}
                      style={{ display: "none" }}
                      onChange={handleNextInvoiceDateChange}
                      value={nextInvoiceDate}
                      variant="dialog"
                      inputVariant="standard"
                      open={showInvoiceDatePicker}
                      onClose={() => {
                        setShowInvoiceDatePicker(false);
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Information consommation
            </Typography>
            <Grid container>
              <Grid item xs={12} className={classes.companyItem}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Card style={{ height: "100%" }}>
                      <CardHeader subheader="Fournisseur" />
                      <CardContent>
                        <Typography style={{ textAlign: "center", marginBottom: 24 }}>
                          <Typography variant="h4" component="span">
                            {company.signatureProvider}
                          </Typography>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.plan}>
                      <CardHeader subheader="Abonnement" />
                      <CardContent>
                        <Typography style={{ textAlign: "center", marginBottom: 24 }}>
                          <Typography variant="h2" component="span">
                            {company.plan}
                          </Typography>
                          <Typography variant="body1" component="span">
                            /mois
                          </Typography>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.available}>
                      <CardHeader subheader="Signatures disponibles">404</CardHeader>
                      <CardContent>
                        <Typography style={{ textAlign: "center", marginBottom: 24 }}>
                          <Typography
                            variant="h2"
                            component="span"
                            style={{ color: getColorFromSignatures(company.signatures, company.plan) }}
                          >
                            {company.signatures}
                          </Typography>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Paramètres
            </Typography>
            <Grid container>
              {/* <Grid item xs={12} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Forfait
                </Typography>
                <Typography variant="body1" color="textPrimary" component="p">
                  {company.plan ? `${company.plan} signature${company.plan > 1 && "s"} par mois` : "-"}
                </Typography>
              </Grid> */}
              <Grid item xs={12} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Délai de paiement
                </Typography>
                <Typography variant="body1" color="textPrimary" component="p">
                  {company.paymentDelay ? `Dans un délai de ${company.paymentDelay} jours` : "À réception"}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Modalités
                </Typography>
                <Typography variant="body1" color="textPrimary" component="p">
                  {company.paymentTerms}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Pénalités
                </Typography>
                <Typography variant="body1" color="textPrimary" component="p">
                  {company.paymentPenalty}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.companyItem}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Couleurs
                </Typography>
                <Chip
                  style={{ textTransform: "uppercase", marginRight: 5, marginTop: 5 }}
                  variant="outlined"
                  avatar={
                    <span
                      style={{
                        backgroundColor:
                          company.color && company.color.primaryColor ? company.color.primaryColor : "#1976d2",
                        borderRadius: 16
                      }}
                    />
                  }
                  label={company.color && company.color.primaryColor ? company.color.primaryColor : "#1976d2"}
                />
                <Chip
                  style={{ textTransform: "uppercase", marginTop: 5 }}
                  variant="outlined"
                  avatar={
                    <span
                      style={{
                        backgroundColor:
                          company.color && company.color.secondaryColor ? company.color.secondaryColor : grey[800],
                        borderRadius: 16
                      }}
                    />
                  }
                  label={company.color && company.color.secondaryColor ? company.color.secondaryColor : grey[800]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={handleEditCompany}
        title="Modifier l'entreprise"
      >
        <EditIcon />
      </Fab>
    </Grid>
  );
}

export default memo(withRouter(CompanyInformations));
