import React from "react";
import AssignmentLateOutlined from "@material-ui/icons/AssignmentLateOutlined";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedInOutlined";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { Grid } from "@material-ui/core";

import Widget from "../Widget";
import { formatCurrency } from "../../../utils/numberFormat";
import { ORDERFROM_STATUS } from "../../../utils/status";

const OrderFormsWidgets = ({ ordersData }) => {
  const data = [
    {
      title: ORDERFROM_STATUS.DRAFT.dashboardLabel,
      count: ordersData.DRAFT.count,
      amount: formatCurrency(ordersData.DRAFT.amountWithoutTVA),
      color: ORDERFROM_STATUS.DRAFT.color,
      icon: <ScheduleIcon fontSize="large" style={{ color: "#FFF" }} />
    },
    {
      title: ORDERFROM_STATUS.SIGNED.dashboardLabel,
      count: ordersData.SIGNED.count,
      amount: formatCurrency(ordersData.SIGNED.amountWithoutTVA),
      color: ORDERFROM_STATUS.SIGNED.color,
      icon: <AssignmentTurnedIn fontSize="large" style={{ color: "#FFF" }} />
    },
    {
      title: ORDERFROM_STATUS.INVOICED.dashboardLabel,
      count: ordersData.INVOICED.count,
      amount: formatCurrency(ordersData.INVOICED.amountWithoutTVA),
      color: ORDERFROM_STATUS.INVOICED.color,
      icon: <AssignmentLateOutlined fontSize="large" style={{ color: "#FFF" }} />
    },
    {
      title: ORDERFROM_STATUS.CANCEL.dashboardLabel,
      count: ordersData.CANCEL.count,
      amount: formatCurrency(ordersData.CANCEL.amountWithoutTVA),
      color: ORDERFROM_STATUS.CANCEL.color,
      icon: <AssignmentLateOutlined fontSize="large" style={{ color: "#FFF" }} />
    }
  ];

  return (
    <Grid container spacing={3}>
      {data.map(element => (
        <Grid item xs={12} sm={6} key={element.title}>
          <Widget
            title={element.title}
            amount={element.amount}
            count={element.count}
            color={element.color}
            icon={element.icon}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default OrderFormsWidgets;
