import Paper from "@material-ui/core/Paper";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  container: {
    display: "flex",
    height: "100%"
  },
  paper: {
    height: "100%",
    overflow: "hidden"
  },
  icon: {
    alignItems: "center",
    color: "#FFF",
    display: "flex",
    flex: "0 0 100px",
    justifyContent: "center"
  },
  right: {
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "10px 15px 10px 0"
  }
};

function Widget(props) {
  const { classes, title, color, icon, amount, count } = props;
  return (
    <Paper className={classes.paper}>
      <div
        className={classes.container}
        style={{
          backgroundColor: color,
          flexDirection: "column",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div>{icon}</div>
          <div style={{ flex: 2 }}>
            <Typography variant="h4" style={{ color: "#FFF", textAlign: "right" }}>
              {count}
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div>
            <Typography variant="body2" style={{ color: "#FFF" }}>
              {title}
            </Typography>
          </div>
          <div style={{ flex: 2 }}>
            <Typography variant="body1" style={{ color: "#FFF", textAlign: "right" }}>
              {amount}
            </Typography>
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default withStyles(styles)(Widget);
