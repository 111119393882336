import React from "react";
import { MenuItem, Divider } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";

import TableAction from "../TableAction";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";
import { privateRoutes } from "../../routes";

function InvoiceListActionsRow({
  invoice,
  sessionStore,
  onOpen,
  onClose,
  onPreviewPDF,
  onValidateInvoice,
  onSendInvoice,
  onMarkInvoiceAsSent,
  onDownloadPDF,
  handleShowPaymentsManager,
  handleDeletion,
  onDeleteOldDraft,
  transformIntoCreditNote,
  duplicateInvoice,
  addAdditionalDocument,
  handleShowLinkedAdditionalDocumentsDialog
}) {
  const isUsersInvoice = invoice.user.id === sessionStore.user.id;
  const userCanRead =
    isUsersInvoice ||
    sessionStore.userHasAccess({
      requiredAcls: ["INVOICE_READ"],
      requiredFeatures: ["INVOICE"]
    });
  const userCanWrite =
    isUsersInvoice ||
    sessionStore.userHasAccess({
      requiredAcls: ["INVOICE_WRITE"],
      requiredFeatures: ["INVOICE"]
    });
  const userCanAdditionalDocument = sessionStore.userHasAccess({
    requiredFeatures: ["ADDITIONAL_DOCUMENT"],
    requiredAcls: ["ADDITIONAL_DOCUMENT_MY"]
  });
  const userCanEndWorksAcceptance =
    userCanAdditionalDocument &&
    sessionStore.userHasAccess({
      requiredFeatures: ["OPTION_ADDITIONAL_DOCUMENT_WORKS_ACCEPTANCE"]
    });
  const userCanVatDeclaration =
    userCanAdditionalDocument &&
    sessionStore.userHasAccess({
      requiredFeatures: ["OPTION_ADDITIONAL_DOCUMENT_VAT_DECLARATION"]
    });

  return (
    <TableAction onOpen={onOpen} onClose={onClose}>
      {userCanRead && <MenuItem onClick={onPreviewPDF}>Prévisualiser</MenuItem>}
      {invoice.status === "DRAFT" &&
        userCanWrite && [
          <ConfirmationDialog
            key="validate-invoice"
            situationText="En validant cette facture, vous ne pourrez plus la modifier. Êtes-vous sûr ?"
          >
            {confirmBeforeAct => (
              <MenuItem
                onClick={e => {
                  stopPropagationForConfirmation(
                    e,
                    confirmBeforeAct(() => onValidateInvoice())
                  );
                }}
              >
                Valider
              </MenuItem>
            )}
          </ConfirmationDialog>,
          <MenuItem
            key="edit-invoice"
            component={Link}
            to={privateRoutes.admin.invoices.editionForm.path.replace(":invoiceId", invoice.id)}
          >
            Modifier
          </MenuItem>,
          <Divider key="edit-validate-invoice" />
        ]}

      {invoice.status !== "DRAFT" && invoice.status !== "CANCEL" && userCanWrite && (
        <ConfirmationDialog situationText="Êtes-vous sûr de vouloir envoyer cette facture au client ?">
          {confirmBeforeAct => (
            <MenuItem
              onClick={e => {
                stopPropagationForConfirmation(
                  e,
                  confirmBeforeAct(() => onSendInvoice())
                );
              }}
            >
              Envoyer par e-mail
            </MenuItem>
          )}
        </ConfirmationDialog>
      )}
      {invoice.status !== "DRAFT" && invoice.status !== "CANCEL" && invoice.status !== "SENT" && userCanWrite && (
        <MenuItem onClick={() => onMarkInvoiceAsSent({ invoice, status: "SENT" })}>Marquer comme envoyée</MenuItem>
      )}
      {invoice.status !== "DRAFT" &&
        invoice.status !== "CREDIT_NOTE" &&
        userCanAdditionalDocument &&
        userCanEndWorksAcceptance && [
          <Divider key="additional-documents-divider" />,
          invoice.additionalDocuments && invoice.additionalDocuments.length > 0 ? (
            <MenuItem key="additional-documents-list" onClick={() => handleShowLinkedAdditionalDocumentsDialog()}>
              Documents liés
            </MenuItem>
          ) : null,
          userCanWrite ? (
            <MenuItem key="ad-works-acceptance" onClick={() => addAdditionalDocument("WORKS_ACCEPTANCE")}>
              Créer un PV de fin de travaux
            </MenuItem>
          ) : null,
          userCanVatDeclaration ? (
            <MenuItem key="ad-vat-declaration" onClick={() => addAdditionalDocument("VAT_DECLARATION")}>
              Créer une attestation de TVA
            </MenuItem>
          ) : null,
          <Divider key="additional-documents-divider-after" />
        ]}
      {invoice.status === "DRAFT" && userCanWrite && (
        <ConfirmationDialog situationText="En envoyant cette facture, vous ne pourrez plus la modifier. Êtes-vous sûr ?">
          {confirmBeforeAct => (
            <MenuItem
              onClick={e => {
                stopPropagationForConfirmation(
                  e,
                  confirmBeforeAct(() => onSendInvoice())
                );
              }}
            >
              Envoyer par e-mail
            </MenuItem>
          )}
        </ConfirmationDialog>
      )}
      {userCanRead && <MenuItem onClick={() => onDownloadPDF()}>Télécharger</MenuItem>}
      {userCanWrite &&
        ((invoice.status === "DRAFT" && invoice.validationDate === null) ||
          (invoice.status !== "CANCEL" && invoice.status !== "CREDIT_NOTE")) && <Divider />}
      {userCanWrite &&
        (invoice.status === "VALIDATED" ||
          invoice.status === "INVOICED" ||
          invoice.status === "SENT" ||
          invoice.status === "PARTIALLY_PAID" ||
          invoice.status === "PAID") && [
          <MenuItem key="manage-payments" onClick={() => handleShowPaymentsManager()}>
            Gérer les paiements
          </MenuItem>,
          <Divider key="payment-divider" />
        ]}

      {userCanWrite && invoice.status === "DRAFT" && invoice.validationDate === null && (
        <ConfirmationDialog situationText="Êtes-vous sûr de vouloir supprimer cette facture ? Attention, cette action est irréversible.">
          {confirmBeforeAct => (
            <MenuItem
              onClick={e => {
                stopPropagationForConfirmation(
                  e,
                  confirmBeforeAct(() => handleDeletion())
                );
              }}
            >
              Supprimer
            </MenuItem>
          )}
        </ConfirmationDialog>
      )}
      {userCanWrite && invoice.status === "DRAFT" && invoice.validationDate !== null && (
        <ConfirmationDialog
          confirmText="Supprimer ma facture par avoir"
          title="Information"
          situationText="Afin de supprimer cette facture, vous devez la valider puis l'annuler par avoir car celle-ci possède déjà une numérotation."
        >
          {confirmBeforeAct => (
            <MenuItem
              onClick={e => {
                stopPropagationForConfirmation(
                  e,
                  confirmBeforeAct(() => {
                    onDeleteOldDraft();
                  })
                );
              }}
            >
              Supprimer
            </MenuItem>
          )}
        </ConfirmationDialog>
      )}
      {userCanWrite &&
        invoice.status !== "DRAFT" &&
        invoice.status !== "CANCEL" &&
        invoice.status !== "CREDIT_NOTE" &&
        sessionStore.userHasAccess({
          requiredAcls: ["INVOICE_MY"],
          requiredFeatures: ["INVOICE"]
        }) && (
          <ConfirmationDialog situationText="Êtes-vous sûr de vouloir annuler cette facture par un avoir total ?">
            {confirmBeforeAct => (
              <MenuItem
                onClick={e => {
                  stopPropagationForConfirmation(
                    e,
                    confirmBeforeAct(() => transformIntoCreditNote())
                  );
                }}
              >
                Annuler par avoir
              </MenuItem>
            )}
          </ConfirmationDialog>
        )}

      {userCanRead &&
        invoice.status !== "CREDIT_NOTE" &&
        sessionStore.userHasAccess({
          requiredAcls: ["INVOICE_MY"],
          requiredFeatures: ["INVOICE"]
        }) && [
          <Divider key="divider-duplicate" />,
          <MenuItem key="duplicate-invoice" onClick={() => duplicateInvoice()}>
            Dupliquer
          </MenuItem>
        ]}
    </TableAction>
  );
}

export default withRouter(InvoiceListActionsRow);
