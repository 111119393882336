import moment from "moment";

import SignatureService from "../services/SignatureService";

class DocumentSignature {
  constructor(signature) {
    const { docSignature, ...rest } = signature;
    this.documentSignature = docSignature;
    this.companyId = docSignature.company.id;
    this.signatureId = docSignature.id;
    this.signatory = { ...rest };
  }

  getCompanyLogoPath() {
    return `${process.env.API_URL}/public/logo/${this.companyId}.png`;
  }

  getDocumentPublicUrl() {
    return `${process.env.API_URL}/doc-signatures/${this.signatureId}/signatories/${this.signatory.id}/downloadPDF`;
  }

  canBeSign() {
    if (
      this.documentSignature.status !== "WAITING_SIGN" ||
      moment(this.documentSignature.deadlineSignature).isBefore(moment())
    ) {
      return "EXPIRED";
    }
    if (this.signatory.hasSigned) {
      return "ALREADY_SIGN";
    }
    return "OK";
  }

  async askForCode() {
    const sent = await SignatureService.sendSignCode(this.signatureId, this.signatory.id)
      .then(() => true)
      .catch(() => false);
    return sent;
  }

  async checkCode(code) {
    const isValid = await SignatureService.checkSignCode(this.signatureId, this.signatory.id, code)
      .then(() => true)
      .catch(() => false);
    return isValid;
  }
}

export default DocumentSignature;
