import moment from "moment";
import request, { METHOD } from "../utils/request";

class AdditionalDocumentService {
  static getAdditionalDocument(id) {
    return request({
      url: `/additionalDocuments/${id}`,
      method: METHOD.GET
    });
  }

  static getAdditionalDocuments({ filter, page }) {
    return request({
      url: `/additionalDocuments?q=${filter.q || ""}&contactId=${filter.client || ""}&from=${
        filter.from ? moment(filter.from).format("YYYY-MM-DD") : ""
      }&to=${filter.to ? moment(filter.to).format("YYYY-MM-DD") : ""}&page=${page}&status=${filter.status || ""}`,
      method: METHOD.GET
    });
  }

  static getAdditionalDocumentPDF(id) {
    return request({
      url: `/additionalDocuments/${id}/pdf`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static patchAdditionalDocumentFields(id, fields) {
    return request({
      url: `/additionalDocuments/${id}`,
      method: METHOD.PATCH,
      data: { fields }
    });
  }

  static patchAdditionalDocumentStatus(id, status) {
    return request({
      url: `/additionalDocuments/${id}/status`,
      method: METHOD.PATCH,
      data: { status }
    });
  }

  static cancelAdditionalDocument(id) {
    return request({
      url: `/additionalDocuments/${id}`,
      method: METHOD.DELETE
    });
  }

  static sendAdditionalDocument(id) {
    return request({
      url: `/additionalDocuments/${id}/mail`,
      method: METHOD.POST
    });
  }

  static sendAdditionalDocumentForSignature(additionalDocument, signatureData) {
    return request({
      url: `/additionalDocuments/${additionalDocument.id}/signature`,
      method: METHOD.POST,
      data: { ...signatureData }
    });
  }

  static getAdditionalDocumentSignature(additionalDocumentId) {
    return request({
      url: `/additionalDocuments/${additionalDocumentId}/signature`,
      method: METHOD.GET
    });
  }

  static signatureDeadline = additionalDocument => {
    if (additionalDocument.signature && additionalDocument.signature.signatureExpirationDate) {
      return moment(additionalDocument.signature.signatureExpirationDate);
    }
    return "";
  };

  static signatureExpired = additionalDocument =>
    AdditionalDocumentService.signatureDeadline(additionalDocument).isBefore(moment(), "second");
}

export default AdditionalDocumentService;
