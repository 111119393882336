import request, { METHOD } from "../utils/request";

class SignatureService {
  static getSignatures({ page = 0, sort = "signature.createAt", order = "DESC", filter = {}, realUserId }) {
    const { q = "", status = "", from = "", to = "", client = "", hasSigned = "" } = filter;
    return request({
      url: `/doc-signatures?page=${page}&q=${q}&from=${from}&to=${to}&status=${status}&sort=${sort}&order=${order}&hasSigned=${
        hasSigned !== "ALL" ? hasSigned : ""
      }&userId=${hasSigned !== "ALL" ? client || realUserId : ""}`,
      method: METHOD.GET
    });
  }

  static getSignature(id) {
    return request({
      url: `/doc-signatures/${id}`,
      method: METHOD.GET
    });
  }

  static postSignature(signature) {
    const formData = new FormData();
    formData.append("document", signature.document);
    formData.append("subject", signature.subject);
    formData.append("signatureType", signature.signatureType);
    formData.append("mySignature", signature.mySignature);
    formData.append("mySignatureType", signature.mySignatureType);
    formData.append("sendInvitations", signature.sendInvitations);
    formData.append("contacts", JSON.stringify(signature.contacts));

    return request({
      url: `/doc-signatures`,
      method: METHOD.POST,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    });
  }

  static patchSignature(signature) {
    const formData = new FormData();
    if (signature.document) formData.append("document", signature.document);
    formData.append("subject", signature.subject);
    formData.append("signatureType", signature.signatureType);
    formData.append("mySignature", signature.mySignature);
    formData.append("mySignatureType", signature.mySignatureType);
    formData.append("sendInvitations", signature.sendInvitations);
    formData.append("contacts", JSON.stringify(signature.contacts));

    return request({
      url: `/doc-signatures/${signature.signatureId}`,
      method: METHOD.PATCH,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    });
  }

  static downloadDocument(signatureId) {
    return request({
      url: `/doc-signatures/${signatureId}/downloadPdf`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static initSignature(signatureId) {
    return request({
      url: `/doc-signatures/${signatureId}/initSign`,
      method: METHOD.POST
    });
  }

  static getYouSignSignature(signatureId) {
    return request({
      url: `/doc-signatures/${signatureId}/youSignSignature`,
      method: METHOD.GET
    });
  }

  static getSignatureUrl(signatureId) {
    return request({
      url: `/doc-signatures/${signatureId}/signature`,
      method: METHOD.GET
    });
  }

  static closeTransaction(signatureId) {
    return request({
      url: `/doc-signatures/${signatureId}/closeSign`,
      method: METHOD.POST
    });
  }

  static retrySignature(signatureId) {
    return request({
      url: `/doc-signatures/${signatureId}/reInitSign`,
      method: METHOD.POST
    });
  }

  static deleteSignature(signatureId) {
    return request({
      url: `/doc-signatures/${signatureId}`,
      method: METHOD.DELETE
    });
  }

  static duplicateSignature(signatureId) {
    return request({
      url: `/doc-signatures/${signatureId}/duplicate`,
      method: METHOD.POST
    });
  }

  static cancelSignature(signatureId) {
    return request({
      url: `/doc-signatures/${signatureId}/cancel`,
      method: METHOD.POST
    });
  }

  static getSignatureAsSignatory(signatureId, signatoryId) {
    return request({
      url: `/doc-signatures/${signatureId}/signatories/${signatoryId}`,
      method: METHOD.GET
    });
  }

  static getDocumentSignatureAsSignatory(signatureId, signatoryId) {
    return request({
      url: `/doc-signatures/${signatureId}/signatories/${signatoryId}/downloadPDF`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static sendSignCode(signatureId, signatoryId) {
    return request({
      url: `/doc-signatures/${signatureId}/signatories/${signatoryId}/genOTP`,
      method: METHOD.GET
    });
  }

  static checkSignCode(signatureId, signatoryId, code) {
    return request({
      url: `/doc-signatures/${signatureId}/signatories/${signatoryId}/checkOTP`,
      method: METHOD.POST,
      data: { otp: code }
    });
  }
}

export default SignatureService;
