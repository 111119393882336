import React from "react";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import Papa from "papaparse";

import CompaniesSelect from "../../components/admin/CompaniesSelect";
import UploadFileButton from "../../components/button/UploadFileButton";
import AdminService from "../../services/AdminService";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  card: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(),
    "&:hover": {
      cursor: "pointer"
    }
  },
  cardNoClick: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing()
  },
  cardIcon: {
    flex: 2,
    color: "#3C3C3C"
  }
}));

export default function SuperAdminImportCatalogPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [companyId, setCompanyId] = React.useState("");
  const [file, setFile] = React.useState();
  const [products, setProducts] = React.useState([]);

  const handleCompanyChange = _company => {
    setCompanyId(_company.id);
  };

  const handleChange = e => {
    setFile({ content: e.file, id: e.id });
    Papa.parse(e.file, {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        setProducts(results.data.map((product, index) => ({ ...product, index })));
      }
    });
  };

  const handleSubmit = () => {
    AdminService.addProducts(companyId, products)
      .then(() => {
        enqueueSnackbar("Import OK", { variant: "success" });
      })
      .catch(e => {
        enqueueSnackbar("Import KO", { variant: "error" });
        console.log(" ERROR ", e);
      });
  };

  return (
    <div>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Import d&apos;un catalogue</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 16 }}>
            <CompaniesSelect onChange={handleCompanyChange} selectedValue={companyId} />
          </Paper>
        </Grid>
        {companyId && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16, textAlign: "center" }}>
              <UploadFileButton
                accept=".csv"
                id="file"
                label={file && file.content && file.content.name ? file.content.name : "Choisir un fichier"}
                onChange={handleChange}
              />
            </Paper>
          </Grid>
        )}
        {file && products.length === 0 && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16, textAlign: "center" }}>
              <Typography>Aucun produit</Typography>
            </Paper>
          </Grid>
        )}
        {file && products.length > 0 && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16, textAlign: "center" }}>
              <Typography>Produits : {products.length}</Typography>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: "20vw" }}>Dénomination</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Prix HT</TableCell>
                    <TableCell align="right">TVA</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map(product => (
                    <TableRow key={product.index}>
                      <TableCell component="th" scope="row">
                        {product.title}
                      </TableCell>
                      <TableCell>{product.description}</TableCell>
                      <TableCell align="right">{product.price}</TableCell>
                      <TableCell align="right">{product.tva}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        )}
        {file && products.length > 0 && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Valider
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
