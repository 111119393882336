import React, { Component, Fragment } from "react";
import { Fab, Grid, Hidden, Paper, TextField, Typography, withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { inject, observer } from "mobx-react";

import ProductService from "../../services/ProductService";
import InvoiceProduct from "./InvoiceProduct";
import InvoiceCurrency from "./InvoiceCurrency";
import InvoiceOptimus from "./InvoiceOptimus";

const styles = theme => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    padding: theme.spacing()
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  paperNature: {
    padding: theme.spacing(2)
  },
  typoNature: {
    marginTop: theme.spacing(2)
  }
});

@inject("newInvoiceStore", "sessionStore")
@observer
class InvoiceActionStep extends Component {
  constructor(props) {
    super(props);
    this.state = { suggestions: [] };
  }

  componentDidMount() {
    ProductService.getProducts({ productsStatus: "ACTIVE" }).then(response =>
      this.setState({ suggestions: response.content })
    );
  }

  handleClick = () => {
    const { newInvoiceStore } = this.props;
    newInvoiceStore.handleAddDetail();
    setTimeout(() => {
      this.formBottom.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  isNatureInError = () => {
    const { newInvoiceStore } = this.props;
    const value = newInvoiceStore.isFirstDetailOk && newInvoiceStore.nature === "";
    return value;
  };

  render() {
    const { classes, newInvoiceStore, sessionStore } = this.props;
    const { suggestions } = this.state;

    return (
      <Fragment>
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          {sessionStore.userHasAccess({ requiredFeatures: ["OPTION_CURRENCY"] }) && (
            <Grid item xs={12}>
              <Typography variant="h5">Options</Typography>
              <InvoiceCurrency />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.typoNature}>
              Objet
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paperNature}>
              <TextField
                autoFocus
                fullWidth
                label="Objet"
                name="nature"
                placeholder="Objet"
                onChange={newInvoiceStore.handleChange}
                required
                value={newInvoiceStore.nature}
                variant="outlined"
                error={this.isNatureInError()}
                helperText={this.isNatureInError() ? "L'objet doit être spécifié" : ""}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {newInvoiceStore.details.length > 1 ? (
              <Typography variant="h5">Détails des prestations</Typography>
            ) : (
              <Typography variant="h5">Détail de la prestation</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {newInvoiceStore.details.map((detail, index) => (
              <InvoiceProduct
                canDraw
                detail={detail}
                index={index}
                key={`card-${detail.id}-${index + 1}`}
                suggestions={suggestions}
              />
            ))}
            <div
              ref={el => {
                this.formBottom = el;
              }}
            />
          </Grid>
        </Grid>
        {sessionStore.userHasAccess({ requiredFeatures: ["OPTION_OPTIMUS"] }) && (
          <Grid item xs={12}>
            <Typography variant="h5" style={{ marginBottom: 8 }}>
              Primes
            </Typography>
            <InvoiceOptimus />
          </Grid>
        )}
        <Hidden smDown>
          <Fab
            variant="extended"
            color="primary"
            aria-label="Ajouter une prestation"
            onClick={this.handleClick}
            className={classes.fab}
          >
            <AddIcon /> Ajouter une prestation à la facture
          </Fab>
        </Hidden>
        <Hidden mdUp>
          <Fab color="primary" aria-label="Ajouter une prestation" onClick={this.handleClick} className={classes.fab}>
            <AddIcon />
          </Fab>
        </Hidden>
      </Fragment>
    );
  }
}

export default withStyles(styles)(InvoiceActionStep);
