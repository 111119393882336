import React from "react";
import { Provider } from "mobx-react";
import { SnackbarProvider } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

import App from "./App";
import "./index.css";

import sessionStore from "./stores/SessionStore";
import addSellerStore from "./stores/AddSellerStore";
import newOrderFormStore from "./stores/NewOrderFormStore";
import newInvoiceStore from "./stores/NewInvoiceStore";
import newEstimateStore from "./stores/NewEstimateStore";

const stores = {
  sessionStore,
  addSellerStore,
  newOrderFormStore,
  newInvoiceStore,
  newEstimateStore
};

export default function Root() {
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <Provider {...stores}>
      <SnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        action={key => (
          <IconButton size="small" key="close" aria-label="Close" color="inherit" onClick={onClickDismiss(key)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      >
        <App />
      </SnackbarProvider>
    </Provider>
  );
}
