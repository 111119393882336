import React, { Component, Fragment } from "react";
import { IconButton, Menu } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

class TableAction extends Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    const { onOpen } = this.props;
    if (onOpen) {
      onOpen();
    }
    this.setState({ anchorEl: event.currentTarget });
    event.stopPropagation();
  };

  handleClose = e => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
    this.setState({ anchorEl: null });
    e.stopPropagation();
  };

  render() {
    const { anchorEl } = this.state;
    const { children, disabled, size = "medium" } = this.props;

    return (
      <Fragment>
        <IconButton
          size={size}
          aria-label="Actions"
          aria-owns={anchorEl ? "simple-menu" : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          disabled={disabled}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          onClick={this.handleClose}
        >
          {children}
        </Menu>
      </Fragment>
    );
  }
}

export default TableAction;
