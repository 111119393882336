import React, { Component } from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import {
  withStyles,
  Grid,
  TextField,
  ExpansionPanelActions,
  Button,
  ExpansionPanelDetails,
  withWidth
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

let timeoutSearch;

class OrderFormListFilter extends Component {
  state = {
    expanded: false
  };

  handleInputChange = e => {
    const { filter, updateFilter } = this.props;
    updateFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
  };

  handleQueryChange = e => {
    const { filter, onSubmit, updateFilter } = this.props;
    updateFilter(Object.assign({}, filter, { q: e.target.value }));
    clearTimeout(timeoutSearch);
    timeoutSearch = setTimeout(() => {
      onSubmit();
    }, 500);
  };

  handleReminderFromDateChange = date => {
    const { filter, updateFilter } = this.props;
    updateFilter(Object.assign({}, filter, { reminderDateFrom: date }));
  };

  handleReminderToDateChange = date => {
    const { filter, updateFilter } = this.props;
    updateFilter(Object.assign({}, filter, { reminderDateTo: date }));
  };

  handleInstallationFromDateChange = date => {
    const { filter, updateFilter } = this.props;
    updateFilter(Object.assign({}, filter, { installationDateFrom: date }));
  };

  handleInstallationToDateChange = date => {
    const { filter, updateFilter } = this.props;
    updateFilter(Object.assign({}, filter, { installationDateTo: date }));
  };

  handleResetAdvanced = () => {
    const { updateFilter, defaultFilter } = this.props;
    updateFilter(defaultFilter);
  };

  handleExpansion = (e, expanded) => {
    this.setState({ expanded });
  };

  handleSearch = () => {
    this.setState({ expanded: false });
    const { onSubmit } = this.props;
    onSubmit();
  };

  render() {
    const { classes, filter, width } = this.props;
    const { expanded } = this.state;

    return (
      <Grid item xs={12} className={classes.advancedContainer}>
        <ExpansionPanel onChange={this.handleExpansion} expanded={expanded}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <TextField
              className={classes.woMarginTop}
              onClick={e => e.stopPropagation()}
              name="qClients"
              placeholder={width === "xs" ? "Recherche" : "Nom, prénom, entreprise, adresse"}
              label={width === "xs" ? "Recherche" : "Nom, prénom, entreprise, adresse"}
              margin="normal"
              fullWidth
              value={filter.q}
              onChange={this.handleQueryChange}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.advancedFieldsContainer}>
            <Grid container>
              <Grid item xs={12} sm={6} lg={3} className={classes.advancedInputContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                  <DatePicker
                    className={classes.advancedInput}
                    id="installationDateFrom"
                    cancelLabel="Annuler"
                    clearLabel="Réinitialiser"
                    clearable
                    format="D MMM YYYY"
                    fullWidth
                    label="Installation après le"
                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                    rightArrowIcon={<KeyboardArrowRightIcon />}
                    value={filter.installationDateFrom}
                    onChange={this.handleInstallationFromDateChange}
                    maxDate={filter.installationDateTo ? filter.installationDateTo : undefined}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} className={classes.advancedInputContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                  <DatePicker
                    className={classes.advancedInput}
                    id="installationDateTo"
                    cancelLabel="Annuler"
                    clearLabel="Réinitialiser"
                    clearable
                    format="D MMM YYYY"
                    fullWidth
                    label="Installation avant le"
                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                    rightArrowIcon={<KeyboardArrowRightIcon />}
                    minDate={filter.installationDateFrom ? filter.installationDateFrom : undefined}
                    value={filter.installationDateTo}
                    onChange={this.handleInstallationToDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} className={classes.advancedInputContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                  <DatePicker
                    className={classes.advancedInput}
                    id="reminderDateFrom"
                    cancelLabel="Annuler"
                    clearLabel="Réinitialiser"
                    clearable
                    format="D MMM YYYY"
                    fullWidth
                    label="Relance après le"
                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                    rightArrowIcon={<KeyboardArrowRightIcon />}
                    maxDate={filter.reminderDateTo ? filter.reminderDateTo : undefined}
                    value={filter.reminderDateFrom}
                    onChange={this.handleReminderFromDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} className={classes.advancedInputContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                  <DatePicker
                    className={classes.advancedInput}
                    id="reminderDateTo"
                    cancelLabel="Annuler"
                    clearLabel="Réinitialiser"
                    clearable
                    format="D MMM YYYY"
                    fullWidth
                    label="Relance avant le"
                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                    rightArrowIcon={<KeyboardArrowRightIcon />}
                    minDate={filter.reminderDateFrom ? filter.reminderDateFrom : undefined}
                    value={filter.reminderDateTo}
                    onChange={this.handleReminderToDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
          <ExpansionPanelActions className={classes.advancedActionContainer}>
            <Button size="small" color="primary" onClick={this.handleResetAdvanced}>
              Réinitialiser
            </Button>
            <Button size="small" variant="contained" color="primary" onClick={this.handleSearch}>
              Filtrer
            </Button>
          </ExpansionPanelActions>
        </ExpansionPanel>
      </Grid>
    );
  }
}

const styles = theme => ({
  woMarginTop: {
    marginTop: 0
  },
  advancedContainer: {
    marginTop: theme.spacing(3)
  },
  advancedFieldsContainer: {
    paddingBottom: theme.spacing(),
    backgroundColor: "#FCFCFC"
  },
  advancedActionContainer: {
    backgroundColor: "#FCFCFC"
  },
  advancedInput: {
    marginTop: theme.spacing(2)
  },
  advancedInputContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  expWrapper: {
    overflow: "hidden"
  }
});

export default withWidth()(withStyles(styles)(OrderFormListFilter));
