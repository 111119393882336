import React from "react";
import { Grid, TextField, Paper, withStyles, Typography, Button, CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { inject, observer } from "mobx-react";
import { Alert } from "@material-ui/lab";

function ClientForm({
  classes,
  formik,
  woDOM,
  disableFirstnameInput,
  disableLastnameInput,
  disableBusinessNameInput,
  handleSubmit,
  handleCancel,
  isSubmitting,
  excludeDates = false,
  sessionStore
}) {
  const userEmail = sessionStore.user && sessionStore.user.email ? sessionStore.user.email : undefined;

  const finalForm = (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Nom"
          name="lastname"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Nom"
          required
          disabled={disableLastnameInput}
          value={formik.values.lastname}
          variant="outlined"
          error={Boolean(formik.touched.lastname && formik.errors.lastname)}
          helperText={formik.errors.lastname}
          InputProps={{
            classes: {
              focused: classes.errorInput,
              error: classes.errorInput
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Prénom"
          name="firstname"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Prénom"
          required
          disabled={disableFirstnameInput}
          value={formik.values.firstname}
          variant="outlined"
          error={Boolean(formik.touched.firstname && formik.errors.firstname)}
          helperText={formik.errors.firstname}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Entreprise"
          name="businessName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Entreprise"
          value={formik.values.businessName}
          disabled={disableBusinessNameInput}
          variant="outlined"
          error={Boolean(formik.touched.businessName && formik.errors.businessName)}
          helperText={formik.errors.businessName}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Téléphone mobile"
          name="phone"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Téléphone mobile"
          value={formik.values.phone}
          variant="outlined"
          error={Boolean(formik.touched.phone && formik.errors.phone)}
          helperText={formik.errors.phone}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Téléphone fixe"
          name="phone2"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Téléphone fixe"
          value={formik.values.phone2 || ""}
          variant="outlined"
          error={Boolean(formik.touched.phone2 && formik.errors.phone2)}
          helperText={formik.errors.phone2}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          label="E-mail"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="E-mail"
          value={formik.values.email}
          variant="outlined"
          error={Boolean(formik.touched.email && formik.errors.email)}
          helperText={formik.errors.email}
        />
        {userEmail === formik.values.email && (
          <Alert severity="info" style={{ marginTop: 6 }}>
            En renseignant votre propre adresse e-mail, le client ne sera pas en mesure de recevoir les documents par
            e-mail
          </Alert>
        )}
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          label="Adresse"
          name="address"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Adresse"
          value={formik.values.address}
          variant="outlined"
          error={Boolean(formik.touched.address && formik.errors.address)}
          helperText={formik.errors.address}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          required
          fullWidth
          label="Code postal"
          name="zipCode"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Code postal"
          value={formik.values.zipCode}
          variant="outlined"
          error={Boolean(formik.touched.zipCode && formik.errors.zipCode)}
          helperText={formik.errors.zipCode}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          required
          fullWidth
          label="Ville"
          name="city"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Ville"
          value={formik.values.city}
          variant="outlined"
          error={Boolean(formik.touched.city && formik.errors.city)}
          helperText={formik.errors.city}
        />
      </Grid>
      {!excludeDates && (
        <Grid item xs={12} sm={6}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
            <DatePicker
              id="installationDate"
              cancelLabel="Annuler"
              clearLabel="Réinitialiser"
              clearable
              format="D MMM YYYY"
              fullWidth
              label="Date d'installation"
              leftArrowIcon={<KeyboardArrowLeftIcon />}
              rightArrowIcon={<KeyboardArrowRightIcon />}
              value={formik.values.installationDate}
              onChange={date => {
                formik.setFieldValue("installationDate", date);
              }}
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}
      {!excludeDates && (
        <Grid item xs={12} sm={6}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
            <DatePicker
              id="reminderDate"
              cancelLabel="Annuler"
              clearLabel="Réinitialiser"
              clearable
              format="D MMM YYYY"
              fullWidth
              label="Date de relance"
              leftArrowIcon={<KeyboardArrowLeftIcon />}
              rightArrowIcon={<KeyboardArrowRightIcon />}
              value={formik.values.reminderDate}
              onChange={date => {
                formik.setFieldValue("reminderDate", date);
              }}
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Commentaire"
          name="comments"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Commentaire libre"
          value={formik.values.comments}
          variant="outlined"
          error={Boolean(formik.touched.comments && formik.errors.comments)}
          helperText={formik.errors.comments}
          multiline
          rows={3}
        />
      </Grid>
    </Grid>
  );

  if (woDOM) {
    return finalForm;
  }

  return (
    <form>
      <Grid container justify="center">
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Paper className={classes.paper}>
            <Typography variant="h4" className={classes.header}>
              Détails du client
            </Typography>
            {finalForm}
            <Grid justify="flex-end" container className={classes.btnWrapper}>
              <Button variant="outlined" color="primary" className={classes.leftBtn} onClick={handleCancel}>
                Annuler
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitting || !formik.isValid}
              >
                Enregistrer
                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  errorInput: {
    borderColor: "red !important"
  },
  leftBtn: {
    marginRight: theme.spacing(2)
  },
  btnWrapper: {
    paddingTop: theme.spacing(2)
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

export default withStyles(styles)(inject("sessionStore")(observer(props => <ClientForm {...props} />)));
