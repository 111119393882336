import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Decimal } from "decimal.js-light";
import { Typography, withStyles } from "@material-ui/core";

import { FINANCIAL_METHOD } from "../../../../stores/NewOrderFormStore";
import { formatNumber } from "../../../../utils/numberFormat";

const styles = theme => ({
  textfieldNoMarginTop: {
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.spacing()}px`
    }
  }
});

@inject("newEstimateStore")
@observer
class CommonForm extends Component {
  render() {
    const { newEstimateStore, classes } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="deposit"
            label="Acompte à la commande"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={newEstimateStore.financingForm.deposit}
            onChange={newEstimateStore.handleChangeFinancingForm}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Basculer le mode d'acompte"
                    onClick={newEstimateStore.handleChangeFinancingFormDepositMethod}
                  >
                    {newEstimateStore.financingForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE ? "%" : "€"}
                  </IconButton>
                </InputAdornment>
              )
            }}
            helperText={`soit un acompte de
            ${
              newEstimateStore.financingForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE
                ? `${newEstimateStore.computedFinancingFormDeposit}€`
                : `${
                    newEstimateStore.financingForm.deposit > 0
                      ? new Decimal((newEstimateStore.financingForm.deposit / newEstimateStore.amount) * 100)
                          .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
                          .toNumber()
                          .toFixed(2)
                      : 0
                  }%`
            }`}
            className={classes.textfieldNoMarginTop}
          />
        </Grid>

        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="endWorkPayment"
            label="Règlement fin de prestation"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={newEstimateStore.financingForm.endWorkPayment}
            onChange={newEstimateStore.handleChangeFinancingForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
            helperText=" "
            className={classes.textfieldNoMarginTop}
          />
        </Grid>

        <Grid item xs={12} className={classes.item}>
          <Typography variant="body2" align="center">
            Montant total du crédit :
          </Typography>
          <Typography variant="h6" component="p" align="center">
            {formatNumber(newEstimateStore.loanAmount)} €
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(CommonForm);
