import React, { useState } from "react";
import { Button, Typography, Box, makeStyles } from "@material-ui/core";
import PreviewFileDialog from "../dialog/PreviewFileDialog";
import PreviewFileFromApiDialog from "../dialog/PreviewFileFromApiDialog";
import SignatureService from "../../services/SignatureService";

const MAX_FILE_SIZE = 10485760;

const errorReasonToText = reason => {
  switch (reason) {
    case "FILE_TOO_LARGE":
      return "Le fichier fait plus que la limite autorisée (10 Mo)";
    case "FILE_BAD_FORMAT":
      return "Le fichier n'est pas au format PDF";
    default:
      return "";
  }
};

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    "& > *": {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    }
  }
}));

export default function SignatureDocumentStep({ file, onImport, editionMode, signatureId }) {
  const [importError, setImportError] = useState("");
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [showPreviewFromApiDialog, setShowPreviewFromApiDialog] = useState(false);

  const classes = useStyles();

  const handleFileUpload = e => {
    setImportError(null);
    const _file = e.target.files[0];
    if (_file && _file.type === "application/pdf" && _file.size < MAX_FILE_SIZE) {
      onImport(_file);
    } else if (_file && _file.type !== "application/pdf") {
      setImportError("FILE_BAD_FORMAT");
    } else if (_file && _file.size >= MAX_FILE_SIZE) {
      setImportError("FILE_TOO_LARGE");
    }
  };

  const handlePreviewFile = () => {
    setShowPreviewDialog(true);
  };

  const handleClosePreview = () => {
    setShowPreviewDialog(false);
  };

  const handlePreviewFileFromApi = () => {
    setShowPreviewFromApiDialog(true);
  };

  const handleClosePreviewFromApi = () => {
    setShowPreviewFromApiDialog(false);
  };

  return (
    <Box className={classes.mainContainer}>
      <input
        accept="application/pdf"
        style={{ display: "none" }}
        id="uploaded-file"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="uploaded-file">
        <Button
          variant={file ? "outlined" : "contained"}
          color="primary"
          component="span"
          style={{ textAlign: "center" }}
        >
          {editionMode ? "Remplacer le fichier" : file ? `Remplacer ${file.name}` : "Importer un fichier"}
        </Button>
      </label>
      <Typography variant="body2" style={{ textAlign: "center" }}>
        Sélectionnez un document au format PDF de moins de 10 Mo
      </Typography>
      {importError && <Typography variant="caption">{errorReasonToText(importError)}</Typography>}
      {editionMode && !file ? (
        <Button onClick={handlePreviewFileFromApi} variant="outlined" color="primary" size="small">
          Prévisualiser
        </Button>
      ) : file ? (
        <Button onClick={handlePreviewFile} variant="outlined" color="primary" size="small">
          Prévisualiser
        </Button>
      ) : null}
      <PreviewFileDialog open={showPreviewDialog} handleClose={handleClosePreview} file={file} />
      {editionMode && showPreviewFromApiDialog && (
        <PreviewFileFromApiDialog
          open={showPreviewFromApiDialog}
          handleClose={handleClosePreviewFromApi}
          promise={SignatureService.downloadDocument(signatureId)}
        />
      )}
    </Box>
  );
}
