import React, { Fragment } from "react";
import { withStyles, Button } from "@material-ui/core";

const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
  input: {
    display: "none"
  }
});

function UploadFileButton({ classes, onChange, id, label, disabled, accept = "image/*" }) {
  const handleChange = e => {
    const {
      target: { files }
    } = e;
    onChange({ file: files[0], id });
  };

  return (
    <Fragment>
      <input accept={accept} className={classes.input} id={id} type="file" onChange={handleChange} />
      <label htmlFor={id}>
        <Button
          raised="true"
          component="span"
          variant="outlined"
          color="primary"
          className={classes.button}
          disabled={disabled || false}
        >
          {label || "Upload"}
        </Button>
      </label>
    </Fragment>
  );
}

export default withStyles(styles)(UploadFileButton);
