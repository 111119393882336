import { observable } from "mobx";
import shortid from "shortid";

export const TVAS_DROPDOWN = [
  { label: "20", value: "20.00" },
  { label: "10", value: "10.00" },
  { label: "8.5", value: "8.50" },
  { label: "5.5", value: "5.50" },
  { label: "2.1", value: "2.10" },
  { label: "0", value: "0.00" }
];

class Product {
  @observable
  id = "";

  @observable
  title = "";

  @observable
  description = "";

  @observable
  price = "";

  @observable
  tva = "";

  @observable
  quantity = "";

  @observable
  discount = "";

  @observable
  discountType = "HT";

  @observable
  customFields = [];

  @observable
  drawing = null;

  constructor() {
    this.id = shortid.generate();
    this.tva = "20.00";
    this.discount = 0;
    this.discountType = "HT";
    this.customFields = [];
    this.drawing = null;
  }
}

export default Product;
