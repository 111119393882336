import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import React, { useEffect } from "react";

const useStyles = makeStyles(() => ({ label: { width: "100%" } }));

export default function InvoicePaymentMentions({ company, onPatch, defaultPaymentMentions }) {
  const classes = useStyles();
  const invoiceEffectivePaymentMentions =
    company.effectivePaymentMentions && company.effectivePaymentMentions.invoice
      ? company.effectivePaymentMentions.invoice
      : { paymentTerms: company.paymentTerms, paymentDelay: company.paymentDelay };

  const paymentTermsItems = [invoiceEffectivePaymentMentions.paymentTerms, ...company.invoicePaymentTerms, "OTHER"];
  const paymentDelayItems = [invoiceEffectivePaymentMentions.paymentDelay, ...company.invoicePaymentDelay, "OTHER"];

  const defaultPaymentTermsIndex = defaultPaymentMentions.paymentTerms
    ? paymentTermsItems.indexOf(defaultPaymentMentions.paymentTerms)
    : -1;
  const defaultPaymentDelayIndex =
    defaultPaymentMentions.paymentDelay !== undefined && defaultPaymentMentions.paymentDelay !== null
      ? paymentDelayItems.indexOf(defaultPaymentMentions.paymentDelay)
      : -1;

  const [selectedPaymentTerms, setSelectedPaymentTerms] = React.useState(
    defaultPaymentMentions.paymentTerms
      ? defaultPaymentTermsIndex !== -1
        ? defaultPaymentMentions.paymentTerms
        : "OTHER"
      : invoiceEffectivePaymentMentions.paymentTerms
  );
  const [paymentTermsValue, setPaymentTermsValue] = React.useState(
    defaultPaymentTermsIndex !== -1 ? "" : defaultPaymentMentions.paymentTerms || ""
  );

  const [selectedPaymentDelay, setSelectedPaymentDelay] = React.useState(
    defaultPaymentMentions.paymentDelay !== undefined && defaultPaymentMentions.paymentDelay !== null
      ? defaultPaymentDelayIndex !== -1
        ? `${defaultPaymentMentions.paymentDelay || ""}`
        : "OTHER"
      : `${invoiceEffectivePaymentMentions.paymentDelay}`
  );
  const [paymentDelayValue, setPaymentDelayValue] = React.useState(
    defaultPaymentDelayIndex !== -1 ? "" : `${defaultPaymentMentions.paymentDelay}` || ""
  );

  useEffect(() => {
    if (!defaultPaymentMentions.paymentTerms) {
      onPatch({ paymentTerms: invoiceEffectivePaymentMentions.paymentTerms });
    }
    if (defaultPaymentMentions.paymentDelay === undefined) {
      onPatch({ paymentDelay: invoiceEffectivePaymentMentions.paymentDelay });
    }
  }, []);

  const handleFocusPaymentTermsInput = () => {
    setSelectedPaymentTerms("OTHER");
    onPatch({ paymentTerms: paymentTermsValue });
  };

  const handlePaymentTermsChange = e => {
    if (e.target.value === "OTHER") {
      handleFocusPaymentTermsInput();
    } else {
      setSelectedPaymentTerms(e.target.value);
      onPatch({ paymentTerms: e.target.value });
    }
  };

  const handlePaymentTermsValueChange = e => {
    setPaymentTermsValue(e.target.value);
    setSelectedPaymentTerms("OTHER");
    onPatch({ paymentTerms: e.target.value });
  };

  const handleFocusPaymentDelayInput = () => {
    setSelectedPaymentDelay("OTHER");
    onPatch({ paymentDelay: !!paymentDelayValue ? parseInt(paymentDelayValue, 10) : undefined });
  };

  const handlePaymentDelayChange = e => {
    if (e.target.value === "OTHER") {
      handleFocusPaymentDelayInput();
    } else {
      setSelectedPaymentDelay(e.target.value);
      onPatch({ paymentDelay: parseInt(e.target.value, 10) });
    }
  };

  const handlePaymentDelayValueChange = e => {
    setPaymentDelayValue(e.target.value);
    setSelectedPaymentDelay("OTHER");
    onPatch({ paymentDelay: !!e.target.value ? parseInt(e.target.value, 10) : undefined });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 16, paddingTop: 8, paddingBottom: 8 }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Modes de paiement</FormLabel>
        <RadioGroup
          aria-label="paymentTerms"
          name="paymentTerms"
          value={selectedPaymentTerms}
          onChange={handlePaymentTermsChange}
        >
          {paymentTermsItems.map(paymentTermsItem =>
            paymentTermsItem === "OTHER" ? (
              <FormControlLabel
                value="OTHER"
                control={<Radio color="primary" />}
                classes={{ label: classes.label }}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ width: 70 }}>Autre : </span>
                    <TextField
                      size="small"
                      onFocus={handleFocusPaymentTermsInput}
                      name="paymentTermsValue"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={2}
                      value={paymentTermsValue}
                      onChange={handlePaymentTermsValueChange}
                      error={selectedPaymentTerms === "OTHER" && !paymentTermsValue}
                    />
                  </div>
                }
              />
            ) : (
              <FormControlLabel
                value={paymentTermsItem}
                control={<Radio color="primary" />}
                label={paymentTermsItem}
                style={{
                  whiteSpace: "pre-wrap"
                }}
              />
            )
          )}
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend">Délai de paiement</FormLabel>
        <RadioGroup
          aria-label="paymentDelay"
          name="paymentDelay"
          value={selectedPaymentDelay}
          onChange={handlePaymentDelayChange}
        >
          {paymentDelayItems.map(paymentDelayItem =>
            paymentDelayItem === "OTHER" ? (
              <FormControlLabel
                value="OTHER"
                control={<Radio color="primary" />}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ width: 140 }}>Dans un délai de : </span>
                    <TextField
                      size="small"
                      onFocus={handleFocusPaymentDelayInput}
                      name="paymentDelayValue"
                      variant="outlined"
                      fullWidth
                      type="number"
                      inputProps={{ min: "0", step: "1" }}
                      value={paymentDelayValue}
                      onChange={handlePaymentDelayValueChange}
                      style={{ width: 120 }}
                      error={selectedPaymentDelay === "OTHER" && !paymentDelayValue}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">jours</Typography>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                }
              />
            ) : (
              <FormControlLabel
                value={`${paymentDelayItem}`}
                control={<Radio color="primary" />}
                label={paymentDelayItem === 0 ? "À réception" : `Dans un délai de ${paymentDelayItem} jour(s)`}
              />
            )
          )}
        </RadioGroup>
      </FormControl>
    </div>
  );
}
