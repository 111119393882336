import React from "react";
import { Fab, Grid, Paper, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";

import AdminBreadcrumb from "../../components/admin/AdminBreadcrumb";
import { superAdmin } from "../../routes";
import CompaniesList from "../../components/admin/company/CompaniesList";

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  container: {
    padding: theme.spacing(2)
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  }
}));

function SuperAdminCompaniesPage({ match, history }) {
  const classes = useStyles();

  const handleClickOpen = () => {
    history.push("/superbg/entreprises/creation");
  };

  return (
    <div>
      <AdminBreadcrumb routes={superAdmin} match={match} />
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Liste des entreprises</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <CompaniesList />
          </Paper>
        </Grid>
      </Grid>
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
    </div>
  );
}

export default SuperAdminCompaniesPage;
