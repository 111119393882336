import React, { useEffect, useState, useCallback } from "react";
import { withStyles, AppBar, Button, Dialog, IconButton, Toolbar, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ContraliaService from "../../services/ContraliaService";
import { TransitionUp } from "../utils/transitions/TransitionUp";

const styles = {
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
};

function EmbeddedContraliaDialog({ UrlSignature, open, handleClose, transactionId, classes, closeTransactionPromise }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [urlSignature, setUrlSignature] = useState("");

  useEffect(() => {
    setUrlSignature(UrlSignature);
    setIsOpen(open);
  }, [open, UrlSignature]);

  const handleCloseValidateTransaction = useCallback(
    _transactionId => {
      setIsLoading(true);
      const promise = closeTransactionPromise
        ? closeTransactionPromise()
        : ContraliaService.getTerminateTransaction(_transactionId);
      promise
        .then(res => {
          handleClose(res);
        })
        .catch(() => {
          handleClose(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [closeTransactionPromise, handleClose]
  );

  const handleMessage = useCallback(
    event => {
      if (event.origin.includes("contralia.fr") && event.data && event.data.name === "edoc.done") {
        handleCloseValidateTransaction(transactionId);
      }
    },
    [transactionId, handleCloseValidateTransaction]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [transactionId]);

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={TransitionUp}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Signature
          </Typography>
          <Button color="inherit" onClick={() => handleCloseValidateTransaction(transactionId)} disabled={isLoading}>
            Terminer la signature
          </Button>
        </Toolbar>
      </AppBar>

      <iframe style={{ height: "100%" }} src={urlSignature} />
    </Dialog>
  );
}

export default withStyles(styles)(EmbeddedContraliaDialog);
