import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles, Typography, Grid, Tabs, Tab } from "@material-ui/core";
import moment from "moment";

import InvoicesList from "../components/invoice/InvoicesList";
import NotFoundPage from "./NotFoundPage";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  contentContainer: {
    marginTop: -theme.spacing(2)
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
});

@inject("sessionStore")
@observer
class PaymentsPage extends Component {
  state = { selectedTab: "LATE" };

  handleChange = (e, selectedTab) => {
    this.setState({ selectedTab });
  };

  render() {
    const { classes, sessionStore } = this.props;
    const {
      user: { company }
    } = sessionStore;
    const { selectedTab } = this.state;
    const limitDate = moment().subtract(company.paymentDelay || 0, "days");

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["INVOICE_MY", "INVOICE_READ"],
        aclType: "oneof",
        requiredFeatures: ["INVOICE"]
      })
    )
      return <NotFoundPage />;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.container} variant="h4">
            Paiements
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              value={selectedTab}
              onChange={this.handleChange}
              variant="fullWidth"
              className={classes.tabscontainer}
            >
              <Tab value="LATE" label="En retard" className={classes.tab} />
              <Tab value="PENDING" label="En attente" className={classes.tab} />
            </Tabs>
          </Grid>
        </Grid>
        {selectedTab === "LATE" && (
          <Grid container>
            <Grid item xs={12}>
              <InvoicesList
                disableFilter
                disableExport
                filter={{
                  status: ["PARTIALLY_PAID", "VALIDATED", "SENT"],
                  to: limitDate.subtract(1, "days")
                }}
                sort={{ column: "invoice.validationDate", order: "DESC" }}
                disableCreation
              />
            </Grid>
          </Grid>
        )}
        {selectedTab === "PENDING" && (
          <Grid container>
            <Grid item xs={12}>
              <InvoicesList
                disableFilter
                disableExport
                filter={{ status: ["PARTIALLY_PAID", "VALIDATED", "SENT"], from: limitDate }}
                disableCreation
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(PaymentsPage);
