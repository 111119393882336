import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core";
import SellerList from "../components/SellerList";
import NotFoundPage from "./NotFoundPage";

const styles = theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
});

@inject("sessionStore")
@observer
class SellerPage extends Component {
  render() {
    const { sessionStore } = this.props;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["USER_READ"],
        requiredFeatures: ["USER"]
      })
    )
      return <NotFoundPage />;

    const { CGV } = sessionStore.user.company;

    return (
      <Fragment>
        <SellerList disableAddFn={!CGV} />
      </Fragment>
    );
  }
}

export default withStyles(styles)(SellerPage);
