import request, { METHOD } from "../utils/request";

class ProductService {
  static getProductCategories() {
    return request({
      url: "/productCategories",
      method: METHOD.GET
    });
  }

  static postProductCategory(productCategory) {
    return request({
      url: "/productCategories",
      method: METHOD.POST,
      data: productCategory
    });
  }

  static patchProductCategory(productCategory) {
    return request({
      url: `/productCategories/${productCategory.id}`,
      method: METHOD.PATCH,
      data: productCategory
    });
  }

  static deleteProductCategory(productCategoryId) {
    return request({
      url: `/productCategories/${productCategoryId}`,
      method: METHOD.DELETE
    });
  }

  static manageProducts(products, action) {
    return request({
      url: "/products/actions",
      method: METHOD.POST,
      data: { products, action }
    });
  }

  static getProducts({ productsStatus }) {
    return request({
      url: `/products?productsStatus=${productsStatus}`,
      method: METHOD.GET
    });
  }

  static postProduct(product) {
    const _product = {
      ...product,
      customFields: product.customFields.filter(field => field.label !== "")
    };
    return request({
      url: "/products",
      method: METHOD.POST,
      data: _product
    });
  }

  static deleteProduct(productId) {
    return request({
      url: `/products/${productId}`,
      method: METHOD.DELETE
    });
  }

  static patchProduct(product) {
    const _product = {
      ...product,
      customFields: product.customFields.filter(field => field.label !== "")
    };
    return request({
      url: `/products/${product.id}`,
      method: METHOD.PATCH,
      data: _product
    });
  }
}

export default ProductService;
