import React from "react";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";

import useClientEvents from "../../hooks/ClientEventsHook";
import ClientEventsListItem from "./ClientEventsListItem";
import AddEditClientEventDialog from "../dialog/AddEditClientEventDialog";

const ITEMS_PER_PAGE = 15;

const useStyles = makeStyles(() => ({
  paper: { width: "100%", overflowX: "auto" }
}));

function ClientEventsList({ clientId, refreshKey, allowEdition }) {
  const [isRequesting, events, total, pagination, eventActions] = useClientEvents(clientId);
  const [selectedEvent, setSelectedEvent] = React.useState();
  const [withSimplifiedForm, setWithSimplifiedForm] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  React.useEffect(() => {
    if (!isRequesting) {
      eventActions.refresh({ page: 0 });
    }
  }, [refreshKey]);

  const handlePageChange = (event, _page) => {
    pagination.setPage(_page);
  };

  const handleEventUpdate = (event, useSimplifiedForm) => {
    setWithSimplifiedForm(Boolean(useSimplifiedForm));
    setSelectedEvent(event);
  };

  const handleEventDelete = event => {
    eventActions.deleteEvent(event.id);
  };

  const onDialogClose = wRefresh => {
    if (wRefresh) {
      enqueueSnackbar("L'événement a été modifié", { variant: "success" });
      eventActions.refresh({ page: 0 });
    }
    setSelectedEvent(null);
  };

  return (
    <Paper className={classes.paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 30 }} />
            <TableCell style={{ minWidth: 250, maxWidth: 0 }}>Titre / Contenu</TableCell>
            <TableCell style={{ width: 150 }}>Statut / Date limite</TableCell>
            {allowEdition && <TableCell style={{ width: 70 }}>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isRequesting ? (
            events.map(evt => (
              <ClientEventsListItem
                key={`evt-${evt.id}`}
                event={evt}
                allowEdition={allowEdition}
                onUpdate={handleEventUpdate}
                onDelete={handleEventDelete}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={allowEdition ? 4 : 3} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={ITEMS_PER_PAGE}
        rowsPerPageOptions={[ITEMS_PER_PAGE]}
        page={pagination.page}
        backIconButtonProps={{
          "aria-label": "Page précédente"
        }}
        nextIconButtonProps={{
          "aria-label": "Page suivante"
        }}
        onChangePage={handlePageChange}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
      />
      <AddEditClientEventDialog
        open={Boolean(selectedEvent)}
        onClose={onDialogClose}
        clientId={clientId}
        initialEvent={selectedEvent}
        simplified={withSimplifiedForm}
      />
    </Paper>
  );
}

export default ClientEventsList;
