import React, { useState } from "react";
import { TableRow, TableCell, MenuItem } from "@material-ui/core";
import moment from "moment";
import { useSnackbar } from "notistack";

import { formatNumber } from "../../utils/numberFormat";
import TableAction from "../TableAction";
import { PAYMENT_TYPE } from "../../utils/status";
import EditPaymentRow from "./EditPaymentRow";
import PaymentService from "../../services/PaymentService";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";

export default function PaymentRow({ payment, handleDelete, onPaymentEdited, maxPrice, editPayment }) {
  const [showEditionForm, setShowEditionForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onPaymentSubmitted = arg => {
    setShowEditionForm(false);
    onPaymentEdited(arg);
  };

  const deletePayment = () => {
    PaymentService.deletePayment(payment.id)
      .then(() => {
        handleDelete(payment.id);
        enqueueSnackbar("Paiement supprimé", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la suppression du paiement", { variant: "error" });
      });
  };

  return showEditionForm ? (
    <EditPaymentRow
      payment={payment}
      editPayment={editPayment}
      onEditPayment={onPaymentSubmitted}
      maxPrice={maxPrice}
      onCancelEditPayment={() => setShowEditionForm(false)}
    />
  ) : (
    <TableRow>
      <TableCell>{moment(payment.date).format("Do MMMM YYYY")}</TableCell>
      <TableCell>{PAYMENT_TYPE[payment.paymentMode] || "-"}</TableCell>
      <TableCell>{formatNumber(payment.amount)} €</TableCell>
      <TableCell style={{ whiteSpace: "pre-wrap" }}>{payment.comments || ""}</TableCell>
      <TableCell align="center">
        <TableAction size="small">
          <MenuItem onClick={() => setShowEditionForm(true)}>Modifier</MenuItem>
          <ConfirmationDialog>
            {confirmBeforeAct => (
              <MenuItem onClick={e => stopPropagationForConfirmation(e, confirmBeforeAct(() => deletePayment()))}>
                Supprimer
              </MenuItem>
            )}
          </ConfirmationDialog>
        </TableAction>
      </TableCell>
    </TableRow>
  );
}
