import React, { Component, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
  CircularProgress
} from "@material-ui/core";

const statusToIndex = status => {
  switch (status) {
    case "DRAFT":
      return 0;
    case "VALIDATED":
      return 1;
    case "SENT":
      return 2;
    default:
      return 0;
  }
};

class InvoiceStatusManager extends Component {
  constructor(props) {
    super(props);
    const { invoice } = props;
    this.state = {
      currentValue: invoice ? statusToIndex(invoice.status) : 0,
      invoice: invoice || null,
      processing: false
    };
    this.steps = [
      { label: "Brouillon", isDisabled: true, contentSub: "Impossible de revenir à cet état", key: "DRAFT" },
      { label: "Validée", isDisabled: false, key: "VALIDATED" },
      { label: "Envoyée", isDisabled: false, key: "SENT" }
    ];
  }

  static getDerivedStateFromProps(props, prevState) {
    if (prevState.invoice === null && props.invoice) {
      return { currentValue: statusToIndex(props.invoice.status), invoice: props.invoice };
    }
    if (props.open === false) {
      return { currentValue: 0, invoice: null };
    }
    return null;
  }

  handleSubmit = async e => {
    e.preventDefault();
    const { onClose, handleSubmit } = this.props;
    const { currentValue, invoice } = this.state;
    if (invoice.status !== this.steps[currentValue].key) {
      this.setState({ processing: true }, async () => {
        handleSubmit({ invoice, status: this.steps[currentValue].key }).then(() => {
          this.setState({ processing: false }, () => {
            onClose();
          });
        });
      });
    } else {
      onClose();
    }
  };

  render() {
    const { currentValue, invoice, processing } = this.state;
    const { onClose, open } = this.props;

    return (
      <Dialog
        modal="false"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Gestion du statut</DialogTitle>
        <DialogContent style={{ minWidth: 300 }}>
          {invoice && (
            <Fragment>
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Facture {invoice.number}
              </Typography>
              <Typography variant="body1" style={{ textAlign: "center" }}>
                Cliquez sur le nouveau statut pour le changer
              </Typography>
            </Fragment>
          )}
          <Stepper activeStep={currentValue} orientation="vertical">
            {this.steps.map((step, index) => (
              <Step
                disabled={step.isDisabled}
                key={step.label}
                style={{ cursor: index === 0 ? "default" : "pointer" }}
                completed={currentValue >= index}
                onClick={e => {
                  e.preventDefault();
                  if (index > 0) {
                    this.setState({ currentValue: index });
                  }
                }}
              >
                <StepLabel optional={step.contentSub}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Annuler
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={processing}
            style={{ position: "relative" }}
          >
            {processing && (
              <div
                style={{ position: "absolute", top: "50%", marginTop: -10, marginLeft: -10, left: "50%", zIndex: 2 }}
              >
                <CircularProgress size={20} />
              </div>
            )}
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default InvoiceStatusManager;
