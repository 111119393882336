import React from "react";
import { AppBar, Dialog, IconButton, Toolbar, Typography, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import { makeStyles } from "@material-ui/styles";

import FeedbackModal from "../FeedbackModal";
import { TransitionUp } from "../utils/transitions/TransitionUp";
import CompanyService from "../../services/CompanyService";
import ProfileForm from "../form/ProfileForm";

const useStyles = makeStyles(() => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const emptyProfile = {
  name: "",
  acl: []
};

export default function AddEditProfileDialog({ open, selectedProfile, onAddOrUpdate, onClose }) {
  const classes = useStyles();

  const [processing, setProcessing] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [feedback, setFeedback] = React.useState(null);

  React.useEffect(() => {
    setIsOpen(open);
    setProcessing(false);
  }, [open]);

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = profile => {
    setProcessing(true);
    const promise =
      selectedProfile && selectedProfile.id
        ? CompanyService.updateProfile(profile)
        : CompanyService.addProfile(profile);
    promise
      .then(() => {
        onAddOrUpdate();
        setProcessing(false);
        setIsOpen(false);
        if (onClose) {
          onClose();
        }
      })
      .catch(error => {
        if (error.status && error.status === 409) {
          setFeedback({
            title: "Erreur",
            description: "Un profil utilisateur possédant ce nom existe déjà, celui-ci doit être unique"
          });
        } else {
          setFeedback({
            title: "Erreur",
            description: "Une erreur est survenue, veuillez vérifier vos données ou réessayer ultérieurement"
          });
        }

        setProcessing(false);
      });
  };

  const handleCloseFeedBack = () => {
    setFeedback(null);
  };

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={TransitionUp}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            {selectedProfile && selectedProfile.id ? "Modifier un profil utilisateur" : "Ajouter un profil utilisateur"}
          </Typography>
        </Toolbar>
      </AppBar>

      {feedback && (
        <FeedbackModal
          open
          title={feedback.title}
          description={feedback.description}
          handleCloseFeedBack={handleCloseFeedBack}
        />
      )}

      <Grid container justify="center">
        <Grid item xs={12} sm={8}>
          <ProfileForm
            handleSubmit={handleSubmit}
            handleCancel={handleClose}
            isSubmitting={processing}
            profile={selectedProfile || emptyProfile}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
