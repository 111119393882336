import React from "react";

import LogsViewer from "../../components/admin/LogsViewer";
import AdminBreadcrumb from "../../components/admin/AdminBreadcrumb";
import { superAdmin } from "../../routes";

export default function SuperAdminLogsPage({ match }) {
  return (
    <div>
      <AdminBreadcrumb routes={superAdmin} match={match} />
      <LogsViewer />
    </div>
  );
}
