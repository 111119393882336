import React from "react";
import { CircularProgress, Grid, Hidden, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import ClientService from "../services/ClientService";
import EditClientDetails from "../components/client/EditClientDetails";
import BackButton from "../components/button/BackButton";

const useStyles = makeStyles(() => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200
  }
}));

function EditClientPage({ match }) {
  const [client, setClient] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  React.useEffect(() => {
    ClientService.getClient(match.params.clientId)
      .then(response => {
        setClient(response.contact);
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la récupération des informations du client", {
          variant: "error"
        });
      });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.back}>
        <Hidden xsDown>
          <BackButton to="/clients" textLong="Retour aux clients" />
        </Hidden>
        <BackButton to={`/clients/${match.params.clientId}`} textLong="Retour à la fiche client" />
      </Grid>
      {client ? (
        <EditClientDetails client={client} />
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
    </Grid>
  );
}

export default EditClientPage;
