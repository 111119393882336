import React, { Component } from "react";
import { withStyles, AppBar, Dialog, IconButton, Toolbar, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { withSnackbar } from "notistack";

import { TransitionUp } from "../utils/transitions/TransitionUp";
import ClientForm from "../form/ClientForm";
import ClientService, { ClientSchema } from "../../services/ClientService";

const DEFAULT_CLIENT = {
  address: "",
  city: "",
  businessName: "",
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
  phone2: "",
  zipCode: "",
  installationDate: null,
  reminderDate: null
};

class AddEditClientDialog extends Component {
  state = { loading: false };

  handleSubmit = client => {
    const { handleClose } = this.props;

    this.setState({ loading: true }, () => {
      ClientService.postClient(client)
        .then(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Le client a été ajouté", { variant: "success" });
          handleClose({ wRefresh: true });
        })
        .catch(error => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar(
            error.status === 400
              ? "Les informations du client sont incorrectes"
              : "Une erreur est survenue lors de la création du client",
            {
              variant: "error"
            }
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  handleEditSubmit = client => {
    const { handleClose } = this.props;

    this.setState({ loading: true }, () => {
      ClientService.patchClient(client)
        .then(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Le client a été modifié", { variant: "success" });
          this.setState({ loading: false });
          handleClose({ wRefresh: true });
        })
        .catch(error => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar(
            error.status === 400
              ? "Les informations du client sont incorrectes"
              : "Une erreur est survenue lors de la mise à jour du client",
            {
              variant: "error"
            }
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { classes, open, currentClient, handleClose } = this.props;
    const { loading } = this.state;
    const initialClient = currentClient || DEFAULT_CLIENT;

    return open ? (
      <Formik
        initialValues={initialClient}
        validationSchema={ClientSchema}
        onSubmit={values => {
          if (currentClient && currentClient.id) {
            this.handleEditSubmit(values);
          } else {
            this.handleSubmit(values);
          }
        }}
      >
        {formikProps => (
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={TransitionUp}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className={classes.flex}>
                  {currentClient && currentClient.id ? "Modifier le client" : "Ajouter un client"}
                </Typography>
              </Toolbar>
            </AppBar>
            <ClientForm
              formik={formikProps}
              handleCancel={handleClose}
              handleSubmit={formikProps.handleSubmit}
              isSubmitting={loading}
            />
          </Dialog>
        )}
      </Formik>
    ) : (
      <div />
    );
  }
}

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

export default withSnackbar(withStyles(styles)(AddEditClientDialog));
