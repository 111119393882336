import React from "react";
import { Box, Collapse, IconButton, makeStyles, MenuItem, TableCell, TableRow, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import ClientEventChip from "./ClientEventChip";
import TableAction from "../TableAction";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";

const useStyles = makeStyles(() => ({
  root: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    },
    "& > *": {
      borderBottom: "unset"
    }
  },
  rootOpen: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    },
    "& > *": {
      paddingBottom: 0,
      borderBottom: "unset"
    }
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  noMax: {
    maxWidth: 0
  }
}));

function ClientEventsListItem({ event, allowEdition, onDelete, onUpdate }) {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    if (event.content) setOpen(!open);
  };

  const handleDelete = () => {
    setOpen(false);
    onDelete(event);
  };

  const handleUpdate = () => {
    setOpen(false);
    onUpdate(event);
  };

  const handleSimplifiedUpdate = () => {
    setOpen(false);
    onUpdate(event, true);
  };

  return (
    <React.Fragment>
      <TableRow className={open ? classes.rootOpen : classes.root} onClick={handleOpen} selected={selected}>
        <TableCell>
          {event.content && (
            <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell className={classes.noMax}>
          <Typography variant="h6" className={classes.ellipsis}>
            {event.title}
          </Typography>
          {!open && (
            <Typography variant="subtitle1" className={classes.ellipsis}>
              {event.content}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <ClientEventChip event={event} allowEdition={allowEdition} onClick={handleSimplifiedUpdate} />
        </TableCell>
        {allowEdition && (
          <TableCell>
            <TableAction onOpen={() => setSelected(true)} onClose={() => setSelected(false)}>
              <MenuItem onClick={handleUpdate}>Modifier</MenuItem>
              <ConfirmationDialog>
                {confirmBeforeAct => (
                  <MenuItem
                    onClick={e =>
                      stopPropagationForConfirmation(
                        e,
                        confirmBeforeAct(() => {
                          handleDelete();
                        })
                      )
                    }
                  >
                    Supprimer
                  </MenuItem>
                )}
              </ConfirmationDialog>
            </TableAction>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 70, paddingRight: 384 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="subtitle1" style={{ whiteSpace: "pre-wrap" }}>
                {event.content}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ClientEventsListItem;
