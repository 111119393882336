import React, { useEffect, useState } from "react";
import { AppBar, Button, CircularProgress, Dialog, IconButton, Toolbar, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar } from "notistack";

import { TransitionUp } from "../utils/transitions/TransitionUp";
import EstimateService from "../../services/EstimateService";
import { isTouchScreenDevice } from "../../utils/app";
import AdditionalDocumentService from "../../services/AdditionalDocumentService";

const isMobile = isTouchScreenDevice();

function PreviewAdditionalDocumentDialog({ open, onClose, additionalDocument, enqueueSnackbar }) {
  const [filePath, setFilePath] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      AdditionalDocumentService.getAdditionalDocumentPDF(additionalDocument.id)
        .then(res => {
          const _blob = new Blob([res], { type: "application/pdf" });
          setFilePath(window.URL.createObjectURL(_blob));
          setIsLoading(false);
        })
        .catch(err => {
          if (err && err.status && err.status === 406) {
            enqueueSnackbar("La saisie ne permet pas de générer le document, veuillez modifier celui-ci", {
              variant: "error"
            });
            onClose();
          } else {
            enqueueSnackbar("Une erreur est survenue lors de l'affichage du PDF du document", {
              variant: "error"
            });
          }
        });
    } else {
      setFilePath("");
    }
  }, [open, additionalDocument]);

  const handleDownload = () => {
    const tempLink = document.createElement("a");
    tempLink.href = filePath;
    tempLink.setAttribute("download", `Document.pdf`);
    tempLink.click();
  };

  return (
    <Dialog
      fullScreen
      open={Boolean(open && (!!filePath || isLoading || isMobile))}
      onClose={onClose}
      TransitionComponent={TransitionUp}
    >
      <AppBar style={{ position: "static" }}>
        <Toolbar>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" style={{ paddingLeft: 8, flex: 1 }}>
            Document additionnel
          </Typography>
          {!isMobile && (
            <Button color="inherit" onClick={handleDownload}>
              Télécharger
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {filePath ? (
        isMobile ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <Typography style={{ marginTop: 16, marginBottom: 16 }} variant="body2">
              La prévisualisation du document n&apos;est pas disponible
            </Typography>
            <Button color="primary" variant="outlined" onClick={handleDownload}>
              Télécharger
            </Button>
          </div>
        ) : (
          <iframe src={filePath} style={{ width: "100%", height: "100%" }} />
        )
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <CircularProgress />
          <Typography style={{ marginTop: 16 }} variant="body2">
            Chargement du document
          </Typography>
        </div>
      )}
    </Dialog>
  );
}

export default withSnackbar(PreviewAdditionalDocumentDialog);
