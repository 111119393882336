import React, { Component, Fragment } from "react";
import { Fab, Grid, Hidden, Paper, TextField, Typography, withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { inject, observer } from "mobx-react";

import ProductService from "../../services/ProductService";
import OrderFormProduct from "./OrderFormProduct";
import OrderFormOptimus from "./OrderFormOptimus";
import OrderFormCurrency from "./OrderFormCurrency";

const styles = theme => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    padding: theme.spacing()
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  paperNature: {
    padding: theme.spacing(2)
  },
  typoNature: {
    // marginTop: theme.spacing(2)
  }
});

@inject("newOrderFormStore", "sessionStore")
@observer
class OrderFormActionStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: []
    };
  }

  componentDidMount() {
    ProductService.getProducts({ productsStatus: "ACTIVE" }).then(response =>
      this.setState({ suggestions: response.content })
    );
  }

  handleClick = () => {
    const { newOrderFormStore } = this.props;
    newOrderFormStore.handleAddDetail();
    setTimeout(() => {
      this.formBottom.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  isNatureInError = () => {
    const { newOrderFormStore } = this.props;
    const value = newOrderFormStore.isFirstDetailOk && newOrderFormStore.nature === "";
    return value;
  };

  getLimitDate = () =>
    moment()
      // .add(15, "days")
      .format("YYYY-MM-DD");

  render() {
    const { classes, newOrderFormStore, sessionStore } = this.props;
    const { suggestions } = this.state;

    return (
      <Fragment>
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          {sessionStore.userHasAccess({ requiredFeatures: ["OPTION_CURRENCY"] }) && (
            <Grid item xs={12}>
              <Typography variant="h5">Options</Typography>
              <OrderFormCurrency />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.typoNature}>
              Objet
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paperNature}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7} md={8}>
                  <TextField
                    autoFocus
                    fullWidth
                    label="Objet"
                    name="nature"
                    placeholder="Objet"
                    onChange={newOrderFormStore.handleChange}
                    required
                    value={newOrderFormStore.nature}
                    variant="outlined"
                    error={this.isNatureInError()}
                    helperText={this.isNatureInError() ? "L'objet doit être spécifié" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={4}>
                  <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                    <DatePicker
                      id="limitDate"
                      cancelLabel="Annuler"
                      format="D MMM YYYY"
                      inputVariant="outlined"
                      fullWidth
                      label="Date limite d'exécution des prestations"
                      leftArrowIcon={<KeyboardArrowLeftIcon />}
                      rightArrowIcon={<KeyboardArrowRightIcon />}
                      minDate={this.getLimitDate()}
                      value={newOrderFormStore.limitDate}
                      onChange={newOrderFormStore.handleChangeLimitDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {newOrderFormStore.details.length > 1 ? (
              <Typography variant="h5">Détails des prestations</Typography>
            ) : (
              <Typography variant="h5">Détail de la prestation</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {newOrderFormStore.details.map((detail, index) => (
              <OrderFormProduct
                canDraw
                detail={detail}
                index={index}
                key={`card-${detail.id}-${index + 1}`}
                suggestions={suggestions}
              />
            ))}
            <div
              ref={el => {
                this.formBottom = el;
              }}
            />
          </Grid>
        </Grid>
        {sessionStore.userHasAccess({ requiredFeatures: ["OPTION_OPTIMUS"] }) && (
          <Grid item xs={12}>
            <Typography variant="h5" style={{ marginBottom: 8 }}>
              Primes
            </Typography>
            <OrderFormOptimus />
          </Grid>
        )}
        <Hidden smDown>
          <Fab
            variant="extended"
            color="primary"
            aria-label="Ajouter une prestation"
            onClick={this.handleClick}
            className={classes.fab}
          >
            <AddIcon /> Ajouter une prestation au bon de commande
          </Fab>
        </Hidden>
        <Hidden mdUp>
          <Fab color="primary" aria-label="Ajouter une prestation" onClick={this.handleClick} className={classes.fab}>
            <AddIcon />
          </Fab>
        </Hidden>
      </Fragment>
    );
  }
}

export default withStyles(styles)(OrderFormActionStep);
