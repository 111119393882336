import { observable } from "mobx";

class Client {
  constructor(client) {
    const _client = client || {};
    const {
      businessName = "",
      firstname = "",
      lastname = "",
      phone = "",
      phone2 = "",
      email = "",
      address = "",
      zipCode = "",
      city = "",
      id = "",
      comments = "",
      reminderDate = null,
      installationDate = null
    } = _client;
    this.id = id;
    this.businessName = businessName;
    this.lastname = lastname;
    this.firstname = firstname;
    this.phone = phone;
    this.phone2 = phone2;
    this.email = email;
    this.address = address;
    this.zipCode = zipCode;
    this.city = city;
    this.comments = comments;
    this.type = businessName ? "morale" : "physique";
    this.installationDate = installationDate;
    this.reminderDate = reminderDate;
  }

  id = "";

  @observable
  type = "physique";

  @observable
  businessName = "";

  @observable
  lastname = "";

  @observable
  firstname = "";

  @observable
  phone = "";

  @observable
  email = "";

  @observable
  address = "";

  @observable
  zipCode = "";

  @observable
  city = "";

  @observable
  comments = "";

  @observable
  reminderDate = "";

  @observable
  installationDate = "";
}

export default Client;
