import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import AdditionalDocumentService from "../../services/AdditionalDocumentService";
import AdditionalDocumentsListRow from "./AdditionalDocumentsListRow";
import PreviewAdditionalDocumentDialog from "../dialog/PreviewAdditionalDocumentDialog";
import AdditionalDocumentYousignDialog from "../dialog/AdditionalDocumentYousignDialog";
import AdditionalDocumentsListFilter from "./AdditionalDocumentsListFilter";

const ROWS_PER_PAGE = 50;
const defaultFilter = {
  q: "",
  userId: "",
  status: [],
  from: null,
  to: null,
  client: ""
};

const styles = theme => ({
  container: { padding: theme.spacing(2) },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
    overflowX: "auto"
  },
  table: { minWidth: 700 },
  rowDeleted: {
    background: theme.palette.action.disabled,
    color: theme.palette.text.disabled,
    textDecoration: "line-through"
  },
  loadingCell: { paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) },
  buttonsContainer: {
    flex: 2,
    justifyContent: "flex-end",
    display: "flex"
  },
  rightAlign: { textAlign: "right" },
  centerAlign: { textAlign: "center" },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "rgb(224,224,244)"
  }
});

function AdditionalDocumentsList({ canSign, classes, user }) {
  const [filter, setFilter] = React.useState(defaultFilter);
  const [page, setPage] = React.useState(0);
  const [downloadHref, setDownloadHref] = React.useState(undefined);
  const [previewAD, setPreviewAD] = React.useState(undefined);
  const [selectedAdditionalDocument, setSelectedAdditionalDocument] = React.useState(undefined);
  const [showInitSignatureDialog, setShowInitSignatureDialog] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { isFetching, data, refetch } = useQuery({
    queryKey: ["additional_documents", { filter, page }],
    queryFn: () => AdditionalDocumentService.getAdditionalDocuments({ filter, page }),
    refetchOnWindowFocus: false,
    retry: 1
  });

  useEffect(() => {
    if (page !== 0) setPage(0);
  }, [filter]);

  const handleChangePage = (event, _page) => {
    setPage(_page);
  };

  const simulateClick = e => {
    if (e !== null) {
      e.click();
    }
    setDownloadHref(undefined);
  };

  const handleDownload = additionalDocumentId => {
    AdditionalDocumentService.getAdditionalDocumentPDF(additionalDocumentId)
      .then(res => {
        setDownloadHref(window.URL.createObjectURL(res));
      })
      .catch(err => {
        if (err && err.status && err.status === 406) {
          enqueueSnackbar("La saisie ne permet pas de télécharger le document, veuillez modifier celui-ci", {
            variant: "error"
          });
        } else {
          enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF du document", {
            variant: "error"
          });
        }
      });
  };

  const handlePreview = additionalDocument => {
    setPreviewAD(additionalDocument);
  };

  const initAdditionalDocumentSignature = additionalDocument => {
    setSelectedAdditionalDocument(additionalDocument);
    setShowInitSignatureDialog(true);
  };

  const additionalDocuments = data && data.content ? data.content : undefined;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} style={{ display: "flex", marginBottom: 24 }}>
        <Typography variant="h4">Documents additionnels</Typography>
      </Grid>
      <AdditionalDocumentsListFilter filter={filter} defaultFilter={defaultFilter} updateFilter={setFilter} />
      <Grid item xs={12}>
        <Paper className={classes.root}>
          {additionalDocuments && additionalDocuments.length >= 15 && (
            <TablePagination
              component="div"
              count={data.totalCount}
              rowsPerPage={ROWS_PER_PAGE}
              rowsPerPageOptions={[ROWS_PER_PAGE]}
              page={page}
              backIconButtonProps={{ "aria-label": "Page précédente" }}
              nextIconButtonProps={{ "aria-label": "Page suivante" }}
              onChangePage={handleChangePage}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
              className={classes.borderBottom}
            />
          )}
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell style={{ width: "20%" }}>Document</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {additionalDocuments && additionalDocuments.length >= 0 ? (
                additionalDocuments.map(additionalDocument => (
                  <AdditionalDocumentsListRow
                    key={additionalDocument.id}
                    additionalDocument={additionalDocument}
                    onUpdate={refetch}
                    canSign={canSign}
                    handleDownload={handleDownload}
                    handlePreview={handlePreview}
                    initAdditionalDocumentSignature={initAdditionalDocumentSignature}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center" className={classes.loadingCell}>
                    <CircularProgress size={40} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {additionalDocuments && additionalDocuments.length >= 0 && (
            <TablePagination
              component="div"
              count={data.totalCount}
              rowsPerPage={ROWS_PER_PAGE}
              rowsPerPageOptions={[ROWS_PER_PAGE]}
              page={page}
              backIconButtonProps={{ "aria-label": "Page précédente" }}
              nextIconButtonProps={{ "aria-label": "Page suivante" }}
              onChangePage={handleChangePage}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
            />
          )}
          {downloadHref !== "" && (
            <a ref={simulateClick} href={downloadHref} download="Document.pdf" style={{ display: "none" }}>
              Download
            </a>
          )}
          <PreviewAdditionalDocumentDialog
            open={Boolean(previewAD)}
            onClose={() => setPreviewAD(undefined)}
            additionalDocument={previewAD}
          />
          {showInitSignatureDialog && (
            <AdditionalDocumentYousignDialog
              currentUser={user}
              open={showInitSignatureDialog}
              doc={selectedAdditionalDocument}
              handleClose={(needRefresh = false) => {
                setSelectedAdditionalDocument(undefined);
                setShowInitSignatureDialog(false);
                if (needRefresh) refetch();
              }}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withRouter(withStyles(styles)(AdditionalDocumentsList));
