import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Collapse, ListSubheader, withStyles } from "@material-ui/core";
import SimpleMenuItem from "./SimpleMenuItem";

const styles = () => ({
  subhead: {
    cursor: "pointer",
    userSelect: "none"
  }
});

class CollapsableMenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: true
    };
  }

  render() {
    const { classes, handleToggle, item } = this.props;
    const { isExpanded } = this.state;
    return (
      <Fragment key={item.label}>
        <ListSubheader className={classes.subhead} onClick={() => this.setState({ isExpanded: !isExpanded })}>
          {item.label}
        </ListSubheader>
        <Collapse in={isExpanded}>
          {item.subItems.map(subItem => (
            <SimpleMenuItem isSubItem key={subItem.path} handleToggle={handleToggle} item={subItem} />
          ))}
        </Collapse>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(CollapsableMenuItem));
