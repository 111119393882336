import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { withStyles, Fab, Tabs, Tab, Grid, Typography, Hidden, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { privateRoutes } from "../routes";
import InvoicesList from "../components/invoice/InvoicesList";
import NotFoundPage from "./NotFoundPage";
import { INVOICE_STATUS } from "../utils/status";

const hashToTab = hash => {
  if (hash === "#brouillons") return "DRAFT";
  return "INVOICES";
};

const queryToFilter = search => {
  const params = new URLSearchParams(search);
  const filter = {};
  if (params.get("from")) {
    filter.from = moment(params.get("from"));
  }
  if (params.get("to")) {
    filter.to = moment(params.get("to"));
  }
  if (params.get("client")) {
    filter.client = params.get("client");
  }
  return filter;
};

const styles = theme => ({
  container: { padding: theme.spacing(2) },
  buttonsContainer: {
    flex: 2,
    justifyContent: "flex-end",
    display: "flex"
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  tabscontainer: { marginBottom: theme.spacing(2) },
  tab: { borderBottom: "1px solid #E6E6E6" }
});

function InvoicesPage({ classes, sessionStore, history, location }) {
  const [selectedItem, setSelectedItem] = React.useState(hashToTab(location.hash)); // INVOICES | DRAFT
  const [filter, setFilter] = React.useState(queryToFilter(location.search));

  useEffect(() => {
    setSelectedItem(hashToTab(location.hash));
    setFilter(queryToFilter(location.search));
  }, [location]);

  if (
    !sessionStore.userHasAccess({
      requiredAcls: ["INVOICE_MY", "INVOICE_READ"],
      aclType: "oneof",
      requiredFeatures: ["INVOICE"]
    })
  )
    return <NotFoundPage />;

  const disableCreation = !sessionStore.userHasAccess({
    requiredAcls: ["INVOICE_MY"],
    requiredFeatures: ["INVOICE"]
  });

  return (
    <Fragment>
      <Grid container className={classes.container}>
        <Grid item xs={12} style={{ display: "flex", marginBottom: 24 }}>
          <Typography variant="h4">Factures</Typography>
          {!disableCreation && (
            <div className={classes.buttonsContainer}>
              {!disableCreation && (
                <Hidden xsDown>
                  <Button
                    component={Link}
                    to={privateRoutes.admin.invoices.creationForm.path}
                    variant="outlined"
                    color="primary"
                  >
                    Nouvelle facture
                  </Button>
                </Hidden>
              )}
            </div>
          )}
        </Grid>
      </Grid>

      <Tabs
        value={selectedItem}
        onChange={(e, selectedTab) => {
          if (selectedTab === "DRAFT") {
            history.push({
              pathname: "/factures",
              hash: "#brouillons"
            });
          } else {
            history.push({
              pathname: "/factures",
              hash: ""
            });
          }
        }}
        variant="fullWidth"
        className={classes.tabscontainer}
      >
        <Tab value="INVOICES" label="Factures" className={classes.tab} />
        <Tab value="DRAFT" label="Brouillons" className={classes.tab} />
      </Tabs>
      {selectedItem === "INVOICES" && (
        <InvoicesList
          filter={{
            ...filter,
            status: Object.keys(INVOICE_STATUS)
              .filter(statusKey => statusKey !== "DRAFT")
              .map(statusKey => statusKey)
          }}
          disableCreation={disableCreation}
          hideDraft
        />
      )}
      {selectedItem === "DRAFT" && (
        <InvoicesList
          view="draft"
          hideStatusFilter
          disableExport
          filter={{ ...filter, status: ["DRAFT"] }}
          disableCreation={disableCreation}
        />
      )}
      {sessionStore.userHasAccess({
        requiredAcls: ["INVOICE_MY"],
        requiredFeatures: ["INVOICE"]
      }) && (
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          component={Link}
          to={privateRoutes.admin.invoices.creationForm.path}
        >
          <AddIcon />
        </Fab>
      )}
    </Fragment>
  );
}

export default withStyles(styles)(inject("sessionStore")(observer(props => <InvoicesPage {...props} />)));
