import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import ClientService, { ClientEventSchema } from "../../services/ClientService";
import ClientEventForm from "../form/ClientEventForm";
import SimplifiedClientEventForm from "../form/SimplifiedClientEventForm";

const DEFAULT_EVENT = {
  title: "",
  content: "",
  dueDate: null,
  status: "PENDING"
};

export default function AddEditClientEventDialog({
  open,
  initialEvent = DEFAULT_EVENT,
  onClose,
  clientId,
  simplified = false
}) {
  const { enqueueSnackbar } = useSnackbar();
  const handleEventCreation = values => {
    ClientService.addClientEvent(clientId, { ...values })
      .then(() => {
        onClose(true);
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la création de l'événement", { variant: "error" });
      });
  };

  const handleEventUpdate = values => {
    ClientService.patchClientEvent(clientId, initialEvent.id, { ...values })
      .then(() => {
        onClose(true);
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'événement", { variant: "error" });
      });
  };

  const handleClose = () => onClose();

  return open ? (
    <Formik
      initialValues={initialEvent}
      validationSchema={ClientEventSchema}
      onSubmit={values => {
        if (initialEvent && initialEvent.id) {
          handleEventUpdate(values);
        } else {
          handleEventCreation(values);
        }
      }}
    >
      {formikProps => (
        <Dialog maxWidth={false} open onClose={handleClose}>
          <DialogTitle id="event-dialog-title">
            {initialEvent && initialEvent.id
              ? simplified
                ? "Modification rapide"
                : "Modification d'un événement"
              : "Ajouter un événement"}
          </DialogTitle>
          <DialogContent>
            {simplified ? <SimplifiedClientEventForm formik={formikProps} /> : <ClientEventForm formik={formikProps} />}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>
            <Button variant="contained" color="primary" onClick={formikProps.handleSubmit} color="primary">
              {initialEvent && initialEvent.id ? "Modifier" : "Ajouter"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  ) : (
    <div />
  );
}
