import { Checkbox, FormControlLabel, Grid, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const useStyles = makeStyles(() => ({
  checkbox: { display: "flex" }
}));

function ClientEventForm({ formik }) {
  const classes = useStyles();
  const handleChange = event => {
    formik.setFieldValue("status", event.target.checked ? "DONE" : "PENDING");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Titre"
          value={formik.values.title}
          name="title"
          onChange={formik.handleChange}
          variant="outlined"
          error={Boolean(formik.errors.title && formik.touched.title)}
          helperText={formik.touched.title && formik.errors.title ? formik.errors.title : ""}
          onBlur={formik.handleBlur}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Contenu"
          value={formik.values.content}
          name="content"
          onChange={formik.handleChange}
          variant="outlined"
          error={Boolean(formik.errors.content)}
          helperText={formik.errors.content || ""}
          multiline
          rows="3"
        />
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
          <DatePicker
            id="dueDate"
            name="dueDate"
            cancelLabel="Annuler"
            format="D MMM YYYY"
            inputVariant="outlined"
            fullWidth
            label="Date limite"
            clearable
            clearLabel="Réinitialiser"
            leftArrowIcon={<KeyboardArrowLeftIcon />}
            rightArrowIcon={<KeyboardArrowRightIcon />}
            value={formik.values.dueDate}
            onChange={value => {
              formik.setFieldValue("dueDate", value);
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6} className={classes.checkbox}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.status === "DONE"}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label="Marquer comme fait"
        />
      </Grid>
    </Grid>
  );
}

export default ClientEventForm;
