import React, { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { inject, observer } from "mobx-react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/styles";
import { formatCurrency } from "../../utils/numberFormat";
import OptimusPrimeNameField from "../optimus/OptimusPrimeNameField";

const styles = theme => ({
  root: { marginBottom: theme.spacing(2) },
  content: { paddingBottom: `${theme.spacing(2)}px !important` }
});

@inject("newOrderFormStore")
@observer
class OrderFormOptimus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optimus: {
        id: uuidv4(),
        name: "",
        amount: ""
      },
      showForm: false
    };
  }

  handleShowForm = defaultValues => {
    if (defaultValues) {
      this.setState({ optimus: { ...defaultValues }, showForm: true });
    } else {
      this.setState({ showForm: true });
    }
  };

  hideAndResetForm = () => {
    this.setState({ showForm: false, optimus: { id: uuidv4(), name: "", amount: "" } });
  };

  handleSubmit = () => {
    const { optimus } = this.state;
    const { newOrderFormStore } = this.props;
    if (optimus.name && parseFloat(optimus.amount) > 0) {
      const _optimus = newOrderFormStore.optimus;
      const alreadyIn = _optimus.findIndex(item => item.id === optimus.id);
      if (alreadyIn > -1) {
        _optimus[alreadyIn] = { id: optimus.id, name: optimus.name, amount: optimus.amount };
      } else {
        _optimus.push({ id: optimus.id, name: optimus.name, amount: optimus.amount });
      }
      newOrderFormStore.updateOptimus([..._optimus]);
      this.hideAndResetForm();
    }
  };

  handleChange = e => {
    const { value, name } = e.target;
    const { optimus } = this.state;
    this.setState({ optimus: { ...optimus, [name]: value } });
  };

  handleNameChange = name => {
    const { optimus } = this.state;
    this.setState({ optimus: { ...optimus, name } });
  };

  handleDelete = item => {
    const { newOrderFormStore } = this.props;
    const _sanitized = newOrderFormStore.optimus.filter(_item => _item.id !== item.id);
    newOrderFormStore.updateOptimus([..._sanitized.map(_item => ({ ..._item }))]);
  };

  render() {
    const { showForm, optimus } = this.state;
    const { newOrderFormStore, classes } = this.props;
    const currentTotal = newOrderFormStore.optimus
      .filter(item => item.id !== optimus.id)
      .reduce((a, b) => a + parseFloat(b.amount), 0);
    return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">
                Vous pouvez inclure des lignes correspondantes à des primes à votre bon de commande, celle-ci
                n&apos;auront pas d&apos;incidence sur le montant total du bon de commande mais informerons votre client
              </Typography>
            </Grid>
            <Grid item xs={12} align="center">
              {newOrderFormStore.optimus.length > 0 && (
                <Table size="small" style={{ marginBottom: 8 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "60%" }}>Libellé</TableCell>
                      <TableCell style={{ width: "20%" }}>Montant</TableCell>
                      <TableCell style={{ width: 100 }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newOrderFormStore.optimus.map((item, index) => (
                      <TableRow key={`optimus-${item.id}-${index + 1}`}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{formatCurrency(item.amount)}</TableCell>
                        <TableCell>
                          <IconButton disabled={showForm} onClick={() => this.handleShowForm(item)} aria-label="Edit">
                            <EditIcon />
                          </IconButton>
                          <IconButton disabled={showForm} onClick={() => this.handleDelete(item)} aria-label="Delete">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}

              {showForm ? (
                <Grid container spacing={2} style={{ marginTop: 8, marginBottom: 8 }}>
                  <Grid item xs={8}>
                    <OptimusPrimeNameField value={optimus.name} onChange={this.handleNameChange} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="number"
                      name="amount"
                      placeholder="199,00"
                      label="Montant"
                      fullWidth
                      variant="outlined"
                      value={optimus.amount}
                      size="small"
                      autoComplete="off"
                      onChange={this.handleChange}
                      inputProps={{ min: 0, step: 0.1, max: newOrderFormStore.amount - currentTotal }}
                      helperText={`Ne peut excéder ${formatCurrency(newOrderFormStore.amount - currentTotal)}`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={this.hideAndResetForm}
                      style={{ marginLeft: 8, marginRight: 8 }}
                    >
                      Annuler
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.handleSubmit}
                      style={{ marginLeft: 8, marginRight: 8 }}
                    >
                      Enregistrer la prime
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                newOrderFormStore.optimus.length < 6 && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.handleShowForm()}
                    style={{ marginTop: 8 }}
                  >
                    Ajouter une prime
                  </Button>
                )
              )}
            </Grid>
            <Grid item xs={12} align="center">
              {newOrderFormStore.amount - currentTotal < 0 && (
                <Typography variant="body2" style={{ color: "red" }}>
                  Le total des primes ne peut excéder le total du bon de commande
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(OrderFormOptimus);
