import React, { Component, Fragment } from "react";
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import { inject, observer } from "mobx-react";

@inject("sessionStore")
@observer
class OptimusPrimeNameField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelect: true,
      selectValue: props.value || ""
    };
  }

  handleInputChange = e => {
    const { onChange } = this.props;
    const { value: eventValue } = e.target;
    onChange(eventValue);
  };

  handleSelectChange = e => {
    const { onChange } = this.props;
    const { value: eventValue } = e.target;
    if (eventValue === "OTHER") {
      this.setState({ showSelect: false });
    } else {
      onChange(eventValue);
      this.setState({ showSelect: true, selectValue: eventValue });
    }
  };

  render() {
    const { value, sessionStore } = this.props;
    const { showSelect, selectValue } = this.state;
    if (sessionStore.user && sessionStore.user.company && sessionStore.user.company.allowances) {
      const { allowances } = sessionStore.user.company;
      return (
        <Fragment>
          {showSelect && (
            <FormControl variant="outlined" size="small" fullWidth>
              <Select
                fullWidth
                value={selectValue}
                onChange={this.handleSelectChange}
                displayEmpty
                variant="outlined"
                style={{ textAlign: "left" }}
              >
                <MenuItem value="" disabled>
                  <em> -- Sélectionnez une prime --</em>
                </MenuItem>
                {allowances.map(allowance => (
                  <MenuItem value={allowance}>{allowance}</MenuItem>
                ))}
                <MenuItem value="OTHER">Autre</MenuItem>
              </Select>
            </FormControl>
          )}
          <TextField
            style={{ visibility: showSelect ? "hidden" : "visible" }}
            name="primeName"
            placeholder="Prime"
            label="Libellé"
            fullWidth
            variant="outlined"
            value={value}
            size="small"
            onChange={this.handleInputChange}
          />
        </Fragment>
      );
    }
    return (
      <TextField
        name="primeName"
        placeholder="Prime"
        label="Libellé"
        fullWidth
        variant="outlined"
        value={value}
        size="small"
        onChange={this.handleInputChange}
      />
    );
  }
}

export default OptimusPrimeNameField;
