import React, { Component } from "react";
import { withStyles, Grid, Paper, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";

import OrderFormActionStep from "../components/orderForm/OrderFormActionStep";
import NotFoundPage from "./NotFoundPage";
import OrderFormClientStep from "../components/orderForm/OrderFormClientStep";
import BottomConfirmation from "../components/orderForm/BottomConfirmation";
import OrderFormComplementaryStep from "../components/orderForm/OrderFormComplementaryStep";
import FormStepper from "../components/FormStepper";

const styles = theme => ({
  container: {
    padding: theme.spacing()
  },
  title: {
    marginBottom: theme.spacing(2)
  }
});

const STEPS = ["Prestations", "Coordonnées", "Compléments"];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <OrderFormActionStep />;
    case 1:
      return <OrderFormClientStep />;
    case 2:
      return <OrderFormComplementaryStep />;
    default:
      return <NotFoundPage />;
  }
}

@inject("newOrderFormStore", "sessionStore")
@observer
class NewOrderFormPage extends Component {
  constructor(props, context) {
    super(props, context);
    const { location, newOrderFormStore } = props;
    if (location.state && location.state.fromOrderForm) {
      newOrderFormStore.initWithExistant(location.state.fromOrderForm, {
        limitDate: props.sessionStore.orderFormLimitDate
      });
    } else if (location.state && location.state.client) {
      newOrderFormStore.initWithClient(location.state.client, {
        limitDate: props.sessionStore.orderFormLimitDate
      });
    } else {
      newOrderFormStore.init({
        limitDate: props.sessionStore.orderFormLimitDate
      });
    }
    this.state = {
      activeStep: 0,
      skipped: new Set()
    };
  }

  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + 1,
      skipped
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  // handleSkip = () => {
  //   const { activeStep } = this.state;

  //   this.setState(state => {
  //     const skipped = new Set(state.skipped.values());
  //     skipped.add(activeStep);

  //     return {
  //       activeStep: state.activeStep + 1,
  //       skipped
  //     };
  //   });
  // };

  isStepSkipped(step) {
    const { skipped } = this.state;

    return skipped.has(step);
  }

  isNextStepButtonDisabled(step) {
    const { newOrderFormStore } = this.props;
    switch (step) {
      case 0:
        return !newOrderFormStore.isNewOrderFormActionOK;
      case 1:
        return !newOrderFormStore.isNewOrderFormClientOK || !newOrderFormStore.isNewOrderFormDeliveryAddressOK;
      default:
        return false;
    }
  }

  render() {
    const { classes, sessionStore } = this.props;
    const { activeStep } = this.state;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["ORDER_MY", "ORDER_WRITE"],
        aclType: "oneof",
        requiredFeatures: ["ORDER"]
      })
    )
      return <NotFoundPage />;

    return (
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h4">Nouveau bon de commande</Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Paper>
            <FormStepper activeStep={activeStep} steps={STEPS} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {getStepContent(activeStep)}
        </Grid>
        <Grid item xs={12}>
          <BottomConfirmation
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            activeStep={activeStep}
            steps={STEPS}
            disableBack={activeStep === 0}
            disableNext={this.isNextStepButtonDisabled(activeStep)}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(NewOrderFormPage));
