import React from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  signatory: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    "& > p": {
      marginRight: theme.spacing(2)
    }
  },
  centerButton: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    display: "flex",
    justifyContent: "center"
  }
}));

export default function SignaturesListItemSignatory({ signature, launchPadSignature }) {
  const classes = useStyles();

  if (signature.signatureType === "PAD") {
    return (
      <Box>
        {signature.signatories.map(signatory => (
          <Box style={{ paddingLeft: 57 }} key={signatory.id}>
            {signatory.contact ? (
              <Box className={classes.signatory}>
                {!signatory.hasSigned && !signature.signatureRequestDoneDate && !signature.transactionCloseDate && (
                  <HourglassEmptyIcon fontSize="small" style={{ marginRight: 16 }} />
                )}
                {(signatory.hasSigned || signature.signatureRequestDoneDate || signature.transactionCloseDate) && (
                  <VerifiedUserIcon fontSize="small" style={{ marginRight: 16 }} />
                )}
                <Typography variant="body2">
                  {signatory.contact.firstname} {signatory.contact.lastname}
                </Typography>
              </Box>
            ) : (
              <Box className={classes.signatory}>
                {!signatory.hasSigned && !signature.signatureRequestDoneDate && !signature.transactionCloseDate && (
                  <HourglassEmptyIcon fontSize="small" style={{ marginRight: 16 }} />
                )}
                {(signatory.hasSigned || signature.signatureRequestDoneDate || signature.transactionCloseDate) && (
                  <VerifiedUserIcon fontSize="small" style={{ marginRight: 16 }} />
                )}
                <Typography variant="body2">Vous</Typography>
              </Box>
            )}
          </Box>
        ))}
        {signature.status === "WAITING_SIGN" && (
          <Box className={classes.centerButton}>
            <Button variant="contained" color="primary" onClick={launchPadSignature}>
              Signer
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box>
      {signature.signatories.map(signatory => (
        <Box style={{ paddingLeft: 57 }} key={signatory.id}>
          {signatory.contact ? (
            <Box className={classes.signatory}>
              {signature.status === "WAITING_SIGN" && signatory.hasSigned && (
                <VerifiedUserIcon fontSize="small" style={{ marginRight: 16 }} />
              )}
              {signature.status === "WAITING_SIGN" && !signatory.hasSigned && (
                <HourglassEmptyIcon fontSize="small" style={{ marginRight: 16 }} />
              )}
              <Typography variant="body2">
                {signatory.contact.firstname} {signatory.contact.lastname}
              </Typography>
              {signature.status === "WAITING_SIGN" && !signatory.hasSigned && (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  target="_blank"
                  component={Link}
                  to={`/doc-signatures/${signature.id}/signatories/${signatory.id}`}
                >
                  Faire signer
                </Button>
              )}
            </Box>
          ) : (
            <Box className={classes.signatory}>
              {signature.status === "WAITING_SIGN" && signatory.hasSigned && (
                <VerifiedUserIcon fontSize="small" style={{ marginRight: 16 }} />
              )}
              {signature.status === "WAITING_SIGN" && !signatory.hasSigned && (
                <HourglassEmptyIcon fontSize="small" style={{ marginRight: 16 }} />
              )}
              <Typography variant="body2">Vous</Typography>
              {signature.status === "WAITING_SIGN" && !signatory.hasSigned && (
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  target="_blank"
                  component={Link}
                  to={`/doc-signatures/${signature.id}/signatories/${signatory.id}`}
                >
                  Signer
                </Button>
              )}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
