import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  FormControl,
  Grid,
  Paper,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  withStyles
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { FINANCIAL_METHOD } from "../../stores/NewInvoiceStore";

const styles = theme => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    padding: theme.spacing()
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  group: {
    flexDirection: "row"
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  wrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
});

@inject("newInvoiceStore")
@observer
class InvoiceComplementaryStep extends Component {
  componentDidMount() {
    // Used for the ref to work.
    // https://v3-9-0.material-ui.com/demos/text-fields/#components
    // This will probably be better with V4 of MUI
    this.forceUpdate();
  }

  render() {
    const { classes, newInvoiceStore } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={6} className={classes.item}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel
                htmlFor="discount"
                ref={ref => {
                  // eslint-disable-next-line react/no-find-dom-node
                  this.discountLabelRef = ReactDOM.findDOMNode(ref);
                }}
              >
                Remise TTC
              </InputLabel>
              <OutlinedInput
                id="discount"
                name="discount"
                type="number"
                value={newInvoiceStore.discount}
                onChange={newInvoiceStore.handleChange}
                inputProps={{ min: "0" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle discount method"
                      onClick={newInvoiceStore.handleChangeDiscountMethod}
                    >
                      {newInvoiceStore.discountMethod === FINANCIAL_METHOD.PERCENTAGE ? "%" : "€"}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={this.discountLabelRef ? this.discountLabelRef.offsetWidth : 0}
              />
              <FormHelperText id="discount-helper-text">
                soit une remise de {newInvoiceStore.computedDiscount}€
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(InvoiceComplementaryStep);
