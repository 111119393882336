import green from "@material-ui/core/colors/green";
import indigo from "@material-ui/core/colors/indigo";
import moment from "moment";

export const COLORS = [green[600], indigo[400]];

export const MIN_DATE = moment("2018-01-01");

export const MAX_DATE = moment().endOf("month");

export const CURRENT_DATE = moment();
