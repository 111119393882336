import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  makeStyles
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  label: { width: "100%" }
}));

const mergeAdditionalInformations = (company, defaultAdditionalInformation) => {
  const companyAdditionalInformations =
    company.additionalInformation && company.additionalInformation.length > 0 ? [...company.additionalInformation] : [];
  return companyAdditionalInformations.map(item => {
    const _item = defaultAdditionalInformation.defined.find(_it => _it.id === item.id);
    if (_item) return _item;
    return item;
  });
};

export default function DocumentAdditionnalInformations({ company, defaultAdditionalInformation, onPatch }) {
  const classes = useStyles();
  const [additionalInformations, setAdditionalInformations] = React.useState(
    mergeAdditionalInformations(company, defaultAdditionalInformation)
  );
  const [selected, setSelected] = React.useState([
    ...defaultAdditionalInformation.defined.filter(item => additionalInformations.find(_it => _it.id === item.id))
  ]);
  const [extraAdditionalInformation, setExtraAdditionalInformation] = React.useState(
    defaultAdditionalInformation.extra
  );

  const handleChange = e => {
    const _selected = selected.find(el => el.id === e.target.name)
      ? selected.filter(item => item.id !== e.target.name)
      : [
          ...selected,
          {
            ...additionalInformations.find(item => item.id === e.target.name)
          }
        ];

    setSelected([..._selected]);
    onPatch({ defined: _selected });
  };

  const handleExtraChange = e => {
    setExtraAdditionalInformation(e.target.value);
    onPatch({ extra: e.target.value });
  };

  const handleMentionChange = (e, index) => {
    const _additionalInformations = [...additionalInformations];
    const _currentIsSelected = selected.find(el => el.id === _additionalInformations[index].id);
    _additionalInformations[index].content = e.target.value;
    setAdditionalInformations(_additionalInformations);
    if (_currentIsSelected) {
      const _selected = selected.map(item => {
        if (item.id === _additionalInformations[index].id) {
          return { ...item, content: e.target.value };
        }
        return item;
      });
      setSelected([..._selected]);
      onPatch({ defined: _selected });
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 16, paddingTop: 8, paddingBottom: 8 }}>
      {additionalInformations.length > 0 && (
        <FormControl component="fieldset">
          <FormLabel component="legend">Mentions disponibles</FormLabel>
          <FormGroup style={{ paddingTop: 6, gap: 6 }}>
            {additionalInformations.map((additionalInformation, index) => (
              <FormControlLabel
                style={{ alignItems: "flex-start", width: "100%" }}
                classes={{ label: classes.label }}
                control={
                  <Checkbox
                    key={additionalInformation.id}
                    checked={!!selected.find(el => additionalInformation.id === el.id)}
                    onChange={handleChange}
                    name={additionalInformation.id}
                    color="primary"
                  />
                }
                label={
                  <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                    {additionalInformation.picture && (
                      <img
                        src={additionalInformation.picture}
                        alt="currentPicture"
                        style={{ maxWidth: 100, maxHeight: 50 }}
                      />
                    )}
                    <TextField
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                      value={additionalInformation.content}
                      onChange={e => handleMentionChange(e, index)}
                    />
                  </div>
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      )}
      {additionalInformations.length > 0 && <FormLabel>Mentions supplémentaires (saisie libre)</FormLabel>}
      <TextField
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={extraAdditionalInformation}
        onChange={handleExtraChange}
      />
    </div>
  );
}
