import React, { useEffect, useState } from "react";
import { AppBar, Button, CircularProgress, Dialog, IconButton, Toolbar, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar } from "notistack";

import { TransitionUp } from "../utils/transitions/TransitionUp";
import ContraliaService from "../../services/ContraliaService";
import YouSignService from "../../services/YouSignService";
import OrderFormService from "../../services/OrderFormService";
import { isTouchScreenDevice } from "../../utils/app";

const isMobile = isTouchScreenDevice();

function PreviewOrderFormDialog({ open, onClose, orderForm, enqueueSnackbar }) {
  const [filePath, setFilePath] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      if (
        orderForm.transactionId &&
        (!OrderFormService.signatureExpired(orderForm) || orderForm.transactionCloseDate)
      ) {
        ContraliaService.getDownloadPDF(orderForm.transactionId)
          .then(res => {
            const _blob = new Blob([res], { type: "application/pdf" });
            setFilePath(window.URL.createObjectURL(_blob));
            setIsLoading(false);
          })
          .catch(() => {
            enqueueSnackbar("Une erreur est survenue lors de l'affichage du PDF du bon de commande", {
              variant: "error"
            });
          });
      } else if (
        orderForm.signatureRequestId &&
        (!OrderFormService.signatureExpired(orderForm) || orderForm.signatureRequestDoneDate)
      ) {
        YouSignService.getDownloadOrderPDF(orderForm.signatureRequestId)
          .then(res => {
            const _blob = new Blob([res], { type: "application/pdf" });
            setFilePath(window.URL.createObjectURL(_blob));
            setIsLoading(false);
          })
          .catch(() => {
            enqueueSnackbar("Une erreur est survenue lors de l'affichage du PDF du bon de commande", {
              variant: "error"
            });
          });
      } else {
        OrderFormService.downloadRawPDF(orderForm.id)
          .then(res => {
            const _blob = new Blob([res], { type: "application/pdf" });
            setFilePath(window.URL.createObjectURL(_blob));
            setIsLoading(false);
          })
          .catch(() => {
            enqueueSnackbar("Une erreur est survenue lors de l'affichage du PDF du bon de commande", {
              variant: "error"
            });
          });
        setFilePath("");
      }
    } else {
      setFilePath("");
    }
  }, [open, orderForm]);

  const handleDownload = () => {
    const tempLink = document.createElement("a");
    tempLink.href = filePath;
    tempLink.setAttribute("download", `${orderForm.number}.pdf`);
    tempLink.click();
  };

  return (
    <Dialog
      fullScreen
      open={Boolean(open && (!!filePath || isLoading || isMobile))}
      onClose={onClose}
      TransitionComponent={TransitionUp}
    >
      <AppBar style={{ position: "static" }}>
        <Toolbar>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" style={{ paddingLeft: 8, flex: 1 }}>
            Bon de commande {orderForm ? orderForm.number : ""}
          </Typography>
          {!isMobile && (
            <Button color="inherit" onClick={handleDownload}>
              Télécharger
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {filePath ? (
        isMobile ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <Typography style={{ marginTop: 16, marginBottom: 16 }} variant="body2">
              La prévisualisation du bon de commande n&apos;est pas disponible
            </Typography>
            <Button color="primary" variant="outlined" onClick={handleDownload}>
              Télécharger
            </Button>
          </div>
        ) : (
          <iframe src={filePath} style={{ width: "100%", height: "100%" }} />
        )
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <CircularProgress />
          <Typography style={{ marginTop: 16 }} variant="body2">
            Chargement du bon de commande
          </Typography>
        </div>
      )}
    </Dialog>
  );
}

export default withSnackbar(PreviewOrderFormDialog);
