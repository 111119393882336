import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

export default function FinancingItem({ title, value }) {
  return (
    <Fragment>
      <Typography variant="subtitle1" style={{ fontWeight: 300 }}>
        {title}
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 600 }}>
        {value}
      </Typography>
    </Fragment>
  );
}
