import React, { Component } from "react";
import { withRouter } from "react-router";
import { withStyles, Grid, Paper, Typography, CircularProgress } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import InvoiceActionStep from "../components/invoice/InvoiceActionStep";
import NotFoundPage from "./NotFoundPage";
import InvoiceClientStep from "../components/invoice/InvoiceClientStep";
import BottomConfirmation from "../components/invoice/BottomConfirmation";
import InvoiceComplementaryStep from "../components/invoice/InvoiceComplementaryStep";
import InvoiceService from "../services/InvoiceService";
import { privateRoutes } from "../routes";
import FormStepper from "../components/FormStepper";

const styles = theme => ({
  container: {
    padding: theme.spacing()
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center"
  }
});

const STEPS = ["Prestations", "Coordonnées"];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <InvoiceActionStep />;
    case 1:
      return <InvoiceClientStep />;
    case 2:
      return <InvoiceComplementaryStep />;
    default:
      return <NotFoundPage />;
  }
}

@inject("newInvoiceStore", "sessionStore")
@observer
class InvoicePage extends Component {
  constructor(props, context) {
    super(props, context);
    const isAnEdition = Boolean(props.match && props.match.params && props.match.params.invoiceId);
    this.state = {
      activeStep: 0,
      skipped: new Set(),
      isLoading: isAnEdition
    };
    if (!isAnEdition) {
      if (props.location.state && props.location.state.client) {
        props.newInvoiceStore.initWithClient(props.location.state.client);
      } else {
        props.newInvoiceStore.init();
      }
    }
  }

  componentDidMount() {
    const { isLoading } = this.state;
    const {
      history,
      newInvoiceStore,
      match: {
        params: { invoiceId }
      }
    } = this.props;
    if (isLoading && this.hasAccess()) {
      InvoiceService.getInvoice(invoiceId)
        .then(invoice => {
          if (invoice.status !== "DRAFT") {
            history.push(privateRoutes.admin.invoices.listing.path);
          } else {
            newInvoiceStore.initWithExistant(invoice);
            this.setState({ isLoading: false });
          }
        })
        .catch(() => {
          history.push(privateRoutes.admin.invoices.listing.path);
        });
    }
  }

  hasAccess = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["INVOICE_WRITE", "INVOICE_MY"],
      aclType: "oneof",
      requiredFeatures: ["INVOICE"]
    });
  };

  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + 1,
      skipped
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  // handleSkip = () => {
  //   const { activeStep } = this.state;
  //   this.setState(state => {
  //     const skipped = new Set(state.skipped.values());
  //     skipped.add(activeStep);
  //     return {
  //       activeStep: state.activeStep + 1,
  //       skipped
  //     };
  //   });
  // };

  isStepSkipped(step) {
    const { skipped } = this.state;
    return skipped.has(step);
  }

  isNextStepButtonDisabled(step) {
    const { newInvoiceStore } = this.props;
    switch (step) {
      case 0:
        return !newInvoiceStore.isNewInvoiceActionOK;
      case 1:
        return !newInvoiceStore.isNewInvoiceClientOK;
      default:
        return false;
    }
  }

  render() {
    const { classes, newInvoiceStore } = this.props;
    const { activeStep, isLoading } = this.state;

    if (!this.hasAccess()) return <NotFoundPage />;

    if (isLoading) {
      return (
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={12} className={classes.loaderWrapper}>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h4">
            {newInvoiceStore.id ? `Modification de la facture ${newInvoiceStore.number || ""}` : "Nouvelle facture"}
          </Typography>
          {newInvoiceStore.order && (
            <Typography variant="h6">liée au bon de commande {newInvoiceStore.order.number}</Typography>
          )}
          {newInvoiceStore.estimate && (
            <Typography variant="h6">liée au devis {newInvoiceStore.estimate.number}</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FormStepper activeStep={activeStep} steps={STEPS} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {getStepContent(activeStep)}
        </Grid>
        <Grid item xs={12}>
          <BottomConfirmation
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            activeStep={activeStep}
            steps={STEPS}
            disableBack={activeStep === 0}
            disableNext={this.isNextStepButtonDisabled(activeStep)}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(InvoicePage));
