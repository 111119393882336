import React, { Component, Fragment } from "react";
import moment from "moment";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

import { formatCurrency } from "../../../utils/numberFormat";
import OrderFormStatusLabel from "../../orderForm/OrderFormStatusLabel";

const ITEMS_PER_PAGE = 15;

class OrderFormsList extends Component {
  state = { currentPage: 0 };

  render() {
    const { orders } = this.props;
    const { currentPage } = this.state;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Numéro</TableCell>
            <TableCell>Client / Société</TableCell>
            <TableCell>Objet</TableCell>
            <TableCell>Utilisateur</TableCell>
            <TableCell>Statut</TableCell>
            <TableCell>Montant HT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.length > 0 ? (
            orders.slice(currentPage * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE).map(order => (
              <TableRow key={order.id}>
                <TableCell>{moment(order.createAt).format("DD/MM/YYYY")}</TableCell>
                <TableCell>N°{order.number}</TableCell>
                <TableCell>
                  {order.clientBusinessName && (
                    <Fragment>
                      <b>{order.clientBusinessName}</b>
                      <br />
                    </Fragment>
                  )}
                  {`${order.clientFirstname} ${order.clientLastname.toUpperCase()}`}
                </TableCell>
                <TableCell>
                  <b>{order.nature}</b>
                </TableCell>
                <TableCell>{order.user && `${order.user.firstname} ${order.user.lastname.toUpperCase()}`}</TableCell>
                <TableCell>
                  <OrderFormStatusLabel orderForm={order} />
                </TableCell>
                <TableCell>{formatCurrency(order.totalPriceWithoutTVA, order.currency)}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} align="center">
                Aucun bon de commande établi sur la période
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }
}

export default OrderFormsList;
