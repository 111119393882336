import { withRouter } from "react-router-dom";
import React, { Fragment, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Typography, withStyles, Tabs, Tab } from "@material-ui/core";

import ProductListing from "../components/product/ProductListing";

const hashToTab = hash => {
  if (hash === "#inactifs") return "INACTIVE";
  return "ACTIVE";
};

function ProductListingPage({ location, classes, history, sessionStore }) {
  const [selectedItem, setSelectedItem] = React.useState(hashToTab(location.hash)); // ACTIVE | INACTIVE

  useEffect(() => {
    setSelectedItem(hashToTab(location.hash));
  }, [location]);

  return (
    <Fragment>
      <div className={classes.head}>
        <Typography variant="h4">Catalogue</Typography>
      </div>
      <Tabs
        value={selectedItem}
        onChange={(e, selectedTab) => {
          if (selectedTab === "INACTIVE") {
            history.push({ pathname: "/catalogue", hash: "#inactifs" });
          } else {
            history.push({ pathname: "/catalogue", hash: "" });
          }
        }}
        variant="fullWidth"
        className={classes.tabscontainer}
      >
        <Tab value="ACTIVE" label="Catalogue produits" className={classes.tab} />
        <Tab value="INACTIVE" label="Produits désactivés" className={classes.tab} />
      </Tabs>
      {selectedItem === "ACTIVE" && <ProductListing sessionStore={sessionStore} productsStatus="ACTIVE" />}
      {selectedItem === "INACTIVE" && <ProductListing sessionStore={sessionStore} productsStatus="INACTIVE" />}
    </Fragment>
  );
}

const styles = theme => ({
  head: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`
  },
  tabscontainer: { marginBottom: theme.spacing(2) },
  tab: { borderBottom: "1px solid #E6E6E6" }
});

export default withRouter(
  withStyles(styles)(inject("sessionStore")(observer(props => <ProductListingPage {...props} />)))
);
