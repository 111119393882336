import { useSnackbar } from "notistack";
import React from "react";
import ClientService from "../services/ClientService";

function useClientEvents(clientId) {
  const [isRequesting, setIsRequesting] = React.useState(true);
  const [events, setEvents] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const fetchEvents = _clientId => {
    setIsRequesting(true);
    ClientService.getClientEvents(_clientId, page)
      .then(res => {
        setEvents(res.events);
        setTotal(res.eventsCount);
      })
      .catch(() => {
        setEvents(null);
        setTotal(0);
        enqueueSnackbar("Une erreur est survenue lors de la récupération de l'historique des événements");
      })
      .finally(() => {
        setIsRequesting(false);
      });
  };

  React.useEffect(() => {
    fetchEvents(clientId, page);
  }, [clientId, page]);

  const deleteEvent = eventId => {
    ClientService.deleteClientEvent(clientId, eventId)
      .then(() => {
        fetchEvents(clientId, page);
        enqueueSnackbar("L'événement a bien été supprimé", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la suppression de l'événement", { variant: "error" });
      });
  };

  const refresh = (_config = {}) => {
    fetchEvents(clientId, _config.page || page);
  };

  return [isRequesting, events, total, { page, setPage }, { refresh, deleteEvent }];
}

export default useClientEvents;
