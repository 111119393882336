import React from "react";
import { Avatar, Chip, withStyles } from "@material-ui/core";

import { SIGNATURE_STATUS } from "../../utils/status";

const styles = () => ({
  chip: {
    color: "white"
  }
});

const SignatureStatusLabel = ({ signature, signatureStatus, classes, asIcon }) => {
  const status = signatureStatus || signature.status;
  if (asIcon) {
    const { Icon } = SIGNATURE_STATUS[status];
    return (
      <Avatar style={{ backgroundColor: SIGNATURE_STATUS[status].color }}>
        <Icon />
      </Avatar>
    );
    // return (
    //   <Chip
    //     className={classes.chip}
    //     label={SIGNATURE_STATUS[status].label || "Statut inconnu"}
    //     style={{ backgroundColor: SIGNATURE_STATUS[status].color || "#777777" }}
    //   />
    // );
  }
  return (
    <Chip
      className={classes.chip}
      label={SIGNATURE_STATUS[status].label || "Statut inconnu"}
      style={{ backgroundColor: SIGNATURE_STATUS[status].color || "#777777" }}
    />
  );
};

export default withStyles(styles)(SignatureStatusLabel);
