import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Button, Typography, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import ListAlt from "@material-ui/icons/ListAlt";

import { privateRoutes } from "../routes";
import KKHuge from "../assets/images/kingkang_huge.png";

const styles = theme => ({
  mainContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 250
    }
  },
  contentContainer: {
    width: "90%",
    maxWidth: 800,
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonsContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    justifyContent: "flex-start"
  },
  buttonWrapper: {
    marginBottom: theme.spacing(),
    width: 250
  },
  buttonIcon: {
    marginRight: theme.spacing()
  },
  logo: {
    maxHeight: "80%",
    maxWidth: "80%",
    position: "absolute",
    opacity: 0.1,
    zIndex: -1
  }
});

@inject("sessionStore")
@observer
class HomePage extends Component {
  render() {
    const { classes, sessionStore } = this.props;
    const { user } = sessionStore;
    return (
      <div className={classes.mainContainer} style={{ paddingLeft: 0 }}>
        <img src={KKHuge} className={classes.logo} />
        <div className={classes.contentContainer}>
          <div>
            <Typography variant="h4">Bonjour {user.firstname},</Typography>
            <Typography>Que souhaitez-vous faire ?</Typography>
            <div className={classes.buttonsContainer}>
              {sessionStore.userHasAccess({
                requiredAcls: ["ESTIMATE_MY"],
                requiredFeatures: ["ESTIMATE"]
              }) && (
                <div className={classes.buttonWrapper}>
                  <Button
                    component={Link}
                    to={privateRoutes.common.estimates.creationForm.path}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.button}
                    disabled={user.company.CGV === null || user.company.withdrawal === null}
                  >
                    <AddIcon className={classes.buttonIcon} />
                    Nouveau devis
                  </Button>
                </div>
              )}
              {sessionStore.userHasAccess({
                requiredAcls: ["ORDER_MY"],
                aclType: "oneof",
                requiredFeatures: ["ORDER"]
              }) && (
                <div className={classes.buttonWrapper}>
                  <Button
                    component={Link}
                    to={privateRoutes.common.orderForms.creationForm.path}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.button}
                    disabled={user.company.CGV === null || user.company.withdrawal === null}
                  >
                    <AddIcon className={classes.buttonIcon} />
                    Nouveau bon
                  </Button>
                </div>
              )}
              {sessionStore.userHasAccess({
                requiredAcls: ["INVOICE_MY"],
                aclType: "oneof",
                requiredFeatures: ["INVOICE"]
              }) && (
                <div className={classes.buttonWrapper}>
                  <Button
                    component={Link}
                    to={privateRoutes.admin.invoices.creationForm.path}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.button}
                  >
                    <AddIcon className={classes.buttonIcon} />
                    Nouvelle facture
                  </Button>
                </div>
              )}
              <div className={classes.buttonWrapper}>
                <Button
                  component={Link}
                  to={privateRoutes.common.clients.path}
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                >
                  <ListAlt className={classes.buttonIcon} />
                  Annuaire des clients
                </Button>
              </div>
              <div className={classes.buttonWrapper}>
                <Button
                  component={Link}
                  to={privateRoutes.admin.products.path}
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                >
                  <ListAlt className={classes.buttonIcon} />
                  Catalogue produit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(HomePage);
