import React from "react";
import { observable, action, computed } from "mobx";
import moment from "moment";
import { Decimal } from "decimal.js-light";
import CashForm from "../components/invoice/complementaryStepForms/CashForm";
import FinancingForm from "../components/invoice/complementaryStepForms/FinancingForm";
import Client from "../classes/Client";
import Product, { TVAS_DROPDOWN } from "../classes/Product";
import ValidatorService from "../services/ValidatorService";

const PAYMENT_CONDITIONS_VALUES = Object.freeze({ FINANCING: "FINANCING", CASH: "CASH" });

export const PAYMENT_CONDITIONS = [
  {
    label: "Financement",
    value: PAYMENT_CONDITIONS_VALUES.FINANCING,
    component: <FinancingForm />
  },
  {
    label: "Paiement comptant",
    value: PAYMENT_CONDITIONS_VALUES.CASH,
    component: <CashForm />
  }
];

export const PAYMENT_MODES = [
  { value: "cash", label: "Espèces" },
  { value: "cheque", label: "Chèque" },
  { value: "transfer", label: "Virement" },
  { value: "credit_card", label: "Carte bancaire" }
];

export const FINANCIAL_METHOD = Object.freeze({ PERCENTAGE: 1, HARD_VALUE: 2 });

const defaultFinancingForm = {
  depositMethod: FINANCIAL_METHOD.PERCENTAGE,
  deposit: "0",
  financingInstitution: "",
  instalmentNumber: "",
  instalmentAmount: "",
  monthlyCostInsurance: "",
  endWorkPayment: "",
  taeg: "",
  overdrawnRate: "",
  totalAmount: "",
  bookedNumber: true
};

const defaultCashForm = {
  depositMethod: FINANCIAL_METHOD.PERCENTAGE,
  deposit: "0"
};

const defaultPaymentMentions = {
  paymentTerms: "",
  paymentDelay: undefined
};

const defaultAdditionalInformation = {
  defined: [],
  extra: ""
};

const defaultDeliveryAddress = {
  businessName: "",
  lastname: "",
  firstname: "",
  phone: "",
  email: "",
  address: "",
  zipCode: "",
  city: ""
};

class NewInvoiceStore {
  @observable
  id = "";

  @observable
  number = "";

  @observable
  currency = "EUR";

  @observable
  nature = "";

  @observable
  comments = "";

  @observable
  details = [new Product()];

  @observable
  discount = "";

  @observable
  discountMethod = FINANCIAL_METHOD.PERCENTAGE;

  @observable
  limitDate = moment().add(250, "days");

  @observable
  client = new Client();

  @observable
  paymentCondition = PAYMENT_CONDITIONS[0].value;

  @observable
  paymentMode = PAYMENT_MODES[0].value;

  @observable
  financingForm = defaultFinancingForm;

  @observable
  cashForm = defaultCashForm;

  @observable
  order = null;

  @observable
  estimate = null;

  @observable
  optimus = [];

  @observable
  paymentMentions = defaultPaymentMentions;

  @observable
  additionalInformation = defaultAdditionalInformation;

  @observable
  deliveryAddress = defaultDeliveryAddress;

  @observable
  useSpecificDeliveryAddress = false;

  @action
  patchDeliveryAddress = address => {
    this.deliveryAddress = { ...this.deliveryAddress, ...address };
  };

  @action
  toggleUseSpecificDeliveryAddress = value => {
    this.useSpecificDeliveryAddress = value !== true && value !== false ? !this.useSpecificDeliveryAddress : value;
    this.patchDeliveryAddress({ ...this.client });
  };

  @action
  patchAdditionalInformation = value => {
    this.additionalInformation = { ...this.additionalInformation, ...value };
  };

  @action
  patchPaymentMentions = value => {
    this.paymentMentions = { ...this.paymentMentions, ...value };
  };

  @action
  init = () => {
    this.nature = "";
    this.currency = "EUR";
    this.details = [new Product()];
    this.discount = "";
    this.discountMethod = FINANCIAL_METHOD.PERCENTAGE;
    this.limitDate = moment().add(250, "days");
    this.client = new Client();
    this.paymentCondition = PAYMENT_CONDITIONS[0].value;
    this.paymentMode = PAYMENT_MODES[0].value;
    this.financingForm = defaultFinancingForm;
    this.cashForm = defaultCashForm;
    this.id = "";
    this.number = "";
    this.order = null;
    this.estimate = null;
    this.comments = "";
    this.optimus = [];
    this.additionalInformation = defaultAdditionalInformation;
    this.useSpecificDeliveryAddress = false;
    this.deliveryAddress = defaultDeliveryAddress;
  };

  @action
  initWithClient = client => {
    this.nature = "";
    this.currency = "EUR";
    this.details = [new Product()];
    this.discount = "";
    this.discountMethod = FINANCIAL_METHOD.PERCENTAGE;
    this.limitDate = moment().add(250, "days");
    this.client = new Client(client);
    this.paymentCondition = PAYMENT_CONDITIONS[0].value;
    this.paymentMode = PAYMENT_MODES[0].value;
    this.financingForm = defaultFinancingForm;
    this.cashForm = defaultCashForm;
    this.id = "";
    this.number = "";
    this.order = null;
    this.estimate = null;
    this.comments = "";
    this.optimus = [];
    this.additionalInformation = defaultAdditionalInformation;
    this.useSpecificDeliveryAddress = false;
    this.deliveryAddress = defaultDeliveryAddress;
  };

  @action
  initWithExistant = invoice => {
    const informations = JSON.parse(invoice.information);
    this.id = invoice.id;
    this.currency = invoice.currency || "EUR";
    this.number = invoice.number;
    this.nature = invoice.nature;
    this.details = informations.details.map(detail => ({
      ...detail,
      discount: detail.discount || 0,
      discountType: detail.discountType || "HT",
      tva: new Decimal(detail.tva).toNumber().toFixed(2)
    }));
    this.discount = invoice.discount;
    this.discountMethod = invoice.discountMethod;
    this.limitDate = informations.limitDate;
    if (invoice.contact) {
      this.client = new Client(invoice.contact);
    } else {
      this.client = {
        type: invoice.clientType,
        businessName: invoice.clientBusinessName || "",
        lastname: invoice.clientLastname || "",
        firstname: invoice.clientFirstname || "",
        phone: invoice.clientPhone || "",
        email: invoice.clientEmail || "",
        address: invoice.clientAddress || "",
        zipCode: invoice.clientZipCode || "",
        city: invoice.clientCity || ""
      };
    }
    this.comments = invoice.comments || "";
    this.optimus = informations.optimus || [];
    this.paymentCondition = invoice.paymentCondition;
    this.paymentMode = invoice.paymentMode;
    this.financingForm = informations.financingForm;
    this.cashForm = informations.cashForm;
    this.order = invoice.order;
    this.estimate = invoice.estimate;
    this.paymentMentions = {
      paymentTerms: invoice.paymentTerms || defaultPaymentMentions.paymentTerms,
      paymentDelay: invoice.paymentDelay !== undefined ? invoice.paymentDelay : defaultPaymentMentions.paymentDelay
    };
    this.additionalInformation = {
      defined: invoice.additionalInformation || defaultAdditionalInformation.defined,
      extra: invoice.extraAdditionalInformation || defaultAdditionalInformation.extra
    };
    this.useSpecificDeliveryAddress = Boolean(invoice.deliveryAddress);
    this.deliveryAddress = invoice.deliveryAddress || defaultDeliveryAddress;
  };

  @action
  saveDrawing = ({ index, drawing }) => {
    this.details = this.details.map((detail, indexIn) =>
      indexIn === index ? { ...detail, drawing: drawing ? drawing.imgWGrid : null } : detail
    );
  };

  @action
  updateOptimus = _optimus => {
    this.optimus = [..._optimus];
  };

  @action
  handleChange = event => {
    this[event.target.name] = event.target.value;
  };

  @action
  handleChangeLimitDate = date => {
    this.limitDate = date;
  };

  @action
  handleChangeCurrency = currency => {
    this.currency = currency;
  };

  @action
  handleChangeDiscountMethod = () => {
    if (this.discountMethod === FINANCIAL_METHOD.PERCENTAGE) {
      this.discountMethod = FINANCIAL_METHOD.HARD_VALUE;
    } else {
      this.discountMethod = FINANCIAL_METHOD.PERCENTAGE;
    }
  };

  @action
  handleChangeDepositMethod = () => {
    if (this.cashForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE) {
      this.cashForm.depositMethod = FINANCIAL_METHOD.HARD_VALUE;
    } else {
      this.cashForm.depositMethod = FINANCIAL_METHOD.PERCENTAGE;
    }
  };

  @action
  handleSwapDetails = ({ index, newIndex }) => {
    this.details = Array.from(this.details, (item, aIndex) => {
      if (index === aIndex) {
        return this.details[newIndex];
      }
      if (newIndex === aIndex) {
        return this.details[index];
      }
      return item;
    });
  };

  handleChangeFinancingFormDepositMethod = () => {
    if (this.financingForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE) {
      this.financingForm.depositMethod = FINANCIAL_METHOD.HARD_VALUE;
    } else {
      this.financingForm.depositMethod = FINANCIAL_METHOD.PERCENTAGE;
    }
  };

  @action
  handleChangeClient = event => {
    this.client[event.target.name] = event.target.value;
  };

  @action
  setClient = client => {
    this.client = client;
  };

  @action
  handleChangeFinancingForm = event => {
    this.financingForm[event.target.name] = event.target.value;
  };

  @action
  handleChangeCashForm = event => {
    this.cashForm[event.target.name] = event.target.value;
  };

  @action
  handleAddDetail = () => {
    this.details = this.details.concat([new Product()]);
  };

  @action
  handleChangeDetails = index => event => {
    const details = { ...this.details[index] };
    const { name, value } = event.target;
    details[name] = value;
    if (name === "price" && value <= 0) {
      details.discount = 0;
    }
    this.details[index] = details;
  };

  @action
  handleChangeDetailDiscount = (index, discount) => {
    const details = { ...this.details[index] };
    details.discount = discount;
    this.details[index] = details;
  };

  @action
  handleCustomFieldChange = ({ index, customFieldIndex }) => event => {
    const detail = this.details[index];
    const { customFields } = detail;
    customFields[customFieldIndex].value = event.target.value;
    this.details[index] = { ...detail, customFields };
  };

  @action
  getMaxDiscountForItem = index => {
    const item = this.details[index];
    if (item.discountType === "TTC") {
      return new Decimal(Number(item.price))
        .mul(Number(item.quantity))
        .mul(100 + Number(item.tva))
        .div(100)
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber();
    }
    return new Decimal(Number(item.price))
      .mul(Number(item.quantity))
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  };

  @action
  handleChangeDiscountType = index => (e, discountTypeIsTTC) => {
    this.details[index] = { ...this.details[index], discountType: discountTypeIsTTC === true ? "TTC" : "HT" };
  };

  @action
  handleChangeAutocomplete = (index, autocomplete) => {
    const { id, title, description, price, customFields } = autocomplete;
    const tva = TVAS_DROPDOWN.map(option => option.value).includes(autocomplete.tva) ? autocomplete.tva : "20.00";
    this.details[index] = { ...this.details[index], id, description, title, price, tva, customFields };
  };

  @action
  handleRemoveDetail = index => () => {
    if (this.details.length !== 1) {
      this.details = this.details.filter((i, ind) => index !== ind);
    } else {
      this.details = [new Product()];
    }
  };

  @action
  resetNewInvoice = () => {
    this.nature = "";
    this.details = [new Product()];
    this.discount = "";
    this.discountMethod = FINANCIAL_METHOD.PERCENTAGE;
    this.limitDate = moment().add(250, "days");
    this.client = new Client();
    this.paymentCondition = PAYMENT_CONDITIONS[0].value;
    this.paymentMode = PAYMENT_MODES[0].value;
    this.financingForm = {
      depositMethod: FINANCIAL_METHOD.PERCENTAGE,
      deposit: "0",
      financingInstitution: "",
      instalmentNumber: "",
      instalmentAmount: "",
      monthlyCostInsurance: "",
      endWorkPayment: "",
      taeg: "",
      overdrawnRate: "",
      totalAmount: ""
    };
    this.cashForm = {
      depositMethod: FINANCIAL_METHOD.PERCENTAGE,
      deposit: "0"
    };
    this.id = "";
    this.number = "";
  };

  @computed
  get isFirstDetailOk() {
    const { title, description, price, tva, quantity, customFields } = this.details[0];
    let customsOK = true;
    if (customFields && customFields.length > 0) {
      customFields.forEach(field => {
        if (field.required && field.value === "") {
          customsOK = false;
        }
      });
    }
    return title !== "" && description !== "" && price !== "" && tva !== "" && quantity !== "" && customsOK;
  }

  @computed
  get isNewInvoiceActionOK() {
    return this.details.every((value, index) => {
      const { title, discount, description, price, tva, quantity, customFields } = value;
      let customsOK = true;
      if (customFields && customFields.length > 0) {
        customFields.forEach(field => {
          if (field.required && field.value === "") {
            customsOK = false;
          }
        });
      }

      return (
        title !== "" &&
        description !== "" &&
        price !== "" &&
        tva !== "" &&
        quantity !== "" &&
        this.nature !== "" &&
        customsOK &&
        ((discount >= 0 && discount <= this.getMaxDiscountForItem(index)) || discount === 0) &&
        this.amount >= 0 &&
        this.amount - this.optimus.reduce((a, b) => a + parseFloat(b.amount), 0) >= 0
      );
    });
  }

  @computed
  get isNewInvoiceClientOK() {
    const { lastname, firstname, email, address, zipCode, city, type, phone } = this.client;

    return (
      lastname !== "" &&
      firstname !== "" &&
      email !== "" &&
      ValidatorService.validateEmail(email) &&
      address !== "" &&
      zipCode !== "" &&
      city !== "" &&
      type !== "" &&
      (phone ? ValidatorService.validatePhone(phone) : true)
    );
  }

  @computed
  get isNewInvoiceDeliveryAddressOK() {
    if (this.useSpecificDeliveryAddress) {
      const { lastname, firstname, email, address, zipCode, city, phone } = this.deliveryAddress;
      return (
        lastname !== "" &&
        firstname !== "" &&
        email !== "" &&
        ValidatorService.validateEmail(email) &&
        address !== "" &&
        zipCode !== "" &&
        city !== "" &&
        (phone ? ValidatorService.validatePhone(phone) : true)
      );
    }
    return this.isNewInvoiceClientOK;
  }

  @computed
  get isNewInvoicePaymentMentionsOK() {
    const { paymentTerms, paymentDelay } = this.paymentMentions;
    return paymentTerms !== "" && paymentDelay !== undefined && paymentDelay >= 0;
  }

  @computed
  get numberDetails() {
    return this.details.length;
  }

  /**
   * Get the total price of the current Invoice including TVA (Taxe Valeur Ajoutée)
   */
  @computed
  get totalPriceWithTVA() {
    const pricesWithTVA = this.details.map(detail => this.priceWithTVA(detail));

    return pricesWithTVA.reduce((accumulator, price) => Number(accumulator) + Number(price));
  }

  /**
   * Get the total price of the current Invoice without TVA (Taxe Valeur Ajoutée)
   */
  @computed
  get totalPriceWithoutTVA() {
    const pricesWithoutTVA = this.details.map(detail => this.priceWithoutTVA(detail));

    return pricesWithoutTVA.reduce((accumulator, price) => Number(accumulator) + Number(price));
  }

  @computed
  get amount() {
    return new Decimal(Number(this.totalPriceWithTVA))
      .sub(Number(this.computedDiscount))
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2);
  }

  /**
   * Compute the discount based on discount method used.
   *
   * * If the discount method is PERCENTAGE, we need to compute the discount by
   * * multiplying the total price including taxes and the discount, then divide
   * * it by 100.
   *
   * * Else if the discount method is HARD_VALUE, we just need to return the
   * * value
   */
  @computed
  get computedDiscount() {
    if (this.discountMethod === FINANCIAL_METHOD.PERCENTAGE) {
      return new Decimal(Number(this.totalPriceWithTVA))
        .mul(Number(this.discount))
        .div(100)
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(2);
    }

    return Number(this.discount).toFixed(2);
  }

  @computed
  get computedDeposit() {
    if (this.cashForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE) {
      return new Decimal(Number(this.amount))
        .mul(Number(this.cashForm.deposit))
        .div(100)
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(2);
    }

    return Number(this.cashForm.deposit).toFixed(2);
  }

  @computed
  get computedCashFormBalance() {
    return Number(this.amount - this.computedDeposit).toFixed(2);
  }

  @computed
  get computedFinancingFormDeposit() {
    if (this.financingForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE) {
      return new Decimal(Number(this.amount))
        .mul(Number(this.financingForm.deposit))
        .div(100)
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(2);
    }

    return Number(this.financingForm.deposit).toFixed(2);
  }

  @computed
  get computedFinancingFormBalance() {
    return Number(this.amount - this.computedFinancingFormDeposit).toFixed(2);
  }

  /**
   * The price without taxes is computed by multiplying price and quantity.
   */
  priceWithoutTVA = detail => {
    const { discount, discountType, price, quantity, tva } = detail;
    if (discountType === "HT" || discount === 0 || discount === "") {
      return new Decimal(Number(price))
        .mul(Number(quantity))
        .minus(Number(discount || 0))
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(2);
    }
    // We need to get the w/ vates amount before all
    return new Decimal(Number(price))
      .mul(Number(quantity))
      .mul((100 + Number(tva)) / 100)
      .minus(Number(discount || 0))
      .div((100 + Number(tva)) / 100)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2);
  };

  priceWithTVA = detail => {
    const { tva } = detail;
    return new Decimal(this.priceWithoutTVA(detail))
      .mul(Number(tva))
      .div(100)
      .add(this.priceWithoutTVA(detail))
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2);
  };

  @computed
  get selectedPaymentMethod() {
    return PAYMENT_CONDITIONS.find(condition => condition.value === this.paymentCondition).component;
  }
}

export default new NewInvoiceStore();
