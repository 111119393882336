import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";

export const FRANFINANCE_EQUIPMENT_CODES = {
  "062": "Adoucisseurs",
  "058": "Alarme",
  "067": "Aménagement combles",
  "055": "Aménagement jardin",
  "099": "Autres",
  "043": "Chauffage",
  "054": "Chauffage solaire",
  "042": "Cheminée",
  "056": "Clôtures",
  "052": "Conditionnement air",
  "045": "Couverture/charpente",
  "041": "Cuisine",
  "040": "Divers habitat",
  "066": "Fenêtres",
  "047": "Isolation",
  "071": "Photovoltaïque",
  "057": "Piscines",
  "053": "Pompes à chaleur",
  "068": "Portail/porte blindé",
  "048": "Protection charpente",
  "046": "Ravalement",
  "050": "Revêtement de murs",
  "049": "Revêtements de sols",
  "061": "Vérandas"
};

export default function FranfinanceEquipmentCodeSelect({ value, onChange }) {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Code matériel</InputLabel>
      <Select
        id="equipmentCode"
        value={value}
        onChange={e => onChange(e.target.value)}
        input={<OutlinedInput label="Code matériel" />}
        displayEmpty
        // renderValue={selected => (
        //   <div className={classes.chipContainer}>
        //     {selected.map(value => (
        //       <div key={value} className={classes.chip}>
        //         <EstimateStatusLabel estimate={{ status: value }} />
        //       </div>
        //     ))}
        //   </div>
        // )}
        // MenuProps={MenuProps}
      >
        <MenuItem value="" disabled>
          <em> -- Sélectionnez un code matériel --</em>
        </MenuItem>
        {Object.entries(FRANFINANCE_EQUIPMENT_CODES).map(([equipmentCode, equipmentCodeLabel]) => (
          <MenuItem key={equipmentCode} value={equipmentCode}>
            {equipmentCodeLabel} ({equipmentCode})
          </MenuItem>
        ))}
      </Select>
      <FormHelperText> </FormHelperText>
    </FormControl>
  );
}
