import React from "react";
import { Button, Typography } from "@material-ui/core";

const CompanyLightRow = ({ company, handleSelect }) => {
  const handleClick = e => {
    e.stopPropagation();
    handleSelect(company);
  };

  return (
    <div
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: "1px solid #E2E2E2",
        paddingRight: 15,
        paddingLeft: 15
      }}
    >
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row"
        }}
      >
        <div style={{ flex: 2, alignItems: "center" }}>
          <Typography variant="h6">
            <span className="noselect">{company.name}</span>
          </Typography>
          <Typography variant="subtitle1">
            {company.zipCode} {company.city}
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Sélectionner
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyLightRow;
