import React from "react";
import CompanyForm from "../../components/form/CompanyForm";
import AdminBreadcrumb from "../../components/admin/AdminBreadcrumb";
import { superAdmin } from "../../routes";

export default function SuperAdminAddCompanyPage({ match }) {
  return (
    <div>
      <AdminBreadcrumb routes={superAdmin} match={match} />
      <CompanyForm />
    </div>
  );
}
