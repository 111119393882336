import React, { useEffect, useRef, useState } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";

import ClientService from "../../services/ClientService";
import LightRow from "./LightRow";

let timeoutSearch;

export default function ClientsListLight({ compact, handleSubmit, clientIdDisabled }) {
  const [focusedClient, setFocusedClient] = useState(null);
  const [clients, setClients] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastPageFetched, setLastPageFetched] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [q, setQ] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const listEnd = useRef(null);

  const fetchClients = (resetState = false) => {
    if (!isRequesting) {
      setIsRequesting(true);
      ClientService.getClients({ page: lastPageFetched > 0 ? lastPageFetched + 1 : 0, filter: { q } })
        .then(res => {
          if (resetState || clients === null) {
            setClients([...res.contacts]);
          } else {
            setClients([...clients, ...res.contacts]);
          }
          setIsRequesting(false);
          setIsLoadingMore(false);
          setLastPageFetched(lastPageFetched ? lastPageFetched + 1 : 0);
          setTotalCount(res.totalCount);
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la récupération des clients", {
            variant: "error"
          });
          setClients([]);
          setIsRequesting(false);
        });
    }
  };

  useEffect(() => {
    fetchClients(true);
  }, []);

  const isInViewport = (offset = 0) => {
    if (!listEnd) return false;
    const bouncingC = listEnd.current.getBoundingClientRect();
    return bouncingC.top + offset >= 0 && bouncingC.top - offset <= window.innerHeight;
  };

  const handleScroll = () => {
    if (isInViewport() && !isRequesting && totalCount > clients.length) {
      setIsLoadingMore(true);
      fetchClients();
    }
  };

  const handleQueryChange = e => {
    const {
      target: { value }
    } = e;
    setQ(value);
    clearTimeout(timeoutSearch);
    timeoutSearch = setTimeout(() => {
      setLastPageFetched(null);
      fetchClients(true);
    }, 500);
  };

  const handleClientFocus = clientId => {
    setFocusedClient(clientId === focusedClient ? null : clientId);
  };

  return (
    <div>
      {clients || q ? (
        <div>
          {clients.length >= 0 ? (
            <div>
              <TextField
                style={{ marginTop: compact ? 5 : 30, marginBottom: compact ? 5 : 30 }}
                onClick={e => e.stopPropagation()}
                name="qClients"
                placeholder="Recherche"
                label="Recherche"
                margin="normal"
                fullWidth
                value={q}
                autoComplete="off"
                variant="outlined"
                onChange={handleQueryChange}
              />
              <div
                style={{ height: compact ? "50vh" : "45vh", overflowX: "hidden", overflowY: "auto" }}
                onScroll={handleScroll}
              >
                {clients.length > 0 ? (
                  clients.map(client => (
                    <LightRow
                      client={client}
                      key={client.id}
                      showDetails={focusedClient === client.id}
                      handleShowDetails={handleClientFocus}
                      disabled={clientIdDisabled.includes(client.id)}
                      handleSelect={arg => {
                        if (!clientIdDisabled.includes(client.id)) handleSubmit({ ...arg });
                      }}
                    />
                  ))
                ) : (
                  <div>Aucun résultat pour la recherche</div>
                )}
                <div ref={listEnd}>
                  {isLoadingMore && (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                      <CircularProgress size={40} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p>Vous n&apos;avez aucun client dans votre annuaire</p>
            </div>
          )}
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
          <CircularProgress size={80} />
        </div>
      )}
    </div>
  );
}
