import React from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";

export default function ArrayField({ name, title, addLabel, inputProps = {} }) {
  const { handleChange, values, handleBlur } = useFormikContext();
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <div>
          <Typography gutterBottom variant="subtitle1">
            {title}
          </Typography>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 8 }}>
            {values[name] &&
              values[name].length > 0 &&
              values[name].map((row, index) => (
                <div key={`${index + 1}`} style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                  <TextField
                    fullWidth
                    id={`${name}.${index}`}
                    name={`${name}.${index}`}
                    value={row}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    size="small"
                    {...inputProps}
                  />
                  <Button size="small" variant="outlined" color="primary" onClick={() => arrayHelpers.remove(index)}>
                    <DeleteIcon />
                  </Button>
                </div>
              ))}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => arrayHelpers.push("")}
              style={{ alignSelf: "center", marginTop: 8 }}
            >
              {addLabel}
            </Button>
          </div>
        </div>
      )}
    />
  );
}
