import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { withStyles } from "@material-ui/styles";

import AdditionalDocumentService from "../../services/AdditionalDocumentService";
// import { additionalDocumentLinkedDocumentToText } from "../../utils/textFormat";
import WorksAcceptanceForm from "./WorksAcceptanceForm";
import VatDeclarationForm from "./VatDeclarationForm";

const styles = theme => ({
  container: { padding: theme.spacing(2) }
});

function EditAdditionalDocumentContainer({ additionalDocumentId, classes, handleCancel, onPatchSuccess }) {
  const { isFetching, data, error } = useQuery({
    queryKey: ["additional_document", { additionalDocumentId }],
    queryFn: () => AdditionalDocumentService.getAdditionalDocument(additionalDocumentId),
    refetchOnWindowFocus: false,
    cacheTime: 50000,
    staleTime: 50000,
    retry: (failureCount, error) => error.status !== 401
  });

  if (isFetching) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.container} variant="h4">
            Document additionnel
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography>Une erreur est survenue lors de la récupération du document additionnel</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (data.type === "WORKS_ACCEPTANCE") {
    return (
      <WorksAcceptanceForm additionalDocument={data} handleCancel={handleCancel} onPatchSuccess={onPatchSuccess} />
    );
  }

  if (data.type === "VAT_DECLARATION") {
    return <VatDeclarationForm additionalDocument={data} handleCancel={handleCancel} onPatchSuccess={onPatchSuccess} />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.container} variant="h4">
          Document additionnel
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Paper>
            {/* <WorksAcceptanceForm additionalDocument={data} /> */}
            Aucun contenu
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(EditAdditionalDocumentContainer);
